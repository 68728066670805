const ChristianAboutData = {
    address: "Thorapadi Post, Bagayam, Vellore, Tamil Nadu",
    city: "Vellore",
    state: "Tamil Nadu",
    email: "princi@cmcvellore.ac.in",
    established: "1942",
    yearfirst: "1947",
    headoftheinstitution: "Dr Solomon Sathishkumar",
    designation: "Principal",
    institutetype: "Private",
    website: "www.cmch-vellore.edu",
    designation2:"Director",
    institutetype2:"Government",
    antiraggingcell: "Yes",
    nationalserviceschemecell: "Yes",
    internalcomplaintscommitteecell: "Yes",
    internalqualityassurancecell: "Yes",
    totalnumberofmbbs: "4330",
    
  
  };
  export default ChristianAboutData;
  