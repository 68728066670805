import React, { useEffect } from 'react'
import Navbar from '../../home/Header/Navbar';



import Footer from '../../home/Footer/footer'
import ExamDetail from './ExamDetails';

const ExamDetailPage = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Navbar />
            <ExamDetail />
            <Footer padding={"-70px"} margintop={"-20px"} />
        </>
    )
}

export default ExamDetailPage