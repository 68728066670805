/* eslint-disable jsx-a11y/img-redundant-alt */
import { Typography, Box, Grid, } from "@mui/material";
import React from "react";
// import Vector from "../../assets/Vector 1.png"; // Make sure to import your Vector image
import Group from "../../../assets/CareerCounseling/Group 15.png";

const MyComponent = () => {
  return (
    <Grid container>
      <Grid item lg={12} xs={12} sm={12}>
        <Box
          className="CareerCounseling"
          sx={{ marginTop: { lg: "5%", md: "15rem", sm: "20rem", xs: "10rem" } }}
        >
          <img
            src={Group}
            alt="Vector image"
            width="58.5%"
            sx={{ display: "flex" }}
          />
          <Box
            sx={{
              position: "absolute",
              top: { lg: "67%", sm: "50%", xs: "40%" },
              left: { lg: "40%", xs: "52%" },
              transform: "translate(-50%, -50%)",
              textAlign: "start",
            }}
          >
            <Typography
              className="box1"
              sx={{
                fontSize: { xl: "5rem", lg: "4rem", sm: "4rem", xs: "3rem" },
                color: "#EF5835",
                fontWeight: "600",
                textAlign: {
                  xl: "start",
                  lg: "start",
                  md: "start",
                  sm: "center",
                  xs: "center",
                },
              }}
            >
              Career <span style={{ color: "black" }}>Counseling</span>
            </Typography>

            <Typography
              mt={5}
              className="box1"
              sx={{
                fontSize: { xl: "32px", lg: "35px", sm: "25px", xs: "20px" },
                left: "2%",
                fontWeight: "450",
              }}
            >
              Develop <span style={{ fontWeight: "700" }}> Clarity,</span>{" "}
              Discover <span style={{ fontWeight: "700" }}>Confidence,</span>{" "}
              Experience <span style={{ fontWeight: "700" }}>Success</span>
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default MyComponent;
