const AbbsPlacements = [
    {

        data: [
            { title: "Total number of domestic placements (2021-22)", value: "5" },
            { title: "Number of pre-placement offers in 2021-22", value: "0" },
            { title: "Number of companies that offered jobs (2021-22)", value: "15" },
            { title: "Highest salary", value: "3,20,000" }
        ]
    },
    {

        data: [
            { title: "Average salary", value: "2,40,000" },
            { title: "Percentage of students placed (2021-22)", value: "9%" },
            { title: "Number of students who completed their BBA degrees within the stipulated timeline (2021-22)", value: "11" },
            { title: "Percentage of companies that offered jobs (2021-22)", value: "88.6%" }
        ]
    },
    {

        data: [
            { title: "Number of students who opted for entrepreneurship (2021-22)", value: "4" },
            { title: "Number of students who opted for higher studies (postgraduate) (2021-22)", value: "" }
        ]
    },

];

export default AbbsPlacements;
