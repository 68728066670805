import React from "react";
import {
  Grid,
  Button,
  Box,
  Typography,
  Container,
  TextField,
} from "@mui/material";
import Registration from "../../../assets/Group 77.png";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { styled } from "@mui/system";
import emailjs from "emailjs-com";

// Create a Yup validation schema
const validationSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  username: yup.string().required("Username is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  phoneNumber: yup.string().required("Phone number is required"),
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Confirm password is required"),
});

const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    borderRadius: "12px",
    height: "40px",
  },
});

const Register = () => {
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    console.log(data);

    // Prepare email data
    const emailData = {
      name: data.name,
      username: data.username,
      email: data.email,
      phoneNumber: data.phoneNumber,
    };

    // Send data to EmailJS
    emailjs.send('service_7of6qrh', 'template_4p6dhtv', emailData, 'AmoRn02wurxHiUQqO')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
      })
      .catch((err) => {
        console.log('FAILED...', err);
      });
  };

  return (
    <Grid container spacing={2}>
      {/* Left side */}
      <Grid item xs={12} md={6}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <img src={Registration} alt="register" width="100%" />
        </Box>
      </Grid>

      {/* Right side */}
      <Grid item xs={12} md={6} sx={{ mt: { xs: 4, md: -2 } }}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
          sx={{ mt: { md: "-1" } }}
        >
          <Container maxWidth="sm">
            <Box padding="1rem" width="90%">
              <form onSubmit={handleSubmit(onSubmit)}>
                <Typography
                  variant="h4"
                  mb={3}
                  textAlign="center"
                  sx={{ fontFamily: "Montserrat", marginLeft: "20px" }}
                >
                  <span style={{ fontWeight: "700", color: "#FF6B00" }}>
                    Register{" "}
                  </span>
                </Typography>
                <Box mb={2}>
                  <Typography
                    variant="body1"
                    component="label"
                    htmlFor="name"
                    sx={{ fontFamily: "Nonito" }}
                  >
                    Name
                  </Typography>
                  <CustomTextField
                    {...register("name")}
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    placeholder="Eg. John Doe"
                    error={!!errors.name}
                    helperText={errors.name?.message}
                  />
                </Box>
                <Box mb={2}>
                  <Typography
                    variant="body1"
                    component="label"
                    htmlFor="username"
                    sx={{ fontFamily: "Nonito" }}
                  >
                    Username
                  </Typography>
                  <CustomTextField
                    {...register("username")}
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    placeholder="JohnDoe123"
                    error={!!errors.username}
                    helperText={errors.username?.message}
                  />
                </Box>

                <Box mb={2}>
                  <Typography
                    variant="body1"
                    component="label"
                    htmlFor="email"
                    sx={{ fontFamily: "Nonito" }}
                  >
                    Email
                  </Typography>
                  <CustomTextField
                    {...register("email")}
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    placeholder="JohnDo123@email.com"
                    error={!!errors.email}
                    helperText={errors.email?.message}
                  />
                </Box>
                <Box mb={2}>
                  <Typography
                    variant="body1"
                    component="label"
                    htmlFor="phoneNumber"
                    sx={{ fontFamily: "Nonito" }}
                  >
                    Phone Number
                  </Typography>
                  <CustomTextField
                    {...register("phoneNumber")}
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    placeholder="1234567890"
                    error={!!errors.phoneNumber}
                    helperText={errors.phoneNumber?.message}
                  />
                </Box>
                <Box mb={2}>
                  <Typography
                    variant="body1"
                    component="label"
                    htmlFor="password"
                    sx={{ fontFamily: "Nonito" }}
                  >
                    Password
                  </Typography>
                  <CustomTextField
                    {...register("password")}
                    type="password"
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    placeholder="**************"
                    error={!!errors.password}
                    helperText={errors.password?.message}
                  />
                </Box>
                <Box mb={2}>
                  <Typography
                    variant="body1"
                    component="label"
                    htmlFor="confirmPassword"
                    sx={{ fontFamily: "Nonito" }}
                  >
                    Confirm Password
                  </Typography>
                  <CustomTextField
                    {...register("confirmPassword")}
                    type="password"
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    placeholder="**************"
                    error={!!errors.confirmPassword}
                    helperText={errors.confirmPassword?.message}
                  />
                </Box>
                <Box mt={4} textAlign="center" sx={{ borderRadius: "25px" }}>
                  <Button
                    type="submit"
                    variant="outlined"
                    sx={{
                      marginLeft: "20px",
                      fontFamily: "Nonito",
                      color: "black",
                      width: "60%",
                      borderRadius: "25px",
                      padding: "10px 20px",
                      WebkitBackgroundClip: "text",
                      backgroundClip: "text",
                      borderImage:
                        "linear-gradient(to right, #F125DC, #FF9900) ",
                      borderImageSlice: "2",
                      borderWidth: "3px",
                      "&:hover": {
                        background: "none",
                        color: "black",

                        borderWidth: "3px",
                      },
                    }}
                  >
                    Register
                  </Button>
                </Box>
              </form>
            </Box>
          </Container>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Register;
