import { Box, Container, Tabs, Tab, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ExamDetailContent from "../../EntranceExam/ExamDetails/ExamDetailContent";

import SingleHeaderDynamicTable from "../../../components/TableComponent/SingleheaderTable";

import StripHeaderComponent from "../../../components/StripHeadercomponent";
import StripTitle from "../../../components/StripTitle";

import AbbsAboutData from "../../../json/CollegeDetailsJson/AbbsCollegeJson/ABBSAboutData";
import AbbsAboutHeader from "../../../json/CollegeDetailsJson/AbbsCollegeJson/AbbsAboutHeader";
import AbbsAdmissionContent from "../../../json/CollegeDetailsJson/AbbsCollegeJson/AbbsAdmissionContent";
import AbbsFeeData from "../../../json/CollegeDetailsJson/AbbsCollegeJson/AbbsFeesData";
import AbbsFeeHeader from "../../../json/CollegeDetailsJson/AbbsCollegeJson/AbbsFeesHeader";
import AbbsFurtherEducationData from "../../../json/CollegeDetailsJson/AbbsCollegeJson/AbbsFurtherEducationData";
import AbbsFurtherEducationHeader from "../../../json/CollegeDetailsJson/AbbsCollegeJson/AbbsFurtherEducationHeader";
import AbbsPlacements from "../../../json/CollegeDetailsJson/AbbsCollegeJson/AbbsPlacementsData";
import AbbsInternship from "../../../json/CollegeDetailsJson/AbbsCollegeJson/AbbsInternshipData";
import AbbsRankings from "../../../json/CollegeDetailsJson/AbbsCollegeJson/AbbsRankinng";

const AbbsCollegeTabDetails = () => {
    const [selectedTab, setSelectedTab] = useState(0);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    return (
        <>
            <Box sx={{ backgroundColor: "#252525" }}>
                <Container>
                    <Box sx={{ width: "100%" }}>
                        <Tabs
                            value={selectedTab}
                            onChange={handleTabChange}
                            variant="scrollable"
                            scrollButtons
                            allowScrollButtonsMobile
                            aria-label="scrollable auto tabs example"
                            sx={{
                                fontSize: "13px",
                                // mt: "40px",
                                "& .MuiTab-root": {
                                    // fontSize: "13px",
                                    color: "#898383",

                                    "&.Mui-selected": {
                                        color: "#EF5835",
                                    },
                                },
                                "& .MuiTabs-indicator": {
                                    display: "none",
                                },
                                "& .MuiTabs-flexContainer": {
                                    display: "flex",
                                    justifyContent: "space-around",
                                },
                                "& .MuiTabScrollButton-root": {
                                    color: "#ffffff", // Change color of scroll buttons to white
                                },
                            }}
                        >
                            <Tab label="About the College" />
                            <Tab label="Admission" />
                            <Tab label="Courses and Fee" />
                            <Tab label="Placements" />
                            <Tab label="Rankings" />
                        </Tabs>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-evenly",
                                flexWrap: "wrap",
                            }}
                        >
                            {selectedTab === 0 && (
                                <ExamDetailContent
                                    color={"#ffffff"}
                                    showHeader={true}
                                    header={"About the College"}
                                    headerContent={
                                        "Located off Magadi Road in Shushruti Nagar in Bengaluru’s Lingadeeranahalli area, Acharya Bangalore B-School’s campus stands about 45 kilometres from Kempegowda International Airport. The college offers housing to male and female students, as well as facilities for various sports - these include basketball, throwball, cricket, volleyball, table tennis, badminton and football"
                                    }
                                    title={"Details"}
                                    DynamicTabledata={AbbsAboutData}
                                    DynamicTableheader={AbbsAboutHeader}
                                    gapcolor={"#252525"}
                                />
                            )}
                            {selectedTab === 1 && (
                                <Box sx={{ width: "100%" }}>
                                    <Box sx={{ padding: "10px 0px" }}>
                                        {AbbsAdmissionContent?.map((item) => (
                                            <StripHeaderComponent
                                                data={item}
                                                margin={"10px"}
                                                color={"#ffffff"}
                                            />
                                        ))}
                                    </Box>
                                    {/* <StripTitle title={"Cut Off:"} />
                                    {IITDelhiAdmission?.map((item) => (
                                        <HeaderDynamicTable
                                            data={item}
                                            margin={"10px"}
                                            showheader3={false}
                                            header1={"Branch"}
                                            header2={"IIT Delhi JEE Advanced Cutoff"}
                                            subheader1={"Opening rank 2021"}
                                            subheader2={"Opening rank 2020"}
                                        />
                                    ))} */}
                                </Box>
                            )}
                            {selectedTab === 2 && (
                                <>
                                    <Box
                                        sx={{
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            width: "100%",
                                        }}
                                    >
                                        <ExamDetailContent
                                            color="#ffffff"
                                            title={"Further Education"}
                                            DynamicTabledata={AbbsFurtherEducationData}
                                            DynamicTableheader={AbbsFurtherEducationHeader}
                                        />
                                        <ExamDetailContent
                                            color="#ffffff"
                                            title={"Fees"}
                                            DynamicTabledata={AbbsFeeData}
                                            DynamicTableheader={AbbsFeeHeader}
                                        />
                                    </Box>
                                </>
                            )}
                            {selectedTab === 3 && (
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        width: "100%",
                                    }}
                                >
                                    <StripTitle title={"Placements"} />
                                    {AbbsPlacements?.map((item) => (
                                        <SingleHeaderDynamicTable
                                            data={item}
                                            margin={"10px"}
                                            showheader={false}
                                        />
                                    ))}
                                    <StripTitle title={"Internships"} />
                                    {AbbsInternship?.map((item) => (
                                        <SingleHeaderDynamicTable
                                            data={item}
                                            margin={"10px"}
                                            showheader={false}
                                        />
                                    ))}

                                </Box>
                            )}
                            {selectedTab === 4 && (
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        width: "100%",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            mt: "20px",
                                            mb: "20px",
                                        }}
                                    >
                                        <Typography
                                            variant="h6"
                                            sx={{
                                                color: "#ffffff",
                                                fontWeight: "550",
                                                fontSize: "16px",
                                                position: "relative",
                                                "&::before": {
                                                    content: "''",
                                                    position: "absolute",
                                                    left: "-10px",

                                                    width: "5px",
                                                    height: "20px",
                                                    backgroundColor: "#EF5835",
                                                },
                                            }}
                                        >
                                            {"Rankings"}
                                        </Typography>
                                    </Box>
                                    {AbbsRankings?.map((item) => (
                                        <SingleHeaderDynamicTable
                                            data={item}
                                            margin={"10px"}
                                            headercolor={"#ffffff"}
                                        />
                                    ))}
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default AbbsCollegeTabDetails;
