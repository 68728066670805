const AiletEligibilityDataJson = {
    eligbilitycriteria: ["Candidates who have completed 10+2 with Senior Secondary System or equivalent examination from a recognized board. They should score a minimum of 45% per cent marks (40% in case of SC/ST/PwD candidates) in the qualifying examination",

        "Candidates appearing in the qualifying exam (Class XII or equivalent) can also apply for AILET 2022.However, their elgiblity should be determined by the date of result of AILET. In case a candidate is placed in compartment he/she will be considered as incomeptent",

        "Foreign National Candidates are exempted from taking All India Law Entrance Test (AILET). The other regulations regarding the eligibility for admission will be the same as applicable to the other candidates. The candidates belonging to OCI/PIO category shall have the option to apply either under the Foreign National category or under the category of Indian students. In case the OCI/PIO candidate apply under the category of Indian National, they have to appear in the All India Law Entrance Test along with other candidates"],

        qualifyingexam: ["10+2 with Senior Secondary System or equivalent examination from recognized board"],

        agelimit: ["No age limit"],

        totalnumberofattempts: ["No bar on attempts"],
        
        subjectcombination: ["The examination is open for students from all stream i.e., Science, Commerce, Humanities"],

        reservationofseats: [
        "SC 15%",
        "ST 7.5%",
        "OBC-NCl 22%",
        "EWS 10%",
        "PWD 5% (horizontal resevation)",
        "Kashmiri Migrants 1 Supernumerary seat",
        "Residents of Jammu and Kashmir 3",
        "Supernumerary seats",
    ],

};
export default AiletEligibilityDataJson;
