const AmityIncubation = [
    {

        data: [
            { title: "Does the university have an Incubation centre?", value: "Yes" },
            { title: "Number of companies set up in the past five years at the incubation centre", value: "50" }


        ]
    },


];

export default AmityIncubation;
