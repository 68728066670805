const NeetEligibilityDataJson = {
    eligbilitycriteria: ["Provided also that to be eligible for NEET (UG) , the candidate must have passed in Physics, Chemistry, Biology/Biotechnology and English individually and must have obtained a minimum of 50% marks taken together in Physics, Chemistry and Biology/Biotechnology at the qualifying examination as mentioned in the Regulations of NMC and DCI. In addition, they must have got a rank in the merit list of NEET (UG) for admission to Undergraduate Medical Courses",
        "In respect of the candidates belonging to Scheduled Castes, Scheduled Tribes or Other Backward Classes (NCL), the minimum marks obtained in Physics, Chemistry and Biology/Biotechnology taken together in qualifying examination shall be 40% marks instead of 50% marks for Unreserved and General-EWS Candidates. In respect of PwBD candidates as per NMC and DCI Regulations, the minimum marks in the qualifying examination in Physics, Chemistry and Biology (or Botany & Zoology)/Biotechnology taken together shall be 40% instead of 50%",
        "Provided further that the students who have received education abroad and wish to seek admission into medical colleges in India must have passed in Physics, Chemistry, Biology/Biotechnology and English with 50% marks in class 12 and their equivalency determined by the Association of Indian Universities (AIU) as per regulations of the Medical Council of India and the concerned University. Non-Resident Indians (NRIs), Overseas Citizen of India (OCI), and Foreign Nationals are eligible for admission in Medical /Dental Colleges subject to rules and regulations framed by the respective State Governments, Institutions and Government of India, as the case may be. The NRI and OCI Applicants / Candidates have to upload a Certificate from the concerned Indian Diplomatic Mission in the country of their residence in support of their claim as an NRI / OCI Candidate, along with their online application form for NEET (UG) 2021 and retain the original of such document for producing it at the time of Counselling / Admission to the Courses covered under NEET (UG). Similarly, a foreign applicant for NEET (UG) shall also upload Documentary Proof (e.g. relevant pages of a valid passport or a Certificate from Competent Authority in the country of his/her nationality regarding his/her citizenship status) in support of his / her Nationality, along with online application form",
        "the students of UT of Ladakh shall be eligible for admission to 15% of All India Quota seats"],

        qualifyingexam: ["CODE: 01 A candidate who is appearing in the qualifying examination, i.e., 12 Standard in 2021, whose result is awaited, may apply and appear in the test but he/she shall not be eligible for admission to the Undergraduate Medical Courses if he/she does not pass the qualifying examination with the required pass percentage at the time of first round of Counselling. Candidates who have appeared or are appearing at the qualifying Examination with English, Physics, Chemistry and Biology as the main subject and expect to pass the Examination with the required percentage of marks are also eligible to apply and appear in the Competitive Entrance Examination. However, their candidature will be considered only if they provide documentary evidence of having passed the qualifying Examination with the required subjects and percentage of marks. OR",
       "CODE: 02 The Higher/Senior Secondary Examination or the Indian School Certificate Examination which is equivalent to 10+2 Higher/Senior Secondary Examination after a period of 12 years study, the last two years of such study comprising of Physics, Chemistry, Biology/Bio-technology (which shall include practical tests in these subjects) and Mathematics or any other elective subject with English at a level not less than the core course for English as prescribed by the National Council of Educational Research and Training after the introduction of the 10+2+3 educational structure as recommended by the National Committee on Education. Candidates who have passed 10+2 from Open School or as private candidates shall not be eligible to appear for the ‘National Eligibility Cum Entrance Test’. Furthermore, a study of Biology/Biotechnology as an Additional Subject at 10+2 level also shall not be permissible. (The candidates who have passed 10+2 level with Biology as an additional subject will also be eligible for MBBS Entrance Examination . If the institute has to consider an examination of an Indian University or of a foreign/university to be equivalent to the 12 Class under 10+2 scheme/intermediate science examination, the candidates shall have to produce a certificate from the concerned Indian University/Association of Indian Universities to the effect that the examination passed by him/her is considered to be equivalent to the 12 class under 10+2 scheme/intermediate science examination. Therefore, the candidature of candidates in the NEET (UG)-2021 who have passed the qualifying examinations i.e. 10+2 from National Institute of Open Schooling or State Open Schools or as private candidates from recognised State Boards; or with Biology/Biotechnology as an additional subject shall be allowed but subject to the outcome of Special Leave Petitions/Appeals filed by the Medical Council of India)",
       "OR",
       "CODE: 03 The Intermediate/Pre-degree Examination in Science of an Indian University/Board or other recognised examining body with Physics, Chemistry, Biology /Biotechnology (which shall include practical test in these subjects) and also English as a compulsory subject.",
       "OR",
       "CODE: 04 The Pre-professional/Pre-medical Examination with Physics, Chemistry, Biology/Biotechnology and English after passing either the Higher Secondary Examination or the Pre-University or an equivalent examination. The Preprofessional/Pre-medical examination shall include practical tests in these subjects and also English as a compulsory subject",
       " OR",
       "CODE: 05 The first year of the three years’ degree course of a recognised University with Physics, Chemistry and Biology/Biotechnology including practical tests in these subjects provided the examination is a University Examination and candidate has passed the earlier qualifying examination with Physics, Chemistry, Biology/ Biotechnology with English at a level not less than a core course.",
       " OR",
       "CODE: 06 B.Sc. Examination of an Indian University provided that he/she has passed the B.Sc. Examination with not less than two of the subjects Physics, Chemistry, Biology (Botany, Zoology)/Biotechnology and further that he/ she has passed the earlier qualifying examination with Physics, Chemistry, Biology and English.",
       "OR",
       "CODE: 07 Any other examination which in scope and standard (Last 02 years of 10+2 Study comprising of Physics, Chemistry and Biology/Biotechnology; which shall include practical test in these subjects) is found to be equivalent to the Intermediate Science Examination of an Indian University/Board, taking Physics, Chemistry and Biology/Biotechnology including practical tests in each of these subjects and English"],

        agelimit: ["He/she has completed 17 years of age at the time of admission or will complete that age on or before 31 December of the year of his/her admission to the first year Undergraduate Medical Course. Accordingly, the lower age limit should be born on or before 31.12.2005",
            "No upper age limit"],

        totalnumberofattempts: ["No limit"],
        
        subjectcombination: ["Physics, Chemistry, Biology"],

        reservationofseats: [
            "S.C.- 15%",
            "S.T.- 7.5%",
            "PwBD- 5% Horizontal Reservation",
            "OBC (Non- Creamy Layer)- 27 % Only in National", "Institutes/Central Universities",
            "EWS- 10% Only in National Institutes/Central Universities"
    ],

};
export default NeetEligibilityDataJson;
