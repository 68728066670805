import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './swiper.css'
import { EffectCoverflow, Navigation } from 'swiper/modules';

import abc from "../assets/swiper/Madras1.png"
import abc1 from "../assets/swiper/Madras2.png"
import abc2 from "../assets/swiper/Madras3.png"
import abc3 from "../assets/swiper/Madras4.png"
import abc4 from "../assets/swiper/Madras5.png"
import { Box, Button, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function App() {
    const navigate = useNavigate();
    return (
        <>
            <Box sx={{ displpay: "flex", justifyContent: "center", }}>
                <Container>

                    <div className="container">
                        {/* <Box sx={{ displpay: "flex", justifyContent: "center", textAlign: "center" }}> */}
                        <h1 style={{ display: "flex", flexDirection: "row", justifyContent: "center", fontSize: "34px" }}>Browse&nbsp;<h1 style={{ color: "#FF8A00", fontSize: "34px" }}>Top Colleges</h1></h1>
                        {/* </Box> */}
                        <Swiper
                            effect={'coverflow'}
                            grabCursor={true}
                            centeredSlides={true}
                            loop={true}
                            slidesPerView={"auto"}
                            autoplay={true}
                            coverflowEffect={{
                                rotate: 0,
                                stretch: 0,
                                depth: 130,
                                modifier: 7,
                            }}

                            navigation={{
                                nextEl: '.swiper-button-next',
                                prevEl: '.swiper-button-prev',
                                clickable: true,
                            }}
                            modules={[EffectCoverflow, Navigation]}
                            className="swiper_container"
                        > <div className="swiper-button-prev slider-arrow" style={{ color: "#FF8A00" }}>
                                <ion-icon name="chevron-backward-circle-outline"></ion-icon>
                            </div>
                            <SwiperSlide>
                                <img src={abc} alt="slide_image" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={abc1} alt="slide_image" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={abc2} alt="slide_image" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={abc3} alt="slide_image" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={abc4} alt="slide_image" />
                            </SwiperSlide>
                            <div className="swiper-button-next slider-arrow" style={{ color: "#FF8A00" }}>
                            </div>




                        </Swiper>
                    </div>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Button variant="outlined"
                            sx={{
                                color: "#EF5835",
                                borderRadius: 10,
                                mt: "-50px",
                                borderColor: "#EF5835",

                                "&:hover": {
                                    backgroundColor: "#EF5835",
                                    borderColor: "#EF5835",
                                    color: "#ffffff",
                                },
                                width: "200px",
                                height: "30px",
                            }} onClick={() => navigate("/college-list")}

                        >
                            View All
                        </Button>
                    </Box>
                </Container>

            </Box>
        </>
    );
}

export default App;