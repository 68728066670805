import { Box, Container, Tabs, Tab, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ExamDetailContent from "../../EntranceExam/ExamDetails/ExamDetailContent";
import SingleHeaderDynamicTable from "../../../components/TableComponent/SingleheaderTable";
import AiimsRankings from "../../../json/CollegeDetailsJson/AiimsJson/AiimsRanking";
import StripTitle from "../../../components/StripTitle";
import AiimsFurtherEducation from "../../../json/CollegeDetailsJson/AiimsJson/AiimsRanking";
import AiimsFeeData from "../../../json/CollegeDetailsJson/AiimsJson/AiimsFeesData";
import AiimsFeeHeader from "../../../json/CollegeDetailsJson/AiimsJson/AiimsFeesHeader";
import AiimsAboutData from "../../../json/CollegeDetailsJson/AiimsJson/AiimsAboutData";
import AiimsAboutHeader from "../../../json/CollegeDetailsJson/AiimsJson/AiimsAboutHeader";

const AiimsTabDetails = () => {
    const [selectedTab, setSelectedTab] = useState(0);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    return (
        <>
            <Box sx={{ backgroundColor: "#252525" }}>
                <Container>
                    <Box sx={{ width: "100%" }}>
                        <Tabs
                            value={selectedTab}
                            onChange={handleTabChange}
                            variant="scrollable"
                            scrollButtons
                            allowScrollButtonsMobile
                            aria-label="scrollable auto tabs example"
                            sx={{
                                fontSize: "13px",
                                // mt: "40px",
                                "& .MuiTab-root": {
                                    // fontSize: "13px",
                                    color: "#898383",

                                    "&.Mui-selected": {
                                        color: "#EF5835",
                                    },
                                },
                                "& .MuiTabs-indicator": {
                                    display: "none",
                                },
                                "& .MuiTabs-flexContainer": {
                                    display: "flex",
                                    justifyContent: "space-around",
                                },
                                "& .MuiTabScrollButton-root": {
                                    color: "#ffffff", // Change color of scroll buttons to white
                                },
                            }}
                        >
                            <Tab label="About the College" />

                            <Tab label="Courses and Fee" />

                            <Tab label="Rankings" />
                        </Tabs>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-evenly",
                                flexWrap: "wrap",
                            }}
                        >
                            {selectedTab === 0 && (
                                <ExamDetailContent
                                    color={"#ffffff"}
                                    showHeader={true}
                                    header={"About the College"}
                                    headerContent={
                                        "AIIMS, New Delhi runs a variety of undergraduate courses in medicine, nursing and related areas, and postgraduate courses in virtually all the basic and clinical medical specialties and super specialties. Admissions to these courses are based on entrance examinations conducted all over the country. AIIMS Delhi has several hostels for its undergraduate and postgraduate students, PhD scholars and resident doctors. Most hostels have designated common rooms equipped with televisions. In addition, the men’s hostel has a playground and a floodlit volleyball court, basketball court, tennis court and a gymnasium and an excellent eight-lane swimming pool is also available in the premises of the students’ gymkhana adjacent to the men’s hostel."}
                                    title={"Details"}
                                    DynamicTabledata={AiimsAboutData}
                                    DynamicTableheader={AiimsAboutHeader}
                                    gapcolor={"#252525"}
                                />
                            )}

                            {selectedTab === 1 && (
                                <>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            width: "100%",
                                        }}
                                    >
                                        <ExamDetailContent
                                            color="#ffffff"
                                            title={"Fees"}
                                            DynamicTabledata={AiimsFeeData}
                                            DynamicTableheader={AiimsFeeHeader}
                                        />

                                        <StripTitle title={"Further Education"} />
                                        {AiimsFurtherEducation?.map((item) => (
                                            <SingleHeaderDynamicTable
                                                data={item}
                                                margin={"10px"}
                                                showheader={false}
                                            />
                                        ))}


                                    </Box>
                                </>
                            )}

                            {selectedTab === 2 && (
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        width: "100%",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            mt: "20px",
                                            mb: "20px",
                                        }}
                                    >
                                        <Typography
                                            variant="h6"
                                            sx={{
                                                color: "#ffffff",
                                                fontWeight: "550",
                                                fontSize: "16px",
                                                position: "relative",
                                                "&::before": {
                                                    content: "''",
                                                    position: "absolute",
                                                    left: "-10px",

                                                    width: "5px",
                                                    height: "20px",
                                                    backgroundColor: "#EF5835",
                                                },
                                            }}
                                        >
                                            {"Rankings"}
                                        </Typography>
                                    </Box>
                                    {AiimsRankings?.map((item) => (
                                        <SingleHeaderDynamicTable
                                            data={item}
                                            margin={"10px"}
                                            headercolor={"#ffffff"}
                                        />
                                    ))}
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default AiimsTabDetails;
