const AmityPlacements = [
    {
        data: [
            { title: "Total number of placements", value: "1,251" },
            { title: "Total number of international placements", value: "25" },
            { title: "Number of companies that offered jobs", value: "165" },
            { title: "Duration of placement season (days)", value: "180" }
        ]
    },
    {
        data: [
            { title: "Highest salary (INR)", value: "15,50,000" },
            { title: "Average salary (INR)", value: "5,28,624" },
            { title: "Number of students who completed their PG degree courses within the stipulated timeframe (2022)", value: "3,280" },
            { title: "Number of PG graduates who chose entrepreneurship in the 2022 graduating batch", value: "85" }
        ]
    },
    {
        data: [
            { title: "Number of graduates from the 2022 graduating batch that chose higher studies", value: "117" },
            { title: "Duration of placement season (days)", value: "180" },
            { title: "Number of graduates in PhD programmes after completing their PG degree courses in 2022", value: "60" }
        ]
    }
];

export default AmityPlacements;
