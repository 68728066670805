const IITDelhiInternship = [
    {

        data: [
            { title: "Number of companies that offered internships in 2022", value: "213" },
            { title: "Average stipend (INR)", value: "87,993" },
            { title: "Maximum stipend (In INR)", value: "3,00,000" },

        ]
    },


];

export default IITDelhiInternship;
