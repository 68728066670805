const ChristianRankings = [
    {
        header: "Ranking over the years",
        data: [
            { title: "Rank 2023", value: "3" },
            { title: "Rank 2022", value: "2" },
     
        ]
    },
];

export default ChristianRankings;
