import { Box, Typography } from "@mui/material";
import React from "react";

const StripHeaderComponent = ({ color, data, margin }) => {
    return (
        <Box sx={{pt:1.2, maring: margin ?? "0px" }}>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Typography
                    variant="h2"
                    sx={{
                        color: color ?? "#000000",
                        fontWeight: "500",
                        fontSize: "20px",
                        position: "relative",
                        "&::before": {
                            content: "''",
                            position: "absolute",
                            left: "-10px",
                            width: "4px",
                            height: "20px",
                            backgroundColor: "#EF5835"
                        }
                    }}
                >
                    {data?.title}
                </Typography>
            </Box>

            <Box sx={{ display: "flex", padding: "15px" }}>
                {/* Check if data.content is an array */}
                {Array.isArray(data?.content) ? (

                    <ul>
                        {data.content.map((item, index) => (
                            <li key={index} style={{ listStyle: "inside", color: "#ffffff", padding: "5px 0", textAlign: "left" }}>
                                <Typography
                                    variant="caption"
                                    sx={{ color: color ?? "#000000" }}
                                    fontWeight={"300"}
                                    fontSize={"14px"}
                                >
                                    {item}
                                </Typography>
                            </li>
                        ))}
                    </ul>
                ) : (
                    // If it's not an array, render it as a single string
                    <Typography
                        variant="h2"
                        sx={{ color: color ?? "#000000" }}
                        fontWeight={"300"}
                        fontSize={"14px"}
                    >
                        {data?.content}
                    </Typography>
                )}
            </Box>
        </Box>
    );
};

export default StripHeaderComponent;
