import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import Point from "../../../assets/Points.png";
import Arrive from "../../../assets/Arrive.png";
import Point2 from "../../../assets/Point2.png";
import Group from "../../../assets/Group 60.png";
import Point3 from "../../../assets/Point3.png";
import Studying from "../../../assets/studying 1.png";

const HelpCenter = () => {
  return (
    <>
      <Container maxWidth="xxl">
        <Grid
          container
          sx={{
            display: {
              xl: "screen",
              lg: "screen",
              md: "screen",
              sm: "none",
              xs: "none",
            },
            justifyContent: { md: "center" }
          }}
        >
          <Box
            mt={5}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              // paddingLeft: { xl: "19%", md: "15%", sm: "13%", xs: "8%" },
            }}
          >
            <Typography
              className="box1"
              sx={{
                fontSize: { xl: "45px", lg: "45px", sm: "45px ", md: "45px" },
                fontWeight: "700",
              }}
            >
              Providing Help at{" "}
              <span style={{ color: "#EF5835" }}>every Step</span>{" "}
            </Typography>
          </Box>
          <Grid>

          </Grid>
          <Grid item xl={8} lg={6} md={6} sm={12} xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                padding: "2rem",
                marginTop: { md: "2rem" },
                marginLeft: { md: "6rem" },
                gap: "1rem",
              }}
            >
              <Box mt={2}>
                <img src={Point} alt="" width="30px" />
              </Box>
              <Box>
                <Typography
                className="box2"
                  sx={{
                    fontSize: { xl: "40px", lg: "40px" },
                    fontWeight: "600",
                  }}
                >
                  Before And When Student Arrive
                </Typography>
                <ul
                  style={{
                    color: "#4D4D4D",
                    fontSize: {
                      xl: "30px",
                      lg: "30px",
                      md: "25px",
                      sm: "20px",
                    },
                  }}
                >
                  <li className="box2" style={{ paddingBottom: "1rem", paddingTop: "1rem" }}>
                    Arrange for pick up from airport/ railway station / bus
                    stop, if
                    <br /> required.
                  </li>
                  <li className="box2" style={{ paddingBottom: "1rem" }}>
                    Arrange the pre-arrival communication and booklet.
                  </li>
                  <li className="box2" style={{ paddingBottom: "1rem" }}>
                    Welcome Orientation after you arrive in the city to tell you
                    <br /> more about the lifestyle, customs, and school life.
                  </li>
                </ul>
              </Box>
            </Box>
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
            <Box
              mt={8}
              sx={{
                display: "flex",
                justifyContent: {
                  xl: "flex-start",
                  lg: "flex-start",
                  md: "flex-end",
                  sm: "center",
                  xs: "center",
                },
              }}
            >
              <img src={Arrive} alt="" width="45%" />
            </Box>
          </Grid>

          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: {
                  xl: "flex-end",
                  lg: "flex-end",
                  md: "center",
                  sm: "center",
                  xs: "center",
                },
                mt: { md: "4rem" },
                // pl:{md:"10rem"}
                pl: "5rem"
              }}
            >
              <img src={Group} alt="" width="75%" />
            </Box>
          </Grid>

          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                padding: "2rem",
                gap: "1rem",
              }}
            >
              <Box mt={1}>
                <img src={Point2} alt="" width="30px" />
              </Box>
              <Box>
                <Typography
                className="box1"
                  sx={{
                    fontSize: { xl: "40px", lg: "40px" },
                    fontWeight: "600",
                  }}
                >
                  Help Student To Settle In
                </Typography>
                <ul
                  style={{
                    color: "#4D4D4D",
                    fontSize: {
                      xl: "25px",
                      lg: "25px",
                      md: "20px",
                      sm: "20px",
                    },
                  }}
                >
                  <li className="box2" style={{ paddingBottom: "1rem", paddingTop: "1rem" }}>
                    Assistance with finding new accommodation.
                  </li>
                  <li className="box2" style={{ paddingBottom: "1rem" }}>
                    Assistance with opening bank account.{" "}
                  </li>
                  <li className="box2" style={{ paddingBottom: "1rem" }}>
                    Arrange for doctor/hospital visits when and if required
                  </li>
                  <li className="box2" style={{ paddingBottom: "1rem" }}>
                    Assistance to identify a General Practice Doctor.
                  </li>
                  <li className="box2" style={{ paddingBottom: "1rem" }}>
                    {" "}
                    Arrange the best deal on air ticket costs with the local and{" "}
                    <br /> international travel agencies during the holiday
                    break period.
                  </li>
                  <li className="box2" style={{ paddingBottom: "1rem" }}>
                    Regular communication with student by phone/email.
                  </li>
                  <li className="box2" style={{ paddingBottom: "1rem" }}>
                    Assistance to communicate on behalf of students with
                    student's <br /> education provider relating with any
                    concerning issues.
                  </li>
                </ul>
              </Box>
            </Box>
          </Grid>


          <Grid item xl={8} lg={6} md={6} sm={12} xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                padding: "2rem",
                gap: "1rem",
              }}
            >
              <Box mt={1} sx={{ marginLeft: { md: "6rem" } }}>
                <img src={Point3} alt="POINT" width="30px" />
              </Box>
              <Box sx={{ fontFamily: "Nonito", marginLeft: { md: "0.5rem" } }}>
                <Typography
                className="box1"
                  sx={{
                    fontSize: { xl: "42px", lg: "42px" },
                    fontWeight: "600",
                  }}
                >
                  Look After Student While Studying
                </Typography>
                <ul
                  style={{
                    color: "#4D4D4D",
                    fontSize: {
                      xl: "20px",
                      lg: "20px",
                      md: "20px",
                      sm: "20px",
                    },
                  }}
                >
                  <li className="box2" style={{ paddingBottom: "1rem", paddingTop: "1rem" }}>
                    Provide regular updates to parents regarding academic <br />{" "}
                    performance of their child, attendance, exam results and{" "}
                    <br /> class teacher/ faculty review about the child.
                  </li>
                  <li className="box2" style={{ paddingBottom: "1rem" }}>
                    General feedback to parents.
                  </li>
                  <li className="box2" style={{ paddingBottom: "1rem" }}>
                    Assistance to communicate between College, parents and{" "}
                    <br />
                    students if required.
                  </li>
                  <li className="box2" style={{ paddingBottom: "1rem" }}>
                    24/7 emergency contact hotline and emergency assistance.{" "}
                    <br /> We reach out to your child within 4hours of the
                    incident.
                  </li>
                  <li className="box2" style={{ paddingBottom: "1rem" }}>
                    Arrangements/assistance for parents to come to visit their
                    children, for <br /> example: airport transfer,
                    accommodation arrangement, college visit <br /> (Note: we
                    only provide information regarding third party service to{" "}
                    <br /> parents, they are liable for all bills).
                  </li>
                </ul>
              </Box>
            </Box>
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
            <Box
              mt={20}
              sx={{
                display: "flex",
                justifyContent: {
                  xl: "flex-start",
                  lg: "flex-start",
                  md: "flex-end",
                  sm: "center",
                  xs: "center",
                },
              }}
            >
              <img src={Studying} alt=" Studying" width="60%" />
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          sx={{
            display: {
              xl: "none",
              lg: "none",
              md: "none",
              sm: "screen",
              xs: "screen",
            },
          }}
        >
          <Box
            mt={5}
            sx={{
              display: "flex",
              justifyContent: "center",
              paddingLeft: { xl: "19%", md: "15%", sm: "13%", xs: "8%" },
            }}
          >
            <Typography
              className="box1"
              sx={{
                fontSize: { xl: "45px", lg: "45px", sm: "45px ", md: "45px" },
                fontWeight: "700",
              }}
            >
              Providing Help at{" "}
              <span style={{ color: "#EF5835" }}>every Step</span>{" "}
            </Typography>
          </Box>
          <Grid item xl={8} lg={6} md={6} sm={12} xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                padding: "2rem",
                gap: "1rem",
              }}
            >
              <Box mt={1}>
                <img src={Point} alt="" width="30px" />
              </Box>
              <Box sx={{ fontFamily: "Nonito" }}>
                <Typography
                  sx={{
                    fontSize: { xl: "35px", lg: "35px" },
                    fontWeight: "600",
                  }}
                >
                  Before And When Student Arrive
                </Typography>
                <ul
                  style={{
                    color: "#4D4D4D",
                    fontSize: {
                      xl: "20px",
                      lg: "20px",
                      md: "15px",
                      sm: "12px",
                    },
                  }}
                >
                  <li style={{ paddingBottom: "1rem", paddingTop: "1rem" }}>
                    Arrange for pick up from airport/ railway station / bus
                    stop, if
                    <br /> required.
                  </li>
                  <li style={{ paddingBottom: "1rem" }}>
                    Arrange the pre-arrival communication and booklet.
                  </li>
                  <li style={{ paddingBottom: "1rem" }}>
                    Welcome Orientation after you arrive in the city to tell you
                    <br /> more about the lifestyle, customs, and school life.
                  </li>
                </ul>
              </Box>
            </Box>
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
            <Box
              mt={5}
              sx={{
                display: "flex",
                justifyContent: {
                  xl: "flex-start",
                  lg: "flex-start",
                  md: "flex-start",
                  sm: "center",
                  xs: "center",
                },
              }}
            >
              <img src={Arrive} alt="" width="45%" />
            </Box>
          </Grid>

          <Grid item xl={8} lg={6} md={6} sm={12} xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                padding: "2rem",
                gap: "1rem",
              }}
            >
              <Box mt={1}>
                <img src={Point2} alt="" width="30px" />
              </Box>
              <Box sx={{ fontFamily: "Nonito" }}>
                <Typography
                  sx={{
                    fontSize: { xl: "35px", lg: "35px" },
                    fontWeight: "600",
                  }}
                >
                  Help Student To Settle In
                </Typography>
                <ul
                  style={{
                    color: "#4D4D4D",
                    fontSize: {
                      xl: "25px",
                      lg: "25px",
                      md: "15px",
                      sm: "12px",
                    },
                  }}
                >
                  <li style={{ paddingBottom: "1rem", paddingTop: "1rem" }}>
                    Assistance with finding new accommodation.
                  </li>
                  <li style={{ paddingBottom: "1rem" }}>
                    Assistance with opening bank account.{" "}
                  </li>
                  <li style={{ paddingBottom: "1rem" }}>
                    Arrange for doctor/hospital visits when and if required
                  </li>
                  <li style={{ paddingBottom: "1rem" }}>
                    Assistance to identify a General Practice Doctor.
                  </li>
                  <li style={{ paddingBottom: "1rem" }}>
                    {" "}
                    Arrange the best deal on air ticket costs with the local and{" "}
                    <br /> international travel agencies during the holiday
                    break period.
                  </li>
                  <li style={{ paddingBottom: "1rem" }}>
                    Regular communication with student by phone/email.
                  </li>
                  <li style={{ paddingBottom: "1rem" }}>
                    Assistance to communicate on behalf of students with
                    student's <br /> education provider relating with any
                    concerning issues.
                  </li>
                </ul>
              </Box>
            </Box>
          </Grid>

          <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
            <Box
              mt={5}
              sx={{
                display: "flex",
                justifyContent: {
                  xl: "flex-end",
                  lg: "flex-end",
                  md: "flex-end",
                  sm: "center",
                  xs: "center",
                },
              }}
            >
              <img src={Group} alt="" width="48%" />
            </Box>
          </Grid>

          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                padding: "2rem",
                gap: "1rem",
              }}
            >
              <Box mt={1}>
                <img src={Point3} alt="POINT" width="30px" />
              </Box>
              <Box sx={{ fontFamily: "Nonito" }}>
                <Typography
                  sx={{
                    fontSize: { xl: "35px", lg: "35px" },
                    fontWeight: "600",
                  }}
                >
                  Look After Student While Studying
                </Typography>
                <ul
                  style={{
                    color: "#4D4D4D",
                    fontSize: {
                      xl: "20px",
                      lg: "20px",
                      md: "15px",
                      sm: "12px",
                    },
                  }}
                >
                  <li style={{ paddingBottom: "1rem", paddingTop: "1rem" }}>
                    Provide regular updates to parents regarding academic <br />{" "}
                    performance of their child, attendance, exam results and{" "}
                    <br /> class teacher/ faculty review about the child.
                  </li>
                  <li style={{ paddingBottom: "1rem" }}>
                    General feedback to parents.
                  </li>
                  <li style={{ paddingBottom: "1rem" }}>
                    Assistance to communicate between College, parents and{" "}
                    <br />
                    students if required.
                  </li>
                  <li style={{ paddingBottom: "1rem" }}>
                    24/7 emergency contact hotline and emergency assistance.{" "}
                    <br /> We reach out to your child within 4hours of the
                    incident.
                  </li>
                  <li style={{ paddingBottom: "1rem" }}>
                    Arrangements/assistance for parents to come to visit their
                    children, for <br /> example: airport transfer,
                    accommodation arrangement, college visit <br /> (Note: we
                    only provide information regarding third party service to{" "}
                    <br /> parents, they are liable for all bills).
                  </li>
                </ul>
              </Box>
            </Box>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <Box
              mt={10}
              sx={{
                display: "flex",
                justifyContent: {
                  xl: "flex-start",
                  lg: "flex-start",
                  md: "flex-start",
                  sm: "center",
                  xs: "center",
                },
              }}
            >
              <img src={Studying} alt=" Studying" width="100%" />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default HelpCenter;
