const AmityAboutData = {
    address: "Amity Road, Sector 125, Noida, Uttar Pradesh - 201301",
    city: "Noida",
    state: "Uttar Pradesh",
    phone: "1204392000",
    established: "2005",
    btechgraduated: "2007",
    head: "Prof. (Dr.) Balvinder Shukla",
    type: "Private",
    website: "www.auup.amity.edu",
    raggingcell: "Yes",
    nationalservice:
        "No",
    complaintcell: "Yes",
    qualityassurance:
        "Yes",
    noofgraduates: "1,36,897"

};
export default AmityAboutData;
