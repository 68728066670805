const AmityAdmissionContent = [
    {
        title: "Selection Criteria:",
        content: [
            "Through the website, candidates can fill ABBS application form. Acharya Bangalore B-School admission to courses will be according to merit and score secured by the candidates in entrance exams. The final admissions process will have group discussions and personal interviews, which all the selected students should attend to confirm seats.",
        ]
    },
    {
        title: "Eligibility Criteria:",
        content: [
            "MBA",
            "Students must have scored at least 50% marks in graduation + MAT/ GMAT/ CMAT/ CAT/ XAT/ NMAT or Amity written test on the day of the interview",
            "MSc",
            "Aspirants should have passed a BSc in the relevant discipline from a recognised university",
            "MA",
            "Candidates must have a bachelor's degree in any stream with at least 50% marks in any discipline from a recognised university",
            "ME/ MTech",
            "Aspirants must have passed a BTech in the relevant discipline with at least 60% marks from a recognised university",
            "MEd",
            "Students must hold a BElEd or BEd with a minimum of 55% marks"
        ]
    },
    {
        title: "Admission Process",
        content: [
            "Candidates must meet the eligibility criteria.",
            "Candidates have to fill the Amity University application form.",
            "Following that, candidates for Amity University Noida PG admission must submit an online video.",
            "The university chooses candidates based on their graduation score and online video submissions.",
            "Once selected, complete the admission process by getting documents verified and paying the Amity University Noida course fees."
        ]
    },
    {
        title: "Application Process",
        content: [
            "Aspirants must visit the official website of Amity University Noida.",
            "Download the Amity University Noida application materials and fill in the form with all the required information.",
            "Scan and upload the documents.",
            "Pay the Amity University Noida application fees and submit the form."
        ]
    },
    {
        title: "List of required documents:",
        content: [
            "10th and 12th marksheets and certificate",
            "Photo ID and proof of date of birth",
            "Degree marksheet",
            "Work experience certificate",
            "Degree certificate/ provisional certificate in original and attested",
            "Two printouts of application form",
            "Transaction receipt",
            "Research proposal",
            "Work experience certificate",
            "Sponsorship certificate",
            "A copy of UGC NET/ CSIR NET/ ICAR (ASRB)/ GPAT/ ICMR/ GATE/ DST INSPIRE score card",
            "SC/ ST/ PwD certificate (if applicable)",
            "Attested photocopies of documents and photographs"
        ]
    }
];

export default AmityAdmissionContent;
