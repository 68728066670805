const NationalAboutHeader = [
    { id: 1, label: "Address", var: "address" },
    { id: 2, label: "City", var: "city" },
    { id: 3, label: "State", var: "state" },
    { id: 4, label: "Phone", var: "phone" },
    { id: 5, label: "Email", var: "email" },
    { id: 6, label: "Year Established", var: "established" },
    { id: 7, label: "Year first LLB/ BA LLB batch graduated", var: "yearfirst" },
    { id: 8, label: "Head of the Institution", var: "headoftheinstitution" },
    { id: 9, label: "Designation", var: "designation" },
    { id: 10, label: "Institute type", var: "institutetype" },
    { id: 11, label: "Website", var: "website" },
    { id: 12, label: "Anti-ragging cell", var: "antiraggingcell" },
    { id: 13, label: "National Service Scheme cell", var: "nationalserviceschemecell" },
    { id: 14, label: "Internal Complaints Committee cell", var: "internalcomplaintscommitteecell" },
    { id: 15, label: "Internal Quality Assurance Cell", var: "internalqualityassurancecell" },
    { id: 16, label: "Total number of LLB/ BA LLB students graduated since the college was established", var: "totalnumberof" },
    
];

export default NationalAboutHeader;
