const JeeCollegesDataJson = {
    listofcollegesacceptingtheexamscorerank: [
        "ABV IIIT Gwalior",
    "AISECT University",
    "Amity Education Valley",
    "Amity School of Engineering and Technology, Noida",
    "Amity School Of Engineering Greater Noida",
    "Amity School of Engineering Gwalior",
    "Amity School of Engineering Lucknow",
    "Amity School of Engineering, Noida",
    "Amity University Mumbai",
    "Amity University Rajasthan",
    "Amity University Uttar Pradesh",
    "Amity University West Bengal",
    "Amrita Vishwa Vidyapeetham",
    "Army Institute of Technology, Pune",
    "Assam University, Silchar",
    "Atal Bihari Vajpayee Indian Institute of Information Technology & Management, Gwalior, Madhya Pradesh",
    "Bangalore Institute of Technology, Bangalore",
    "Bhagwant University",
    "Bharati Vidyapeeth Deemed University College of Engineering, Pune",
    "Bhilai Institute of Technology, Durg",
    "Bipin Tripathi Kumaon Institute of Technology, Dwarahat",
    "Birla Institute of Technology Mesra",
    "BIT, Deogarh",
    "BIT, Patna",
    "BIT, Ranchi",
    "BLDEA’s VP Dr PG Halakatti College of Engineering and Technology, Bijapur",
    "BS Abdur Rahman University, Chennai",
    "Central Institute of Technology Kokrajar, Assam",
    "Central University of Rajasthan, Rajasthan",
    "Chitkara University, Himachal Pradesh",
    "Chitkara University, Patiala",
    "CV Raman College of Engineering, Bhubaneswar",
    "DAV Institute of Engineering and Technology, Jalandhar",
    "Dayalbagh Educational Institute, Agra",
    "Deenbandhu Chhotu Ram University of Science and Technology, Murthal",
    "Delhi College Of Technology & Management",
    "Delhi Technical Campus Bahadurgarh",
    "Delhi Technological University, New Delhi",
    "Dhirubhai Ambani Institute of Information and Communication Technology, Gandhinagar",
    "DIT University, Dehradun",
    "Dr B R Ambedkar National Institute of Technology, Jalandhar",
    "Dr K.N. Modi University",
    "Dr. APJ Abdul Kalam Technical University",
    "DSCE Bangalore - Dayananda Sagar College of Engineering",
    "Faculty of Engineering and Technology, Manav Rachna International Institute of Research and Studies, Faridabad",
    "Fr C Rodrigues Institute of Technology, Navi Mumbai",
    "Galgotias University",
    "Gandhi Institute for Technological Advancement, Bhubaneswar",
    "GB Pant University of Agriculture and Technology",
    "GH Raisoni College of Engineering, Nagpur",
    "Ghani Khan Choudhary Institute of Engineering and Technology, Malda, West Bengal",
    "GITAM University, Visakhapatnam",
    "GKV, Haridwar",
    "Glocal University",
    "Government College of Engineering, Aurangabad",
    "Graphic Era University, Dehradun",
    "Guru Gobind Singh Indraprastha University",
    "Guru Nanak Dev Engineering College, Ludhiana",
    "Guru Nanak Dev University, Amritsar",
    "Gurukula Kangri Vishwavidyalaya, Haridwar",
    "Haldia Institute of Technology, Haldia",
    "Harcourt Butler Technical University, Kanpur",
    "Heritage Institute of Technology, Kolkata",
    "HNB Garhwal University Srinagar",
    "ICFAI Foundation for Higher Education, Hyderabad",
    "IICT, Bhadohi",
    "IIITD and Management, Kancheepuram",
    "IIITDM Kanchipuram",
    "IIITDM Kurnool",
    "IIITM, Gwalior",
    "Indian Institute of Carpet Technology, Bhadohi (UP)",
    "Indian Institute of Crop Processing Technology, Thanjavur, Tamilnadu",
    "Indian Institute of Engineering Science and Technology, Shibpur",
    "Indian Institute of Handloom Technology, Salem, Tamil Nadu",
    "Indian Institute of Information Technology (IIIT) Nagpur",
    "Indian Institute of Information Technology (IIIT) Pune",
    "Indian Institute of Information Technology (IIIT) Ranchi",
    "Indian Institute of Information Technology Allahabad",
    "Indian Institute of Information Technology and Management, Gwalior",
    "Indian Institute of Information Technology and Management, Kerala",
    "Indian Institute of Information Technology Bhagalpur",
    "Indian Institute of Information Technology Bhopal",
    "Indian Institute of Information Technology Design & Manufacturing, Kancheepuram",
    "Indian Institute of Information Technology Design and Manufacturing Jabalpur",
    "Indian Institute of Information Technology Dharwad",
    "Indian Institute of Information Technology Guwahati, Assam",
    "Indian Institute of Information Technology Kalyani, West Bengal",
    "Indian Institute of Information Technology Kilohrad, Sonepat, Haryana",
    "Indian Institute of Information Technology Kota, Rajasthan",
    "Indian Institute of Information Technology Lucknow",
    "Indian Institute of Information Technology Manipur",
    "Indian Institute of Information Technology Sri City, Chittoor, Andhra Pradesh",
    "Indian Institute of Information Technology Srirangam, Tiruchirapalli",
    "Indian Institute of Information Technology Surat",
    "Indian Institute of Information Technology Una, Himachal Pradesh",
    "Indian Institute of Information Technology Vadodara, Gujarat",
    "Indian Institute of Information Technology, Agartala",
    "Indian Institute of Information Technology, Allahabad",
    "Indian Institute of Information Technology, Bhagalpur",
    "Indian Institute of Information Technology, Bhopal",
    "Indian Institute of Information Technology, Design & Manufacturing, Kanchipuram, Tamil Nadu",
    "Indian Institute of Information Technology, Design and Manufacturing, Jabalpur",
    "Indian Institute of Information Technology, Dharwad",
    "Indian Institute of Information Technology, Guwahati",
    "Indian Institute of Information Technology, Himachal Pradesh",
    "Indian Institute of Information Technology, Jhalwa, Allahabad, Uttar Pradesh",
    "Indian Institute of Information Technology, Kalyani",
    "Indian Institute of Information Technology, Kota",
    "Indian Institute of Information Technology, Kottayam, Kerala",
    "Indian Institute of Information Technology, Kurnool",
    "Indian Institute of Information Technology, Manipur",
    "Indian Institute of Information Technology, Nagpur",
    "Indian Institute of Information Technology, Pune",
    "Indian Institute of Information Technology, Raichur, Karnataka",
    "Indian Institute of Information Technology, Ranchi",
    "Indian Institute of Information Technology, Sonepat",
    "Indian Institute of Information Technology, Sri City",
    "Indian Institute of Information Technology, Surat",
    "Indian Institute of Information Technology, Tiruchirappalli",
    "Indian Institute of Information Technology, Vadodara",
    "Indian Institute of Information Technology, West Bengal",
    "Indian Institute of Petroleum & Energy, Visakhapatnam",
    "Indian Institute of Science, Bangalore",
    "Indian Institute of Space Science and Technology, Thiruvananthapuram",
    "Indira Gandhi Delhi Technical University for Women, New Delhi",
    "Indraprastha Institute of Information Technology, Delhi",
    "Institute of Carpet Technology Bhadohi",
    "Institute of Chemical Technology, Mumbai",
    "Institute of Engineering & Technology, Chandigarh",
    "Institute of Engineering and Management, Kolkata",
    "Institute of Engineering and Technology Jaipur",
    "Institute of Infrastructure Technology, Research & Management, Ahmadabad",
    "Institute of Technology, Guru Ghasidas Vishawavidyalaya, Bilaspur",
    "International Institute of Information Technology Hyderabad",
    "International Institute of Information Technology, Bangalore (Integrated courses)",
    "International Institute of Information Technology, Bhubaneswar",
    "International Institute of Information Technology, Naya Raipur",
    "ITM University Gwalior, Madhya Pradesh",
    "J.K. Institute of Applied Physics & Technology, University of Allahabad",
    "Jadavpur University, Kolkata",
    "Jagan Nath University Jaipur",
    "Jagan Nath University Jhajjar",
    "Jagran Lakecity University",
    "Jaipur University",
    "Jamia Hamdard, New Delhi",
    "Jamia Millia Islamia, New Delhi",
    "Jawaharlal Nehru University, Delhi",
    "Jaypee Institute of Information Technology, Noida",
    "Jaypee University of Information Technology, Solan",
    "JECRC University, Jaipur",
    "JK Institute of Applied Physics and Technology",
    "JKIAPT University, Allahabad",
    "JNMIIT, Jaipur",
    "Jodhpur National University",
    "Kalasalingham Universty Krishnakoil",
    "KIIT, Bubaneswar",
    "KJ Somaiya College of Engineering, Mumbai",
    "KLE Technological University, Hubli",
    "Koneru Lakshmaiah Education Foundation, Guntur",
    "Lakshmi Narain College of Technology, Bhopal",
    "Lovely Professional University, Phagwara",
    "Madan Mohan Malviya University of Technology",
    "Madhav Institute of Technology and Science, Gwalior",
    "Maharaja Sayajirao University of Baroda, Vadodara",
    "Maharana Pratap University of Agriculture and Technology",
    "Maharashtra Institute of Technology, Pune",
    "Maharishi Markandeshwar University",
    "Mahatma Jyoti Rao Phoole University",
    "Malaviya National Institute of Technology, Jaipur",
    "Manipal Institute of Technology",
    "Mar Athanasius College of Engineering, Kothamangalam",
    "Maulana Azad National Institute of Technology, Bhopal",
    "MERI- College of Engineering & Technology",
    "Mewar University",
    "MGRERI, Chennai",
    "MIT College of Engineering, Pune",
    "Mizoram University Aizawl",
    "MJPR University, Bareilly",
    "MM Engineering College",
    "Motilal Nehru National Institute of Technology, Allahabad",
    "MSRIT Bangalore - Ramaiah Institute of Technology",
    "Mukesh Patel School of Technology Management and Engineering, Mumbai",
    "National Institute of Electronics and Information Technology, Aurangabad",
    "National Institute of Food Technology Entrepreneurship and Management Sonipat, Haryana",
    "National Institute of Foundry & Forge Technology, Ranchi",
    "Netaji Subhas Institute of Technology, Delhi",
    "NIFFT, Ranchi",
    "Nirma University, Ahmedabad",
    "NIT Agartala (NITA)",
    "NIT Allahabad (MNNIT)",
    "NIT Andhra Pradesh (NITANP)",
    "NIT Arunachal Pradesh (NITAP)",
    "NIT Bhopal (MANIT)",
    "NIT Calicut (NITC)",
    "NIT Delhi (NITD)",
    "NIT Durgapur (NITDGP)",
    "NIT Goa (NITG)",
    "NIT Hamirpur (NITH)",
    "NIT Jaipur (MNIT)",
    "NIT Jalandhar (NITJ)",
    "NIT Jamshedpur (NITJSR)",
    "NIT Karnataka (NITK)",
    "NIT Kurukshetra (NITKKR)",
    "NIT Manipur (NITMN)",
    "NIT Meghalaya (NITM)",
    "NIT Mizoram (NITMZ)",
    "NIT Nagaland (NITN)",
    "NIT Nagpur (VNIT)",
    "NIT Patna (NITP)",
    "NIT Puducherry (NITPY)",
    "NIT Raipur (NITRR)",
    "NIT Rourkela (NITRKL)",
    "NIT Sikkim (NITSKM)",
    "NIT Silchar (NITS)",
    "NIT Srinagar (NITSRI)",
    "NIT Surat (SVNIT)",
    "NIT Trichy (NITT)",
    "NIT Uttarakhand (NITUK)",
    "NIT Warangal (NITW)",
    "Pandit Dwarka Prasad Mishra Indian Institute of Information Technology, Design & Manufacturing, Jabalpur (MP)",
    "PDSM IIITDM Jabalpur",
    "PEC University of Technology, Chandigarh",
    "People’s College of Research & Technology",
    "Pondicherry Engineering College, Puducherry",
    "Poornima University",
    "PSG College of Technology, Coimbatore",
    "Punjab Engineering College, Chandigarh",
    "Punjab Technical University, Jalandhar",
    "R.P. Inderapratha Institute Of Technology Technical Campus",
    "RV College of Engineering",
    "Samalkha Group Of Institutions",
    "Sant Longowal Institute of Engineering and Technology, Longowal",
    "Sardar Vallabhbhai National Institute of Technology, Surat",
    "School of Engineering & Technology, Bengal Engineering and Science University",
    "School of Engineering & Technology, Mizoram University",
    "School of Engineering, Tezpur University, Napaam, Tezpur",
    "School of Engineering, University of Petroleum and Energy Studies, Dehradun",
    "SCSKMV, Kanchipuram",
    "Sharda University, School Of Engineering",
    "Shiv Nadar University, Dadri",
    "Shobhit University",
    "Shri Govindram Seksaria Institute of Technology and Science, Indore",
    "Shri Mata Vaishno Devi University Katra",
    "Shri Ram College Of Engineering & Management",
    "Sikkim Manipal Institute of Technology",
    "Silicon Institute of Technology",
    "Sir Padampat Singhania University",
    "Sityog Institute of Technology",
    "SMVDU Jammu Tavi",
    "SOAU Bhubaneshwar",
    "SPSU, Udaipur",
    "Sri Manakula Vinayagar Engineering College",
    "SRM Institute Of Science & Technology",
    "SSN College of Engineering, Kalavakkam",
    "Symbiosis Institute of Technology",
    "Techno Global University Shilong",
    "Tezpur University, NAPAAM, Tezpur",
    "Thapar Institute of Engineering and Technology, Patiala",
    "The LNM Institute of Information Technology (LNMIIT)",
    "The University of Petroleum and Energy Studies",
    "University of Hyderabad",
    "University of Petroleum and Energy Studies",
    "Uttaranchal University",
    "Veer Surendra Sai University of Technology, Sambalpur",
    "Vel Tech Multi Tech Dr.Rangarajan Dr.Sakunthala Engineering College, Avadi, Chennai",
    "Vellore Institute of Technology",
    "Vignan University, Guntur",
    "Visvesvaraya National Institute of Technology, Nagpur",
    "Vivekananda Global University",
    "YMCA University of Science and Technology, Faridabad"
    ],
};
export default JeeCollegesDataJson;
