const InsituteAboutData = {
    address: "Sarkhej Gandhinagar Highway, PO Chandlodiya, Near Gota, Ahmedabad, Gujarat",
    city: "Ahmedabad",
    state: "Gujarat",
    phone: "079-71652803/804",
    email: "director.il@nirmauni.ac.in",
    established: "2007",
    yearfirst: "2012",
    headoftheinstitution: "Dr Madhuri Parikh",
    designation: "Director L/C",
    institutetype: "Private",
    website: "www.law.nirmauni.ac.in",
    antiraggingcell: "Yes",
    nationalserviceschemecell: "Yes",
    internalcomplaintscommitteecell: "Yes",
    internalqualityassurancecell: "Yes",
    totalnumberof: "1862",
    
  
  };
  export default InsituteAboutData;
  