const JeeAdmitCardDataJson = {
  availabilityofadmitcard: [
    "June session - Second week of June 2022 July session - Third week of July 2022",
  ],

  officialwebsitetodownloadadmitcard: ["https://jeemain.nta.nic.in/"],

  particularsrequiredtodownloadadmitcard: [
    "Application number, Password/Date of Birth",
  ],

  importantdetailsavailableinadmitcard: [
    "Roll No",
    "Application Number",
    "Candidate's Name",
    "father's name",
    "Gender",
    "Date of Birth",
    "Category",
    "PwD or not",
    "Scribe needed or not",
    "Candidate's Signature",
    "Medium of Question Paper",
    "Date of Examination",
    "Reporting Time",
    "Time of Test",
    "Test Center No",
    "Venue of Test",
  ],

  contactdetails: [
    "Address - C20 1A/8, Sector - 62, IITK outreach centre",
    "Noida - 201309.",
    "Helpline Number - 011-40759000",
    "Email ID- jeemain-nta@gov.in",
  ],

  compatiblebrowsersfordownloading: [
    "Internet Explorer, Firefox, Google Chrome",
  ],

  howtodownloadadmitcard: [
    "Step 1: Visit https://jeemain.nta.nic.in/",
    "Step 2: Click on the link for JEE MAIN 2022 ADMIT CARD, a window requiring login will be opened.",
    "Step 3: The login can be done through either application number and password combination or through application number and date of birth option",
    "Step 4: Enter Security Pin and submit",
    "Step 5: The JEE Main 2022 Admit Card will appear on display, download, and print for future references",
  ],

  stepstodownloadadmitcard: [
    "Step 1: Visit https://jeemain.nta.nic.in/",
    "Step 2: Click on the link for JEE MAIN 2022 ADMIT CARD, a window requiring login will be opened.",
    "Step 3: The login can be done through either application number and password combination or through application number and date of birth option",
    "Step 4: Enter Security Pin and submit",
    "Step 5: The JEE Main 2022 Admit Card will appear on display, download, and print for future references",
  ],

  itemsnotpermittedinsideexamcenter: [
    "Candidates with thick-soled shoes and clothes with large buttons will not be allowed to enter the examination centre",
    "Jewellery and metal items",
    "Personal belongings including electronic devices, mobile phones, calculators, smart watch etc",
    "Candidates are prohibited from smoking or eating inside exam hall",
    "Slide rules, log tables, printed or written material, bits of papers, pager",
  ],

  itemsallowedinsideexamcenter: [
    "Print copy of Admit Card along with Self Declaration duly filled in",
    "One passport size photograph (same as uploaded on the Online Application Form) for pasting on the specific space in the Attendance Sheet at Centre during the examination.",
    "Any one of the authorized photo IDs (must be original, valid, and non-expired) – School Identity Card/ PAN card/ Driving License/ Voter ID/ Passport/ Aadhaar Card (With photograph)/E-Aadhaar with photograph/ Ration Card with photograph/ Class 12 Board Admit Card with photograph/ Bank Passbook with Photograph.",
    "PwD certificate issued by the authorized medical officer, if claiming the relaxation under PwD category or PwD Certificate regarding physical limitation in an examination to write.",
    "A transparent water bottle",
    "A transparent Ball Point Pen",
    "A sanitizer",
    "Diabetic patients can bring in sugar tablets or fruits. However, no packaged food like candy or chocolates will be allowed,",
  ],

  guidelinesforadmitcard: [
    "Candidates must check their JEE Main admit card carefully for their Name, Paper, Date of Birth, Gender, Test Centre Name, City, State Code of Eligibility and Category. In case they find any discrepancy, immediately communicate to JEE Main conducting authority.",
    "In case candidates are unable to download the admit card they need to contact JEE Main authorities at 0120-6895200 or mail at jeemain@nta.ac.in",
  ],

  examdayguidelines: [
    "As a precaution for COVID-19, the candidate must reach centre as indicated against Reporting/Entry time at centre (as a precaution it advised to reach 2 hours before commencement of the examination)",
    "No candidate shall be permitted to enter after the Gate Closing Time.",
    "All candidates are required to download and carefully read the Instructions and Advisory for COVID-19 given with the Admit Card and strictly adhere to them",
    "Before reaching the JEE Main exam centres, candidates must duly fill the self-declaration form, paste their passport sized photograph, and put the left-hand thumb impression in the space provided.",
    "No Candidate would be allowed to enter the Examination Centre, without Admit Card, Valid ID Proof, and proper frisking.",
    "The candidate should ensure that the Question Paper available on the computer is as per his/her opted subject indicated in the Admit Card. In case, the subject of the Question Paper is other than his/her opted subject, the same may be brought to the notice of the Invigilator concerned.",
    "In case a candidate, by furnishing false information, appears in more than one shift/date, his candidature will be cancelled, and his result will not be declared.",
    "For those who are unable to appear on the scheduled date of test for any reason, re-test shall not be held by the NTA under any circumstances.",
    "Blank paper of rough work will be provided in the examination hall. Candidates must write their name and roll number at the top of their sheet. This sheet needs to be returned to the invigilator before leaving the examination hall.",
    "Candidates need to enter the required details in the attendance sheet, put signature and paste photograph at the designated place and they need to ensure that their left thumb impression should be clear and not smudge on the attendance sheet.",
    "If the body temperature of a candidate is higher than COVID-19 norms, he or she will be required to take an exam in a separate room.",
    "Candidates will have to drop their Admit card as well as their rough papers in the box at the centres after displaying it to their invigilators",
    "No candidate shall be permitted to leave the Examination Room/ Hall before the end of the examination.",
    "On completion of the examination, please wait for instructions from Invigilator and do not get up from your seat until advised. The candidates will be permitted to move out one at a time only.",
  ],
};
export default JeeAdmitCardDataJson;
