import React from 'react'
import CareerCounseling from './CareerCounseling/CareerCounseling';
import Choosing from './Choosing/Choosing';
import Steps from './Steps/Steps';
import Navbar from '../../pages/home/Header/Navbar';
import Form from '../../pages/home/Form/Form';
import Footer from '../../pages/home/Footer/footer'

const Career = () => {
  return (
    <>
    <Navbar/>
      <CareerCounseling/>
      <Choosing/>
      <Steps/>
      <Form/>
     <Footer/>
    </>
  )
}

export default Career
