const AiimsFurtherEducation = [
    {
        data: [
            { title: "Does the college offer PhD degrees?", value: "Yes" },
            { title: "Number of PhD graduates in 2021-22", value: "72" },
            { title: "Number of PhD graduates in 2020-21", value: "85" },
        ]
    },
    {
        data: [
            { title: "Number of PhD graduates in 2019-20", value: "53" },

            { title: "Does the college offer super speciality degrees (DM/ MCh)?", value: "Yes" },
            { title: "Number of students who earned super speciality degrees (DM/ MCh) in 2021-22", value: "188" },

        ]
    },
    {
        data: [
            { title: "Number of students who earned super speciality degrees (DM/ MCh) in 2020-21", value: "179" },
            { title: "Number of students who earned super speciality degrees (DM/ MCh) in 2019-20", value: "89" },
            { title: "Does the college offer fellowships?", value: "Yes" },

        ]
    },
    {
        data: [
            { title: "Number of students who completed their fellowships in 2021-22", value: "36" },
            { title: "Number of students who completed their fellowships in 2020-21", value: "34" },
            { title: "Number of students who completed their fellowships in 2019-20", value: "57" },

        ]
    },
    {
        data: [
            { title: "Does the college offer postgraduate courses?", value: "Yes" },
            { title: "Number of students who completed postgraduate courses in medicine/ surgery in 2021-22", value: "39" },
            { title: "Number of students who completed postgraduate courses in medicine/ surgery in 2020-21", value: "40" },

        ]
    },
    {
        data: [

            { title: "Number of students who completed postgraduate courses in medicine/ surgery in 2019-20", value: "38" }

        ]
    }
];

export default AiimsFurtherEducation;
