import { Box, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import image from '../../assets/aboutuskey.png'
import '../../careerplatform.css'



const AboutService = () => {
    const reasons = [
        {
            title: "College Admissions Counseling:",
            content: 'From crafting compelling application essays to strategizing for standardized tests, we provide comprehensive support to help students gain admission to their dream colleges and universities.',

        },
        {
            title: "Career Counseling:",
            content: 'We offer personalized guidance to help individuals explore career options, develop essential skills, and make informed decisions about their future paths.',

        },
        {
            title: "Academic Support:",
            content:
                'Our team of experienced educators provides tutoring, academic coaching, and study skills training to help students excel academically and reach their full potential.'

        },
        {
            title: "Institutional Consultancy:",
            content: 'We partner with educational institutions to enhance teaching and learning outcomes, improve administrative efficiency, and foster a culture of innovation and excellence.'

        },
        {
            title: "At Career Platform, we are not just advisors:",
            content: "we are partners in your educational journey. Whether you're a student embarking on the path to higher education, a parent seeking guidance for your child's academic future, or an institution striving for educational excellence, we are here to support you every step of the way"

        },

    ];
    return (
        <Container >
            <Container sx={{ margin: "30px auto" }}>
                <Typography variant="h4" component="h2" color="#EF5835" className="box1" textAlign={"center"} sx={{ fontWeight: 'bold', pt: "10px" }}>
                    Our Services Encompass a Wide Range of Areas, Including
                </Typography>
                <Grid container columns={{ md: 15, xs: 12, sm: 12 }} spacing={2} sx={{ display: "flex", justifyContent: "center", pt: "30px" }}>


                    {reasons?.map((reason) => (

                        <Grid item md={3} xs={12} sm={6} sx={{ display: "flex", justifyContent: "center" }}>

                            <Box sx={{ width: "200px" }}>

                                <Box sx={{ width: "100px" }}>
                                    <img src={image} alt="aboutus_image" width={"100%"} />
                                </Box>
                                <Typography variant="body1" className="box1" sx={{ fontWeight: 'bold', color: "#EF5835", fontSize: "20px" }}>
                                    {reason.title}                            </Typography>
                                <Typography variant="body2" className="box2" sx={{ color: "#000", fontSize: "16px" }}>
                                    {reason.content}                            </Typography>
                            </Box>
                        </Grid>
                    ))}
                </Grid>

            </Container>
        </Container>
    )
}

export default AboutService
