import { createSlice } from "@reduxjs/toolkit";

const exapleSlice = createSlice({
  name: "example",
  initialState: {
    Language: "en",
  },
  reducers: {
    addLanguage: (state, action) => {
      state.Language = action.payload;
    },
  },
});

export const { addLanguage } = exapleSlice.actions;
export default exapleSlice.reducer;
