import c1 from "../assets/collegelist/1.png";
import c2 from "../assets/collegelist/2.png";
import c3 from "../assets/collegelist/3.png";
import c4 from "../assets/collegelist/4.png";
import c5 from "../assets/collegelist/5.png";
import c6 from "../assets/collegelist/6.png";
import c7 from "../assets/collegelist/7.png";
import c8 from "../assets/collegelist/8.png";
import c9 from "../assets/collegelist/9.png";
import c10 from "../assets/collegelist/10.png";
import c11 from "../assets/collegelist/11.png";
import c12 from "../assets/collegelist/12.png";
import c13 from "../assets/collegelist/13.png";
import c14 from "../assets/collegelist/14.png";
import c15 from "../assets/collegelist/15.png";
import c16 from "../assets/collegelist/16.png";
import c17 from "../assets/collegelist/17.png";
import c18 from "../assets/collegelist/18.png";
import c19 from "../assets/collegelist/19.png";
import c20 from "../assets/collegelist/20.png";
import c21 from "../assets/collegelist/21.png";
import c22 from "../assets/collegelist/22.png";
import c23 from "../assets/collegelist/23.png";
import c24 from "../assets/collegelist/24.png";
import c25 from "../assets/collegelist/25.png";
import c26 from "../assets/collegelist/26.png";
import c27 from "../assets/collegelist/27.png";
import c28 from "../assets/collegelist/28.png";
import c29 from "../assets/collegelist/29.png";
import c30 from "../assets/collegelist/30.png";
import c31 from "../assets/collegelist/31.png";
import c32 from "../assets/collegelist/32.png";
import c33 from "../assets/collegelist/33.png";
import c34 from "../assets/collegelist/34.png";
import c35 from "../assets/collegelist/35.png";
import c36 from "../assets/collegelist/36.png";
import c37 from "../assets/collegelist/37.png";
import c38 from "../assets/collegelist/38.png";
import c39 from "../assets/collegelist/39.png";
import c40 from "../assets/collegelist/40.png";
import c41 from "../assets/collegelist/41.png";
import c42 from "../assets/collegelist/42.png";
import c43 from "../assets/collegelist/43.png";
import c44 from "../assets/collegelist/44.png";
import c45 from "../assets/collegelist/45.png";
import c46 from "../assets/collegelist/46.png";
import c47 from "../assets/collegelist/47.png";
import c48 from "../assets/collegelist/48.png";
import c49 from "../assets/collegelist/49.png";
import c50 from "../assets/collegelist/50.png";
import c51 from "../assets/collegelist/51.png";
import c52 from "../assets/collegelist/52.png";
import c53 from "../assets/collegelist/53.png";
import c54 from "../assets/collegelist/54.png";
import c55 from "../assets/collegelist/55.png";
import c56 from "../assets/collegelist/56.png";
import c57 from "../assets/collegelist/57.png";
import c58 from "../assets/collegelist/58.png";
import c59 from "../assets/collegelist/59.png";
import c60 from "../assets/collegelist/60.png";
import c61 from "../assets/collegelist/61.png";
import c62 from "../assets/collegelist/62.png";
import c63 from "../assets/collegelist/63.png";
import c64 from "../assets/collegelist/64.png";
import c65 from "../assets/collegelist/65.png";
import c66 from "../assets/collegelist/66.png";
import c67 from "../assets/collegelist/67.png";
import c68 from "../assets/collegelist/68.png";
import c69 from "../assets/collegelist/69.png";
import c70 from "../assets/collegelist/70.png";
import c71 from "../assets/collegelist/71.png";
import c72 from "../assets/collegelist/72.png";
import c73 from "../assets/collegelist/73.png";
import c74 from "../assets/collegelist/74.png";
import c75 from "../assets/collegelist/75.png";
import c76 from "../assets/collegelist/76.png";
import c77 from "../assets/collegelist/77.png";
import c78 from "../assets/collegelist/78.png";
import c79 from "../assets/collegelist/79.png";
import c80 from "../assets/collegelist/80.png";
import c81 from "../assets/collegelist/81.png";
import c82 from "../assets/collegelist/82.png";
import c83 from "../assets/collegelist/83.png";
import c84 from "../assets/collegelist/84.png";
import c85 from "../assets/collegelist/85.png";
import c86 from "../assets/collegelist/86.png";
import c87 from "../assets/collegelist/87.png";
import c88 from "../assets/collegelist/88.png";
import c89 from "../assets/collegelist/89.png";
import c90 from "../assets/collegelist/90.png";
import c91 from "../assets/collegelist/91.png";
import c92 from "../assets/collegelist/92.png";
import c93 from "../assets/collegelist/93.png";
import c94 from "../assets/collegelist/94.png";
import c95 from "../assets/collegelist/95.png";
import c96 from "../assets/collegelist/96.png";
import c97 from "../assets/collegelist/97.png";
import c98 from "../assets/collegelist/98.png";
import c99 from "../assets/collegelist/99.png";
import c100 from "../assets/collegelist/100.png";
import c101 from "../assets/collegelist/101.png";
import c102 from "../assets/collegelist/102.png";
import c103 from "../assets/collegelist/103.png";
import c104 from "../assets/collegelist/104.png";
import c105 from "../assets/collegelist/105.png";
import c106 from "../assets/collegelist/106.png";
import c107 from "../assets/collegelist/107.png";
import c108 from "../assets/collegelist/108.png";
import c109 from "../assets/collegelist/109.png";
import c110 from "../assets/collegelist/110.png";
import c111 from "../assets/collegelist/111.png";
import c112 from "../assets/collegelist/112.png";
import c113 from "../assets/collegelist/113.png";
import c114 from "../assets/collegelist/114.png";
import c115 from "../assets/collegelist/115.png";
import c116 from "../assets/collegelist/116.png";
import c117 from "../assets/collegelist/117.png";
import c118 from "../assets/collegelist/118.png";
import c119 from "../assets/collegelist/119.png";
import c120 from "../assets/collegelist/120.png";
import c121 from "../assets/collegelist/121.png";
import c122 from "../assets/collegelist/122.png";
import c123 from "../assets/collegelist/123.png";
import c124 from "../assets/collegelist/124.png";
import c125 from "../assets/collegelist/125.png";
import c126 from "../assets/collegelist/126.png";
import c127 from "../assets/collegelist/127.png";
import c128 from "../assets/collegelist/128.png";
import c129 from "../assets/collegelist/129.png";
import c130 from "../assets/collegelist/130.png";
import c131 from "../assets/collegelist/131.png";
import c132 from "../assets/collegelist/132.png";
import c133 from "../assets/collegelist/133.png";
import c134 from "../assets/collegelist/134.png";
import c135 from "../assets/collegelist/135.png";
import c136 from "../assets/collegelist/136.png";
import c137 from "../assets/collegelist/137.png";
import c138 from "../assets/collegelist/138.png";
import c139 from "../assets/collegelist/139.png";
import c140 from "../assets/collegelist/140.png";
import c141 from "../assets/collegelist/141.png";
import c142 from "../assets/collegelist/142.png";
import c143 from "../assets/collegelist/143.png";
import c144 from "../assets/collegelist/144.png";
import c145 from "../assets/collegelist/145.png";
import c146 from "../assets/collegelist/146.png";
import c147 from "../assets/collegelist/147.png";
import c148 from "../assets/collegelist/148.png";
import c149 from "../assets/collegelist/149.png";
import c150 from "../assets/collegelist/150.png";
import c151 from "../assets/collegelist/151.png";
import c152 from "../assets/collegelist/152.png";
import c153 from "../assets/collegelist/153.png";
import c154 from "../assets/collegelist/154.png";
import c155 from "../assets/collegelist/155.png";
import c156 from "../assets/collegelist/156.png";
import c157 from "../assets/collegelist/157.png";
import c158 from "../assets/collegelist/158.png";
import c159 from "../assets/collegelist/159.png";
import c160 from "../assets/collegelist/160.png";
import c161 from "../assets/collegelist/161.png";
import c162 from "../assets/collegelist/162.png";
import c163 from "../assets/collegelist/163.png";
import c164 from "../assets/collegelist/164.png";
import c165 from "../assets/collegelist/165.png";
import c166 from "../assets/collegelist/166.png";
import c167 from "../assets/collegelist/167.png";
import c168 from "../assets/collegelist/168.png";
import c169 from "../assets/collegelist/169.png";
import c170 from "../assets/collegelist/170.png";
import c171 from "../assets/collegelist/171.png";
import c172 from "../assets/collegelist/172.png";
import c173 from "../assets/collegelist/173.png";
import c174 from "../assets/collegelist/174.png";
import c175 from "../assets/collegelist/175.png";
import c176 from "../assets/collegelist/176.png";
import c177 from "../assets/collegelist/177.png";
import c178 from "../assets/collegelist/178.png";
import c179 from "../assets/collegelist/179.png";
import c180 from "../assets/collegelist/180.png";
import c181 from "../assets/collegelist/181.png";
import c182 from "../assets/collegelist/182.png";
import c183 from "../assets/collegelist/183.png";
import c184 from "../assets/collegelist/184.png";
import c185 from "../assets/collegelist/185.png";
import c186 from "../assets/collegelist/186.png";
import c187 from "../assets/collegelist/187.png";
import c188 from "../assets/collegelist/188.png";
import c189 from "../assets/collegelist/189.png";
import c190 from "../assets/collegelist/190.png";
import c191 from "../assets/collegelist/191.png";
import c192 from "../assets/collegelist/192.png";
import c193 from "../assets/collegelist/193.png";
import c194 from "../assets/collegelist/194.png";
import c195 from "../assets/collegelist/195.png";
import c196 from "../assets/collegelist/196.png";
import c197 from "../assets/collegelist/197.png";
import c198 from "../assets/collegelist/198.png";
import c199 from "../assets/collegelist/199.png";
import c200 from "../assets/collegelist/200.png";
import c201 from "../assets/collegelist/201.png";
import c202 from "../assets/collegelist/202.png";
import c203 from "../assets/collegelist/203.png";
import c204 from "../assets/collegelist/204.png";
import c205 from "../assets/collegelist/205.png";
import c206 from "../assets/collegelist/206.png";
import c207 from "../assets/collegelist/207.png";
import c208 from "../assets/collegelist/208.png";
import c209 from "../assets/collegelist/209.png";
import c210 from "../assets/collegelist/210.png";
import c211 from "../assets/collegelist/211.png";
import c212 from "../assets/collegelist/212.png";
import c213 from "../assets/collegelist/213.png";
import c214 from "../assets/collegelist/214.png";
import c215 from "../assets/collegelist/215.png";
import c216 from "../assets/collegelist/216.png";
import c217 from "../assets/collegelist/217.png";
import c218 from "../assets/collegelist/218.png";
import c219 from "../assets/collegelist/219.png";
import c220 from "../assets/collegelist/220.png";
import c221 from "../assets/collegelist/221.png";
import c222 from "../assets/collegelist/222.png";
import c223 from "../assets/collegelist/223.png";
import c224 from "../assets/collegelist/224.png";
import c225 from "../assets/collegelist/225.png";
import c226 from "../assets/collegelist/226.png";
import c227 from "../assets/collegelist/227.png";
import c228 from "../assets/collegelist/228.png";
import c229 from "../assets/collegelist/229.png";
import c230 from "../assets/collegelist/230.png";
import c231 from "../assets/collegelist/231.png";
import c232 from "../assets/collegelist/232.png";
import c233 from "../assets/collegelist/233.png";
import c234 from "../assets/collegelist/234.png";
import c235 from "../assets/collegelist/235.png";
import c236 from "../assets/collegelist/236.png";
import c237 from "../assets/collegelist/237.png";
import c238 from "../assets/collegelist/238.png";
import c239 from "../assets/collegelist/239.png";
import c240 from "../assets/collegelist/240.png";
import c241 from "../assets/collegelist/241.png";
import c242 from "../assets/collegelist/242.png";
import c243 from "../assets/collegelist/243.png";
import c244 from "../assets/collegelist/244.png";
import c245 from "../assets/collegelist/245.png";
import c246 from "../assets/collegelist/246.png";
import c247 from "../assets/collegelist/247.png";
import c248 from "../assets/collegelist/248.png";
import c249 from "../assets/collegelist/249.png";
import c250 from "../assets/collegelist/250.png";
import c251 from "../assets/collegelist/251.png";
import c252 from "../assets/collegelist/252.png";
import c253 from "../assets/collegelist/253.png";
import c254 from "../assets/collegelist/254.png";
import c255 from "../assets/collegelist/255.png";
import c256 from "../assets/collegelist/256.png";
import c257 from "../assets/collegelist/257.png";
import c258 from "../assets/collegelist/258.png";
import c259 from "../assets/collegelist/259.png";
import c260 from "../assets/collegelist/260.png";
import c261 from "../assets/collegelist/261.png";
import c262 from "../assets/collegelist/262.png";
import c263 from "../assets/collegelist/263.png";
import c264 from "../assets/collegelist/264.png";
import c265 from "../assets/collegelist/265.png";
import c266 from "../assets/collegelist/266.png";
import c267 from "../assets/collegelist/267.png";
import c268 from "../assets/collegelist/268.png";
import c269 from "../assets/collegelist/269.png";
import c270 from "../assets/collegelist/270.png";
import c271 from "../assets/collegelist/271.png";
import c272 from "../assets/collegelist/272.png";
import c273 from '../assets/collegelist/273.png';
import c274 from '../assets/collegelist/274.png';
import c275 from '../assets/collegelist/275.png';
import c276 from '../assets/collegelist/276.png';
import c277 from '../assets/collegelist/277.png';
import c278 from '../assets/collegelist/278.png';
import c279 from '../assets/collegelist/279.png';
import c280 from '../assets/collegelist/280.png';
import c281 from '../assets/collegelist/281.png';
import c282 from '../assets/collegelist/282.png';
import c283 from '../assets/collegelist/283.png';
import c284 from '../assets/collegelist/284.png';
import c285 from '../assets/collegelist/285.png';
import c286 from '../assets/collegelist/286.png';
import c287 from '../assets/collegelist/287.png';
import c288 from '../assets/collegelist/288.png';
import c289 from '../assets/collegelist/289.png';
import c290 from '../assets/collegelist/290.png';
import c291 from '../assets/collegelist/291.png';
import c292 from '../assets/collegelist/292.png';
import c293 from '../assets/collegelist/293.png';
import c294 from '../assets/collegelist/294.png';
import c295 from '../assets/collegelist/295.png';
import c296 from '../assets/collegelist/296.png';
import c297 from '../assets/collegelist/297.png';
import c298 from '../assets/collegelist/298.png';
import c299 from '../assets/collegelist/299.png';
import c300 from '../assets/collegelist/300.png';
import c301 from '../assets/collegelist/301.png';
import c302 from '../assets/collegelist/302.png';
import c303 from '../assets/collegelist/303.png';
import c304 from '../assets/collegelist/304.png';
import c305 from '../assets/collegelist/305.png';
import c306 from '../assets/collegelist/306.png';
import c307 from '../assets/collegelist/307.png';
import c308 from '../assets/collegelist/308.png';
import c309 from '../assets/collegelist/309.png';
import c310 from '../assets/collegelist/310.png';
import c311 from '../assets/collegelist/311.png';
import c312 from '../assets/collegelist/312.png';
import c313 from '../assets/collegelist/313.png';
import c314 from '../assets/collegelist/314.png';
import c315 from '../assets/collegelist/315.png';
import c316 from '../assets/collegelist/316.png';
import c317 from '../assets/collegelist/317.png';
import c318 from '../assets/collegelist/318.png';
import c319 from '../assets/collegelist/319.png';
import c320 from '../assets/collegelist/320.png';
import c321 from '../assets/collegelist/321.png';
import c322 from '../assets/collegelist/322.png';
import c323 from '../assets/collegelist/323.png';
import c324 from '../assets/collegelist/324.png';
import c325 from '../assets/collegelist/325.png';
import c326 from '../assets/collegelist/326.png';
import c327 from '../assets/collegelist/327.png';
import c328 from '../assets/collegelist/328.png';
import c329 from '../assets/collegelist/329.png';
import c330 from '../assets/collegelist/330.png';
import c331 from '../assets/collegelist/331.png';
import c332 from '../assets/collegelist/332.png';
import c333 from '../assets/collegelist/333.png';
import c334 from '../assets/collegelist/334.png';
import c335 from '../assets/collegelist/335.png';
import c336 from '../assets/collegelist/336.png';
import c337 from '../assets/collegelist/337.png';
import c338 from '../assets/collegelist/338.png';
import c339 from '../assets/collegelist/339.png';
import c340 from '../assets/collegelist/340.png';
import c341 from '../assets/collegelist/341.png';
import c342 from '../assets/collegelist/342.png';
import c343 from '../assets/collegelist/343.png';
import c344 from '../assets/collegelist/344.png';
import c345 from '../assets/collegelist/345.png';
import c346 from '../assets/collegelist/346.png';
import c347 from '../assets/collegelist/347.png';
import c348 from '../assets/collegelist/348.png';
import c349 from '../assets/collegelist/349.png';
import c350 from '../assets/collegelist/350.png';
import c351 from '../assets/collegelist/351.png';
import c352 from '../assets/collegelist/352.png';
import c353 from '../assets/collegelist/353.png';
import c354 from '../assets/collegelist/354.png';
import c355 from '../assets/collegelist/355.png';
import c356 from '../assets/collegelist/356.png';
import c357 from '../assets/collegelist/357.png';
import c358 from '../assets/collegelist/358.png';
import c359 from '../assets/collegelist/359.png';
import c360 from '../assets/collegelist/360.png';
import c361 from '../assets/collegelist/361.png';
import c362 from '../assets/collegelist/362.png';
import c363 from '../assets/collegelist/363.png';
import c364 from '../assets/collegelist/364.png';
import c365 from '../assets/collegelist/365.png';
import c366 from '../assets/collegelist/366.png';
import c367 from '../assets/collegelist/367.png';
import c368 from '../assets/collegelist/368.png';
import c369 from '../assets/collegelist/369.png';
import c370 from '../assets/collegelist/370.png';
import c371 from '../assets/collegelist/371.png';
import c372 from '../assets/collegelist/372.png';
import c373 from '../assets/collegelist/373.png';
import c374 from '../assets/collegelist/374.png';
import c375 from '../assets/collegelist/375.png';
import c376 from '../assets/collegelist/376.png';
import c377 from '../assets/collegelist/377.png';
import c378 from '../assets/collegelist/378.png';
import c379 from '../assets/collegelist/379.png';
import c380 from '../assets/collegelist/380.png';
import c381 from '../assets/collegelist/381.png';
import c382 from '../assets/collegelist/382.png';
import c383 from '../assets/collegelist/383.png';
import c384 from '../assets/collegelist/384.png';
import c385 from '../assets/collegelist/385.png';
import c386 from '../assets/collegelist/386.png';
import c387 from '../assets/collegelist/387.png';
import c388 from '../assets/collegelist/388.png';
import c389 from '../assets/collegelist/389.png';
import c390 from '../assets/collegelist/390.png';
import c391 from '../assets/collegelist/391.png';
import c392 from '../assets/collegelist/392.png';
import c393 from '../assets/collegelist/393.png';
import c394 from '../assets/collegelist/394.png';
import c395 from '../assets/collegelist/395.png';
import c396 from '../assets/collegelist/396.png';
import c397 from '../assets/collegelist/397.png';
import c398 from '../assets/collegelist/398.png';
import c399 from '../assets/collegelist/399.png';
import c400 from '../assets/collegelist/400.png';
import c401 from '../assets/collegelist/401.png';
import c402 from '../assets/collegelist/402.png';
import c403 from '../assets/collegelist/403.png';
import c404 from '../assets/collegelist/404.png';
import c405 from '../assets/collegelist/405.png';
import c406 from '../assets/collegelist/406.png';


const CollageList = [
  {
    id: 1,
    img: c1,
    title: "ABBS School of Law"
    
  },
  {
    id: 2,
    img: c2,
    title: "Acharya Bangalore B School",
    path: "/Abbscollegedetails"
  },
  {
    id: 3,
    img: c3,
    title: "Acharya Institute of Management Bangalore"
  },
  {
    id: 4,
    img: c4,
    title: "Acharya Institute of Technology"
  },
  {
    id: 5,
    img: c5,
    title: "Acharya NRV School of Architecture"
  },
  {
    id: 6,
    img: c6,
    title: "Achutha Institue of Technology"
  },
  {
    id: 7,
    img: c7,
    title: "Achutha NRV School of Architecture"
  },
  {
    id: 8,
    img: c8,
    title: "Achutha Institute of Technology"
  },
  {
    id: 9,
    img: c9,
    title: "ACPM Medical College"
  },
  {
    id: 10,
    img: c10,
    title: "ACS College of Engineering"
  },
  {
    id: 11,
    img: c11,
    title: "Adichunchanagiri Institute of Medical Sciences"
  },
  {
    id: 12,
    img: c12,
    title: "Ajeenkya D Y Patil University"
  },
  {
    id: 13,
    img: c13,
    title: "Al Ameen Dental College"
  },
  {
    id: 14,
    img: c14,
    title: "Alliance University"
  },
  {
    id: 15,
    img: c15,
    title: "Alliance University Law"
  },
  {
    id: 16,
    img: c16,
    title: "AMC Engineering College"
  },
  {
    id: 17,
    img: c17,
    title: "Amity University Noida",
    path: "/amityuniversity"
  },
  {
    id: 18,
    img: c18,
    title: "Amity University Bangalore"
  },
  {
    id: 19,
    img: c19,
    title: "Amurta Institute of Engineering"
  },
  {
    id: 20,
    img: c20,
    title: "APS College of Engineering"
  },
  {
    id: 21,
    img: c21,
    title: "Architecture Colleges in Bangalore"
  },
  {
    id: 22,
    img: c22,
    title: "Architecture Colleges in India"
  },
  {
    id: 23,
    img: c23,
    title: "Arihant Group of Institution"
  },
  {
    id: 24,
    img: c24,
    title: "Arts Colleges in Bangalore"
  },
  {
    id: 25,
    img: c25,
    title: "Arts Colleges in India"
  },
  {
    id: 26,
    img: c26,
    title: "Ashwini Ayurvedic Medical College"
  },
  {
    id: 27,
    img: c27,
    title: "Atreya Ayurvedic Medical College"
  },
  {
    id: 28,
    img: c28,
    title: "Atria Institute of Technology"
  },
  {
    id: 29,
    img: c29,
    title: "Atria University"
  },
  {
    id: 30,
    img: c30,
    title: "Ayurvedic Colleges in Bangalore"
  },
  {
    id: 31,
    img: c31,
    title: "Ayurvedic Colleges in Karnataka"
  },
  {
    id: 32,
    img: c32,
    title: "Balaji Insitute of Modern Management"
  },
  {
    id: 33,
    img: c33,
    title: "Bangalore City College"
  },
  {
    id: 34,
    img: c34,
    title: "Bangalore Institute of Dental Sciences"
  },
  {
    id: 35,
    img: c35,
    title: "Bangalore Institute of Technology"
  },
  {
    id: 36,
    img: c36,
    title: "Bangalore University"
  },
  {
    id: 37,
    img: c37,
    title: "Bangalore North University"
  },
  {
    id: 38,
    img: c38,
    title: "BAMS Colleges in Karnataka"
  },
  {
    id: 39,
    img: c39,
    title: "BA LLB Colleges in Bangalore"
  },
  {
    id: 40,
    img: c40,
    title: "BA LLB Colleges in Delhi"
  },
  {
    id: 41,
    img: c41,
    title: "BA LLB Colleges in India"
  },
  {
    id: 42,
    img: c42,
    title: "Bangalore School of Design"
  },
  {
    id: 43,
    img: c43,
    title: "Bapuji Dental College Hospital"
  },
  {
    id: 44,
    img: c44,
    title: "B Arch Colleges in Bangalore"
  },
  {
    id: 45,
    img: c45,
    title: "BBA Colleges in Delhi"
  },
  {
    id: 46,
    img: c46,
    title: "BBA Colleges in Hyderabad"
  },
  {
    id: 47,
    img: c47,
    title: "BBA Colleges in India"
  },
  {
    id: 48,
    img: c48,
    title: "BBA Colleges in Kolkata"
  },
  {
    id: 49,
    img: c49,
    title: "BBA Colleges in Mumbai"
  },
  {
    id: 50,
    img: c50,
    title: "BBA Colleges in Pune"
  },
  {
    id: 51,
    img: c51,
    title: "BBA LLB Colleges in Bangalore"
  },
  {
    id: 52,
    img: c52,
    title: "BBA LLB Colleges in Delhi"
  },
  {
    id: 53,
    img: c53,
    title: "BBA LLB Colleges in India"
  },
  {
    id: 54,
    img: c54,
    title: "BCA Colleges in Bangalore"
  },
  {
    id: 55,
    img: c55,
    title: "B.Com Colleges in Bangalore"
  },
  {
    id: 56,
    img: c56,
    title: "B.Com Colleges India"
  },
  {
    id: 57,
    img: c57,
    title: "B.Com LLB Colleges in Bangalore"
  },
  {
    id: 58,
    img: c58,
    title: "B.Com LLB Colleges in Delhi"
  },
  {
    id: 59,
    img: c59,
    title: "B.Com LLB Colleges in India"
  },
  {
    id: 60,
    img: c60,
    title: "Bachelor of Design Colleges in Bangalore"
  },
  {
    id: 61,
    img: c61,
    title: "BDS Colleges in Bangalore"
  },
  {
    id: 62,
    img: c62,
    title: "BDS Colleges in Karnataka"
  },
  {
    id: 63,
    img: c63,
    title: "Bennett University"
  },
  {
    id: 64,
    img: c64,
    title: "BEST Innovation Univeristy"
  },
  {
    id: 65,
    img: c65,
    title: "Best Schools in Bangalore"
  },
  {
    id: 66,
    img: c66,
    title: "Bharati Vidyapeeth University"
  },
  {
    id: 67,
    img: c67,
    title: "BHMS Colleges in Bangalore"
  },
  {
    id: 68,
    img: c68,
    title: "BML Munjal University"
  },
  {
    id: 69,
    img: c69,
    title: "BMS College for Women"
  },
  {
    id: 70,
    img: c70,
    title: "BMS Colleges of Architecture"
  },
  {
    id: 71,
    img: c71,
    title: "BMS College of Engineering"
  },
  {
    id: 72,
    img: c72,
    title: "BMS College of Law"
  },
  {
    id: 73,
    img: c73,
    title: "BMS Colleges in India"
  },
  {
    id: 74,
    img: c74,
    title: "BMS Institute of Technology"
  },
  {
    id: 75,
    img: c75,
    title: "BMS PU College for Women"
  },
  {
    id: 76,
    img: c76,
    title: "BMS Schools of Architecture"
  },
  {
    id: 77,
    img: c77,
    title: "BNM Institute of Technology"
  },
  {
    id: 78,
    img: c78,
    title: "BNYS Colleges in Bangalore"
  },
  {
    id: 79,
    img: c79,
    title: "Bodoland University"
  },
  {
    id: 80,
    img: c80,
    title: "BPT Colleges in Bangalore"
  },
  {
    id: 81,
    img: c81,
    title: "Brainware University"
  },
  {
    id: 82,
    img: c82,
    title: "Brindavan College of Engineering"
  },
  {
    id: 83,
    img: c83,
    title: "B.Sc LLB Colleges in Bangalore"
  },
  {
    id: 84,
    img: c84,
    title: "B.Sc LLB Colleges in Delhi"
  },
  {
    id: 85,
    img: c85,
    title: "B.Sc LLB Colleges in India"
  },
  {
    id: 86,
    img: c86,
    title: "B.Sc LLB Colleges in India"
  },
  {
    id: 87,
    img: c87,
    title: "C Byregowda Institute of Technology"
  },
  {
    id: 88,
    img: c88,
    title: "Chandrasekhar Institute of Speech and Hearing"
  },
  {
    id: 89,
    img: c89,
    title: "Channabasaveshwara Institute of Technology"
  },
  {
    id: 90,
    img: c90,
    title: "Chinmaya Vishwa Vidyapeeth"
  },
  {
    id: 91,
    img: c91,
    title: "Christ Academy Bangalore"
  },
  {
    id: 92,
    img: c92,
    title: "Christ Academy Law College"
  },
  {
    id: 93,
    img: c93,
    title: "Christ Academy MBA"
  },
  {
    id: 94,
    img: c94,
    title: "Christ Academy M.Sc Psychology"
  },
  {
    id: 95,
    img: c95,
    title: "Christ Law College"
  },
  {
    id: 96,
    img: c96,
    title: "Chirst University"
  },
  {
    id: 97,
    img: c97,
    title: "Chirst University BA LLB"
  },
  {
    id: 98,
    img: c98,
    title: "Chirst University BBA"
  },
  {
    id: 99,
    img: c99,
    title: "Chirst University BBA LLB"
  },
  {
    id: 100,
    img: c100,
    title: "Chirst University MBA"
  },
  { id: 101, img: c101, title: "Christian Medical College Vellore", path: "/christiancollegedetail" },
  { id: 102, img: c102, title: "CMR Institute of Technology" },
  { id: 103, img: c103, title: "CMR University Bangalore" },
  { id: 104, img: c104, title: "Dayanandar Sagar Academy of Technology & Mangament " },
  { id: 105, img: c105, title: "DSU School Of Law" },
  { id: 106, img: c106, title: "Dayanandar Sagar College of Art & Commerce" },
  { id: 107, img: c107, title: "Dayanandar Sagar College of Dental Sciences " },
  { id: 108, img: c108, title: "Dayanandar Sagar Colleges  of Engineering" },
  { id: 109, img: c109, title: "Dental Colleges in Bangalore" },
  { id: 110, img: c110, title: "Dental Colleges in India" },
  { id: 111, img: c111, title: "Dos Bosco Institute of Technology" },
  { id: 112, img: c112, title: "Dr Syamala Reddy Dental College" },
  { id: 113, img: c113, title: "Dr. T Thimaiah Institute of Technology" },
  { id: 114, img: c114, title: "DY Patil Medical College" },
  { id: 115, img: c115, title: "DY Patil Medical College Mumbai" },
  { id: 116, img: c116, title: "DY Patil Medical College Pune" },
  { id: 117, img: c117, title: "East West Institute of Technology" },
  { id: 118, img: c118, title: "FIIB Delhi" },
  { id: 119, img: c119, title: "FOSTIIMA Buisness School" },
  { id: 120, img: c120, title: "Garden City University" },
  { id: 121, img: c121, title: "Galgotias University" },
  { id: 122, img: c122, title: "Gauhati University" },
  { id: 123, img: c123, title: "Gautam Buddha University" },
  { id: 124, img: c124, title: "Gangadhar Meher Universiity" },
  { id: 125, img: c125, title: "Ganpat University" },
  { id: 126, img: c126, title: "Gandhigram Rural University" },
  { id: 127, img: c127, title: "GD Goenka University" },
  { id: 128, img: c128, title: "Geetanjali University" },
  { id: 129, img: c129, title: "Ghousia College of Engineering" },
  { id: 130, img: c130, title: "Glocal University" },
  { id: 131, img: c131, title: "Gondwana University" },
  { id: 132, img: c132, title: "Govind Guru Tribal University" },
  { id: 133, img: c133, title: "GSFC University" },
  { id: 134, img: c134, title: "G.S Group of College" },
  { id: 135, img: c135, title: "Gujarat Forensic Sciences Universty" },
  { id: 136, img: c136, title: "Gulbarga University" },
  { id: 137, img: c137, title: "Guru Angad Dev Veterinary and Animal Sciences University" },
  { id: 138, img: c138, title: "Guru Kashi University" },
  { id: 139, img: c139, title: "Guru Gobind Singh Indraprastha University" },
  { id: 140, img: c140, title: "Guru Nanak Dev University" },
  { id: 141, img: c141, title: "HKBK College of Engineering" },
  { id: 142, img: c142, title: "Harcourt Butler Technical Uniersity" },
  { id: 143, img: c143, title: "Hemwati Nandan Bahuguna Garhwal Univeristy" },
  { id: 144, img: c144, title: "Hemwati Nandan Bahuguna Uttarakhand Medical Education University" },
  { id: 145, img: c145, title: "Hemchandracharya North Gujurat University" },
  { id: 146, img: c146, title: "Hidayatullah National Law University" },
  { id: 147, img: c147, title: "Himachal Pradesh Techinical University " },
  { id: 148, img: c148, title: "Himachal Pradesh University Shimla" },
  { id: 149, img: c149, title: "Himachal Garhwal University" },
  { id: 150, img: c150, title: "Himachal University Itanagar" },
  { id: 151, img: c151, title: "Himgiri ZEE University" },
  { id: 152, img: c152, title: "IBA Bangalore" },
  { id: 153, img: c153, title: "IBMR Gurgaon" },
  { id: 154, img: c154, title: "ICFAI University, Dehradun" },
  { id: 155, img: c155, title: "ICFAI University, Himachal Pradesh" },
  { id: 156, img: c156, title: "ICFAI University, Jaipur" },
  { id: 157, img: c157, title: "ICFAI University, Jharkhand" },
  { id: 158, img: c158, title: "ICFAI University, Meghalaya" },
  { id: 159, img: c159, title: "ICFAI University, Mizoram" },
  { id: 160, img: c160, title: "ICFAI University, Nagaland" },
  { id: 161, img: c161, title: "ICFAI University, Raipur" },
  { id: 162, img: c162, title: "ICFAI University, Sikkim" },
  { id: 163, img: c163, title: "ICFAI University, Tripura" },
  { id: 164, img: c164, title: "IEC University" },
  { id: 165, img: c165, title: "IFIM Business School" },
  { id: 166, img: c166, title: "IFIM College Bangalore" },
  { id: 167, img: c167, title: "IFIM Law College" },
  { id: 168, img: c168, title: "IFTM University Moradabad" },
  { id: 169, img: c169, title: "IIBS Bangalore" },
  { id: 170, img: c170, title: "IIHMR University" },
  { id: 171, img: c171, title: "IIDE, Mumbai" },
  { id: 172, img: c172, title: "IIMT University" },
  { id: 173, img: c173, title: "IIIT-Bangalore" },
  { id: 174, img: c174, title: "IILM, Lodhi" },
  { id: 175, img: c175, title: "IMI Bhubaneswar" },
  { id: 176, img: c176, title: "IMI Kolkata" },
  { id: 177, img: c177, title: "IMS Ghaziabad" },
  { id: 178, img: c178, title: "IMS Noida" },
  { id: 179, img: c179, title: "Indian Academy" },
  { id: 180, img: c180, title: "Indian Institute of Fashion Technology" },
  { id: 181, img: c181, title: "Indo Asian Academy" },
  { id: 182, img: c182, title: "ISBM Pune" },
  { id: 183, img: c183, title: "ISBR Business School" },
  { id: 184, img: c184, title: "ISME Bangalore" },
  { id: 185, img: c185, title: "ITM Bangalore" },
  { id: 186, img: c186, title: "ITM Navi Mumbai" },
  { id: 187, img: c187, title: "ITM University" },
  { id: 188, img: c188, title: "ITS Ghaziabad" },
  { id: 189, img: c189, title: "Jain University" },
  { id: 190, img: c190, title: "Jain University Engineering" },
  { id: 191, img: c191, title: "Jaipuria School of Business, Ghaziabad" },
  { id: 192, img: c192, title: "Jaipuria Ghaziabad" },
  { id: 193, img: c193, title: "Jaipuria Noida" },
  { id: 194, img: c194, title: "Jaipuria Noida" },
  { id: 195, img: c195, title: "Karnataka Samskrit University" },
  { id: 196, img: c196, title: "KLE Degree College Nagarbhavi" },
  { id: 197, img: c197, title: "KLE Degree College Rajajinagar" },
  { id: 198, img: c198, title: "KLE Medical College" },
  { id: 199, img: c199, title: "KLE Society’s Institute of Dental Sciences" },
  { id: 200, img: c200, title: "KLE Society’s Law College" },
  { id: 201, img: c201, title: "KLE Society’s Institute of Hotel Management Catering Technology" },
  { id: 202, img: c202, title: "KNS Institute of Technology" },
  { id: 203, img: c203, title: "Krishnadevaraya College of Dental Sciences" },
  { id: 204, img: c204, title: "Krishna Institute of Medical Sciences, Karad" },
  { id: 205, img: c205, title: "Kristu Jayanti" },
  { id: 206, img: c206, title: "Krupanidhi College of Pharmacy" },
  { id: 207, img: c207, title: "Krupanidhi College of Management" },
  { id: 208, img: c208, title: "Lady Hardinge Medical College" },
  { id: 209, img: c209, title: "Liberal Arts Colleges In Bangalore" },
  { id: 210, img: c210, title: "LLB Colleges in Bangalore" },
  { id: 211, img: c211, title: "LLB College in Delhi" },
  { id: 212, img: c212, title: "LLB College in India" },
  { id: 213, img: c213, title: "LLM College in Bangalore" },
  { id: 214, img: c214, title: "LLM College in Delhi" },
  { id: 215, img: c215, title: "LLM College in India" },
  { id: 216, img: c216, title: "Maaruti Dental College" },
  { id: 217, img: c217, title: "Maharani Lakshmi Ammanni College for Women" },
  { id: 218, img: c218, title: "Maharashi Dayanand Saraswasti University" },
  { id: 219, img: c219, title: "Maharashi Animal and fishery Sciences University" },
  { id: 220, img: c220, title: "Malwanchal University" },
  { id: 221, img: c221, title: "Manipal Institute of Technology" },
  { id: 222, img: c222, title: "Maratha Mandal Dental College" },
  { id: 223, img: c223, title: "M.Arch Colleges in Bangalore" },
  { id: 224, img: c224, title: "MBA Colleges in Bangalore" },
  { id: 225, img: c225, title: "MBA Colleges in Chennai" },
  { id: 226, img: c226, title: "MBA Colleges in Delhi" },
  { id: 227, img: c227, title: "MBA Colleges in India" },
  { id: 228, img: c228, title: "MBA Colleges in Mumbai" },
  { id: 229, img: c229, title: "MBA Colleges in Pune" },
  { id: 230, img: c230, title: "MBA Colleges in Karnataka" },
  { id: 231, img: c231, title: "MD Colleges in Karnataka" },
  { id: 232, img: c232, title: "M Design Colleges in Bangalore" },
  { id: 233, img: c233, title: "MDS Colleges in Karnataka" },
  { id: 234, img: c234, title: "Medical Colleges in Bangalore" },
  { id: 235, img: c235, title: "Medical Colleges in India" },
  { id: 236, img: c236, title: "Medical Colleges in Karnataka" },
  { id: 237, img: c237, title: "Medical Colleges in Maharashtra" },
  { id: 238, img: c238, title: "Medical Colleges in Mumbai" },
  { id: 239, img: c239, title: "MGM Medical College" },
  { id: 240, img: c240, title: "MGM Medical College Aurangabad" },
  { id: 241, img: c241, title: "MGM Medical College Navi Mumbai" },
  { id: 242, img: c242, title: "Mizoram University" },
  { id: 243, img: c243, title: "Mody University" },
  { id: 244, img: c244, title: "Motherhood University" },
  { id: 245, img: c245, title: "Mother Teresa Women’s University" },
  { id: 246, img: c246, title: "Mount Carmel College" },
  { id: 247, img: c247, title: "MR Ambedkar Dental College" },
  { id: 248, img: c248, title: "MS Ayurvedic in Karnataka" },
  { id: 249, img: c249, title: "MS Colleges in Karnataka" },
  { id: 250, img: c250, title: "MS Ramaiah College of Law" },
  { id: 251, img: c251, title: "MS Ramaiah Dental College" },
  { id: 252, img: c252, title: "MS Ramaiah Institute of Management" },
  { id: 253, img: c253, title: "MS Ramaiah Institute of Technology" },
  { id: 254, img: c254, title: "MS Ramaiah Medical College" },
  { id: 255, img: c255, title: "MS Ramaiah University of Applied Science" },
  { id: 256, img: c256, title: "MVJ Medical College" },
  { id: 257, img: c257, title: "Naish College" },
  { id: 258, img: c258, title: "Nagaland University, Lumami" },
  { id: 259, img: c259, title: "Nandi Institute of Technology" },
  { id: 260, img: c260, title: "Nanaji Deshmukh Veterinary Science University" },
  { id: 261, img: c261, title: "Narendra dev University of Agriculture and Technology, Ayodhya" },
  { id: 262, img: c262, title: "National Institute of Design Bangalore" },
  { id: 263, img: c263, title: "National Law School of India University", path: "/nationalcollegedetail" },
  { id: 264, img: c264, title: "Navodaya Dental College" },
  { id: 265, img: c265, title: "Navodaya Medical College" },
  { id: 266, img: c266, title: "Navsari Agricultural University" },
  { id: 267, img: c267, title: "Navrachana University" },
  { id: 268, img: c268, title: "National Law University and Judicial Academy, Assam" },
  { id: 269, img: c269, title: "National University of Study and Research in Law" },
  { id: 270, img: c270, title: "New Delhi Institute of Management" },
  { id: 271, img: c271, title: "New Horizon College of Engineering" },
  { id: 272, img: c272, title: "NIILM University" },
  { id: 273, img: c273, title: "NIMS University, Jaipur" },
  { id: 274, img: c274, title: "NITTE Engineering College" },
  { id: 275, img: c275, title: "NITTE MBBS College" },
  { id: 276, img: c276, title: "Nilamber-Pitamber University" },
  { id: 277, img: c277, title: "Nirma University, Ahemdabad", path:"/institutecollegedetail" },
  { id: 278, img: c278, title: "NMKRV PU College for Women" },
  { id: 279, img: c279, title: "NMKRV College for Women" },
  { id: 280, img: c280, title: "Nursing College in Bangalore" },
  { id: 281, img: c281, title: "OP Jindal Global University" },
  { id: 282, img: c282, title: "Osmania University" },
  { id: 283, img: c283, title: "Oxford College of Engineering " },
  { id: 284, img: c284, title: "Oxford College of Nursing" },
  { id: 285, img: c285, title: "Oxford College of Science" },
  { id: 286, img: c286, title: "Oxford Dental College" },
  { id: 287, img: c287, title: "Pacific Medical University" },
  { id: 288, img: c288, title: "Pandit Ravishakar Shukla University" },
  { id: 289, img: c289, title: "PDM University Bahadurgarh" },
  { id: 290, img: c290, title: "PES University" },
  { id: 291, img: c291, title: "PES School of Law" },
  { id: 292, img: c292, title: "Pharmacy Colleges in Bangalore" },
  { id: 293, img: c293, title: "Physiotheraphy Colleges in Bangalore ( BPT )" },
  { id: 294, img: c294, title: "Physiotheraphy Colleges in India ( BPT )" },
  { id: 295, img: c295, title: "P.K University" },
  { id: 296, img: c296, title: "Pondicherry University" },
  { id: 297, img: c297, title: "Poornima University" },
  { id: 298, img: c298, title: "Prasanna Ayurvedic Medical College" },
  { id: 299, img: c299, title: "Pratap University" },
  { id: 300, img: c300, title: "Presidency College Bangalore" },
  { id: 301, img: c301, title: "Presidency University" },
  { id: 302, img: c302, title: "Punjabi University" },
  { id: 303, img: c303, title: "Punjabi Agricultural University" },
  { id: 304, img: c304, title: "Punyashlok Ahilyadevi Holkar University, Solaput" },
  { id: 305, img: c305, title: "Quantum University, Roorkee" },
  { id: 306, img: c306, title: "Ranbindra Bharati University" },
  { id: 307, img: c307, title: "Rabindranath Tagore University" },
  { id: 308, img: c308, title: "Rai Technology University" },
  { id: 309, img: c309, title: "Rai University- Ahemdabad School of Law" },
  { id: 310, img: c310, title: "Raiganj University" },
  { id: 311, img: c311, title: "RajaRajeswari Dental College" },
  { id: 312, img: c312, title: "Rajasthan University of Veterinary and Animal Sciences" },
  { id: 313, img: c313, title: "Rajasthan University of Health Sciences" },
  { id: 314, img: c314, title: "Rajasthan Technical University, Kota" },
  { id: 315, img: c315, title: "Rajiv Gandhi Dental College" },
  { id: 316, img: c316, title: "Rajiv Gandhi Institute of Technology" },
  { id: 317, img: c317, title: "Rama Devi Women’s University" },
  { id: 318, img: c318, title: "Ramaiah Institute of Business Studies" },
  { id: 319, img: c319, title: "Rani Lakshmi Bai Central Agricultutal University" },
  { id: 320, img: c320, title: "Rashtrasant Tukadoji Maharaj Nagpur University" },
  { id: 321, img: c321, title: "Ramaiah Institute of Legal Studies" },
  { id: 322, img: c322, title: "REVA University" },
  { id: 323, img: c323, title: "RIMS Bangalore" },
  { id: 324, img: c324, title: "RNS Institute of Technology Bangalore" },
  { id: 325, img: c325, title: "RNS School of Architecture" },
  { id: 326, img: c326, title: "R R Institute of Technology" },
  { id: 327, img: c327, title: "RV College of Architecture" },
  { id: 328, img: c328, title: "RV College of Engineering" },
  { id: 329, img: c329, title: "RV College of Nursing" },
  { id: 330, img: c330, title: "RV College of Physiotherapy" },
  { id: 331, img: c331, title: "RV Dental College" },
  { id: 332, img: c332, title: "RV Institute of Legal Studies" },
  { id: 333, img: c333, title: "RV Institute of Technology and Management" },
  { id: 334, img: c334, title: "RV University Bangalore" },
  { id: 335, img: c335, title: "RV School of Law" },
  { id: 336, img: c336, title: "Sai Vidya Institute of Technology" },
  { id: 337, img: c337, title: "Sambhram Institute of Technology" },
  { id: 338, img: c338, title: "Sapthagiri College of Allied Health Sciences" },
  { id: 339, img: c339, title: "Sapthagiri College of Engineering " },
  { id: 340, img: c340, title: "Sapthagiri College of Nursing" },
  { id: 341, img: c341, title: "Sapthagiri College Physiotherapy" },
  { id: 342, img: c342, title: "Sapthagiri Medical College" },
  { id: 343, img: c343, title: "SASTRA University" },
  { id: 344, img: c344, title: "Sadar Vallabhbhai Patel University of Agriculture and Technology" },
  { id: 345, img: c345, title: "Sardarkrushinagar Dantiwada Agriculture University" },
  { id: 346, img: c346, title: "Dr. Sarvepalli Radhakrishnan Rajathan Ayurvedic University" },
  { id: 347, img: c347, title: "Savitribai Phule Pune University" },
  { id: 348, img: c348, title: "Sharnbasva University" },
  { id: 349, img: c349, title: "Sharda University" },
  { id: 350, img: c350, title: "Shri Guru Ram Rai University" },
  { id: 351, img: c351, title: "Silicon City College" },
  { id: 352, img: c352, title: "Singhania University" },
  { id: 353, img: c353, title: "SJC Institute of Technology Bangalore" },
  { id: 354, img: c354, title: "SJES College of Mangament Studies" },
  { id: 355, img: c355, title: "SJR Law College Bangalore" },
  { id: 356, img: c356, title: "Sophia Evening College Bangalore" },
  { id: 357, img: c357, title: "Soundarya Law College" },
  { id: 358, img: c358, title: "Soundarya Institute of Management and Science" },
  { id: 359, img: c359, title: "SP Jain School of Global Management" },
  { id: 360, img: c360, title: "Sri Kalabyraveshwara Swamy Ayurvedic College" },
  { id: 361, img: c361, title: "Sri Krishnadevaraya University" },
  { id: 362, img: c362, title: "Sri Ramachandra Medical College & Research Ins." },
  { id: 363, img: c363, title: "Sri Siddharatha Dental College" },
  { id: 364, img: c364, title: "Sri Siddharatha Medical College" },
  { id: 365, img: c365, title: "SSMRV Degree College" },
  { id: 366, img: c366, title: "SSMRV PU College" },
  { id: 367, img: c367, title: "Starex University" },
  { id: 368, img: c368, title: "St George College" },
  { id: 369, img: c369, title: "St. Joseph College" },
  { id: 370, img: c370, title: "St. Joseph’s College of Commerce" },
  { id: 371, img: c371, title: "St. Joseph’s Dental College" },
  { id: 372, img: c372, title: "St. Joseph’s Law College" },
  { id: 373, img: c373, title: "Subbaiah Dental College" },
  { id: 374, img: c374, title: "Subbaiah Institute of Medical Sciences" },
  { id: 375, img: c375, title: "Surana College Bangalore" },
  { id: 376, img: c376, title: "Suresh Gyan Vihar University" },
  { id: 377, img: c377, title: "Sushrutha Ayurvedic Medical College" },
  { id: 378, img: c378, title: "Swarnim Gujarat Sports University" },
  { id: 379, img: c379, title: "Symbiosis Law College" },
  { id: 380, img: c380, title: "Symbiosis MBA" },
  { id: 381, img: c381, title: "Symbiosis Pune BA LLB" },
  { id: 382, img: c382, title: "Symbiosis Pune BBA LLB" },
  { id: 383, img: c383, title: "Symbiosis University BBA" },
  { id: 384, img: c384, title: "Tapmi Manipal" },
  { id: 385, img: c385, title: "Terna Medical College" },
  { id: 386, img: c386, title: "T John College" },
  { id: 387, img: c387, title: "Top Allied Health Science College in Bangalore" },
  { id: 388, img: c388, title: "Top Colleges in Bangalore" },
  { id: 389, img: c389, title: "Top Engineering College in Bangalore" },
  { id: 390, img: c390, title: "Top Engineering College in India" },
  { id: 391, img: c391, title: "Top Law Colleges in Bangalore" },
  { id: 392, img: c392, title: "Top Law Colleges in India" },
  { id: 393, img: c393, title: "Universal Business School" },
  { id: 394, img: c394, title: "UPES Dehradun" },
  { id: 395, img: c395, title: "Vemana Institute of Technology" },
  { id: 396, img: c396, title: "Vijaybhoomi University" },
  { id: 397, img: c397, title: "Visvesvaraya Institute of Technology" },
  { id: 398, img: c398, title: "VIT University Vellore" },
  { id: 399, img: c399, title: "Vokkaligara Sangha Dental College" },
  { id: 400, img: c400, title: "Vydehi College of Physiotherapy" },
  { id: 401, img: c401, title: "Vydehi Institute of Allied Health Sciences" },
  { id: 402, img: c402, title: "Vydehi Institute of Dental Sciences" },
  { id: 403, img: c403, title: "Vydehi Nursing College" },
  { id: 404, img: c404, title: "We School Welingkar" },
  { id: 405, img: c405, title: "St. Xavier’s University, Kolkata" },
  { id: 406, img: c406, title: "Yenepoya University Bangalore" },
];
export default CollageList;
