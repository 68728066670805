import { Box, Container, Grid, Hidden, Typography } from "@mui/material";
import React from "react";
import User from "../../../assets/CareerCounseling/Group 52.png";
import Group1 from "../../../assets/CareerCounseling/Group 55.png";
import Scan from "../../../assets/CareerCounseling/Scan.png";
import Group3 from "../../../assets/CareerCounseling/Group 53.png";
import User2 from "../../../assets/CareerCounseling/Users.png";

const Steps = () => {
  return (
    <Container >
      <Box
        mt={5}
        sx={{
          display: {
            xl: "screen",
            lg: "screen",
            md: "screen",
            sm: "none",
            xs: "none",
          },
        }}
      >
        <Typography
          className="box1"
          sx={{ fontSize: "45px", fontWeight: "700" }}
        >
          Get the <span style={{ color: "#EF5835" }}> Right </span> Career in
          just <span style={{ color: "#EF5835" }}> 4 -Steps </span>
        </Typography>
      </Box>
      <Grid
        mt={10}
        container
        sx={{
          display: {
            xl: "screen",
            lg: "screen",
            md: "screen",
            sm: "none",
            xs: "none",
          },
        }}
      >
        <Grid item xl={4.5} lg={4.5} md={4.5}>
          <Box>
            <Box  sx={{ display: "flex", justifyContent: "center" ,mt:"5rem"}}>
              <Typography className="box1" sx={{ fontSize: "25px" }}>
                Meet with our Experts
              </Typography>
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "center", mt: "9rem" }}
            >
              <img src={Scan} alt="" width="35%" />
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "center", width: "70%" }}
            >
              <Typography
                className="box1"
                sx={{ fontSize: "30px", width: "100%", paddingLeft: "25%" ,mt: "8rem" }}
              >
                Then we guide you towards a right career path.
              </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", mt: "4rem" }}>
              <img src={User2} alt="" width="40%" />
            </Box>
          </Box>
        </Grid>
        <Grid item xl={3} lg={3} md={3}>
          <Box mt={5}>
            <Box sx={{ display: "flex", justifyContent: "center",ml:"40%",width:{lg:"20%",md:"26%"} }}>
              <img src={Group1} alt="" width="100%" height="15%" />
            </Box>
          </Box>
        </Grid>
        <Grid item xl={4.5} lg={4.5} md={4.5}>
          <Box>
            <Box sx={{ display: "flex", justifyContent: "center", mt: "4rem" }}>
              <img src={User} alt="" width="38%" />
            </Box>
            <Box
              mt={15}
              sx={{ display: "flex", justifyContent: "center", mt: "3rem" }}
            >
              <Typography
                className="box1"
                sx={{ fontSize: "30px", width: "55%" }}
              >
                We analyze your true Potential
              </Typography>
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "center", mt: "3rem" }}
            >
              <img src={Group3} alt="" width="45%" />
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "center", mt: "6rem" }}
            >
              <Typography
                className="box1"
                sx={{ fontSize: "30px", width: "65%" }}
              >
                Also provide additional help in getting admitted to the college
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Box
        mt={5}
        sx={{
          display: {
            xl: "none",
            lg: "none",
            md: "none",
            sm: "screen",
            xs: "screen",
          },
        }}
      >
        <Typography
          className="box1"
          sx={{ fontSize: "25px", fontWeight: "700", textAlign: "center" }}
        >
          Get the <span style={{ color: "#EF5835" }}> Right </span> Career in
          just <span style={{ color: "#EF5835" }}> 4 -Steps </span>
        </Typography>
      </Box>
      <Grid
        mt={10}
        container
        sx={{
          display: {
            xl: "none",
            lg: "none",
            md: "none",
            sm: "screen",
            xs: "screen",
          },
        }}
      >
        <Grid item xl={4.5} lg={4.5} md={4.5}>
          <Box>
            <Box sx={{ display: "flex", justifyContent: "center", mt: "1rem" }}>
              <img src={User} alt="" width="40%" />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", mt: "4rem" }}>
              <Typography
                className="box1"
                sx={{
                  fontSize: "25px",
                  textAlign: "center",
                  fontWeight: "600",
                }}
              >
                Meet with our Experts
              </Typography>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "center", mt: "3rem" }}>
              <img src={Group3} alt="" width="40%" />
            </Box>

            <Box sx={{ display: "flex", justifyContent: "center", mt: "4rem" }}>
              <Typography
                className="box1"
                sx={{
                  fontSize: "25px",
                  textAlign: "center",
                  fontWeight: "600",
                }}
              >
                Then we guide you towards a right career path.
              </Typography>
            </Box>

            <Box>
              <Box
                sx={{ display: "flex", justifyContent: "center", mt: "3rem" }}
              >
                <img src={Scan} alt="" width="45%" />
              </Box>
              <Box
                mt={15}
                sx={{ display: "flex", justifyContent: "center", mt: "5rem" }}
              >
                <Typography
                  className="box1"
                  sx={{
                    fontSize: "25px",
                    textAlign: "center",
                    fontWeight: "600",
                  }}
                >
                  We analyze your true Potential
                </Typography>
              </Box>
              <Box
                sx={{ display: "flex", justifyContent: "center", mt: "3rem" }}
              >
                <img src={User2} alt="" width="45%" />
              </Box>
              <Box
                sx={{ display: "flex", justifyContent: "center", mt: "4rem" }}
              >
                <Typography
                  className="box1"
                  sx={{
                    fontSize: "25px",
                    textAlign: "center",
                    fontWeight: "600",
                  }}
                >
                  Also provide additional help in getting admitted to the
                  college
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Steps;
