const NationalPlacements = [
    {

        data: [
            { title: "Total number of domestic placements (2021-22)", value: "65" },
            { title: "Total number of Foreign placements (2021-22)", value: "0" },
            { title: "Pre-placement offers in 2021-22", value: "21" },
            { title: "Number of companies that offered jobs (2021-22)", value: "16" }
        ]
    },
    {

        data: [
            { title: "Highest salary (INR)", value: "18,00,000" },
            { title: "Average salary (INR)", value: "16,00,000" },
            { title: "Number of students that completed their LLB/ BA LLB degrees within the stipulated time", value: "76" },
            { title: "Percentage of students placed (2021-22)", value: "100%" }
        ]
    },
    {

        data: [
            { title: "Number of students that opted for higher studies (postgraduate) (2021-22)", value: "11" },
        ]
    },

];

export default NationalPlacements;
