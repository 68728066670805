const ChristianDelhiCourse = [
    {

        data: [
            { title: "Does the college offer PhD courses?", value: "1" },
            { title: "Number of PhDs graduated in 2021-22", value: "15" },
            { title: "Number of PhDs graduated in 2020-21", value: "4" },
        ]
    },
    {

        data: [
            { title: "Number of PhDs graduated in 2019-20", value: "10" },
            { title: "Does the college offer super speciality degrees (DM/ MCh)?", value: "Yes" },
            { title: "Number of students who earned super speciality degrees (DM/ MCh) in 2021-22", value: "56" },
        ]
    },
    {

        data: [
            { title: "Number of students who earned super speciality degrees (DM/ MCh) in 2020-21", value: "11" },
            { title: "Number of students who earned super speciality degrees (DM/ MCh) in 2019-20", value: "54" },
            { title: "Does the college offer fellowships?", value: "Yes" },
        ]
    },
    {

        data: [
            { title: "Number of students who completed their fellowships in 2021-22", value: "63" },
            { title: "Number of students who completed their fellowships in 2020-21", value: "91" },
            { title: "Number of students who completed their fellowships in 2019-20", value: "76" },
        ]
    },
    {

        data: [
            { title: "Does the college offer postgraduate courses?", value: "Yes" },
            { title: "Number of students who completed postgraduate courses in medicine/ surgery in 2021-22", value: "152" },
            { title: "Number of students who completed postgraduate courses in medicine/ surgery in 2020-21", value: "176" },
        ]
    },
    {

        data: [
            { title: "Number of students who completed postgraduate courses in medicine/ surgery in 2019-20", value: "21" },
        ]
    },

];

export default ChristianDelhiCourse;
