import React from "react";
import Navbar from "../../pages/home/Header/Navbar";
import Registration from "./Registration/Register";
import Addmition from "../../pages/home/Footer/footer";
import { Box } from "@mui/material";
const form = () => {
  return (
    <div>
      <Navbar />

      <Registration />
      <Addmition margintop={-0} />
    </div>
  );
};

export default form;
