

import { Box, Container, Grid, Link, Typography } from "@mui/material";
import React from "react";
import White from "../../../assets/Footer/White letter 1 .png";
import Group from "../../../assets/Footer/Group 13.png";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';

const Addmition = ({ margintop, paddingtop }) => {
  const openWhatsAppInNewTab = () => {
    // const phoneNumber = "+917980876611";
    const phoneNumber = "+919379660310";
    const whatsappLink = `https://wa.me/${phoneNumber}`;
    window.open(whatsappLink, "_blank");
  };


  const openFacebookPage = () => {
    const url = 'https://www.facebook.com/people/Career-Platform/61563527044618/';
    window.open(url, '_blank');
  };
  return (
    <Box sx={{ background: "#001724" }}>
      <Container maxWidth='xxl'>
        <Box className="box2" mt={margintop ?? -20} pt={paddingtop ?? 30} >
          <Grid item md={6} sm={6} >
            <Box
              mt={-5}
              sx={{
                color: "grey",
                display: "flex",
                justifyContent: "center",
                padding: {
                  xl: "0rem",
                  lg: "10px",
                  md: "0px",
                  sm: "0px",
                  xs: "0px",
                },
                textAlign: { xs: "center" },
                gap: { xl: "10%", lg: "9%", md: "10%" },
                flexDirection: {
                  xl: "row",
                  lg: "row",
                  md: "row",
                  sm: "column",
                  xs: "column",
                },
                paddingBottom: "5%",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  mt={10}
                  sx={{
                    display: "flex",
                    justifyContent: {
                      xl: "start",
                      lg: "start",
                      md: "start",
                      sm: "center",
                      xs: "center",
                    },
                  }}
                >
                  <img
                    src={White}
                    alt="White"
                    style={{ maxWidth: "100%", height: "50px" }}
                  />
                </Typography>
                <Typography
                  className="box2"
                  mt={3}
                  component="a"
                  href="tel:+919379660310"
                  sx={{
                    fontSize: { xl: "1rem", lg: "1rem", xs: "1rem" },
                    textAlign: {
                      xl: "start",
                      lg: "start",
                      md: "center",
                      xs: "center",
                    },
                    textDecoration: 'none',
                    color: 'inherit',
                    cursor: 'pointer',
                  }}
                >
                  (+91) 93796 60310
                </Typography>
                <Typography
                  mt={1}
                  className="box2"
                  component="a"
                  href="mailto:info@careerplatform.co.in"
                  sx={{
                    fontSize: { xl: "1rem", lg: "1rem", xs: "1rem" },
                    textAlign: {
                      xl: "start",
                      lg: "start",
                      md: "center",
                      xs: "center",
                    },
                    textDecoration: 'none',
                    color: 'inherit',
                    cursor: 'pointer',
                  }}
                >
                  info@careerplatform.co.in
                </Typography>
                <Typography
                  mt={1}
                  className="box2"
                  sx={{
                    fontSize: { xl: "1rem", lg: "1rem", xs: "1rem" },
                    textAlign: {
                      xl: "start",
                      lg: "start",
                      md: "center",
                      xs: "center",
                    },
                  }}
                >
                  3rd flr, HRBR Layout 1st Block, HRBR Layout, <br />Kalyan Nagar, Bengaluru, Karnataka 560043
                  <br />
                  Landmark: Above ICICI Bank
                </Typography>
              </Box>
              <Box>
                <Typography
                  mt={10}
                  className="box2"
                  sx={{
                    color: "#007BC1",
                    fontSize: { xl: "1.5rem", lg: "1.5rem", md: "1rem" },
                    textAlign: {
                      xl: "start",
                      lg: "start",
                      md: "center",
                      xs: "center",
                    },
                  }}
                >
                  Company
                </Typography>
                <Typography
                  mt={3}
                  className="box2"
                  sx={{
                    fontSize: { xl: "1rem", lg: "1rem", xs: "1rem" },
                    textAlign: {
                      xl: "start",
                      lg: "start",
                      md: "center",
                      xs: "center",
                    },
                  }}
                >
                  <Link href="/aboutus" sx={{ color: "grey", textDecoration: "none" }}>
                    About Us
                  </Link>
                </Typography>


              </Box>
              <Box>
                <Typography
                  mt={10}
                  className="box2"
                  sx={{
                    color: "#007BC1",
                    fontSize: { xl: "1.5rem", lg: "1.5rem", md: "1rem" },
                    textAlign: {
                      xl: "start",
                      lg: "start",
                      md: "center",
                      xs: "center",
                    },
                  }}
                >
                  Quick Links
                </Typography>
                <Typography
                  mt={3}
                  className="box2"
                  sx={{
                    fontSize: { xl: "1rem", lg: "1rem", xs: "1rem" },
                    textAlign: {
                      xl: "start",
                      lg: "start",
                      md: "center",
                      xs: "center",
                    },
                  }}
                >
                  <Link href={"/"} sx={{ color: "grey", textDecoration: "none" }}>Home{" "}</Link>
                </Typography>
                <Typography
                  mt={1}
                  className="box2"
                  sx={{
                    fontSize: { xl: "1rem", lg: "1rem", xs: "1rem" },
                    textAlign: {
                      xl: "start",
                      lg: "start",
                      md: "center",
                      xs: "center",
                    },
                  }}
                >
                  <Link href="/career" sx={{ color: "grey", textDecoration: "none" }}>
                    Career Counselling
                  </Link>
                </Typography>
                <Typography
                  mt={1}
                  className="box2"
                  sx={{
                    fontSize: { xl: "1rem", lg: "1rem", xs: "1rem" },
                    textAlign: {
                      xl: "start",
                      lg: "start",
                      md: "center",
                      xs: "center",
                    },
                  }}
                ><Link href="/college-list" sx={{ color: "grey", textDecoration: "none" }}>
                    Top Colleges
                  </Link>
                </Typography>
                {/* <Typography
                  mt={1}
                  className="box2"
                  sx={{
                    fontSize: { xl: "1rem", lg: "1rem", xs: "1rem" },
                    textAlign: {
                      xl: "start",
                      lg: "start",
                      md: "center",
                      xs: "center",
                    },
                  }}
                >
                  Top Universities
                </Typography> */}
              </Box>
              <Box>
                <Typography
                  mt={10}
                  className="box2"
                  sx={{
                    color: "#007BC1",
                    fontSize: { xl: "1.5rem", lg: "1.5rem", md: "1rem" },
                    textAlign: {
                      xl: "start",
                      lg: "start",
                      md: "center",
                      xs: "center",
                    },
                  }}
                >
                  Useful Links
                </Typography>
                <Typography
                  mt={3}
                  className="box2"
                  sx={{
                    fontSize: { xl: "1rem", lg: "1rem", xs: "1rem" },
                    textAlign: {
                      xl: "start",
                      lg: "start",
                      md: "center",
                      xs: "center",
                    },
                  }}
                >
                  <Link href="/refundpolicy" sx={{ color: "grey", textDecoration: "none" }}>

                    Refund Policy
                  </Link>

                </Typography>
                <Typography
                  mt={1}
                  className="box2"
                  sx={{
                    fontSize: { xl: "1rem", lg: "1rem", xs: "1rem" },
                    textAlign: {
                      xl: "start",
                      lg: "start",
                      md: "center",
                      xs: "center",
                    },
                  }}
                >
                  <Link href="/privacypolicy" sx={{ color: "grey", textDecoration: "none" }}>

                    Privacy policy
                  </Link>

                </Typography>
                <Typography
                  mt={1}
                  className="box2"
                  sx={{
                    fontSize: { xl: "1rem", lg: "1rem", xs: "1rem" },
                    textAlign: {
                      xl: "start",
                      lg: "start",
                      md: "center",
                      xs: "center",
                    },
                  }}
                >
                  <Link href="/termsandconditions" sx={{ color: "grey", textDecoration: "none" }}>
                    Terms and Conditions
                  </Link>
                </Typography>
              </Box>
              <WhatsAppIcon
                message="Hello, how can we help you?"
                phoneNumber="+919379660310"
                position="right"
                onClick={openWhatsAppInNewTab}
                sx={{
                  position: "fixed",
                  bottom: "20px",
                  right: "20px",
                  fontSize: "40px",
                  padding: "8px",
                  backgroundColor: "#25d366",
                  color: "#fff !important",
                  border: "none",
                  height: "50px",
                  width: "50px",
                  borderRadius: "50%",
                  cursor: "pointer",
                  zIndex: 20,
                }}
              />
              <FacebookIcon
                message="Hello, how can we help you?"
                phoneNumber="6267293696"
                position="left"
                onClick={openFacebookPage}

                style={{
                  position: "fixed",
                  bottom: "20px",
                  fontSize: "40px",
                  padding: "8px",
                  right: "90px",
                  height: "50px",
                  width: "50px",
                  color: "#F0F0F0",
                  backgroundColor: "#1976d2",
                  border: "none",
                  borderRadius: "50%",
                  cursor: "pointer",
                  zIndex: 20,
                }}
              />
            </Box>

            <Box sx={{ marginTop: { lg: "0%", xs: "0rem" }, width: "100%" }}>
              <Typography sx={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={Group}
                  alt=""
                  style={{ maxWidth: "100%", height: "auto" }}
                />
              </Typography>
            </Box>

            <Box sx={{
              marginTop: {
                lg: "0%", xs: "0rem",
              },
              pb: "40px"
            }}>
              <Typography variant="h6" sx={{ color: "#fff", textAlign: "center" }}>
                Copyright © 2024 All Rights Reserved - CareerPlatform
              </Typography>
              <Typography variant="h6" sx={{ color: "#fff", textAlign: "center" }}>
                Made with ❤️ by &nbsp;<Link href="https://www.evdtechnology.com/" sx={{ color: "#EF5835", textDecoration: "none" }}>EVD Technology</Link>
              </Typography>
            </Box>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default Addmition;
