import { Box, Container, Grid, Typography } from '@mui/material';
import React from 'react'
import image from '../../assets/aboutuskey.png'
import '../../careerplatform.css'


const AboutUsTypeService = () => {
    const reasons = [
        {
            title: "School-Based",
            content: "Advisors, counselors, mentors.",
        },
        {
            title: "Private Agencies",
            content: "Comprehensive support, especially for international students.",
        },
        {
            title: "Community Programs",
            content: "Non-profit mentorship and tutoring",
        },
        {
            title: "Choosing the Right Service",
        },
        {
            title: "Consider the service's reputation,",
            content: "Range, communication quality, and cost",
        },

    ];
    return (

        <Container sx={{ margin: "30px auto" }}>
            <Typography variant="h4" className='box1' component="h2" color="#EF5835" textAlign={"center"} sx={{ fontWeight: 'bold', pt: "10px" }}>
                Types of Services

            </Typography>
            <Grid container spacing={2} columns={{}} sx={{ display: "flex", justifyContent: "center", pt: "30px", pb: "30px" }}>

                {reasons?.map((reason) => (

                    <Grid item md={3} xs={12} sm={6} sx={{ display: "flex", justifyContent: "center" }}>

                        <Box sx={{ width: "210px" }}>

                            <Box sx={{ width: "100px" }}>
                                <img src={image} alt="aboutus_image" width={"100%"} />
                            </Box>
                            <Typography variant="body1" className='box1' sx={{ fontWeight: 'bold', color: "#EF5835", fontSize: "18px" }}>
                                {reason.title}                            </Typography>
                            <Typography variant="body2" className='box2' sx={{ color: "#000", fontSize: "16px" }}>
                                {reason.content}                            </Typography>
                        </Box>
                    </Grid>
                ))}
            </Grid>

        </Container>
    )
}

export default AboutUsTypeService
