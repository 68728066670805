const NationaDelhiCourse = [
    {

        data: [
            { title: "Does the college offer PhD courses?", value: "Yes" },
            { title: "Number of PhDs graduated in 2021-22", value: "6" },
            { title: "Number of PhDs graduated in 2020-21", value: "4" },
        ]
    },
    {

        data: [
            { title: "Number of PhDs graduated in 2019-20", value: "3" },
            { title: "Does the college offer postgraduate courses?", value: "Yes" },
            { title: "Number of students who completed postgraduate courses in 2021-22", value: "4" },
        ]
    },
    {

        data: [
            { title: "Number of students who completed postgraduate courses in 2020-21", value: "46" },
            { title: "Number of students who completed postgraduate courses in 2019-20", value: "46" },
        ]
    },

];

export default NationaDelhiCourse;
