import React from "react";
import {

  CardMedia,
  Typography,
  Grid,
  Container,
  Box,
} from "@mui/material";
import Frame2 from "../../../assets/Streams/Frame 2.png";
import Frame3 from "../../../assets/Streams/Frame 3.png";
import Frame4 from "../../../assets/Streams/Frame 4.png";
import Frame5 from "../../../assets/Streams/Frame 5.png";
import Frame6 from "../../../assets/Streams/Frame 6.png";
import Frame7 from "../../../assets/Streams/Frame 7.png";
import Frame8 from "../../../assets/Streams/Frame 8.png";
import Frame9 from "../../../assets/Streams/Frame 9.png";
import { useNavigate } from "react-router-dom";
// import "./App.css";
// import './Streams.css';

const occupancyData = [
  { img: Frame2, type: "Engineering", params: '/engineeringcollege' },
  { img: Frame3, type: "Management", params: '/managementcollege' },
  { img: Frame4, type: "Medical", params: '/medicalcollege' },
  { img: Frame5, type: "Law" },
  { img: Frame6, type: "Architecture" },
  { img: Frame7, type: "Ayurvedic" },
  { img: Frame8, type: "Dental" },
  { img: Frame9, type: "Commerce" },
  // Add more occupancy data as needed
];

const Amenities = () => {
  const navigate = useNavigate()
  return (
    <Container>
      <Grid mt={5}>
        <Grid
          className="Streams"
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "flexstart",
          }}
        >
          <Typography className="box1" sx={{ fontSize: "2.5rem" }}>
            <span style={{ color: "#EF5835", fontWeight: "700" }}>I</span>{" "}
            Choose From your
            <span style={{ color: "#EF5835", fontWeight: "700" }}>
              {" "}
              Favourite
              <br />
            </span>{" "}
            <span style={{ color: "#EF5835", fontWeight: "700", paddingLeft: "30px" }}>Streams</span>
          </Typography>
        </Grid>

        <Grid
          container
          rowGap={{ xl: 8, lg: 8, md: 7, sm: 6, xs: 3 }}
          spacing={1}
          style={{ marginTop: "35px", marginLeft: "-50px" }}
        >
          {occupancyData.map((item, index) => (
            <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
              <Box onClick={() => navigate(item.params)}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",

                  flexDirection: "column",
                  gap: { xl: 4, lg: 4, md: 3, sm: 3, xs: 1 },
                }}
              >
                <CardMedia
                  component="img"
                  className="img"
                  src={item.img}
                  alt=""
                  sx={{ width: "50%" }}
                />
                <Typography
                  className="box2"
                  variant="body2"
                  component="p"
                  sx={{
                    fontSize: "20px",
                    fontWeight: "545",
                  }}

                >
                  {item.type}
                </Typography>{" "}
              </Box>
            </Grid>
          ))}

        </Grid>

      </Grid>
    </Container>
  );
};

export default Amenities;
