import React from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box
} from "@mui/material";

const SingleHeaderDynamicTable = ({ data, margin, headercolor, showheader = true }) => {


    return (
        <Box sx={{ width: "100%" }}>
            <TableContainer component={Paper}
                sx={{

                    margin: margin ?? "0",
                }}
            >
                <Table>
                    {showheader && (
                        <TableHead>
                            <TableRow sx={{ backgroundColor: "#EF5835" }}>
                                <TableCell colSpan={2} sx={{
                                    color: headercolor ?? "#880800",
                                    fontWeight: "600",
                                    textAlign: "start",
                                    verticalAlign: "top",
                                    fontSize: "16px",
                                    borderBottom: "none"

                                }} >{data?.header}</TableCell>

                            </TableRow>
                        </TableHead>
                    )}
                    <TableBody sx={{ borderCollapse: "collapse" }}>
                        {data?.data.map((item, index) => (
                            <React.Fragment key={item.id}>
                                <TableRow
                                    sx={{
                                        width: "100%",
                                        backgroundColor:
                                            index % 2 === 0 ? "#FF876B" : "#EF5835",

                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            borderRight: "4px solid white",
                                            width: "40%",
                                            color: "#ffffff",
                                            fontWeight: "600",
                                            textAlign: "start",
                                            verticalAlign: "top",
                                            borderBottom: "none"
                                        }}
                                    >
                                        {item.title}
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            width: "60%",
                                            color: "#ffffff",
                                            fontWeight: "400",
                                            padding: "10px 40px",
                                            borderBottom: "none"

                                        }}
                                    >

                                        {item.value}

                                    </TableCell>
                                </TableRow>

                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default SingleHeaderDynamicTable;
