const AmityAcademicProgression = [
    {

        data: [
            { title: "Setting up Centres for Preservation and Promotion of Endangered Languages in Central universities", value: "No" },
            { title: "Number of companies set up at the incubation centre in the past 5 years", value: "No" },
            { title: "Establishment of a Chair in the name of Nobel laureates/ other illustrious persons", value: "No" },
        ]
    },
    {

        data: [

            { title: "Epoch-making social thinkers scheme", value: "No" },
            { title: "Development of sports infrastructure and equipment in universities and colleges", value: "No" },
            { title: "Assistance from the UGC under NME-ICT for development of e-content", value: "No" },
        ]
    },
    {

        data: [

            { title: "Assistance for setting up an equal-opportunity cell", value: "No" },
            { title: "Universities with Potential for Excellence (UPE)/ Universities of Excellence (UoE) scheme", value: "No" },
            { title: "Setting up Deen Dayal Upadhyay Centres for Knowledge Acquisition and Upgradation of Skilled Human Abilities and Livelihood (KAUSHAL)", value: "No" }
        ]
    },


];

export default AmityAcademicProgression;
