import React from 'react'
import Addmition from '../home/Footer/footer'
import Navbar from "../home/Header/Navbar";
import AboutUsBanner from './AboutUsBanner';
import AboutService from './AboutService';
import { Box, Container, Typography } from '@mui/material';
import AboutUsBenefits from './AboutUsBenefits';
import AboutUsTypeService from './AboutUsTypeService';
import AboutUsKey from './AboutUsKey';
import '../../careerplatform.css'


const AboutUsPage = () => {
    return (
        <>
            <Navbar />
            <AboutUsBanner />
            <AboutService />
            <Box sx={{ bgcolor: "#ef5835 ", padding: "20px 0" }}>
                <Container>
                    <Typography variant="body1" className='box1' sx={{ fontWeight: 'bold', color: "#fff", textAlign: "center", fontSize: "24px" }}>
                        Guardian Services for Parents and Students
                    </Typography>
                    <Typography variant="body2" className='box2' sx={{ color: "#fff", textAlign: "center", fontSize: "18px" }}>
                        Guardian services support students' academic, emotional, and social needs while keeping parents informed and involved
                    </Typography>
                </Container>
            </Box>
            <AboutUsKey />
            <AboutUsBenefits />
            <AboutUsTypeService />

            <Box sx={{ bgcolor: "#ef5835", padding: "20px 0" }}>
                <Container>

                    <Typography variant="body1" className='box1' sx={{ fontWeight: 'bold', color: "#fff", textAlign: "center", fontSize: "24px" }}>
                        Conclusion
                    </Typography>
                    <Typography variant="body2" className='box2' sx={{ color: "#fff", textAlign: "center", fontSize: "18px" }}>
                        Guardian services ensure students thrive academically and personally while keeping parents reassured and involved. These services are essential in modern education.
                        Join us as we empower minds, inspire dreams, and shape the leaders of tomorrow. Together, let's unlock the limitless potential of education.
                    </Typography>
                </Container>

            </Box>

            <Addmition margintop={"40px"} paddingtop={"-30px"} />
        </>
    )
}

export default AboutUsPage
