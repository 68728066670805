const jeeAdvanceDetailContentJson = {
    formatName: "Joint Entrance Exam Advanced",
    shortHand: "JEE Advanced",
    testingAuthority: "IIT Bombay (2022) (Under authority of the Joint Admission Board)",
    examMode: "Computer based",
    frequency: "Once a year",
    duration: "Two papers, 3 hours each",
    sections: "3 (in each paper)",
    languagesAvailable: "English, Hindi",
    questionFormat: "Multiple choice questions, Questions with numerical answers, Single digit integer answer types",
    numberofQuestions: "Paper 1: 54; Paper 2: 54",
    examPattern:
        "Both papers are conducted over a single day. Paper 1 comprises 18 questions from Physics, Chemistry and Math (each), further subdivided into three sections based on question type.",
    totalMarks: "396",
    noofTestCitiesCentres: 217,
    coursesAvailableAfterExam: ["4-year Bachelors - BTech, BS", "5-year Barch", "5-year Dual Degree - BTech, MTech, BS, MS", "5-year Integrated Masters - MTech, MSc, Dual Degre"],
    website: "https://jeeadv.ac.in/",
    contactDetails: [
        "Address: JEE (Advanced) Office, IIT Bombay, Mumbai - 400076‍",
        "Phone: +91-22-2576 4063 / +91-22-2576 9093",
        "Email: jeeadv2022@iitb.ac.in"
    ]
};
export default jeeAdvanceDetailContentJson;
