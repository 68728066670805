const IITDelhiAboutData = {
  address: "Hauz Khas, New Delhi, Delhi - 110016",
  city: "New Delhi",
  state: "Delhi",
  email: "rankingiitd@admin.iitd.ac.in",
  established: "1961",
  btechgraduated: "1965",
  head: "Prof. Rangan Banerjee",
  type: "Government",
  website: "www.iitd.ac.in",
  raggingcell: "Yes",
  nationalservice:
    "Yes",
  complaintcell: "Yes",
  qualityassurance:
    "Yes",
  noofgraduades: "Yes",

};
export default IITDelhiAboutData;
