import React, { useEffect } from "react";
import { Box, Typography, Grid } from "@mui/material";
import iitdelhi from "../../../assets/IItdelhi.png";
import { Icon } from "@iconify/react";


const AiimsBanner = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);



    return (
        <>
            <Box height={"auto"} width={"auto"}>
                <Grid container padding={"0px "}>
                    <Grid item md={5} sm={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                        <Box sx={{ display: "flex", alignItems: "left", flexDirection: "column" }}>
                            <Typography sx={{ fontSize: "24px", color: "#ef5835", fontWeight: "600" }}>

                                All India Institute of <br></br>Medical Sciences

                            </Typography>
                            <Box sx={{ display: "flex", flexDirection: "row" }}>
                                <Icon icon="mdi:location" fontSize="22px" color="#ef5835" />
                                <Typography sx={{ fontSize: "18px", fontWeight: "400" }}>

                                    New Delhi, Delhi
                                </Typography>
                            </Box>


                        </Box></Grid>
                    <Grid item md={7} sm={12} sx={{ display: "flex", justifyContent: { md: "end", sm: "center", xs: "center" } }}>
                        <img src={iitdelhi} alt={"CollegeName"} style={{ width: "100%" }} />
                    </Grid>
                </Grid>
            </Box>


        </>
    );
};

export default AiimsBanner;


