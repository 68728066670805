const JeeAdvanceCollegesDataJson = {
    listofcollegesacceptingtheexamscorerank: [
        "Indian Institute of Technology (Banaras Hindu University)",
        "Indian Institute of Technology Bhubaneswar",
        "Indian Institute of Technology Bombay",
        "Indian Institute of Technology Delhi",
        "Indian Institute of Technology Guwahati",
        "Indian Institute of Technology Hyderabad",
        "Indian Institute of Technology Indore",
        "Indian Institute of Technology Jodhpur",
        "Indian Institute of Technology Kanpur",
        "Indian Institute of Technology Kharagpur",
        "Indian Institute of Technology Madras",
        "Indian Institute of Technology Mandi",
        "Indian Institute of Technology Palakkad",
        "Indian Institute of Technology Patna",
        "Indian Institute of Technology Roorkee",
        "Indian Institute of Technology Ropar",
        "Indian Institute of Technology Tirupati",
        "Indian Institute of Technology Bhilai",
        "Indian Institute of Technology Goa",
        "Indian Institute of Technology Jammu",
        "Indian Institute of Technology Dharwad",
        "Indian Institute of Technology (Indian School Of Mines), Dhanbad",
        "Indian Institute of Science (IISc), Bangalore",
        "Indian Institute of Petroleum and Energy (IIPE), Visakhapatnam",
        "Indian Institutes of Science Education and Research (IISER), Bhopal",
        "Indian Institutes of Science Education and Research (IISER), Mohali",
        "Indian Institutes of Science Education and Research (IISER), Kolkata",
        "Indian Institutes of Science Education and Research (IISER), Pune",
        "Indian Institutes of Science Education and Research (IISER), Thiruvananthapuram",
        "Indian Institute of Petroleum and Energy, Visakhapatnam",
        "Rajiv Gandhi Institute of Petroleum Technology (RGIPT), Rae Bareli"
    ]
};

export default JeeAdvanceCollegesDataJson;
