const AbbsAdmissionContent = [
    {
        title: "Selection Criteria:",
        content: [
            "Through the website can fill ABBS application form. Acharya Bangalore B-School admission to courses will be according to merit and score secured by the candidates in entrance exams. The final admissions process will have group discussions and personal Interviews, which all the selected students should attend to confirm seats.",

        ]
    },
    {
        title: "Eligibility Criteria:",
        content: [
            "Students who have completed their 10+2 are eligible to apply for ABBS admissions. Shortlisting of students will be done based on their application form and finally called for personal Interviews and group discussions"
        ]
    },
    {
        title: "Admission Process",
        content: [
           "Candidates should register on the official website of ABBS and apply for the desired course.",

            "After the application process, the screening process will be done in which the selection committee of ABBS will evaluate students and shortlist them based on their profiles.",
            
            "There will be aptitude tests, personal interviews, and group discussions during the selection process.",
            
            "Students will receive an admission offer letter upon confirmation of admissions to ABBS."
        ]
    },
   
];

export default AbbsAdmissionContent;
