const examDetailContentJson = {
  formatName: "All India Law Entrance Test",
  shortHand: "AILET",
  testingAuthority: "National Law University, Delhi",
  examMode: "Pen and paper based (in person)",
  frequency: "Once a year",
  duration: "1 hour 30 minutes",
  sections: "English, Current Affairs & General Knowledge, Logical Reasoning",
  languagesAvailable: "English",
  questionFormat: "Objective type",
  numberofQuestions: 150,
  examPattern:
    "The exam consists of 3 sections: SectionA (English) 50 questions, 50 marks; Section B (Current Affairs & General Knowledge), 30 questions, 30 marks; Section C (Logical Reasoning) 70 questions, 70 marks",
  totalMarks: "150",
  markingScheme:
    "(+1) for correct answers, (-0.25) for incorrect answers, 0 for unattempted questions",
  noofTestCitiesCentres: 24,
  coursesAvailableAfterExam: ["BA", "LLB", "LLM", "PhD"],
  website: "https://nludelhi.ac.in/ailet2022.aspx",
  contactDetails: [
    "Contact Number: 011-40787555",

    "E-mail: ailetsupport@nludelhi.ac.in"
  ]
};
export default examDetailContentJson;
