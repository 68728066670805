import { Box, Container, Tabs, Tab, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ExamDetailContent from "../../EntranceExam/ExamDetails/ExamDetailContent";

import SingleHeaderDynamicTable from "../../../components/TableComponent/SingleheaderTable";

import StripHeaderComponent from "../../../components/StripHeadercomponent";
import StripTitle from "../../../components/StripTitle";


import AmityAboutData from "../../../json/CollegeDetailsJson/AmityUniversityJson/AmityAboutData";
import AmityAboutHeader from "../../../json/CollegeDetailsJson/AmityUniversityJson/AmityAboutHeader";
import AmityAdmissionContent from "../../../json/CollegeDetailsJson/AmityUniversityJson/AmityAdmissionContent";
import AmityCourseData from "../../../json/CollegeDetailsJson/AmityUniversityJson/AmityCourseDat";
import AmityCourseHeader from "../../../json/CollegeDetailsJson/AmityUniversityJson/AmityCourseHeader";
import AmityFeeData from "../../../json/CollegeDetailsJson/AmityUniversityJson/AmityFeedata";
import AmityFeeHeader from "../../../json/CollegeDetailsJson/AmityUniversityJson/AmityFeeHeader";
import AmityPlacements from "../../../json/CollegeDetailsJson/AmityUniversityJson/AmityPlacement";
import AmityIncubation from "../../../json/CollegeDetailsJson/AmityUniversityJson/AmityIncubation";
import AmityAcademicProgression from "../../../json/CollegeDetailsJson/AmityUniversityJson/AmityAcademicProgression";
import AmityRankings from "../../../json/CollegeDetailsJson/AmityUniversityJson/AmityRanking";

const AmityUniversityTabDetails = () => {
    const [selectedTab, setSelectedTab] = useState(0);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    return (
        <>
            <Box sx={{ backgroundColor: "#252525" }}>
                <Container>
                    <Box sx={{ width: "100%" }}>
                        <Tabs
                            value={selectedTab}
                            onChange={handleTabChange}
                            variant="scrollable"
                            scrollButtons
                            allowScrollButtonsMobile
                            aria-label="scrollable auto tabs example"
                            sx={{
                                fontSize: "13px",
                                // mt: "40px",
                                "& .MuiTab-root": {
                                    // fontSize: "13px",
                                    color: "#898383",

                                    "&.Mui-selected": {
                                        color: "#EF5835",
                                    },
                                },
                                "& .MuiTabs-indicator": {
                                    display: "none",
                                },
                                "& .MuiTabs-flexContainer": {
                                    display: "flex",
                                    justifyContent: "space-around",
                                },
                                "& .MuiTabScrollButton-root": {
                                    color: "#ffffff", // Change color of scroll buttons to white
                                },
                            }}
                        >
                            <Tab label="About the College" />
                            <Tab label="Admission" />
                            <Tab label="Courses and Fee" />
                            <Tab label="Placements" />
                            <Tab label="Rankings" />
                        </Tabs>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-evenly",
                                flexWrap: "wrap",
                            }}
                        >
                            {selectedTab === 0 && (
                                <ExamDetailContent
                                    color={"#ffffff"}
                                    showHeader={true}
                                    header={"About the College"}
                                    headerContent={
                                        "Amity University Noida offers several programmes at the undergraduate, postgraduate and doctoral level, in a variety of subjects. These span the range, from Actuarial Science, Aeronautical & Automobile, Aerospace and Agriculture to Commerce, Computer Science/ IT, Construction and Real Estate Management, Data Sciences & Business Analytics, Defence & Strategic Studies, and onward, including Economics, Engineering, English, Finance, Forensic Sciences, Health & Medical Science, Insurance and Banking, Journalism & Mass Communication, Nanotechnology and Public Policy, to name just a few.The institute’s world-class infrastructure includes classrooms, syndicate rooms and e-learning studios, aside from well-stocked libraries, laboratories, conference halls, auditoriums and so on. The institute also has a placement cell to assist graduates with the transition to the corporate world. "}
                                    title={"Details"}
                                    DynamicTabledata={AmityAboutData}
                                    DynamicTableheader={AmityAboutHeader}
                                    gapcolor={"#252525"}
                                />
                            )}
                            {selectedTab === 1 && (
                                <Box sx={{ width: "100%" }}>
                                    <Box sx={{ padding: "10px 0px" }}>
                                        {AmityAdmissionContent?.map((item) => (
                                            <StripHeaderComponent
                                                data={item}
                                                margin={"10px"}
                                                color={"#ffffff"}
                                            />
                                        ))}
                                    </Box>
                                    {/* <StripTitle title={"Cut Off:"} />
                                    {IITDelhiAdmission?.map((item) => (
                                        <HeaderDynamicTable
                                            data={item}
                                            margin={"10px"}
                                            showheader3={false}
                                            header1={"Branch"}
                                            header2={"IIT Delhi JEE Advanced Cutoff"}
                                            subheader1={"Opening rank 2021"}
                                            subheader2={"Opening rank 2020"}
                                        />
                                    ))} */}
                                </Box>
                            )}
                            {selectedTab === 2 && (
                                <>
                                    <Box
                                        sx={{
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            width: "100%",
                                        }}
                                    >
                                        <ExamDetailContent
                                            color="#ffffff"
                                            title={"PhD/M.Phil"}
                                            DynamicTabledata={AmityCourseData}
                                            DynamicTableheader={AmityCourseHeader}
                                        />
                                        <ExamDetailContent
                                            color="#ffffff"
                                            title={"Fees"}
                                            DynamicTabledata={AmityFeeData}
                                            DynamicTableheader={AmityFeeHeader}
                                        />
                                    </Box>
                                </>
                            )}
                            {selectedTab === 3 && (
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        width: "100%",
                                    }}
                                >
                                    <StripTitle title={"Placements"} />
                                    {AmityPlacements?.map((item) => (
                                        <SingleHeaderDynamicTable
                                            data={item}
                                            margin={"10px"}
                                            showheader={false}
                                        />
                                    ))}
                                    <StripTitle title={"Incubation Centre"} />
                                    {AmityIncubation?.map((item) => (
                                        <SingleHeaderDynamicTable
                                            data={item}
                                            margin={"10px"}
                                            showheader={false}
                                        />
                                    ))}
                                    <StripTitle title={"Academic Progression"} />
                                    {AmityAcademicProgression?.map((item) => (
                                        <SingleHeaderDynamicTable
                                            data={item}
                                            margin={"10px"}
                                            showheader={false}
                                        />
                                    ))}

                                </Box>
                            )}
                            {selectedTab === 4 && (
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        width: "100%",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            mt: "20px",
                                            mb: "20px",
                                        }}
                                    >
                                        <Typography
                                            variant="h6"
                                            sx={{
                                                color: "#ffffff",
                                                fontWeight: "550",
                                                fontSize: "16px",
                                                position: "relative",
                                                "&::before": {
                                                    content: "''",
                                                    position: "absolute",
                                                    left: "-10px",

                                                    width: "5px",
                                                    height: "20px",
                                                    backgroundColor: "#EF5835",
                                                },
                                            }}
                                        >
                                            {"Rankings"}
                                        </Typography>
                                    </Box>
                                    {AmityRankings?.map((item) => (
                                        <SingleHeaderDynamicTable
                                            data={item}
                                            margin={"10px"}
                                            headercolor={"#ffffff"}
                                        />
                                    ))}
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default AmityUniversityTabDetails;
