const JeeAdmitCardHeader = [
    {
      id: 1,
      label: "Availability of Admit Card",
      var: "availabilityofadmitcard",
    },
    {
      id: 2,
      label: "Official website to download Admit Card",
      var: "officialwebsitetodownloadadmitcard",
    },
    {
      id: 3,
      label: "Particulars Required to download admit card",
      var: "particularsrequiredtodownloadadmitcard",
    },
    {
      id: 4,
      label: "Important Details available in Admit Card",
      var: "importantdetailsavailableinadmitcard",
    },
    { id: 5, label: "Contact Details", var: "contactdetails" },
    {
      id: 6,
      label: "Compatible Browsers for downloading",
      var: "compatiblebrowsersfordownloading",
    },
    { id: 7, label: "How to download Admit Card", var: "howtodownloadadmitcard" },
    {
      id: 8,
      label: "Steps to Download Admit Card",
      var: "stepstodownloadadmitcard",
    },
    {
      id: 9,
      label: "Items not permitted inside Exam center",
      var: "itemsnotpermittedinsideexamcenter",
    },
    {
      id: 10,
      label: "Items allowed inside exam center",
      var: "itemsallowedinsideexamcenter",
    },
    
    { id: 11, label: "Guidelines for Admit Card", var: "guidelinesforadmitcard" },
  
    { id: 12, label: "Exam Day guidelines", var: "examdayguidelines" },
  ];
  export default JeeAdmitCardHeader;
  