import React from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,

} from "@mui/material";

const DualColumnDynamicTable = ({
    data,
    margin,
    showheader = true,
    header1,
    coloumn1width,
    coloumn2width,
    header2,
    gapcolor
}) => {
    return (
        <TableContainer component={Paper} sx={{ margin: margin ?? "0" }}>
            <Table>
                {showheader && (
                    <TableHead>
                        <TableRow sx={{ backgroundColor: "#FF876B" }}>
                            <TableCell
                                sx={{
                                    borderRight: "4px solid white",
                                    color: "#880800",
                                    fontWeight: "600",
                                    textAlign: "start",
                                    fontSize: "10px",
                                    width: coloumn1width ?? "auto",
                                }}
                            >
                                {header1}
                            </TableCell>

                            <TableCell

                                sx={{
                                    color: "#880800",

                                    fontWeight: "600",
                                    textAlign: "start",
                                    fontSize: "10px",
                                    width: coloumn2width ?? "auto",
                                }}
                            >
                                {header2}



                            </TableCell>

                        </TableRow>
                    </TableHead>
                )}
                {showheader && (
                    <TableBody>
                        <TableRow sx={{ backgroundColor: gapcolor ?? "#ffffff" }}>
                            <TableCell colSpan={2}></TableCell>
                        </TableRow>
                    </TableBody>
                )}
                <TableBody sx={{ borderCollapse: "collapse" }}>
                    {data?.data.map((item, index) => (
                        <React.Fragment key={item.id}>
                            <TableRow
                                sx={{
                                    width: "100%",
                                    backgroundColor: index % 2 === 0 ? "#FF876B" : "#EF5835"

                                }}
                            >
                                <TableCell
                                    sx={{
                                        borderRight: "4px solid white",
                                        color: "#ffffff",
                                        fontWeight: "600",
                                        textAlign: "start",
                                        verticalAlign: "top",
                                        borderBottom: "none",
                                        width: coloumn1width ?? "auto",
                                    }}
                                >
                                    {item.title}
                                </TableCell>
                                <TableCell
                                    sx={{
                                        color: "#ffffff",
                                        fontWeight: "400",
                                        textAlign: "start",
                                        verticalAlign: "top",
                                        borderBottom: "none",
                                        width: coloumn2width ?? "auto",
                                    }}
                                >
                                    {Array.isArray(item.value) ? (
                                        <ul>
                                            {item.value.map((val, index) => (
                                                <li key={index} style={{ listStyle: "inside", }}>{val}</li>
                                            ))}
                                        </ul>
                                    ) : (
                                        item.value
                                    )}
                                </TableCell>

                            </TableRow>
                            {(index + 1) % 2 === 0 && index < data.data.length - 1 && (
                                <TableRow
                                    key={`gap_${index}`}
                                    style={{ backgroundColor: gapcolor ?? "#ffffff", border: "none" }}
                                >
                                    <TableCell colSpan={3} style={{ border: "none" }} />
                                </TableRow>
                            )}
                        </React.Fragment>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default DualColumnDynamicTable;
