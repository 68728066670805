import { Box, Typography, Grid, Container, Button } from "@mui/material";
import Search from "../../components/Search";
import React, { useState } from "react";
import ExamCard from "../../components/card-components/ExamCard";
import entranceExamData from "../../json/entranceExamData";
import Navbar from '../home/Header/Navbar';
import Footer from '../home/Footer/footer';
import Form from '../home/Form/Form';
import '../../careerplatform.css'

const EntranceExams = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [displayCount, setDisplayCount] = useState(8); // Initial count of items to display

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleReset = () => {
        setSearchTerm("");
    };

    const handleButtonClick = () => {
        setDisplayCount(prevCount => prevCount + 8); // Increase display count by 8 on button click
    };

    const filteredExams = entranceExamData.filter((exam) =>
        exam.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <>
            <Navbar />
            <Box>
                <Search
                    title="Entrance Exams"
                    placeholder="Search ..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                    onReset={handleReset}
                />
                {/* <Box
                    
                        sx={{
                            color: "#EF5835",
                            mt: 4,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: 1,
                        }}
                    >
                        <Typography className="box2"> Filter</Typography>
                        <Icon icon="mingcute:filter-fill" />
                    </Box> */}

                {/* <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: { md: 5, xs: 2 },
                            mt: 3,
                            flexWrap: "wrap",
                        }}
                    >
                        <FormControl sx={{ height: "60px" }}>
                            <Select
                                value={value}


                                sx={{
                                    borderRadius: 10,
                                    border: "1px solid #FC8800",
                                    color: "gray",
                                    "&:hover": {
                                        borderColor: "#FC8800 !important",
                                        color: "#FC8800",
                                    },

                                }}
                                size="small"
                                onChange={handleChange}
                                displayEmpty
                            >
                                <MenuItem value="">
                                    <em>Choose Courses</em>
                                </MenuItem>
                                <MenuItem value={10}>Course 1</MenuItem>
                                <MenuItem value={20}>Course 2</MenuItem>
                                <MenuItem value={30}>Course 3</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl sx={{ height: "60px" }}>
                            <Select
                                value={value}
                                sx={{
                                    borderRadius: 10,
                                    border: "1px solid #FC8800",
                                    color: "gray",
                                    "&:hover": {
                                        borderColor: "#FC8800",
                                        color: "#FC8800",
                                    },

                                }}
                                size="small"
                                onChange={handleChange}
                                displayEmpty
                            >
                                <MenuItem value="">
                                    <em>Choose Popular</em>
                                </MenuItem>
                                <MenuItem value={10}>Popular 1</MenuItem>
                                <MenuItem value={20}>Popular 2</MenuItem>
                                <MenuItem value={30}>Popular 3</MenuItem>
                            </Select>
                        </FormControl>



                        <Button
                            className="box2"
                            variant="outlined"
                            sx={{
                                color: "#EF5835",
                                borderRadius: 10,
                                mt: "-20px",
                                borderColor: "#EF5835",
                                "&:hover": {
                                    borderColor: "#FC8800",
                                    color: "#FC8800",
                                },
                                width: "100px",
                                height: "40px",
                            }}
                        >
                            {" "}
                            Filter
                        </Button>
                    </Box> */}
                <Container sx={{ py: 3 }}>
                    <Typography
                        className="box1"
                        variant="h6"
                        sx={{ color: "#EF5835" }} fontWeight={"500"}
                    >
                        List Of All Entrance Exams
                    </Typography>
                    <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                        {filteredExams.length === 0 ? (
                            <Typography variant="h5" className="box1" sx={{ color: "red", px: 3, textAlign: "center", fontWeight: "550" }}>
                                No Entrance Exams Found.
                            </Typography>
                        ) : (
                            <Grid container sx={{ py: 3 }} spacing={4}>
                                {filteredExams.slice(0, displayCount).map((item) => (
                                    <Grid sx={{ display: "flex", justifyContent: "center" }} item key={item.id} md={3} sm={4} xs={12}>
                                        <ExamCard data={item} />
                                    </Grid>
                                ))}
                            </Grid>
                        )}
                        {displayCount < filteredExams.length && (
                            <Button variant="outlined"
                                sx={{
                                    color: "#EF5835",
                                    borderRadius: 10,
                                    mt: "20px",
                                    borderColor: "#EF5835",
                                    "&:hover": {
                                        backgroundColor: "#EF5835",
                                        borderColor: "#EF5835",
                                        color: "#ffffff",
                                    },
                                    width: "240px",
                                    height: "40px",
                                }} onClick={handleButtonClick}
                            >
                                Load More
                            </Button>
                        )}
                    </Box>
                </Container>
            </Box>
            <Form />
            <Footer />
        </>
    );
};

export default EntranceExams;
