const neetexamDetailContentJson = {
    formatName: "National Eligiblity and Entrance Test",
    shortHand: "NEET",
    testingAuthority: "National Testing Agency (NTA, since 2019)",
    examMode: "Pen and paper based (in person)",
    frequency: "Once a year",
    duration: "3 hours 20 minutes",
    sections: 2,
    languagesAvailable: "English, Hindi, Assamese, Bengali, Gujarati, Marathi, Tamil, Telugu, Odiya, Kannada and Urdu",
    questionFormat: "Multiple choice questions",
    numberofQuestions: "200 (180 to be attempted)",
    examPattern:
      "Each subject exam (Physics, Chemistry, Botany and Zoology) has two sections, a Section A with 35 compulsory questions and a Section B with 15 optional questions out of which 10 must be attempted.",
    totalMarks: 720,
    markingScheme:
      "(+1) for correct answers, (-0.25) for incorrect answers, 0 for unattempted questions",
    noofTestCitiesCentres: 543,
    coursesAvailableAfterExam: ["MBBS (Bachelor of Medicine and Bachelor of Surgery)",
        "BDS (Bachelor of Dental Surgery)",
        "BHMS (Bachelor of Homeopathic Medicine and Surgery)",
        "BAMS (Bachelor of Ayurvedic Medicine and Surgery)",
        "BUMS (Bachelor of Unani Medicine and Surgery)",
        "BSMS (Bachelor of Siddha Medicine and Surgery)",
        "BSc Nursing (Honours)",],
    website: "https://nludelhi.ac.in/ailet2022.aspx",
    contactDetails: [
      "Contact Number: 011-40787555",
  
      "E-mail: ailetsupport@nludelhi.ac.in"
    ]
  };
  export default neetexamDetailContentJson;
  