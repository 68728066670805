import e1 from '../assets/streamcollegelist/1.png'
import e2 from '../assets/streamcollegelist/2.png'
import e3 from '../assets/streamcollegelist/3.png'
import e4 from '../assets/streamcollegelist/4.png'

const  EngineeringCollageListData =[
    {
        id: 1,
        img: e1,
        title: "Bangalore Institute of Technology",
      },
      {
        id: 2,
        img: e2,
        title: "BMS College of Engineering",
      },
      {
        id: 3,
        img: e3,
        title: "BNM Institute of Technology",
      },
      {
        id: 4,
        img: e4,
        title: "Manipal Institute of Technology",
      },
]
  
export default EngineeringCollageListData;