const AiletAcceptingCollegesDataJson = {
    listofcollegesacceptingtheexamscorerank: [
        "Birla Global University, Bhubaneshwar",
        "Jagan Nath University, Haryana",
        "Jagran Lakecity University",
        "Mody University of science and Technology",
        "National Law University, Delhi",
        "Ramiah IInstitute of Legal studies",
        "School of Law, Sushant University",
        "SRM University, Panipat, Delhi",
        "The Northcap University, Gurgaon",
        "Vellore Institute of Technology, Vellore University, Chennai",
        "Xavier Law School, Xavier University, Bhubaneshwar",
    ],
};
export default AiletAcceptingCollegesDataJson;
