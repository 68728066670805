const AbbsAboutData = {
    address: "Off Magadi Road, Andarahalli Main Road, Bengaluru, Karnataka - 560091",
    city: "Bengaluru",
    state: "Karnataka",
    phone: "080-23245515",
    established: "2008",
    btechgraduated: "2013",
    head: "Dr DM Mahishi",
    type: "Private",
    website: "www.acharyabbs.ac.in",
    raggingcell: "Yes",
    nationalservice:
        "Yes",
    complaintcell: "Yes",
    qualityassurance:
        "Yes",

};
export default AbbsAboutData;
