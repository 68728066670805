import React, { useEffect, useState } from "react";
import { Box, Typography, Container, Grid } from "@mui/material";
import Search from "../../components/Search";
import Navbar from "../home/Header/Navbar";
import Footer from "../home/Footer/footer";
import Form from "../home/Form/Form";
import { useNavigate } from "react-router-dom";
import ManagementCollageListData from "../../json/ManagementListdata";
import '../../careerplatform.css'

const MedicalCollage = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleReset = () => {
    setSearchTerm("");
  };

  const filteredColleges = ManagementCollageListData.filter((college) =>
    college.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <Navbar />
      <Box>
        <Search
          title="Medical Colleges"
          placeholder="Search ..."
          value={searchTerm}
          onChange={handleSearchChange}
          onReset={handleReset}
        />
        <Container sx={{ py: 3 }}>
          <Typography
            variant="h6"
            sx={{ color: "#EF5835", px: 3, textTransform: "uppercase" }}
          >
            List Of All Medical Colleges
          </Typography>
          <Grid container spacing={2} sx={{ justifyContent: "center", display: "flex", alignItems: "center", pt: "30px" }}>
            {filteredColleges.length === 0 ? (
              <Typography variant="h5" sx={{ color: "red", px: 3, textAlign: "center", }}>
                No Medical College Found.
              </Typography>
            ) : (
              filteredColleges.map((item) => (
                <Grid
                  sx={{ display: "flex", justifyContent: "center" }}
                  item
                  key={item.id}
                  lg={3}
                  md={3}
                  sm={6}
                  xs={12}
                >
                  <Box
                    sx={{
                      borderRadius: "15px",
                      height: "240px",
                      width: "240px",
                      color: "transparent",
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => navigate("/collegedetails")}
                  >
                    <img
                      src={item?.img}
                      alt="college"
                      style={{
                        width: "100%",
                        height: "150px",
                        borderRadius: "15px",
                      }}
                    />
                    <Box
                      sx={{
                        bgcolor: "#FC8800",
                        py: 1,
                        height: "80px",
                        width: "100%",
                        px: 2,
                        p: 1.5,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "15px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "white",
                          fontFamily: "Nunito",
                          fontSize: "20px",
                          fontWeight: "100",
                        }}
                      >
                        {item.title}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              ))
            )}
          </Grid>
        </Container>
      </Box>
      <Form />
      <Footer />
    </>
  );
};

export default MedicalCollage;
