import React, { useEffect } from "react";

import Navbar from "../home/Header/Navbar";
import Addmition from "../home/Footer/footer";


import { Box } from "@mui/material";
import InstitudeCollegeTabDetails from "./InstituteCollege/InstituteCollegeTabDetails";
import InstituteCollegeBanner from "./InstituteCollege/InstituteCollegeBanner";

const InstituteCollegeDetail = () => {
    // const [value, setValue] = React.useState("");
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Navbar />
            <InstituteCollegeBanner />
            <Box sx={{ backgroundColor: "#252525" }}>
                <InstitudeCollegeTabDetails />
                <Addmition margintop={"40px"} paddingtop={"40px"} />
            </Box>
        </>
    );
};

export default InstituteCollegeDetail;
