import React, { useEffect } from 'react'
// import Navbar from '../../home/Header/Navbar';
import Navbar from '../home/Header/Navbar';



import Footer from '../home/Footer/footer'
import JeeExamDetail from './JeeExamDetail';

const JeeExamDetailPage = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Navbar />
            <JeeExamDetail />
            <Footer padding={"-70px"} margintop={"-20px"} />
        </>
    )
}

export default JeeExamDetailPage