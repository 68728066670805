import React, { useEffect } from "react";

import Navbar from "../home/Header/Navbar";
import Addmition from "../home/Footer/footer";


import { Box } from "@mui/material";
import NationalCollegeBanner from "./NationalCollegeDetails/NationalCollegeBanner";
import NationalCollegeTabDetails from "./NationalCollegeDetails/NationalCollegeTabDetails";

const NationalCollegeDetail = () => {
    // const [value, setValue] = React.useState("");
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Navbar />
            <NationalCollegeBanner />
            <Box sx={{ backgroundColor: "#252525" }}>
                <NationalCollegeTabDetails />
                <Addmition margintop={"40px"} paddingtop={"40px"} />
            </Box>
        </>
    );
};

export default NationalCollegeDetail;
