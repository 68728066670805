import React, { useState } from "react";
import { Box, Typography, Container, Grid, Button } from "@mui/material";
import Search from "../../components/Search";
import Navbar from "../home/Header/Navbar";
import Footer from "../home/Footer/footer";
import Form from "../home/Form/Form";
import { useNavigate } from "react-router-dom";
import CollageListData from "../../json/CollageListData";
import '../../careerplatform.css'

const CollageListPage = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleReset = () => {
    setSearchTerm("");
  };

  const filteredColleges = CollageListData.filter((college) =>
    college.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <Navbar />
      <Box>
        <Search
          title="Search College"
          placeholder="Search ..."
          value={searchTerm}
          onChange={handleSearchChange}
          onReset={handleReset}
        />

        <Container sx={{ py: 3 }}>
          <Typography
            variant="h6"
            sx={{ color: "#EF5835", px: 3, textTransform: "uppercase", mb: 4 }}
          >
            List Of All Colleges
          </Typography>

          {filteredColleges.length === 0 ? (
            <Typography variant="h5" className="box1" sx={{ color: "red", px: 3, textAlign: "center", fontWeight: "550" }}>
              No colleges found.
            </Typography>
          ) : (
            <Grid container spacing={2} >
              {filteredColleges.map((item) => (
                <Grid sx={{ display: "flex", justifyContent: "center" }} item key={item.id} lg={3} md={3} sm={6} xs={12}>
                  <Box
                    sx={{
                      borderRadius: "15px",
                      height: "240px",
                      width: "240px",
                      color: "transparent",
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => navigate(item.path)}
                  >
                    <img
                      src={item?.img}
                      alt={item.title}
                      style={{
                        width: "100%",
                        height: "150px",
                        borderRadius: "15px",
                      }}
                    />
                    <Box
                      sx={{
                        bgcolor: "#FC8800",
                        py: 1,
                        height: "80px",
                        width: "100%",
                        px: 2,
                        p: 1.5,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "15px",
                      }}
                    >
                      <Typography
                        className="box2"
                        sx={{
                          color: "white",
                          fontSize: "15px",
                          fontWeight: "700",
                        }}
                      >
                        {item.title}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          )}
        </Container>
      </Box>
      <Form />
      <Footer />
    </>
  );
};

export default CollageListPage;
