

import { Box, Dialog, DialogContent, Grid, Link, Typography } from "@mui/material";
import React, { useState } from "react";
import AskYourExpertForm from "../pages/home/Form/AskYourExpert";
import { useNavigate } from "react-router-dom";


const ServiceComponent = () => {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <Box sx={{ background: "#ffffff", pt: "30px", pb: "50px" }}>
            <Grid item md={6} sm={6}>
                <Box

                    sx={{
                        color: "grey",
                        display: "flex",
                        justifyContent: "center",
                        padding: {
                            xl: "0rem",
                            lg: "10px",
                            md: "0px",
                            sm: "0px",
                            xs: "0px",
                        },
                        textAlign: { xs: "center" },
                        gap: { xl: "10%", lg: "9%", md: "10%" },
                        flexDirection: {
                            xl: "row",
                            lg: "row",
                            md: "row",
                            sm: "column",
                            xs: "column",
                        },
                        paddingBottom: "5%",
                    }}
                >
                    {/* Exam */}
                    <Box>
                        <Typography
                            mb={2}
                            sx={{

                                color: "#000000",
                                fontSize: { xl: "1rem", lg: "1rem", md: "1rem" },
                                fontWeight: "600",
                                textAlign: {
                                    xl: "start",
                                    lg: "start",
                                    md: "center",
                                    xs: "center",
                                },
                            }}
                        >
                            Exam
                        </Typography>


                        <Typography
                            mb={2}
                            sx={{
                                fontSize: { xl: "1rem", lg: "1rem", xs: "1rem" },
                                textAlign: {
                                    xl: "start",
                                    lg: "start",
                                    md: "center",
                                    xs: "center",
                                },
                            }}
                        >
                            <Link href={"/examlist"} sx={{ color: "grey", textDecoration: "none" }}>List all Exams</Link>
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: { xl: "1rem", lg: "1rem", xs: "1rem" },
                                textAlign: {
                                    xl: "start",
                                    lg: "start",
                                    md: "center",
                                    xs: "center",
                                },
                                cursor: "pointer"
                            }} onClick={handleClickOpen}
                        >
                            Apply Online
                        </Typography>
                    </Box>
                    {/* College and Streams */}
                    <Box>
                        <Typography
                            mb={2}
                            sx={{
                                color: "#000000",
                                fontSize: { xl: "1rem", lg: "1rem", md: "1rem" },
                                fontWeight: "600",
                                textAlign: {
                                    xl: "start",
                                    lg: "start",
                                    md: "center",
                                    xs: "center",
                                },
                            }}
                        >
                            College and Streams
                        </Typography>

                        <Typography
                            mb={2}
                            sx={{

                                fontSize: { xl: "1rem", lg: "1rem", xs: "1rem" },
                                textAlign: {
                                    xl: "start",
                                    lg: "start",
                                    md: "center",
                                    xs: "center",
                                },
                            }}
                        >
                            <Link href={"/college-list"} sx={{ color: "grey", textDecoration: "none" }}>
                                List all Colleges</Link>

                        </Typography>
                        <Typography
                            mb={2}
                            sx={{
                                fontSize: { xl: "1rem", lg: "1rem", xs: "1rem" },
                                textAlign: {
                                    xl: "start",
                                    lg: "start",
                                    md: "center",
                                    xs: "center",
                                },
                                cursor: "pointer"
                            }} onClick={handleClickOpen}


                        >
                            Apply Online
                        </Typography>
                        {/* <Typography

                            sx={{
                                fontSize: { xl: "1rem", lg: "1rem", xs: "1rem" },
                                textAlign: {
                                    xl: "start",
                                    lg: "start",
                                    md: "center",
                                    xs: "center",
                                },
                            }}
                        >
                            List all Streams
                        </Typography> */}
                    </Box>
                    {/* Admissions */}
                    <Box>
                        <Typography
                            mb={2}
                            sx={{
                                color: "#000000",
                                fontSize: { xl: "1rem", lg: "1rem", md: "1rem" },
                                fontWeight: "600",
                                textAlign: {
                                    xl: "start",
                                    lg: "start",
                                    md: "center",
                                    xs: "center",
                                },
                            }}
                        >
                            Admissions
                        </Typography>



                        <Typography
                            sx={{
                                fontSize: { xl: "1rem", lg: "1rem", xs: "1rem" },
                                textAlign: {
                                    xl: "start",
                                    lg: "start",
                                    md: "center",
                                    xs: "center",
                                },
                            }}
                        >
                            <Link
                                href={"/applyOnline"}
                                sx={{ color: "grey", textDecoration: "none" }}>Admission to Colleges</Link>
                        </Typography>
                    </Box>
                    {/* Counseling */}
                    <Box>
                        <Typography
                            mb={2}
                            sx={{
                                color: "#000000",
                                fontSize: { xl: "1rem", lg: "1rem", md: "1rem" },
                                fontWeight: "600",
                                textAlign: {
                                    xl: "start",
                                    lg: "start",
                                    md: "center",
                                    xs: "center",
                                },

                            }}
                        >
                            Counselling
                        </Typography>

                        <Typography
                            sx={{
                                fontSize: { xl: "1rem", lg: "1rem", xs: "1rem" },
                                textAlign: {
                                    xl: "start",
                                    lg: "start",
                                    md: "center",
                                    xs: "center",
                                },
                            }}
                        >
                            <Link href={"/career"} sx={{ color: "grey", textDecoration: "none" }}>Career Counseling</Link>
                        </Typography>

                    </Box>
                </Box>


            </Grid>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm" sx={{
                "& .MuiPaper-root": {
                    backgroundColor: "transparent",
                    borderRadius: "28px"
                },
            }}>
                <DialogContent sx={{ padding: "5px", backgroundColor: "transparent" }}>
                    <Box sx={{ backgroundColor: "#fff" }}>
                        <AskYourExpertForm onClose={handleClose} />
                    </Box>
                </DialogContent>

            </Dialog>
        </Box>

    );
};

export default ServiceComponent;
