import { Typography, Box, Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from "@mui/material";
import React, { useState } from "react";
import Vector from "../../../assets/Vector 1.png";
import Group from "../../../assets/Group 14.png";
import { useNavigate } from "react-router-dom";
import AskYourExpertForm from "../Form/AskYourExpert";
import { Icon } from "@iconify/react/dist/iconify.js";

const MyComponent = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
   return (
    <>
      <Box
        sx={{
          height: { md: "720px", sm: "550px", xs: "00px" },
          position: "relative",
        }}
      >
        <Box sx={{
          zIndex: -10, position: "absolute",
        }}>

          <img
            src={Vector}
            alt="Vector_image"
            width="58.5%"
            style={{ marginTop: "50px" }}
          />
        </Box>
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 2,
          }}
        >
          <Box sx={{ mt: { md: "-180px", sm: "-250px", xs: "300px" } }}>
            <Typography
              sx={{
                fontSize: {
                  xl: "90px",
                  lg: "70px",
                  xs: "40px",
                  // sm: "40px",
                  // xs: "40px",
                },
                fontWeight: 600,
                textAlign: "center",
              }}
            >
              Building a&nbsp;
              <span
                style={{
                  color: "#EF5835",
                }}
              >
                Future
              </span>
            </Typography>
            <Typography
              sx={{
                fontSize: {
                  xl: "50px",
                  lg: "40px",
                  xs: "30px",
                  // sm: "25px",
                  // xs: "25px",
                },
                fontWeight: 400,
                textAlign: "center",
              }}
            >
              One&nbsp;
              <span
                style={{
                  color: "#EF5835",
                }}
              >
                Student&nbsp;
              </span>
              at a Time
            </Typography>

            <Box
              mt={{ xl: "40px", lg: "30px", xs: "25px" }}
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: { xl: "40px", md: "30px", xs: "20px" },
              }}
            >
              <Button
                variant="output"
                sx={{
                  color: "#EF5835",
                  border: "1px solid #EF5835",
                  height: "42px",
                  px: { xl: "30px", md: "25px", xs: "20px" },
                  borderRadius: "112px",
                  cursor: "pointer",
                  background: "#fff",
                  zIndex: 222,
                  "&:hover": { background: "#EF5835", color: "#fff" },
                  fontSize: { xl: "20px", lg: "16px", md: "14px", xs: "12px" },
                }}
                onClick={() => navigate("/form")}
              >
                {/* <Link
                  to={"/form"}
                  style={{ textDecoration: "none", color: "gray" }}
                > */}
                REGISTER
                {/* </Link> */}
              </Button>

              <Button
                variant="container"
                sx={{
                  color: "#fff",
                  background: "#EF5835",
                  height: "42px",
                  px: { xl: "30px", md: "25px", xs: "20px" },
                  borderRadius: "112px",
                  "&:hover": {
                    border: "1px solid #EF5835",
                    color: "#EF5835",
                  },
                  fontSize: { xl: "20px", lg: "16px", md: "14px", xs: "12px" },
                }}
                onClick={handleClickOpen}
              >
                Ask an expert
              </Button>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            left: 0,
            display: { sm: "flex", xs: "none" },

            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",

          }}
        >
          <Box
            sx={{
              width: { lg: "550px", md: "550px", sm: "500px", xs: "500px" },
              textAlign: "center",
            }}
          >
            <img src={Group} alt="Group_image" width="100%" />
          </Box>
        </Box>
      </Box>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm" sx={{
        "& .MuiPaper-root": {
          backgroundColor: "transparent",
          borderRadius: "28px"
        },
      }}>
        <DialogContent sx={{ padding: "5px", backgroundColor: "transparent" }}>
          <Box sx={{ backgroundColor: "#fff" }}>
            <AskYourExpertForm onClose={handleClose} />
          </Box>
        </DialogContent>
       
      </Dialog>

    </>
  );
};

export default MyComponent;
