import { Box, Typography, Container, Tabs, Tab } from "@mui/material";
import React, { useState } from "react";
import HeaderDynamicTable from "../../components/TableComponent/HeaderDynamicTable";
import jeeExamDetailContentJson from "../../json/jeeExamDetailContentJson";
import JeeAiletAboutTableHeader from "../../json/JeeAiletAboutTableHeader";
import JeeAiletDateTableHeader from "../../json/JeeAiletDateTableHeader";
import JeeAiletApplicationDataJson from "../../json/JeeAiletApplicationDataJson";
import JeeAiletApplicationProcessHeader from "../../json/JeeAiletApplicationProcessHeader";
import JeeExamCuttoffData from "../../json/JeeExamCuttoffData";
import JeeAiletEligibilityDataJson from "../../json/JeeAiletEligibilityDataJson";
import JeeAiletEligibilityHeader from "../../json/JeeAiletEligibilityHeader";
import JeeAdmitCardHeader from "../../json/JeeAdmitCardHeader";
import JeeAdmitCardDataJson from "../../json/JeeAdmitCardDataJson";
import JeeExamSyllabus from "../../json/JeeExamSyllabus";
import JeeCollegesHeader from "../../json/JeeCollegesHeader";
import JeeCollegesDataJson from "../../json/JeeCollegesDataJson";
import ExamDetailContent from "../EntranceExam/ExamDetails/ExamDetailContent";
import JeeAiletDateDataJson from "../../json/JeeAiletDateDataJson";
import DualColumnDynamicTable from "../../components/TableComponent/TwoColumnTable";
import StripTitle from "../../components/StripTitle";

const JeeExamDetail = () => {
    const [selectedTab, setSelectedTab] = useState(0);

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };



    return (
        <>
            <Box>
                <Container sx={{ py: 3 }}>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: { md: "row", sm: "column", xs: "column" }
                        }}
                    >
                        <Typography
                            variant="h6"
                            sx={{ color: "#EF5835" }}
                            fontWeight={"500"}
                            fontSize={"30px"}
                        >
                            {"JEE Main 1"}
                        </Typography>
                        <Typography
                            variant="h6"
                            sx={{ color: "#000000" }}
                            fontWeight={"600"}
                            fontSize={"30px"}
                        >
                            {" "}
                            {"\u00A0\u00A0"}
                            {"2024 -"}
                        </Typography>
                        <Typography variant="h6" fontWeight={"300"} fontSize={"30px"}>
                            {"\u00A0\u00A0"}
                            Dates, Application Form, Cutoff, Eligibility, Syllabus
                        </Typography>
                    </Box>
                    <Tabs
                        value={selectedTab}
                        onChange={handleTabChange}
                        variant="scrollable"
                        scrollButtons
                        allowScrollButtonsMobile





                        aria-label="scrollable auto tabs example"
                        sx={{
                            mt: "40px", '& .MuiTab-root': {
                                // fontSize: "13px",
                                color: '#898383',


                                '&.Mui-selected': {


                                    color: "#EF5835",



                                },

                            },
                            '& .MuiTabs-indicator': {
                                display: 'none'
                            }
                        }}
                    >
                        <Tab label="About the Exam" />
                        <Tab label="Dates" />
                        <Tab label="Application Process" />
                        <Tab label="Exams Cut off" />
                        <Tab label="Eligibility" />
                        <Tab label="Admit Card" />
                        <Tab label="Syllabus" />
                        <Tab label="Accepting Colleges" />
                    </Tabs>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            flexWrap: "wrap"
                        }}
                    >
                        {selectedTab === 0 && (

                            <ExamDetailContent
                                showHeader={true}
                                header={"About the Exam"}
                                headerContent={<Typography sx={{width:"100%",display:"flex",justifyContent:"center",alignItems:"center",maxWidth:{md:"none",xs:"70%"},textAlign:{md:"none",xs:"justify"}}}>"The JEE (Joint Entrance Examination) Main is a central exam conducted for admissions to engineering, architecture and planning programmes at Centrally-Funded Technical Institutions (CFTIs), and institutions and universities funded or recognised by participating state governments. These include colleges like the IITs (Indian Institutes of of Technology), NITs (National Institutes of Technology) and so on. This exam has been conducted by the NTA (National Testing Authority) since 2019; prior to this, it was conducted by the CBSE (Central Board of Secondary Education).  
                                JEE Main comprises two papers - one for admission to undergraduate engineering programmes and another for admission to architecture and planning programmes. (The exam also serves as an eligibility test for the JEE Advanced paper, which serves as an extrance exam for admission to the IITs.) Aside from JEE Main, candidates seeking admission to engineering programmes at IITs, NITs and other CFTIs must have secured at least 75 per cent in their 12th examinations, or be in the top 20th percentile of the examination. For candidates from SC/ ST backgrounds, the qualifying percentage is 65 per cent.
                                For academic session 2022-23, the JEE Main 1 paper will be conducted twice, in two sessions. This, per the Ministry of Education's Department of Higher Education, will give candidates several advantages. For one, it gives students first-hand experience of the exam format and process knowing there is the option of a second attempt. It will also ease the burden on students sitting for multiple examinations - for example, if students find that one of the JEE Main exam dates clashes with another required/ competitive entrance examination, they will have the opportunity of taking it on another date. Candidates are not required to appear for both sessions, but if they do so, the better of their two scores will be considered while generating the all-India merit list/ ranking."</Typography>}
                                title={"Details"}
                                DynamicTabledata={jeeExamDetailContentJson}
                                DynamicTableheader={JeeAiletAboutTableHeader}
                                // customIndices={[3, 6, 9, 12, 15]}


                            />
                        )}
                        {selectedTab === 1 && (
                            <ExamDetailContent
                                title={"Dates"}
                                DynamicTabledata={JeeAiletDateDataJson}
                                DynamicTableheader={JeeAiletDateTableHeader}

                            />
                        )}
                        {selectedTab === 2 && (
                            <ExamDetailContent
                                title={"Application Process"}
                                DynamicTabledata={JeeAiletApplicationDataJson}
                                DynamicTableheader={JeeAiletApplicationProcessHeader}
                            />
                        )}
                        {selectedTab === 3 && (
                            <>
                                <Box
                                    sx={{
                                        justifyContent: "center", flexDirection: "column", width: '100%'
                                    }}
                                >

                                    <StripTitle title={"Exam Cut Off"} color={"#252525"} />
                                    {JeeExamCuttoffData?.map((item) => (
                                        <HeaderDynamicTable
                                            gapcolor={"transparent"}
                                            data={item}
                                            margin={"10px"}
                                            showsubheader={false}
                                            header1={"Category"}
                                            header2={"JEE Main Cut-Off 2022"}
                                            header3={"JEE Main Cut-Off 2021"}
                                            header4={"JEE Main Cut-Off 2020"}
                                        />
                                    ))}
                                </Box>
                            </>

                        )}
                        {selectedTab === 4 && (
                            <ExamDetailContent
                                title={"Exam Eligibility"}
                                DynamicTabledata={JeeAiletEligibilityDataJson}
                                DynamicTableheader={JeeAiletEligibilityHeader}
                            />
                        )}
                        {selectedTab === 5 && (
                            <ExamDetailContent
                                title={"Admit Card"}
                                DynamicTabledata={JeeAdmitCardDataJson}
                                DynamicTableheader={JeeAdmitCardHeader}
                            />
                        )}
                        {selectedTab === 6 && (
                            <>
                                <Box
                                    sx={{
                                        justifyContent: "center", flexDirection: "column", width: '100%'
                                    }}
                                >

                                    <StripTitle title={"Syllabus"} color={"#252525"} />
                                    {JeeExamSyllabus?.map((item) => (
                                        <DualColumnDynamicTable
                                            gapcolor={"transparent"}
                                            data={item}
                                            margin={"10px"}

                                            header1={"Syllabus and Subjects"}
                                            header2={"Physics, Chemistry, Maths"}


                                        />
                                    ))}
                                </Box>
                            </>

                        )}
                        {selectedTab === 7 && (
                            <ExamDetailContent
                                title={"Accepting Colleges"}
                                DynamicTabledata={JeeCollegesDataJson}
                                DynamicTableheader={JeeCollegesHeader}

                            />
                        )}
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default JeeExamDetail;
