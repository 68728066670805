const IITDelhiCourse = [
  {
    header: "Biotechnology and Biochemical Engineering",
    data: [
      { title: "Total Seats", value: "72" },
      { title: "Opening Rank 2022", value: "10,103" },
      { title: "Closing Rank 2022", value: "2,747" }
    ]
  },
  {
    header: "Chemical Engineering",
    data: [
      { title: "Total Seats", value: "121" },
      { title: "Opening Rank 2022", value: "4,570" },
      { title: "Closing Rank 2022", value: "1,261" }
    ]
  },
  {
    header: "Civil Engineering",
    data: [
      { title: "Total Seats", value: "110" },
      { title: "Opening Rank 2022", value: "8,274" },
      { title: "Closing Rank 2022", value: "2,766" }
    ]
  },
  {
    header: "Computer Science and Engineering",
    data: [
      { title: "Total Seats", value: "136" },
      { title: "Opening Rank 2022", value: "450" },
      { title: "Closing Rank 2022", value: "29" }
    ]
  },
  {
    header: "Electrical Engineering (Power)",
    data: [
      { title: "Total Seats", value: "65" },
      { title: "Opening Rank 2022", value: "2,088" },
      { title: "Closing Rank 2022", value: "603" }
    ]
  },
  {
    header: "Electrical Engineering",
    data: [
      { title: "Total Seats", value: "120" },
      { title: "Opening Rank 2022", value: "1,572" },
      { title: "Closing Rank 2022", value: "358" }
    ]
  },
  {
    header: "Engineering Physics",
    data: [
      { title: "Total Seats", value: "63" },
      { title: "Opening Rank 2022", value: "6,738" },
      { title: "Closing Rank 2022", value: "1,404" }
    ]
  },
  {
    header: "Materials Science and Engineering",
    data: [
      { title: "Total Seats", value: "40" },
      { title: "Opening Rank 2022", value: "10,094" },
      { title: "Closing Rank 2022", value: "2,966" }
    ]
  },
  {
    header: "Mathematics and Computing",
    data: [
      { title: "Total Seats", value: "122" },
      { title: "Opening Rank 2022", value: "942" },
      { title: "Closing Rank 2022", value: "140" }
    ]
  },
  {
    header: "Mechanical Engineering ",
    data: [
      { title: "Total Seats", value: "95" },
      { title: "Opening Rank 2022", value: "4,401" },
      { title: "Closing Rank 2022", value: "918" }
    ]
  },
  {
    header: "Production and Industrial Engineering",
    data: [
      { title: "Total Seats", value: "89" },
      { title: "Opening Rank 2022", value: "9,192" },
      { title: "Closing Rank 2022", value: "1,928" }
    ]
  },
  {
    header: "Production and Industrial Engineering",
    data: [
      { title: "Total Seats", value: "96" },
      { title: "Opening Rank 2022", value: "12,034" },
      { title: "Closing Rank 2022", value: "4,097" }
    ]
  },
];

export default IITDelhiCourse;
