import { Box, Container, Tabs, Tab, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ExamDetailContent from "../../EntranceExam/ExamDetails/ExamDetailContent";
import SingleHeaderDynamicTable from "../../../components/TableComponent/SingleheaderTable";
import StripHeaderComponent from "../../../components/StripHeadercomponent";
import StripTitle from "../../../components/StripTitle";
import InsituteAdmissionContent from "../../../json/InstituteDetailJson/InstitudeAdmissionContent";
import InstitutePlacements from "../../../json/InstituteDetailJson/InstitutePlacements";
import ChristianAboutHeader from "../../../json/ChristianDetailsJson/ChristianAboutHeader";
import ChristianAboutData from "../../../json/ChristianDetailsJson/ChristianAboutData";
import ChristianFeeData from "../../../json/ChristianDetailsJson/ChristianFeeData";
import ChristianFeeHeader from "../../../json/ChristianDetailsJson/ChristianFeeHeader";
import ChristianDelhiCourse from "../../../json/ChristianDetailsJson/ChristianDelhiCourse";
import ChristianRankings from "../../../json/ChristianDetailsJson/ChristianRankings";

const ChristianCollegeTabDetails = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <>
      <Box sx={{ backgroundColor: "#252525" }}>
        <Container>
          <Box sx={{ width: "100%" }}>
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              aria-label="scrollable auto tabs example"
              sx={{
                fontSize: "13px",
                // mt: "40px",
                "& .MuiTab-root": {
                  // fontSize: "13px",
                  color: "#898383",

                  "&.Mui-selected": {
                    color: "#EF5835",
                  },
                },
                "& .MuiTabs-indicator": {
                  display: "none",
                },
                "& .MuiTabs-flexContainer": {
                  display: "flex",
                  justifyContent: "space-around",
                },
                "& .MuiTabScrollButton-root": {
                  color: "#ffffff", // Change color of scroll buttons to white
                },
              }}
            >
              <Tab label="About the College" />
              {/* <Tab label="Admission" /> */}
              <Tab label="Courses and Fee" />
              {/* <Tab label="Placements" /> */}
              <Tab label="Rankings" />
            </Tabs>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                flexWrap: "wrap",
              }}
            >
              {selectedTab === 0 && (
                <ExamDetailContent
                  color={"#ffffff"}
                  showHeader={true}
                  header={"About the College"}
                  headerContent={
                    "Christian Medical College Vellore offers an extensive range of undergraduate, postgraduate and higher speciality courses in medicine, nursing, allied health sciences and related disciplines. The college campus on the southern outskirts of Vellore city in Tamil Nadu is a 200-acre wooded site at the foot of College Hill. The central college chapel opens on to a sunken garden and connects with the women’s hostel and various classrooms through peaceful stone corridors. Interspersed among the tall trees and winding roads are other hostels, administrative, teaching and research facilities, staff residences, guest houses, playing fields and more. A large playground for athletics, football, hockey and cricket is located at one end of the campus. There are two state-of-the-art outdoor basketball courts and basketball continues to be the one of the most popular games in the campus. Volleyball courts, indoor shuttle courts and two tennis courts are also available."
                  }
                  title={"Details"}
                  DynamicTabledata={ChristianAboutData}
                  DynamicTableheader={ChristianAboutHeader}
                  gapcolor={"#252525"}
                />
              )}
              
              {selectedTab === 1 && (
                <>
                  <Box
                    sx={{
                      justifyContent: "center",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <ExamDetailContent
                      color="#ffffff"
                      title={"Fees"}
                      DynamicTabledata={ChristianFeeData}
                      DynamicTableheader={ChristianFeeHeader}
                    />
                    <StripTitle title={"Further Education"} />

                    {ChristianDelhiCourse?.map((item) => (
                      <SingleHeaderDynamicTable data={item} margin={"10px"} />
                    ))}
                  </Box>
                </>
              )}
              
              {selectedTab === 2 && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      mt: "20px",
                      mb: "20px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        color: "#ffffff",
                        fontWeight: "550",
                        fontSize: "16px",
                        position: "relative",
                        "&::before": {
                          content: "''",
                          position: "absolute",
                          left: "-10px",

                          width: "5px",
                          height: "20px",
                          backgroundColor: "#EF5835",
                        },
                      }}
                    >
                      {"Rankings"}
                    </Typography>
                  </Box>
                  {ChristianRankings?.map((item) => (
                    <SingleHeaderDynamicTable
                      data={item}
                      margin={"10px"}
                      headercolor={"#ffffff"}
                    />
                  ))}
                </Box>
              )}
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default ChristianCollegeTabDetails;
