const JeeAdvanceDateDataJson = {
    notification: "December 01, 2023",
    applicationformdate: "April 21, 2024",
    lastfillform: "April 30, 2024",
    appicationcorrect: "First week of May, 2024 (Tentative)",
    admitcarddate: "May 17, 2024",
    examdate: "May 26, 2024",
    answerkeydate: "June 2, 2024",
    resultdate: "June 9, 2024",

};
export default JeeAdvanceDateDataJson;
