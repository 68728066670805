const ExamSyllabus = [
    {


        data: [
            { title: "Subject 1", value: "English", },
            {
                title: "Syllabus", value: ["Fill in the blanks",
                    "Comprehension passages",
                    "Antonyms",
                    "Synonyms",
                    "Idioms and phrases",
                    "Jumble words and sentences",
                    "Choosing the correct words"],
            },
            { title: "Subject 2", value: "Current Affairs & General Knowledge", },
            {
                title: "Syllabus", value: ["Economics",
                    "Civics",
                    "Current Affairs",
                    "History",
                    "Geography",
                    "General Science"],
            },
            { title: "Subject 3", value: "Logical Reasoning", },
            {
                title: "Syllabus", value: ["Logical and analytical reasoning skills",
                    "Legal prepositions and reasoning"],
            },
            { title: "Subject 1", value: "English", },
            {
                title: "Books", value: ["Word Power Made Easy by Norman Lewis",
                    "High School English Grammar and Composition by Wren & Martin",
                    "English is Easy by Chetananand Singh",
                    "Objective General English by RS Aggarwal",
                    "Barron’s Pocket Guide to Vocabulary"],
            },

            { title: "Subject 2", value: "General knowledge", },
            {
                title: "Books", value: ["Lucent General Knowledge",
                    "GK Today",
                    "Arihant General knowledge",
                    "Pratiyogita Darapan",
                    "Manorama Book"],
            },
            { title: "Subject 3", value: "Legal Reasoning", },
            {
                title: "Books", value: ["Universal Guide to CLAT & LLB Entrance Examination",
                    "LexisNexis Butterworths",
                    "Bare Acts of Indian Constitution",
                    "Legal aptitude and Legal reasoning by A. P Bharadwaj",
                    "Verbal and Non Verbal Reasoning by RS Agarwal",
                    "A Modern Approach to Logical Reasoning by RS Agarwal",
                    "Analytical Reasoning by MK Pandey"],
            },

        ]
    },

];

export default ExamSyllabus;
