import React from "react";
import { Box, Typography, Button, Grid } from "@mui/material";
import iitdelhi from "../../../assets/IItdelhi.png";
import { Icon } from "@iconify/react";

import { useNavigate } from "react-router-dom";

const InstituteCollegeBanner = () => {
    // const [value, setValue] = React.useState("");
    // useEffect(() => {
    //   window.scrollTo(0, 0);
    // }, []);


    const navigate = useNavigate();

    return (
        <>
            <Box height={"auto"} width={"auto"}>
                <Grid container padding={"0px "}>
                    <Grid item md={5} sm={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                        <Box sx={{ display: "flex", alignItems: "left", flexDirection: "column" }}>
                            <Typography sx={{ fontSize: "24px", color: "#ef5835", fontWeight: "600" }}>

                            Institute of Law, Nirma University
                            </Typography>
                            <Box sx={{ display: "flex", flexDirection: "row" }}>
                                <Icon icon="mdi:location" fontSize="22px" color="#ef5835" />
                                <Typography sx={{ fontSize: "18px", fontWeight: "400" }}>

                                Ahmedabad, Gujarat
                                </Typography>
                            </Box>
                            <Button variant="outlined"
                                sx={{
                                    color: "#EF5835",
                                    borderRadius: 10,
                                    mt: "10px",
                                    mb: "10px",
                                    borderColor: "#EF5835",

                                    "&:hover": {
                                        backgroundColor: "#EF5835",
                                        borderColor: "#EF5835",
                                        color: "#ffffff",
                                    },
                                    width: "150px",
                                    height: "40px",
                                }} onClick={() => navigate("/college-list")}

                            >
                                Apply Now
                            </Button>

                        </Box></Grid>
                    <Grid item md={7} sm={12} sx={{ display: "flex", justifyContent: { md: "end", sm: "center", xs: "center" } }}>
                        <img src={iitdelhi} alt={"CollegeName"} style={{ width: "100%" }} />
                    </Grid>
                </Grid>
            </Box>


        </>
    );
};

export default InstituteCollegeBanner;


