const AbbsInternship = [
    {

        data: [
            { title: "Number of companies that offered internships in 2021-22", value: "21" },


        ]
    },


];

export default AbbsInternship;
