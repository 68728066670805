const JeeAdvanceExamCuttoffData = [
    {


        data: [
            { title: "Common rank list (CRL)", value: "6", valuetwo: "63" },
            { title: "OBC-NCL rank list", value: "5", valuetwo: "56" },
            { title: "GEN-EWS rank list", value: "5", valuetwo: "56" },
            { title: "SC rank list", value: "3", valuetwo: "31" },
            { title: "ST rank list", value: "3", valuetwo: "31" },
            { title: "Common-PwD rank list(CRL-PwD)", value: "3", valuetwo: "31" },
            { title: "GEN-EWS-PwD rank list", value: "3", valuetwo: "31" },
            { title: "SC-PwD rank list", value: "3", valuetwo: "31" },
            { title: "ST-PwD rank list", value: "3", valuetwo: "31" },
            { title: "Preparatory course (PC) rank lists", value: "0", valuetwo: "9" },

        ]
    },

];

export default JeeAdvanceExamCuttoffData;
