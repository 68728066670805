import React from 'react'
import Addmition from '../home/Footer/footer'
import { Box } from '@mui/material'
import Navbar from "../home/Header/Navbar";
import AbbsCollegeTabDetails from './CollegeDetails/AbbsCollegeTabdetails'
import AbbsBanner from './CollegeDetails/AbbsBanner';


const ABBSCollegeDetails = () => {
  return (
    <div>
      <Navbar />
      <AbbsBanner />
      <Box sx={{ backgroundColor: "#252525" }}>
        <AbbsCollegeTabDetails />
        <Addmition margintop={"40px"} paddingtop={"-40px"} />
      </Box>

    </div>
  )
}

export default ABBSCollegeDetails
