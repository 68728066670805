const ExamCuttoffData = [
    {


        data: [
            { title: "General", value: "100 (Rank - 82)", valuetwo: "100.25 (Rank - 60)" },
            { title: "OBC-NCL", value: "84.25 (Rank - 653)", valuetwo: "92.25 (Rank - 156)" },
            { title: "Scheduled Caste", value: "72.75 (Rank - 1819)", valuetwo: "78 (Rank - 490), 72.75 (Rank - 1826)" },
            { title: "Scheduled Tribe", value: "63.25 (Rank - 3183)", valuetwo: "74 (Rank - 621)" },
            { title: "Persons with Disability (General)", value: "71.25 (Rank - 1988)", valuetwo: "96.25 (Rank - 109)" },
            { title: "PwD (OBC-Non Creamy Layer)", value: "70 (Rank - 2185)", valuetwo: "----" },
            { title: "PwD (SC)", value: "49.5 (Rank - 6069)", valuetwo: "----" },
            { title: "Kashmiri Migrants", value: "78.5 (Rank - 1166)", valuetwo: "74.75 (Rank - 598)" },
            { title: "Residents of Jammu and Kashmir", value: "88.75 (Rank - 387)", valuetwo: "----" },
            { title: "EWS", value: "89.5 (Rank - 358)", valuetwo: "91.75 (Rank - 167)" }
        ]
    },

];

export default ExamCuttoffData;
