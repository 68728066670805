import { Box, Container, Grid, List, ListItem, ListItemText, Typography } from '@mui/material';
import React from 'react'
import '../../careerplatform.css'

const AboutUsBenefits = () => {
    const reasons = [
        {
            title: "For Students:",
            content: "Personalized academic and emotional support, improved grades, emotional resilience, and safety.",
        },
        {
            title: "For Parents:",
            content: "Peace of mind, informed decision-making, better communication with schools, and a professional support network.",
        },
    ];
    return (
        <Box sx={{ bgcolor: "#ef5835", margin: "40px auto" }}>
            <Container>
                <Typography variant="h4" className="box1" component="h2" color="#fff" textAlign={"center"} sx={{ fontWeight: 'bold', pt: "30px" }}>
                    Benefits
                </Typography>
                <Grid
                    container
                    display="flex"
                    justifyContent="space-between"
                    style={{
                        height: "100%",
                        padding: "10px 0"
                    }}
                >                            {reasons?.map((reason, index) => (

                    <Grid item xs={12} sm={6} md={6} lg={6} sx={{ padding: "0 20px" }}>

                        <Box >

                            <List>
                                <ListItem key={index} sx={{
                                    display: 'list-item', '::marker': {
                                        color: 'white'
                                    }, listStyleType: 'disc', paddingLeft: 0
                                }}>
                                    <ListItemText
                                        primary={
                                            <Typography variant="body1" className="box2" color={"#fff"}>
                                                <Typography variant="body1" className="box1" component="span" sx={{ fontWeight: 'bold', color: "#fff" }}>
                                                    {reason.title}
                                                </Typography> {reason.content}
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                            </List>
                        </Box>

                    </Grid>
                ))}

                </Grid>
            </Container>
        </Box>
    )
}

export default AboutUsBenefits
