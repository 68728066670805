const NeetApplicationDataJson = {
    applicationMode: "Online",
    stepstoApply: ["Step 1: Visit the NEET 2022 official website",

        "Step 2: Click on the tab indicating the application form",
        
        "Step 3: Read all the instructions and register with personal details such as name, contact details, password, etc. to generate application number and password",
        
        "Step 4:After the credentials are generated, log in and fill the application form with personal and educational details, exam medium and exam city preference",
        
        "Step 5: upload photograph and signature",
        "Step 6 : Pay the application fee in online mode through credit/debit card, net banking, Paytm and UPI"
            ],
    documentsRequired: ["Scanned images of passport-size and postcard-size photograph, signature, left-hand thumb impression",
        "Class 10 pass certificate category certificate (SC/ST/OBC/EWS etc.) PwBD certificate (pdf file) Citizenship certificate/ Embassy certificate or any Documentary proof of Citizenship certificate (PDF)"],
    HowtofillapplicationForm: ["NEET Registration 2022: To fill neet.nta.nic.in 2022 application form, candidates are required to click on 'fill application form",
        "On the page opened, click on 'New registration' which will lead to the instructions page which must be read carefully. Then, click on a declaration that instructions have been read. A password must also be created as per the given specifications which will be the password for all future login",
        "A provisional application number will be generated after registration for NEET 2022 login. This will be the user ID that will have to be used to neet.nta.nic.in login 2022",
        "The following details must be entered during NEET 2022 registration: Candidate's Name, Mother's Name, Father's Name, Date of Birth, Gender, Nationality, State of Eligibility, Category, Person with Disabilities, Identification Type, Identification Number, Mobile Number, Email ID, Security Pin (as displayed on the screen)",
        "Choose Password: The next step would be to choose a password, retype the same, enter a security question, and choose the answer. Keep the security question and answer safe as this may be required to retrieve the password in case a candidate forgets it",
        "After completing the registration process. candidates must click on 'Complete Application Form' as shown in the image below. Login using the provisional registration number and password. It is mandatory to fill remaining details in the application form of NEET 2022",
        "The other details to be filled in the NEET 2022 application form are",
        "Section 1. Personal Details –Candidate’s Name, Mother’s and Father’s Name, Gender, Category, Date of Birth, Nationality, Persons with disability, Do you belong to Minority Category, Are you suffering from Diabetes Type I, Identification Type, Identification Number",
        "Section 2. Apply for - The entrance exam details: Medium of NEET question, Selection of NEET 2022 exam city",
        "Section 3. Academic details",
        "Click on 'I Agree",
        "Uploading of scanned documents and images: Passport Photograph, Signature, Left-hand thumb impression, Postcard size photo",
        "Payment of NEET Application Fee: NEET 2022 application fee can be paid in online mode only"
    ],
    applicationFees: ["General: 1600",
        "General-EWS/OBC-NCL: 1500",
        "SC/ST/PwD/Third Gender: 900",
        "Outside India: 8500",],
    feePayment: ["Processing charges & Goods and Services Tax (GST) are to be paid by the candidate,as applicable",
        "Pay the fee using SBI/Canara Bank/ HDFC/ ICICI/ Paytm Payment Gateway through Debit Card/Credit Card/Net-banking/UPI/Wallet and keep proof of fee paid safely for future reference"],

    importantPoints: [
        "Candidates are advised to take 6-8 Passport size and 4-6 Post Card size (4” X6”) coloured photographs with white background. The photographs are to be used for uploading an Online Application Form, for examination, and also for Counselling/Admission. This is to ensure that the same photograph is used for all documents and all purposes related to NEET (UG)-2022",
"The candidate, before submitting the application form online, must ensure his eligibility to appear in the test. The candidate is required to go through the Information Bulletin carefully and be clear about all requirements with regard to the submission of the Application Form",
"The Candidate should fill in complete correspondence and permanent address with the pin code, e-mail address, and mobile number (own or Parents/Guardian only) for future correspondence",
"Candidates must ensure that mobile numbers and e-mail ID filled in the online Application Form are their own or Parents/Guardian only as NTA will communicate through SMS or e-mail on the given mobile number and e-mail ID",
"Candidate should not give the postal address, contact number/mobile number, and email ID of Coaching Centres in their Online Application Form",
"Those Candidates who have passed should fill Code [02 to 07] against the column provided for in case of Qualifying Examination",
"Candidates appearing in Class 12 in 2022 should select a qualifying code as 01",
"The Application Form other than the one filled in online mode will not be accepted",
"The Candidates are not required to send the Confirmation Page of the Online Application Form or any other document to the NTA. However, the candidates are advised to preserve at least four copies of the Confirmation Page along with the proof of fee paid for future reference",
"Candidates from UT of J&K and Ladakh are required to submit an online Self Declaration for seats under 15% All India Quota"
],

applicationformcorrection:["To be announced"]

};
export default NeetApplicationDataJson;
