const AbbsAboutHeader = [
    { id: 1, label: "Address", var: "address" },
    { id: 2, label: "City", var: "city" },
    { id: 3, label: "State", var: "state" },
    { id: 4, label: "Phone", var: "phone" },
    { id: 5, label: "Year established", var: "established" },
    { id: 6, label: "First BBA Batch graduated", var: "btechgraduated" },
    { id: 7, label: "Head of the Institution", var: "head" },
    { id: 8, label: "Institute type", var: "type" },
    { id: 9, label: "Website", var: "website" },
    { id: 10, label: "Anti-ragging cell", var: "raggingcell" },
    { id: 11, label: "National Service Scheme unit", var: "nationalservice" },
    { id: 12, label: "Internal Complaints Committee cell", var: "complaintcell" },
    { id: 13, label: "Internal Quality Assurance Cell", var: "qualityassurance" },

];

export default AbbsAboutHeader;
