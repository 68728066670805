const JeeAiletDateDataJson = {
    notification: "September 19, 2023",
    applicationformdate: "January session- November 1, 2023 April Sesssion- February 2, 2024",
    lastfillform: "January Session- December 4, 2023 April Session session- March 2, 2024",
    appicationcorrect: "January Session- December 6-8, 2023 April session- March, 2024",
    admitcarddate: "January Session- Three days before the exam April session - Three days before the exam",
    examdate: "January session - January 24-February 1, 2024 April Session - April 1-15, 2024",
    answerkeydate: "January session: February, 2024 April session: April, 2024",
    resultdate: "January session: February 12, 2024 April session: April 25, 2024",

};
export default JeeAiletDateDataJson;
