import React from 'react';
import { Box, Container, Typography, List, ListItem, ListItemText, Link } from '@mui/material';
import ResponsiveAppBar from './home/Header/Navbar';
import Addmition from './home/Footer/footer';

const ReturnRefundPolicy = () => {
    return (
        <div>
            <ResponsiveAppBar />
            <Box sx={{ mb: "60px" }}>
                <Container>
                    <Typography variant="h4" sx={{ fontFamily: 'Montserrat', fontWeight: 700, margin: "20px 0" }}>
                        Return and Refund Policy
                    </Typography>
                    <Typography variant="body1" sx={{ fontFamily: 'Nunito' }}>
                        Last updated: July 10, 2024
                    </Typography>

                    <Typography variant="body1" sx={{ fontFamily: 'Nunito', margin: "20px 0" }}>
                        Thank you for shopping at careerplatform.
                    </Typography>
                    <Typography variant="body1" sx={{ fontFamily: 'Nunito', margin: "20px 0" }}>
                        If, for any reason, You are not completely satisfied with a purchase We invite You to review our policy on refunds and returns.
                    </Typography>
                    <Typography variant="body1" sx={{ fontFamily: 'Nunito', margin: "20px 0" }}>
                        The following terms are applicable for any products that You purchased with Us.
                    </Typography>

                    <Typography variant="h5" sx={{ fontFamily: 'Montserrat', fontWeight: 700, margin: "20px 0" }}>
                        Interpretation and Definitions
                    </Typography>
                    <Typography variant="h6" sx={{ fontFamily: 'Montserrat', fontWeight: 700, margin: "20px 0" }}>
                        Interpretation
                    </Typography>
                    <Typography variant="body1" sx={{ fontFamily: 'Nunito' }}>
                        The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
                    </Typography>

                    <Typography variant="h6" sx={{ fontFamily: 'Montserrat', fontWeight: 700, margin: "20px 0" }}>
                        Definitions
                    </Typography>
                    <Typography variant="body1" sx={{ fontFamily: 'Nunito' }}>
                        For the purposes of this Return and Refund Policy:
                    </Typography>

                    <List sx={{ fontFamily: 'Nunito' }}>
                        <ListItem>
                            <ListItemText primary={
                                <>
                                    <strong>Company:</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to careerplatform.
                                </>
                            } />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={
                                <>
                                    <strong>Goods:</strong> refer to the items offered for sale on the Service.
                                </>
                            } />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={
                                <>
                                    <strong>Orders:</strong> mean a request by You to purchase Goods from Us.
                                </>
                            } />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={
                                <>
                                    <strong>Service:</strong> refers to the Website.
                                </>
                            } />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={
                                <>
                                    <strong>Website:</strong> refers to careerplatform, accessible from <Link href="https://www.careerplatform.co.in">www.careerplatform.co.in</Link>

                                </>
                            } />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={
                                <>
                                    <strong>You:</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.
                                </>
                            } />
                        </ListItem>
                    </List>

                    <Typography variant="h5" sx={{ fontFamily: 'Montserrat', fontWeight: 700, margin: "20px 0" }}>
                        Your Order Cancellation Rights
                    </Typography>
                    <Typography variant="body1" sx={{ fontFamily: 'Nunito' }}>
                        You are entitled to cancel Your Order within 30 days without giving any reason for doing so.
                    </Typography>
                    <Typography variant="body1" sx={{ fontFamily: 'Nunito' }}>
                        The deadline for cancelling an Order is 30 days from the date on which You received the Goods or on which a third party you have appointed, who is not the carrier, takes possession of the product delivered.
                    </Typography>
                    <Typography variant="body1" sx={{ fontFamily: 'Nunito' }}>
                        In order to exercise Your right of cancellation, You must inform Us of your decision by means of a clear statement. You can inform us of your decision by:
                    </Typography>
                    <Typography variant="body1" sx={{ fontFamily: 'Nunito', marginLeft: "20px" }}>
                        By phone number: <a href='tel:+919379660310'>+91-9379660310</a>
                    </Typography>
                    <Typography variant="body1" sx={{ fontFamily: 'Nunito', margin: "20px 0" }}>
                        We will reimburse You no later than 14 days from the day on which We receive the returned Goods. We will use the same means of payment as You used for the Order, and You will not incur any fees for such reimbursement.
                    </Typography>

                    <Typography variant="h5" sx={{ fontFamily: 'Montserrat', fontWeight: 700, margin: "20px 0" }}>
                        Conditions for Returns
                    </Typography>
                    <Typography variant="body1" sx={{ fontFamily: 'Nunito' }}>
                        In order for the Goods to be eligible for a return, please make sure that:
                    </Typography>
                    <List sx={{ fontFamily: 'Nunito' }}>
                        <ListItem>
                            <ListItemText primary="The Goods were purchased in the last 30 days" />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="The Goods are in the original packaging" />
                        </ListItem>
                    </List>
                    <Typography variant="body1" sx={{ fontFamily: 'Nunito' }}>
                        The following Goods cannot be returned:
                    </Typography>
                    <List sx={{ fontFamily: 'Nunito' }}>
                        <ListItem>
                            <ListItemText primary="The supply of Goods made to Your specifications or clearly personalized." />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="The supply of Goods which according to their nature are not suitable to be returned, deteriorate rapidly or where the date of expiry is over." />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="The supply of Goods which are not suitable for return due to health protection or hygiene reasons and were unsealed after delivery." />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="The supply of Goods which are, after delivery, according to their nature, inseparably mixed with other items." />
                        </ListItem>
                    </List>
                    <Typography variant="body1" sx={{ fontFamily: 'Nunito', margin: "20px 0" }}>
                        We reserve the right to refuse returns of any merchandise that does not meet the above return conditions in our sole discretion.
                    </Typography>
                    <Typography variant="body1" sx={{ fontFamily: 'Nunito', margin: "20px 0" }}>
                        Only regular priced Goods may be refunded. Unfortunately, Goods on sale cannot be refunded. This exclusion may not apply to You if it is not permitted by applicable law.
                    </Typography>

                    <Typography variant="h5" sx={{ fontFamily: 'Montserrat', fontWeight: 700, margin: "20px 0" }}>
                        Returning Goods
                    </Typography>
                    <Typography variant="body1" sx={{ fontFamily: 'Nunito' }}>
                        You are responsible for the cost and risk of returning the Goods to Us. You should send the Goods at the following address:
                    </Typography>
                    <Typography variant="body1" sx={{ fontFamily: 'Nunito', marginLeft: "20px" }}>
                        website
                    </Typography>
                    <Typography variant="body1" sx={{ fontFamily: 'Nunito', margin: "20px 0" }}>
                        We cannot be held responsible for Goods damaged or lost in return shipment. Therefore, We recommend an insured and trackable mail service. We are unable to issue a refund without actual receipt of the Goods or proof of received return delivery.
                    </Typography>

                    <Typography variant="h5" sx={{ fontFamily: 'Montserrat', fontWeight: 700, margin: "20px 0" }}>
                        Gifts
                    </Typography>
                    <Typography variant="body1" sx={{ fontFamily: 'Nunito' }}>
                        If the Goods were marked as a gift when purchased and then shipped directly to you, You'll receive a gift credit for the value of your return. Once the returned product is received, a gift certificate will be mailed to You.
                    </Typography>
                    <Typography variant="body1" sx={{ fontFamily: 'Nunito', margin: "20px 0" }}>
                        If the Goods weren't marked as a gift when purchased, or the gift giver had the Order shipped to themselves to give it to You later, We will send the refund to the gift giver.
                    </Typography>
                    <Typography variant="h5" sx={{ fontFamily: 'Montserrat', fontWeight: 700, margin: "20px 0" }}>
                        Contact Us
                    </Typography>
                    <Typography variant="body1" sx={{ fontFamily: 'Nunito' }}>
                        If you have any questions about our Returns and Refunds Policy, please contact us:
                    </Typography>
                    <List sx={{ fontFamily: 'Nunito' }}>
                        <ListItemText
                            primary={<Typography component="div">By phone number: <a href='tel:+919379660310'>+91-9379660310</a></Typography>}
                        />
                    </List>
                </Container>
                <Addmition margintop={"40px"} paddingtop={"-30px"} />
            </Box>

        </div>)
};

export default ReturnRefundPolicy;
