
import { Box, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import icon1 from '../../assets/benefit1.png';
import icon2 from '../../assets/benefit2.png';
import icon3 from '../../assets/benefit3.png';
import icon4 from '../../assets/benefit4.png';
import OverseasValueCard from './OverseasValueCard';


const OverseasValue = () => {




    const valueCardData = [
        {
            id: 1,
            icon: icon1,
            title: "Expertise and Experience",
            about: "Seasoned professionals in international education."
        },
        {
            id: 2,
            icon: icon2,
            title: "Customized Approach",
            about: "Services tailored to each student's needs."
        },
        {
            id: 3,
            icon: icon3,
            title: "Comprehensive Support",
            about: "End-to-end assistance from university selection to pre-departure."
        },
        {
            id: 4,
            icon: icon4,
            title: "Stress Reduction",
            about: "Reducing stress and uncertainty in the application process."
        },
    ];
    return (
        <Box sx={{ padding: "30px", backgroundColor: "#ef5835" }}>

            <Container
                sx={{
                    padding: "50px 0",
                    height: "auto",

                    width: "100%"
                }}
            >
                <Grid container
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    direction="column"
                >
                    <Typography
                        textAlign="center" className='box1' sx={{ fontWeight: "550", fontSize: "28px", color: "#fff" }}>
                        Benefits
                    </Typography>



                    <Container
                        style={{
                            height: "100%",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    ><Box
                        style={{
                            gap: "2rem",
                            justifyContent: "center",
                            margin: "auto",
                        }}

                    > <Grid container>

                                {
                                    valueCardData.map((card) => (
                                        <Grid item md={3} xs={12}>

                                            <OverseasValueCard
                                                key={card.id}
                                                id={card.id}
                                                icon={card.icon}
                                                title={card.title}
                                                about={card.about}
                                            />
                                        </Grid>
                                    ))
                                }
                            </Grid>
                        </Box>
                    </Container>
                </Grid>
            </Container>
        </Box>
    )
}

export default OverseasValue