const AiletAdmitCardDataJson = {
    availabilityofadmitcard: ["16-Jun-2022"],

    officialwebsitetodownloadadmitcard: ["https://nationallawuniversitydelhi.in/"],

    particularsrequiredtodownloadadmitcard: ["Registration Id and Password"],

    importantdetailsavailableinadmitcard: ["Name of the candidate",
        "Roll No. and Application No.",
        "Category",
        "Date of birth",
        "Parent/guardian’s name",
        "Name of the course",
        "Exam date, time of examination, Reporting time",
        "Address of exam center",
    ],
    
    contactdetails: [
        "Email: ailetadmissions@nludelhi.ac.in",
    "Help Desk: 022-61306293, 011-28034257",
],

compatiblebrowsersfordownloading: [
        "Internet Explorer, Firefox, Google Chrome",
    ],
    
    howtodownloadadmitcard: [
        "Visit the official admission portal of NLU Delhi",
        "Login using registration ID and password",
        "Click on admit card link given there",
        "The AILET admit card 2022 will be displayed on the screen",
        "Download and save the admit card and take a print-out of it for future reference",
    ],

    stepstodownloadadmitcard: [
        "Step 1: Visit the NLU Delhi Official Admission Portal",
        "Step 2: Sign in with your registration ID and password",
        "Step 3: Click on the link to the registration card there AILET admission ticket 2022 will be displayed on the screen",
        "Step 4: Download the admission ticket, save it and print it for later reference",
    ],
    
    itemsnotpermittedinsideexamcenter: [
        "Electronic gadgets",
        "Calculators",
        "Mobile phones",
        "Watches",
        "Paper",
        "Study materials",
    ],

    itemsallowedinsideexamcenter: [
        "A hardcopy of the AILET 2022 admit card/hall ticket",
        "A valid photo ID card such as an Aadhar card, Voter ID, Passport or Driving license",
        "A blue or black ballpoint pen Mask",
        "Gloves",
        "Sanitizer",
        "A transparent water bottle.",
    ],

    guidelinesforadmitcard: [
        "The admission card must contain the correct details of the candidate, such as name, date of birth, and category. In the event of a discrepancy, the candidate should immediately contact the testing office and request any necessary corrections.",
    ],

    examdayguidelines: [
        "Candidates should report to the designated AILET exam centre at least one hour before the commencement of the exam.",

        "Candidates arriving late at the designated exam centre will not be allowed to appear in the examination.",

        "The candidate must carry an admit card and valid ID card to the examination centre and produce them when asked.",

        "Candidates must not carry any paper, study material, electronic devices such as mobile phones, calculators, or watches to the examination centre. These items are strictly prohibited in the examination hall.",

        "Candidates will not be allowed to leave the examination hall before the completion of the AILET examination.",

        "Candidates should preserve the admit card of AILET 2022 even after the completion of the examination. The university will ask the candidate to produce the admit cards at the time of AILET counselling and the seat allocation process.",
    ],

};
export default AiletAdmitCardDataJson;
