const AmityRankings = [
    {
        header: "Rank among Govt/ Pvt universities",
        data: [
            { title: "G/P Rank (2023)", value: "1" },
            { title: "G/P Rank (2022)", value: "1" },

        ]
    },


];

export default AmityRankings;
