import React from 'react'
import PersonalDetails from "../ApplyOnline/PersonalDetails/PersonalDetails";
// import PresentAddress from "../ApplyOnline/PresentAddress/PresentAddress";
// import FamilyDetails from "../ApplyOnline/FamilyDetails/FamilyDetails"
// import EntranceExamScores from '../ApplyOnline/EntranceExamScores/EntranceExamScores';
// import AcademicQualification from '../ApplyOnline/AcademicQualification/AcademicQualification';
// import UploadDocument from '../ApplyOnline/UploadDocument/UploadDocument';
// import UploadProfilePicture from '../ApplyOnline/UploadProfilePicture/UploadProfilePicture';
// import Submit from '../ApplyOnline/Submit/Submit';
import Navbar from '../../pages/home/Header/Navbar';
import Footer from '../../pages/home/Footer/footer';


const applyOnline = () => {
  return (
    <div>
      <Navbar />
      <PersonalDetails />

      <Footer margintop={0} />
    </div>
  )
}

export default applyOnline
