import { Box, Container, Tabs, Tab, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ExamDetailContent from "../../EntranceExam/ExamDetails/ExamDetailContent";
import SingleHeaderDynamicTable from "../../../components/TableComponent/SingleheaderTable";
import StripHeaderComponent from "../../../components/StripHeadercomponent";
import NationalAboutHeader from "../../../json/NationalCollegeDetail/NationalAboutHeader";
import NationalAboutData from "../../../json/NationalCollegeDetail/NationalAboutData";
import NationalAdmissionContent from "../../../json/NationalCollegeDetail/NationalAdmissionContent";
import StripTitle from "../../../components/StripTitle";
import NationalFeeData from "../../../json/NationalCollegeDetail/NationalFeeData";
import NationaDelhiCourse from "../../../json/NationalCollegeDetail/NationaDelhiCourse";
import NationalPlacements from "../../../json/NationalCollegeDetail/NationalPlacements";
import NationalRankings from "../../../json/NationalRankings";
import NationalFeeHeader from "../../../json/NationalCollegeDetail/NationalFeeHeader";

const NationalCollegeTabDetails = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <>
      <Box sx={{ backgroundColor: "#252525" }}>
        <Container>
          <Box sx={{ width: "100%" }}>
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              aria-label="scrollable auto tabs example"
              sx={{
                fontSize: "13px",
                // mt: "40px",
                "& .MuiTab-root": {
                  // fontSize: "13px",
                  color: "#898383",

                  "&.Mui-selected": {
                    color: "#EF5835",
                  },
                },
                "& .MuiTabs-indicator": {
                  display: "none",
                },
                "& .MuiTabs-flexContainer": {
                  display: "flex",
                  justifyContent: "space-around",
                },
                "& .MuiTabScrollButton-root": {
                  color: "#ffffff", // Change color of scroll buttons to white
                },
              }}
            >
              <Tab label="About the College" />
              <Tab label="Admission" />
              <Tab label="Courses and Fee" />
              <Tab label="Placements" />
              <Tab label="Rankings" />
            </Tabs>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                flexWrap: "wrap",
              }}
            >
              {selectedTab === 0 && (
                <ExamDetailContent
                  color={"#ffffff"}
                  showHeader={true}
                  header={"About the College"}
                  headerContent={
                    "The National Law School of India University campus stands on Gnana Bharathi Main Road near Outer Ring Road in Bengaluru’s Naagarabhaavi area, some 44 kilometres from Kempegowda International Airport. Non-academic infrastructure includes dining facilities and a health and wellness centre. The institute also has hostels for male and female students. Other on-campus infrastructure includes facilities for various sports like football, basketball, throwball, volleyball, tennis and badminton"
                  }
                  title={"Details"}
                  DynamicTabledata={NationalAboutData}
                  DynamicTableheader={NationalAboutHeader}
                  gapcolor={"#252525"}
                />
              )}
              {selectedTab === 1 && (
                <Box sx={{ width: "100%" }}>
                  <Box sx={{ padding: "10px 0px" }}>
                    {NationalAdmissionContent?.map((item) => (
                      <StripHeaderComponent
                        data={item}
                        margin={"10px"}
                        color={"#ffffff"}
                      />
                    ))}
                  </Box>
                  {/* <StripTitle title={"Cut Off:"} />
                  {IITDelhiAdmission?.map((item) => (
                    <HeaderDynamicTable
                      data={item}
                      margin={"10px"}
                      showheader3={false}
                      header1={"Branch"}
                      header2={"IIT Delhi JEE Advanced Cutoff"}
                      subheader1={"Opening rank 2021"}
                      subheader2={"Opening rank 2020"}
                    />
                  ))} */}
                </Box>
              )}
              {selectedTab === 2 && (
                <>
                  <Box
                    sx={{
                      justifyContent: "center",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <ExamDetailContent
                      color="#ffffff"
                      title={"Fees"}
                      DynamicTabledata={NationalFeeData}
                      DynamicTableheader={NationalFeeHeader}
                    />
                    <StripTitle title={"Further Education"} />

                    {NationaDelhiCourse?.map((item) => (
                      <SingleHeaderDynamicTable data={item} margin={"10px"} />
                    ))}
                  </Box>
                </>
              )}
              {selectedTab === 3 && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <StripTitle title={"Placements"} />
                  {NationalPlacements?.map((item) => (
                    <SingleHeaderDynamicTable
                      data={item}
                      margin={"10px"}
                      showheader={false}
                    />
                  ))}
                  {/* <StripTitle title={"Internships"} />
                  {IITDelhiInternship?.map((item) => (
                    <SingleHeaderDynamicTable
                      data={item}
                      margin={"10px"}
                      showheader={false}
                    />
                  ))}
                  <StripTitle title={"Incubation Center"} />
                  {IITDelhiIncubation?.map((item) => (
                    <SingleHeaderDynamicTable
                      data={item}
                      margin={"10px"}
                      showheader={false}
                    />
                  ))} */}
                </Box>
              )}
              {selectedTab === 4 && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      mt: "20px",
                      mb: "20px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        color: "#ffffff",
                        fontWeight: "550",
                        fontSize: "16px",
                        position: "relative",
                        "&::before": {
                          content: "''",
                          position: "absolute",
                          left: "-10px",

                          width: "5px",
                          height: "20px",
                          backgroundColor: "#EF5835",
                        },
                      }}
                    >
                      {"Rankings"}
                    </Typography>
                  </Box>
                  {NationalRankings?.map((item) => (
                    <SingleHeaderDynamicTable
                      data={item}
                      margin={"10px"}
                      headercolor={"#ffffff"}
                    />
                  ))}
                </Box>
              )}
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default NationalCollegeTabDetails;
