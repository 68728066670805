import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
// import image from "../../assets/aboutUsBanner.png";
import image from "../../assets/aboutUsBanner2.png";
import "../../careerplatform.css";

const OverseasBanner = () => {
  return (
    <Box sx={{ bgcolor: "#ef5835", height: "auto", pt: "20px" }}>
      <Container>
        <Grid container spacing={2}>
          <Grid
            item
            md={6}
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography
                className='box1'
                sx={{
                  color: "#ffffff",
                  fontFamily: "montserrat",
                  fontWeight: "600",
                  fontSize: "24px",
                }}
              >
                Introducing Our New Overseas Admission Consultancy Service
              </Typography>
              <Typography
                className='box2'
                sx={{
                  color: "#ffffff",
                  fontFamily: "Nunito",
                  fontWeight: "500",
                  fontSize: "20px",
                  pt: "30px",
                }}
              >
                We are thrilled to launch our Overseas Admission Consultancy,
                offering personalized guidance to help students navigate the
                complexities of applying to universities abroad.
              </Typography>
            </Box>
          </Grid>
          <Grid item md={6} xs={12}>
            <Box sx={{ width: { md: "600px", xsx: "300px" } }}>
              <img src={image} alt='aboutus_image' width={"100%"} />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default OverseasBanner;
