import { Box, Container, List, ListItem, ListItemText, Typography } from "@mui/material";
import React from "react";
import "./flipcard.css";
import '../../careerplatform.css'

const FlipCard = ({
    data,
    AbleTitle = true,
    backPaddingTop,
    fontSize,
    isFlip = true,
    isHover = false,
    cardwidth,
    cardheight,
    titlefontsize,
    padding, pb, sx, listalign,
    liststyle
}) => {
    return (
        <Container sx={sx}>
            <Box
                className="flip-card"
                sx={{
                    justifyContent: "center",
                    display: "flex",
                    width: cardwidth ?? "230px",
                    height: cardheight ?? "230px",
                    "&:hover .flip-card-inner": {
                        transform: isFlip ? "rotateY(-180deg)" : "none",
                    },
                    "&:hover .flip-card-front": {
                        backgroundColor: isHover ? "#00999E" : "#ffffff",
                    },
                    "&:hover .flip-card-front .flip-header": {
                        color: isHover ? "#ffffff" : "#000000",
                    }
                }}
            >
                <Box className="flip-card-inner">
                    <Box className="flip-card-front">
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                paddingTop: "10px",
                                pb: pb ?? "10px",
                            }}
                        >
                            <img
                                src={data?.Image}
                                alt="EligibilityImage"
                                style={{

                                    width: "100px",
                                    height: "100px",
                                }}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                p: padding ?? "20px",
                            }}
                        >
                            <Typography variant="body1" className="box1" sx={{ fontWeight: 'bold', textAlign: "center" }}>
                                {data?.heading}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography sx={{ fontSize: "11px", color: "#706C6D", pl: "5px", pr: "8px" }}>
                                {data?.content}
                            </Typography>
                        </Box>
                    </Box>
                    <Box className="flip-card-back">

                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center", overflow: "hidden",
                                paddingTop: backPaddingTop ?? "5px",
                                pl: "12px", pr: "10px"
                                , pb: "5px",
                            }}
                        >
                            <List>
                                {data?.reasons?.map((reason, index) => (
                                    <ListItem key={index} sx={{ display: 'list-item', listStyleType: 'disc', }}>
                                        <ListItemText
                                            primary={
                                                <Box>

                                                    <Typography variant="body1" className="box1" sx={{ fontWeight: 'bold', color: "#fff", textAlign: "center", fontSize: "13px" }}>
                                                        {reason.title}
                                                    </Typography>
                                                    <Typography variant="body2" className="box2" sx={{ color: "#fff", textAlign: "center", fontSize: "13px" }}>
                                                        {reason.content}
                                                    </Typography>
                                                </Box>
                                            }
                                        />
                                    </ListItem>
                                ))}
                            </List>


                        </Box>
                    </Box>
                </Box>
            </Box>
        </Container>
    );
};

export default FlipCard;
