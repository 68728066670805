/* eslint-disable jsx-a11y/img-redundant-alt */
import { Typography, Box, Grid } from "@mui/material";
import React from "react";
import Group from '../../../assets/CareerCounseling/Group 50.png'
const MyComponent = () => {
  return (
    <Box sx={{ marginTop: { xl: "5rem", lg: "20%", sm: "5rem", xs: "15rem" }, background: "#EF5835" }}>
      <Grid container sx={{ paddingLeft: { xl: '10%' } }}>
        <Grid item lg={6} xs={12} sm={12}>
          <Box
            sx={{ paddingTop: { lg: "8%", sm: "5rem", xs: "3rem" } }}
          >
            <Typography className="box2" sx={{ fontSize: "25px", color: "#fff", flexDirection: "column", display: "flex", justifyContent: "center", alignItems: "center", textAlign: { xl: "start", lg: "start", md: "justify", sm: "center", xs: "center" }, paddingLeft: { xl: "2rem", lg: "5rem" } }}>
              In Today’s competitive world, It is Important<br /> for one to choose and decide on a <br />
              a definite career path early in one’s life.<br /> With the wide Range of Courses available <br />
              and companies seeking  specialized people,<br /> choosing a career has become a<br />
              serious and tedious affair. While slecting a <br /> career , It is important for the student to <br />
              discover thier potential, aptitude and <br /> interest, and then select the right course <br />
              accordingly. Unfortunately, many students<br /> fail to do so which eventually leads them <br />
              to make the wrong career choices. While a<br /> good decision at the right time can<br />
              relate can create a huge, long-lasting<br /> impact on your life, a wrong one can ruin it.<br />
              Our Career Counselling services have been<br /> designed to help students develop<br />
              clarity, discover confidence and experience<br /> success. In case you are in a dilemma<br />
              about choosing a suitable career for<br /> yourself, approaching our eperienced and<br />
              qualified career counsellors is always<br /> advisable
            </Typography>

          </Box>
        </Grid>
        <Grid item lg={6} xs={12} sm={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: { lg: "center", md: "flex-end", sm: "flex-end", xs: "center" },
              width: { xl: "70%", lg: "80%", sm: "70%", xs: "100%" },
              pt: "7rem"
            }}
          >
            <img src={Group} alt="Group image" style={{ minWidth: "200px", width: "50%", maxWidth: "100%" }} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MyComponent;
