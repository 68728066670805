const JeeAiletEligibilityDataJson = {
    eligbilitycriteria: ["Only those candidates who have passed the Class 12/equivalent examination in 2020, 2021, or those who are appearing in Class 12/equivalent examination in 2022, are eligible to appear in JEE (Main) - 2022 (Note: The eligblity of passing 12/ equivalents examinations with 75% is only required at the time of taking admissions in the institutes)"],

        qualifyingexam: ["The final examination of the 10+2 system, conducted by any recognized Central/ State Board",
            "Intermediate or two-year Pre-University examination conducted by a recognized Board/University",
            "Final examination of the two-year course of the Joint Services Wing of the National Defence Academy",
            "Senior Secondary School Examination conducted by the National Institute of Open Schooling with a minimum of five subjects",
            "Any Public School/ Board/ University examination in India or any foreign country is recognized as equivalent to the 10+2 system by the Association of Indian Universities (AIU)",
            
            "Higher Secondary Certificate Vocational Examination",
            
            "A Diploma recognized by AICTE or a State board of technical education of at least 3 years duration General Certificate Education (GCE) examination (London/Cambridge/Sri Lanka) at the Advanced (A) level",

            "High School Certificate Examination of the Cambridge University or International Baccalaureate Diploma of the International Baccalaureate Office, Geneva",

            "Candidates who have completed the Class 12 (or equivalent) examination outside India or from a Board not specified above should produce a certificate from the Association of Indian Universities (AIU) to the effect that the examination they have passed is equivalent to the Class 12 Examination",
            "In case the Class 12 Examination is not a public examination, the candidate must have passed at least one public (Board or Pre-University) examination earlier"],

        agelimit: ["No age limit for JEE Mains, However there can be age criterias by the institutes which the candidate needs to fulfill for getting admissions"],

        totalnumberofattempts: ["Attempts for 3 consecutive years are allowed"],
        
        subjectcombination: ["Mathematics",
            "Physics",
            "Chemistry/Biology/Biotechnology/Technical", "Vocational Subject",
            "Any other subject"],

        reservationofseats: [
            "Other Backward Classes who belong to Non-Creamy Layer (OBC-NCL): 27%",
            "Scheduled Caste (SC): 15%",
            "Scheduled Tribe (ST): 7.5%",
            "Persons with Disability (PwD) with 40% or more disability: 5%",
            "Economically Weaker Section (GEN- EWS): 10%",
    ],

};
export default JeeAiletEligibilityDataJson;
