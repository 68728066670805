const NeetDateDataJson = {
    notification: "March, 2024 (Tentative)",
    applicationformdate: "First week of March 2024 (Tentative)",
    lastfillform: "First week of April 2024 (Tentative)",
    appicationcorrect: "Second week of April 2024 (Tentative)",
    admitcarddate: "Fourth week of April 2024 (Tentative)",
    examdate: "May 5, 2024",
    answerkeydate: "First week of June 2024 (Tentative)",
    resultdate: "First week of June 2024 (Tentative)",

};
export default NeetDateDataJson;
