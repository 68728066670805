import React from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Container
} from "@mui/material";

const DynamicTable = ({ header, data, showheader, gapcolor, singleTableheader }) => {
    // const defaultCustomIndices = Array.from({ length: Math.ceil(header.length / 3) }, (_, i) => (i + 1) * 3);

    // const gapIndices = customIndices || defaultCustomIndices;

    

    return (
        <TableContainer component={Paper}
        sx={{width:{md:"100%",xs:"290px"}}}
        >
            <Table>
                {showheader && (
                    <TableHead>
                        <TableRow sx={{ backgroundColor: "#EF5835" }}>
                            <TableCell colSpan={2} sx={{
                                color: "#880800",
                                fontWeight: "600",
                                textAlign: "start",
                                verticalAlign: "top",
                                fontSize: "10px"
                            }} >{singleTableheader}</TableCell>

                        </TableRow>
                    </TableHead>
                )}
                <TableBody sx={{ borderCollapse: "collapse" }}>
                    {header?.map((item, index) => (
                        <React.Fragment key={item.id}>
                            <TableRow
                                sx={{
                                    backgroundColor: (
                                        Math.floor(index / 3) % 2 === 0
                                            ? (index % 3 === 0 ? "#FF876B" : (index % 3 === 1 ? "#EF5835" : "#FF876B"))
                                            : (index % 3 === 0 ? "#FF876B" : (index % 3 === 1 ? "#EF5835" : "#FF876B"))
                                    ),
                                }}
                            >
                                <TableCell
                                    sx={{
                                        borderRight: "4px solid white",
                                        width: "40%",
                                        color: "#ffffff",
                                        fontWeight: "600",
                                        textAlign: "start",
                                        verticalAlign: "top",
                                        borderBottom: "none"
                                        // fontSize: "px"
                                    }}
                                >
                                    {item.label}
                                </TableCell>
                                <TableCell
                                    sx={{
                                        width: "60%",
                                        color: "#ffffff",
                                        fontWeight: "400",
                                        padding: "10px 40px",
                                        borderBottom: "none"
                                    }}
                                >
                                    {Array.isArray(data[item.var]) ? (
                                        <ul >
                                            {data[item.var]?.map((value, index) => (
                                                <li key={index}>{value}</li>
                                            ))}
                                        </ul>
                                    ) : (
                                        data[item.var]
                                    )}
                                </TableCell>
                            </TableRow>
                            {(index + 1) % 3 === 0 && index !== header.length - 1 && (
                                <TableRow key={`gap_${index}`} sx={{ backgroundColor: gapcolor ?? "#ffffff", border: "none" }}>
                                    <TableCell colSpan={2} sx={{ borderLeft: "none" }} />
                                </TableRow>
                            )}
                            {/* Insert gap row if current index is in gapIndices */}
                            {/* {gapIndices.includes(index + 1) && index !== header.length - 1 && (
                                <TableRow key={`gap_${index}`} style={{ backgroundColor: "#ffffff", border: "none" }}>
                                    <TableCell colSpan={2} style={{ border: "none" }} />
                                </TableRow>
                            )} */}
                        </React.Fragment>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default DynamicTable;
