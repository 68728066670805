const NeetExamSyllabus = [
    {


        data: [
            { title: "Subject 1", value: "Physics", },
            {
                title: "Syllabus", value: ["Physical-world and measurement",
                    "Electrostatics",
                    "Kinematics",
                    "Current Electricity",
                    "Laws of Motion",
                    "Magnetic Effects of Current and Magnetism",
                    "Work, Energy and Power",
                    "Electromagnetic Induction and Alternating - Currents",
                    "Motion of System of Particles and Rigid Body",
                    "Electromagnetic Waves",
                    "Gravitation",
                    "- Optics",
                    "Properties of Bulk Matter",
                    "Dual Nature of Matter and Radiation",
                    "Thermodynamics",
                    "Atoms and Nuclei",
                    "Behaviour of Perfect Gas and Kinetic Theory",
                    "Electronic Devices",
                    "Oscillations and Waves"],
            },
            { title: "Subject 2", value: "Chemistry", },
            {
                title: "Syllabus", value: ["Some Basic Concepts of Chemistry",
                    "Solid State",
                    "Structure of Atom",
                    "Solutions",
                    "Classification of Elements and Periodicity in Properties",
                    "Electrochemistry",
                    "Chemical Bonding and Molecular Structure",
                    "Chemical Kinetics",
                    "States of Matter: Gases and Liquids",
                    "Surface Chemistry",
                    "Thermodynamics 6 General Principles and Processes of Isolation of - Elements Equilibrium",
                    "p- Block Elements",
                    "Redox Reactions",
                    "d and f Block Elements",
                    "Hydrogen",
                    "Coordination Compounds",
                    "s-Block Element (Alkali and Alkaline earth metals)",
                    "Haloalkanes and Haloarenes",
                    "Some p-Block Elements",
                    "Alcohols, Phenols and Ethers",
                    "Oganic Chemistry- Some Basic Principles and Techniques",
                    "Aldehydes, Ketones and Carboxylic Acids",
                    "Hydrocarbons",
                    "Organic Compounds Containing Nitrogen",
                    "Environmental Chemistry",
                    "Biomolecules",
                    "Polymers",
                    "Chemistry in Everyday Life"],
            },
            { title: "Subject 3", value: "Biology", },
            {
                title: "Syllabus", value: ["Diversity in Living World",
                    "Reproduction",
                    "Structural Organisation in Animals and Plants",
                    "Genetics and Evolution",
                    "Cell Structure and Function",
                    "Biology and Human Welfare",
                    "Plant Physiology",
                    "Biotechnology and Its Applications",
                    "Human physiology",
                    "Ecology and environment"],
            },
            { title: "Subject 1", value: "Physics", },
            {
                title: "Books", value: ["NCERT Physics Class XI & Class XII",
                    "Concepts of Physics by H. C. Verma",
                    "DC Pandey Physics for NEET",
                    "Fundamentals of Physics by Halliday, Resnick and Walker",
                    "Fundamental Physics by Pradeep",
                    "Problems in General Physics by IE Irodov"],
            },

            { title: "Subject 2", value: "Chemistry", },
            {
                title: "Books", value: ["NCERT Chemistry textbooks for Class XI and XII",
                    "Physical Chemistry by OP Tandon",
                    "ABC of Chemistry for Classes 11 and 12 by Modern",
                    "Concise Inorganic Chemistry by JD Lee",
                    "Dinesh Chemistry Guide",
                    "Practise books by VK Jaiswal (Inorganic)",
                    "MS Chauhan (Organic) and N Awasthi (Physical)"],
            },
            { title: "Subject 3", value: "Biology", },
            {
                title: "Books", value: ["NCERT Biology Class XI and Class XII textbooks",
                    "Biology Vol 1 and Vol 2 by Trueman",
                    "Objective Biology by Dinesh",
                    "Objective Botany by Ansari",
                    "Pradeep Guide on Biology",
                    "GR Bathla publications for Biology",],
            },

        ]
    },

];

export default NeetExamSyllabus;
