const JeeAiletDateTableHeader = [
    { id: 1, label: "Release of Notification", var: "notification" },
    { id: 2, label: "Release of application form", var: "applicationformdate" },
    { id: 3, label: "Last date to fill form", var: "lastfillform" },
    { id: 4, label: "Application Correction", var: "appicationcorrect" },
    { id: 5, label: "Release of Admit Card", var: "admitcarddate" },
    { id: 6, label: "Exam Date", var: "examdate" },
    { id: 7, label: "Display of Answer Key", var: "answerkeydate" },
    { id: 8, label: "Declaration of Result", var: "resultdate" },
   
];

export default JeeAiletDateTableHeader;