import React from 'react'
import Navbar from '../home/Header/Navbar';
import Hero from '../home/Hero/Hero';
import Addmition from '../home/Addmistion/Addmition ';
import Streams from '../home/Streams/Streams';

import Form from '../home/Form/Form';

import Footer from '../home/Footer/footer';
import SwiperSlider from '../../components/SwiperSlider';

const HomePage = () => {
  return (
    <>
      <Navbar />
      <Hero />
      <Addmition />
      <Streams />
      <SwiperSlider />
      <Form />
      <Footer />
    </>
  )
}

export default HomePage