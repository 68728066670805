import img1 from '../../assets/key/key1.png'
import img2 from '../../assets/key/key2.png'
import img3 from '../../assets/key/key3.png'
import img4 from '../../assets/key/key4.png'
import img5 from '../../assets/key/key5.png'
import img6 from '../../assets/key/key6.png'
const OverseasKeyData = [
    {
        Image: img1,
        heading: "Personalized Counseling",
        reasons: [
            {
                title: "Individual Assessments:",
                content: "Tailored advice based on each student's background and goals."
            },
            {
                title: "Expert Advisors:",
                content: "One-on-one counseling for selecting the right universities and programs"
            },
        ]
    },
    {
        Image: img2,
        heading: "Application Support",
        reasons: [
            {
                title: "University Selection:",
                content: "Help in choosing universities that fit the student's profile."
            },
            {
                title: "Application Guidance:",
                content: " Assistance with forms, personal statements, and document preparation."
            },

        ]
    },
    {
        Image: img3,
        heading: "Test Preparation",
        reasons: [
            {
                title: "Standardized Tests:",
                content: "Guidance for SAT, ACT, GRE, GMAT, TOEFL, and IELTS"
            },
            {
                title: "Practice Resources:",
                content: "Access to practice tests and study materials."
            },
        ]
    },
    {
        Image: img4,
        heading: "Essay and Personal Statement Editing",
        reasons: [
            {
                title: "Crafting Narratives:",
                content: "Assistance in writing impactful essays."
            },
            {
                title: "Editing and Feedback:",
                content: "Professional editing to refine application essays."
            },
        ]
    },
    {
        Image: img5,
        heading: "Interview Preparation",
        reasons: [
            {
                title: "Mock Interviews:",
                content: "Practice sessions to build confidence."
            },
            {
                title: "Feedback:",
                content: "Constructive feedback to improve interview skills."
            },
        ]
    },
    {
        Image: img6,
        heading: "Visa Assistance",
        reasons: [
            {
                title: "Application Guidance:",
                content: "Support in applying for student visas."
            },
            {
                title: "Document Preparation:",
                content: "Ensuring correct document submission."
            },
        ]
    },
    {
        Image: img1,
        heading: "Pre-Departure Support",
        reasons: [
            {
                title: "Orientation:",
                content: "Information on cultural adjustments and academic expectations."
            },
            {
                title: "Travel Arrangements:",
                content: "Assistance with flights and accommodation."
            },
        ]
    },
]
export default OverseasKeyData;