


import { Box, Typography } from "@mui/material";
import React from "react";

const StripTitle = ({ title, color }) => {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                mt: "20px",
                mb: "20px",

            }}
        >
            <Typography
                variant="h6"
                sx={{
                    color: color ?? "#ffffff",
                    fontWeight: "550",
                    fontSize: "16px",
                    position: "relative",
                    "&::before": {
                        content: "''",
                        position: "absolute",
                        left: "-10px",

                        width: "5px",
                        height: "20px",
                        backgroundColor: "#EF5835"
                    }
                }}
            >
                {title}
            </Typography>
        </Box>
    );
};

export default StripTitle;
