import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import {
  styled,
  alpha,
  Drawer,
  useMediaQuery,
  useTheme,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Link,
} from "@mui/material";
import LOGO from "../../../assets/Artboard 2 copy .png"; // Adjust the path to your image asset
import { useState } from "react";
import ServiceComponent from "../../../components/serviceComponent";
import { Icon } from "@iconify/react/dist/iconify.js";

import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
// import { Link } from "react-router-dom";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "2rem",
  height: "45px",
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(10),
    width: "auto",
  },
  border: "1px solid black",
  marginBottom: theme.spacing(2),
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  borderColor: "black",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "30ch",
    },
  },
}));

function ResponsiveAppBar() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [searchOpen, setSearchOpen] = React.useState(false);
  const [hideLogo, setHideLogo] = React.useState(false);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleSearchOpen = () => {
    setSearchOpen(true);
    if (isSmallScreen) {
      setHideLogo(true);
    }
  };

  const handleSearchClose = () => {
    setSearchOpen(false);
    if (isSmallScreen) {
      setHideLogo(false);
    }
  };

  // const [condition, setCondition] = useState(false);
  // const [defaultValueValue, setDefaultValueValue] = useState(10);

  const [serviceOpen, setServiceOpen] = React.useState(false);

  const handleServiceClick = () => {
    setServiceOpen(!serviceOpen);
  };

  const navigate = useNavigate();
  // Define the 'settings' array
  // const settings = ["Setting 1", "Setting 2", "Setting 3"];

  const [drawerOpen, setDrawerOpen] = useState(false);
  // const isSmScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const [open, setOpen] = useState(false);
  const [examOpen, setExamOpen] = useState(false);
  const [collegeOpen, setCollegeOpen] = useState(false);
  const [admissionsOpen, setAdmissionsOpen] = useState(false);
  const [counsellingOpen, setCounsellingOpen] = useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleExamToggle = () => {
    setExamOpen(!examOpen);
  };
  const handleCollegeToggle = () => {
    setCollegeOpen(!collegeOpen);
  };
  const handleAdmissionsToggle = () => {
    setAdmissionsOpen(!admissionsOpen);
  };
  const handleCounsellingToggle = () => {
    setCounsellingOpen(!counsellingOpen);
  };

  return (
    <AppBar position="static" sx={{ background: "#fff", width: "100%" }}>
      <Box
        // maxWidth="xxl"
        position="relative"
        sx={{ background: "#fff", width: "100%" }}
      >
        {/* <Toolbar
          disableGutters
          sx={{
            display: "flex",
            justifyContent: "flexEnd",
            alignItems: "center",
            gap: { xl: "1px", lg: "4px", md: "0rem", sm: "1%", xs: "5px" },
            paddingLeft:{xl:"10rem",lg:"10rem"}
          }}
        >
          <Box sx={{ }} onClick={() => navigate("/")}>
          <img
              src={LOGO}
              alt="logo img"
              width="100%"
              style={{ display: hideLogo ? "none" : "block" }}
            />

          </Box>

          <Box */}
        <Container>
          <Toolbar
            disableGutters
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: { xl: "1px", lg: "4px", md: "0rem", sm: "1%", xs: "5px" },
            }}
          >
            <Box sx={{ cursor: "pointer", width: "300px" }} onClick={() => navigate("/")}>
              <img
                src={LOGO}
                alt="logo img"
                width="100%"
                style={{ display: hideLogo ? "none" : "block" }}
              />
            </Box>

            <Box
              sx={{
                justifyContent: "center",

                display: {
                  xl: "screen",
                  lg: "screen",
                  md: "screen",
                  sm: "none",
                  xs: "none",
                },
                mx: 2,
                alignItems: "center",
                fontSize: "1px",
                paddingLeft: { xl: "10rem", lg: "5rem" },
                gap: {
                  xl: "50px",
                  lg: "10px",
                  md: "0rem",
                  sm: "1%",
                  xs: "5px",
                },
              }}
            >
              <Box>
                <Button
                  sx={{
                    color: "grey",

                    textAlign: "center",
                    textTransform: "none",
                  }}
                >
                  <Link href="/overseas-study" sx={{ color: "grey", textDecoration: "none" }}>
                    Overseas Study
                  </Link>
                </Button>
              </Box>
              <Box>
                <Button
                  sx={{
                    color: "grey",

                    textAlign: "center",
                    textTransform: "none",
                  }}
                >
                  <Link href="/getLocal" sx={{ color: "grey", textDecoration: "none" }}>
                    Get Local Guardian
                  </Link>
                </Button>
              </Box>

              <Box>
                <Button
                  sx={{
                    color: serviceOpen ? "#EF5835" : "grey",

                    textAlign: "center",
                    marginLeft: { xl: "0%", lg: "0%", md: "0%", sm: "-25%" },
                    textTransform: "none",
                  }}
                  onClick={handleServiceClick}
                >
                  {"Services"}
                  {serviceOpen ? (
                    <Icon icon="ph:caret-up-light" /> // Up arrow icon
                  ) : (
                    <Icon icon="ph:caret-down-light" /> // Down arrow icon
                  )}
                </Button>
              </Box>

              <Box
                id="service-box"
                sx={{
                  display: serviceOpen ? "block" : "none",
                  position: "absolute",
                  top: "100%",
                  left: 0,
                  zIndex: 6,
                  width: "100%",
                  backgroundColor: "#fff",
                  boxShadow: serviceOpen
                    ? "inset 0px -6px 22px 0px rgba(0,0,0,0.1)" // Apply shadow only to the bottom when service box is open
                    : "",
                  // borderTop: "1px solid #EF5835",
                  // borderBottom: "1px solid #EF5835",
                  borderRadius: "4px",
                  padding: "8px",
                  overflowX: "hidden",
                }}
              >
                <ServiceComponent />
              </Box>

              <Box>
                {/* <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid gray",
                    borderRadius: 25,
                    width: "100%",
                    p: "5px",
                  }}
                >
                  <IconButton
                    style={{ padding: 4 }}
                    aria-label="search"
                    onClick={handleSearchOpen}
                  >
                    <Icon
                      icon="material-symbols:search"
                      fontSize={24}
                      color="#d9d9d9"
                    />
                  </IconButton>
                  <InputBase
                    style={{
                      marginLeft: 8,
                      flex: 1,
                      color: "black",
                      minWidth: 300,
                    }}
                    placeholder="Search Top Colleges and Courses"
                    inputProps={{ "aria-label": "search" }}
                  />
                </Box> */}
              </Box>
            </Box>

            <Box
              sx={{ display: { xs: "block", md: "none", sm: "block" }, gap: 2 }}
            >
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                {/* {searchOpen ? (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      border: "1px solid gray",
                      borderRadius: 25,
                      width: "100%",
                      p: "5px",
                    }}
                  >
                    <IconButton
                      style={{ padding: 4 }}
                      aria-label="search"
                      onClick={handleSearchOpen}
                    >
                      <Icon
                        icon="material-symbols:search"
                        fontSize={24}
                        color="#d9d9d9"
                      />
                    </IconButton>
                    <InputBase
                      style={{ marginLeft: 8, flex: 1, color: "black" }}
                      placeholder="Search Top Colleges and Courses"
                      inputProps={{ "aria-label": "search" }}
                    />
                  </Box>
                ) : (
                  <IconButton onClick={handleSearchOpen}>
                    <SearchIcon sx={{ color: "grey" }} />
                  </IconButton>
                )} */}
                <IconButton
                  size="large"
                  aria-label="open navigation menu"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  // onClick={handleOpenNavMenu}
                  onClick={handleDrawerOpen}
                  color="black"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                >
                  <Drawer
                    anchor="right"
                    open={drawerOpen}
                    onClose={handleDrawerClose}
                    ModalProps={{ keepMounted: true }}
                  >
                    <Box
                      sx={{
                        gap: "2rem",
                        flexGrow: 1,
                        display: { xl: "none", lg: "none", md: "none" },
                        width: 250,
                      }}
                    >
                      <Typography
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        <List>
                          <ListItem
                            button
                            component={Link}
                            href="/overseas-study"
                            style={{ fontWeight: 600, color: "#000000" }}
                          >
                            <ListItemText primary="Overseas Study" />
                          </ListItem>
                          <ListItem
                            button
                            component={Link}
                            href="/getLocal"
                            style={{ fontWeight: 600, color: "#000000" }}
                          >
                            <ListItemText primary="Get Local Guardian" />
                          </ListItem>
                          <ListItem
                            button
                            onClick={handleToggle}
                            style={{
                              fontWeight: 600,
                              color: open ? "#ef5835" : "#000000",
                            }}
                          >
                            <ListItemText primary="Services" />
                            {open ? <ExpandLess /> : <ExpandMore />}
                          </ListItem>
                          <Collapse in={open} timeout={300} unmountOnExit>
                            {" "}
                            {/* Fix timeout value */}
                            <List component="div" disablePadding>
                              <ListItem button onClick={handleExamToggle}>
                                <ListItemText primary="Exams" />
                                {examOpen ? <ExpandLess /> : <ExpandMore />}
                              </ListItem>
                              <Collapse
                                in={examOpen}
                                timeout={300}
                                unmountOnExit
                              >
                                {" "}
                                {/* Fix timeout value */}
                                <List component="div" disablePadding>
                                  <ListItem
                                    button
                                    component={Link}
                                    href="/examlist"
                                  >
                                    <ListItemText primary="List all Exams"></ListItemText>
                                  </ListItem>
                                  <ListItem button>
                                    <ListItemText primary="Apply Online" />
                                  </ListItem>
                                  {/* Add more exams as needed */}
                                </List>
                              </Collapse>
                              <ListItem button onClick={handleCollegeToggle}>
                                <ListItemText primary="College and Streams" />
                                {collegeOpen ? <ExpandLess /> : <ExpandMore />}
                              </ListItem>
                              <Collapse
                                in={collegeOpen}
                                timeout={300}
                                unmountOnExit
                              >
                                {" "}
                                {/* Fix timeout value */}
                                <List component="div" disablePadding>
                                  <ListItem
                                    button
                                    component={Link}
                                    href="/college-list"
                                  >
                                    <ListItemText primary="List all Colleges" />
                                  </ListItem>
                                  <ListItem button>
                                    <ListItemText primary="Apply Online" />
                                  </ListItem>
                                  <ListItem button>
                                    <ListItemText primary="List all Streams" />
                                  </ListItem>
                                  {/* Add more exams as needed */}
                                </List>
                              </Collapse>
                              <ListItem button onClick={handleAdmissionsToggle}>
                                <ListItemText primary="Admissions" />
                                {admissionsOpen ? (
                                  <ExpandLess />
                                ) : (
                                  <ExpandMore />
                                )}
                              </ListItem>
                              <Collapse
                                in={admissionsOpen}
                                timeout={300}
                                unmountOnExit
                              >
                                {" "}
                                {/* Fix timeout value */}
                                <List component="div" disablePadding>
                                  <ListItem
                                    button
                                    component={Link}
                                    href="/applyOnline"
                                  >
                                    <ListItemText primary="Admission to Colleges" />
                                  </ListItem>

                                  {/* Add more exams as needed */}
                                </List>
                              </Collapse>
                              <ListItem
                                button
                                onClick={handleCounsellingToggle}
                              >
                                <ListItemText primary="Counselling" />
                                {counsellingOpen ? (
                                  <ExpandLess />
                                ) : (
                                  <ExpandMore />
                                )}
                              </ListItem>
                              <Collapse
                                in={counsellingOpen}
                                timeout={300}
                                unmountOnExit
                              >
                                {" "}
                                {/* Fix timeout value */}
                                <List component="div" disablePadding>

                                  <ListItem
                                    button
                                    component={Link}
                                    href="/career"
                                  >
                                    <ListItemText primary="Career Counselling" />
                                  </ListItem>

                                  {/* Add more exams as needed */}
                                </List>
                              </Collapse>
                            </List>
                          </Collapse>
                        </List>
                      </Typography>
                    </Box>
                  </Drawer>
                </Menu>
              </Box>
            </Box>
          </Toolbar>
        </Container>
      </Box>
    </AppBar>
  );
}

export default ResponsiveAppBar;
