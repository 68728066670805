import React from "react";
import { Box, Button, Typography, Grid, IconButton } from "@mui/material";
import { Input, inputClasses } from "@mui/base/Input";
import { styled } from "@mui/system";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import emailjs from "emailjs-com";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import 'react-toastify/dist/ReactToastify.css';

const StyledInput = styled(Input)(
    ({ theme }) => `
    .${inputClasses.input} {
      width: 100%;
      height: 50px;
      font-family: 'IBM Plex Sans', sans-serif;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.5;
      padding: 8px 12px;
      border-radius: 50px;
      color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
      background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
      border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]
        };
      box-shadow: 0px 2px 2px ${theme.palette.mode === "dark" ? grey[900] : grey[50]
        };

      &:hover {
        border-color: ${blue[400]};
      }

      &:focus {
        outline: 0;
        border-color: ${blue[400]};
        box-shadow: 0 0 0 3px ${theme.palette.mode === "dark" ? blue[600] : blue[200]
        };
      }
    }
  `
);

const blue = {
    100: "#DAECFF",
    200: "#b6daff",
    400: "#3399FF",
    500: "#007FFF",
    600: "#0072E5",
    900: "#003A75",
};

const grey = {
    50: "#F3F6F9",
    100: "#E5EAF2",
    200: "#DAE2ED",
    300: "#C7D0DD",
    400: "#B0B8C4",
    500: "#9DA8B7",
    600: "#6B7A90",
    700: "#434D5B",
    800: "#303740",
    900: "#1C2025",
};

const validationSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    email: yup.string().email("Invalid email format").required("Email is required"),
    phoneNumber: yup
        .string()
        .matches(/^[0-9]+$/, "Phone number must be only digits")
        .required("Phone number is required"),
    help: yup.string().required("This field is required"),
});

const AskYourExpertForm = ({ onClose }) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(validationSchema),
    });

    const onSubmit = (data) => {
        emailjs
            .send(
                "service_7of6qrh",
                "template_m18c8bb",
                {
                    name: data.name,
                    email: data.email,
                    phoneNumber: data.phoneNumber,
                    help: data.help,
                },
                "AmoRn02wurxHiUQqO"
            )
            .then(
                (response) => {
                    console.log("SUCCESS!", response.status, response.text);
                    toast.success("Email submitted successfully!");
                    //   reset(); // Clear the form
                    //   if (onClose) {
                    //     onClose(); // Close the dialog
                    //   }
                },
                (error) => {
                    console.log("FAILED...", error);
                    toast.error("Email submission failed!");
                }
            );
    };

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
            }}
        >
            <IconButton
                onClick={onClose}
                sx={{
                    position: "absolute",
                    top: 8,
                    right: 8,
                    zIndex: 1,
                }}
            >
                <CloseIcon />
            </IconButton>
            <Box
                sx={{
                    border: "1px solid white",
                    background: "#fff",
                    margin: "0 auto",
                    boxShadow: "-4.8px -4px 0px 1px rgb(255, 57, 69)",
                    borderRadius: "2vh",
                    padding: { xl: "5rem", lg: "5rem", md: "5rem", sm: "5rem", xs: "3rem" },
                    width: "100%",
                    maxWidth: { xl: "100%", lg: "100%", md: "100%", xs: "100%" },
                }}
            >
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    style={{ width: "100%", textAlign: "center" }}
                    noValidate
                >
                    <Box sx={{ color: "#ff763B", marginTop: "-3em" }}>
                        <Typography
                            className="box1"
                            component="h1"
                            sx={{
                                width: "100%",
                                maxWidth: {
                                    xl: "100%",
                                    lg: "100%",
                                    md: "100%",
                                    sm: "100%",
                                    xs: "350px",
                                },
                                paddingTop: { sm: "none", xs: "36px" },
                                fontSize: { sm: "2.6rem", xs: "1.5rem" }
                            }}
                        >
                            <span style={{ fontWeight: "700" }}>GET IN TOUCH </span>
                            <Typography className="box1" sx={{
                                width: "100%",
                                maxWidth: {
                                    xl: "100%",
                                    lg: "100%",
                                    md: "100%",
                                    sm: "100%",
                                    xs: "350px",
                                },
                                fontSize: { sm: "1.5rem", xs: "1rem" }
                            }}>with our Experts</Typography>
                        </Typography>
                    </Box>
                    <Box mt={3}>
                        <Typography
                            variant="body1"
                            component="label"
                            htmlFor="name"
                            sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                                paddingLeft: { xl: "1.5rem", lg: "1.5rem", xs: "1.5rem" },
                            }}
                        >
                            Name
                        </Typography>
                        <StyledInput
                            {...register("name")}
                            id="name"
                            placeholder="Enter Your Name"
                        />
                        {errors.name && <Typography color="error">{errors.name.message}</Typography>}
                    </Box>
                    <Box mt={3}>
                        <Typography
                            variant="body1"
                            component="label"
                            htmlFor="email"
                            sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                                paddingLeft: { xl: "1.5rem", lg: "1.5rem", xs: "1.5rem" },
                            }}
                        >
                            Email
                        </Typography>
                        <StyledInput
                            {...register("email")}
                            id="email"
                            placeholder="Enter your Email"
                        />
                        {errors.email && <Typography color="error">{errors.email.message}</Typography>}
                    </Box>
                    <Box mt={3}>
                        <Typography
                            variant="body1"
                            component="label"
                            htmlFor="phoneNumber"
                            sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                                paddingLeft: { xl: "1.5rem", lg: "1.5rem", xs: "1rem" },
                            }}
                        >
                            Phone Number
                        </Typography>
                        <StyledInput
                            {...register("phoneNumber")}
                            id="phoneNumber"
                            placeholder="Enter Your Number"
                        />
                        {errors.phoneNumber && <Typography color="error">{errors.phoneNumber.message}</Typography>}
                    </Box>
                    <Box mt={3}>
                        <Typography
                            variant="body1"
                            component="label"
                            htmlFor="help"
                            sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                                paddingLeft: { xl: "1.5rem", lg: "1.5rem", xs: "1.5rem" },
                            }}
                        >
                            What do you need help in?
                        </Typography>
                        <StyledInput
                            {...register("help")}
                            id="help"
                            placeholder="Enter your query"
                        />
                        {errors.help && <Typography color="error">{errors.help.message}</Typography>}
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
                        <Button
                            type="submit"
                            variant="output"
                            sx={{
                                border: "2px solid rgb(241, 37, 220)",
                                width: "100%",
                                borderRadius: "2rem",
                                maxWidth: { lg: "50%", md: "50%", sm: "50%x" },
                            }}
                        >
                            Submit
                        </Button>
                    </Box>
                    <Grid container justifyContent="flex-end"></Grid>
                </form>
            </Box>
            <ToastContainer position="top-right" autoClose={5000} />
        </Box>
    );
};

export default AskYourExpertForm;
