const JeeAiletApplicationDataJson = {
    applicationMode: "Online",
    stepstoApply: [
        "Application Form can now be filled in small sections.",

        "Step 1 - Fill Registration Form. After this an Application number will be generated and it will be used to complete the remaining Steps of the Application Form and will also be required for all future reference. This application number will be referred for both Sessions of JEE (Main) - 2022",

        "Step 2 - Fill Application Form",

        "Step 3 - Fee Payment"],
    documentsRequired: ["Scanned Images of Candidate’s Photograph",

        "Signature",
        
        "Category Certificate (if applicable)",
        
        "PwD Certificate (wherever applicable)"],
    HowtofillapplicationForm: ["The Candidates can log in with the system-generated Application Number and pre-created password for completing the Application Form including filling up of personal details, applying for the Paper, choosing the Examination Cities, providing the details of Educational Qualifications, and uploading the relevant documents",

    "A column/field has been added in the Online Application Form for capturing the AADHAAR number of the candidates and it will be on a voluntary basis. The candidate will be asked to verify their particulars i.e. Name, Date of Birth, and Gender, and authenticate again. If not matched, the candidate has to select the second radio button - My Information on Aadhaar is not the same as entered in the Application Form and then proceed further.",
    ],
    applicationFees: ["Male (Gen/Gen-EWS/OBC(NCL)): Rs. 650 (in india); Rs. 3000 (outside India)",
        "Female (Gen/Gen-EWS/OBC(NCL)): Rs. 325 (in india); Rs. 1500 (outside India)",
        "Male & Female (SC/ST/PwD): Rs. 325 (in india); Rs. 1500 (outside India)",
        "Third Gender : Rs. 325 (in india); Rs. 1500 (outside India)"],
    feePayment: ["The fee can be submitted only online through Net Banking, Credit Card, Debit Card, UPI, or Paytm Services. Processing charges and GST as applicable are chargeable from the candidate (in addition to the examination fee) by the concerned Bank/Payment Gateway Integrator"],

    importantPoints: [
        "Registration Form includes filling of personal details (name, father's name, mother's name, dob, gender, identity type & identification number), present address, permanent address, choosing a password for generating application and passowrd for future references",
        "After the application is generated carefully fill all the [personal details, nationality, category, if you have any disabilities, annual family income, educational qualifications, prefernce of exam test centres as no corrections are allowed",
        "Documents are required to be uploaded as per the instructions: the recent photograph should be either in colour or black & white with 80% face against a white background, Scanned photograph and signature should be in JPG/JPEG format, The size of the scanned photograph should be between 10 kb to 200 kb, The size of the scanned signature should be between 4 kb to 30 kb, The size of the scanned copy of the Category certificate (SC/ST/OBC/EWS etc.) or PwD certificate should be in pdf between 50kb to 300kb",
        "Candidates can fill up the Application Form and pay the requisite examination fee for each session separately at the time of re-opening the portal for that session. The candidate can not apply for both sessions together.",

        
    ],
    applicationformcorrection: ["Not allowed"],

};
export default JeeAiletApplicationDataJson;
