const entranceExamData = [
    {
        name: "AIIMS Paramedical",
        title: "Medical",
    },
    {
        name: "AILET 2024",
        title: "Law",
        path: "/examdetails"
    },
    {
        name: "AJEE 2023",
        title: "Joint Entrance Exam",
        path: "/jeeExamDetail"
    },
    {
        name: "AIMA UGAT",
        title: "MBA , BBA, BCA, BHM",
    },
    {
        name: "ANUPGCET",
        title: "Common Entrance",
    },
    {
        name: "BITSAT",
        title: "Engineering",
    },
    {
        name: "CEED",
        title: "Design",
    },
    {
        name: "CG PPT 2023",
        title: "Pharmacy",
    },
    {
        name: "CMI Exam 2023",
        title: "Engineering",
    },
    {
        name: "CUEE",
        title: "Management",
    },
    {
        name: "CUET 2023",
        title: "Common Entrance ",
    },
    {
        name: "CUET PG ",
        title: "Common Entrance - Post Graduate",
    },
    {
        name: "DAVUET",
        title: "Common Entrance",
    },
    {
        name: "DU LLB 2023",
        title: "Law",
    },
    {
        name: "GATA 2023",
        title: "Aptitude and Logical reasoning",
    },
    {
        name: "GATE 2023",
        title: "Aptitude in Engineering",
    },
    {
        name: "GLAET 2023",
        title: "Medical",
    },
    {
        name: "GMAT 2023",
        title: "Management ",
    },
    {
        name: "GPAT",
        title: "Pharmacy",
    },
    {
        name: "GRE",
        title: "Aptitude ",
    },
    {
        name: "Gujarat PGCET 2023",
        title: "Common Entrance",
    },
    {
        name: "GUJCET",
        title: "Common Entrance",
    },
    {
        name: "HITSEEE",
        title: "Engineering",
    },
    {
        name: "HP LEET",
        title: "Polytechnic",
    },
    {
        name: "HPCET 2023",
        title: "Common Entrance",
    },
    {
        name: "HSTES",
        title: "Common Entrance",
    },
    {
        name: "ICAR AIEEA",
        title: "Agriculture",
    },
    {
        name: "IIFT 2023",
        title: "Management",
    },
    {
        name: "IIITH UGEE 2023",
        title: "Medical",
    },
    {
        name: "IIT JAM",
        title: "Engineering",
    },
    {
        name: "IPMAT",
        title: "Management",
    },
    {
        name: "IRMASAT 2023",
        title: "Management",
    },
    {
        name: "JEE Advanced",
        title: "Engineering",
        path: "/jeeAdvanceDetail"

    },
    {
        name: "JEE Mains",
        title: "Engineering",
        path: "/jeeExamDetail"

    },
    {
        name: "JNUEE",
        title: "Engineering",
    },
    {
        name: "JNUEE 2023",
        title: "Common Entrance",
    },
    {
        name: "KEAM 2023",
        title: "Common entrance",
    },
    {
        name: "MAKAUT PGET",
        title: "Common Entrance",
    },
    {
        name: "MHT CET",
        title: "Common Entrance",
    },
    {
        name: "MUCLET",
        title: "Law",
    },
    {
        name: "NEET PG 2023",
        title: "Medical",
    },
    {
        name: "NEET UG 2023",
        title: "Medical",
        path: "/neetexamdetail"
    },
    {
        name: "NEET-MDS 2023",
        title: "Medical",
    },
    {
        name: "NID DAT 2023",
        title: "Design",
    },
    {
        name: "PU CET 2023",
        title: "Engineering",
    },
    {
        name: "RAJASTHAN JET",
        title: "Agriculture",
    },
    {
        name: "SAAT",
        title: "Medical",
    },
    {
        name: "SET 2023",
        title: "Management",
    },
    {
        name: "SLAT",
        title: "Management",
    },
    {
        name: "SMAT",
        title: "Management",
    },
    {
        name: "SUAT 2023",
        title: "Management",
    },
    {
        name: "TRIPURA JEE 2023",
        title: "Common Entrance Exams",
    },
    {
        name: "TS EAMCET 2023",
        title: "Common Entrance",
    },
    {
        name: "TS POLYCET",
        title: "Common Entrance",
    },
    {
        name: "UCEED 2023",
        title: "Design",
    },
    {
        name: "UGC NET",
        title: "Teaching and Research Positions",
    },
    {
        name: "UKSEE 2023",
        title: "Common Entrance",
    },
    {
        name: "UPCET 2023",
        title: "Common Entrance",
    },
    {
        name: "UPESEAT 2023",
        title: "Engineering",
    },
    {
        name: "UPSC Civil Services Examination",
        title: "Public Services",
    },
    {
        name: "UPSC IFS",
        title: "Public Services",
    },
    {
        name: "UPSC NDA 2023",
        title: "Public Services",
    },
    {
        name: "VITMEE 2023",
        title: "Public Services",
    },
    {
        name: "UPSC Civil Services Examination",
        title: "Public Services",
    },
    {
        name: "UPSC IFS",
        title: "Public Services",
    },
    {
        name: "UPSC NDA 2023",
        title: "Public Services",
    },
    {
        name: "VITMEE 2023",
        title: "Engineering",
    },
    {
        name: "WBJEE 2023",
        title: "Common Entrance",
    },

];

export default entranceExamData;