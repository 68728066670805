import {

    Box,
    Container,
    Grid,
    List,
    ListItem,
    ListItemText,
    Stack,

} from "@mui/material";
import React from "react";
import "../../index.css";
import Typography from "@mui/material/Typography";
import HeroImg1 from "../../assets/aboutchoose1.png";
import HeroImg2 from "../../assets/aboutchoose2.png";
import "../../careerplatform.css";

const OverseasWhyChoose = () => {
    const reasons = [
        {
            title: "Proven Success:",
            content: 'Track record of helping students gain admission to top universities.',

        },
        {
            title: "Personalized Attention:",
            content: 'Dedicated advisors committed to student success.',

        },
        {
            title: "Holistic Approach:",
            content:
                'Preparing students for a successful academic journey abroad.'

        },

    ];



    return (
        <Container >
            <Grid
                container
                display="flex"
                justifyContent="space-between"
                style={{
                    height: "100%",
                    padding: "50px 0"
                }}
            >
                <Grid item xs={12} sm={6} md={7} lg={7} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {/* <Header
            color="#00999e"
            content="Our Story"
          /> */}

                    <Box sx={{ padding: 2 }}>
                        <Typography variant="h4" className="box1" component="h2" color="#EF5835" sx={{ fontWeight: 'bold' }}>
                            Why Choose Us?
                        </Typography>
                        <List>
                            {reasons?.map((reason, index) => (
                                <ListItem key={index} sx={{ display: 'list-item', listStyleType: 'disc', paddingLeft: 0 }}>
                                    <ListItemText
                                        primary={
                                            <Typography variant="body1" className="box2">
                                                <Typography variant="body1" className="box1" component="span" sx={{ fontWeight: 'bold' }}>
                                                    {reason.title}
                                                </Typography> {reason.content}
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </Box>

                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={5}
                    lg={5}
                    sx={{
                        width: "100%",
                        margin: "auto"
                    }}
                >
                    <Stack
                        style={{
                            width: "100%",
                            height: "420px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "relative"
                        }}
                    >
                        <Stack
                            sx={{
                                width: "310px",
                                background: "#ef5835",
                                height: "270px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                margin: "auto",
                                borderRadius: "10px 0px 10px 0px",
                                "@media screen and (min-width: 1024px) and (max-width: 1024px)":
                                {
                                    width: "320px !important"
                                }
                            }}
                        >
                            <img
                                src={HeroImg1}
                                alt="heroImg1"
                                loading="lazy"
                                style={{
                                    width: "280px",

                                    // height: isSmScreen
                                    //     ? "45%"

                                    position: "absolute",
                                    top: 0,
                                    right: 0,
                                    borderRadius: "10px"
                                }}
                            />
                            <img
                                src={HeroImg2}
                                alt="heroImg2"
                                loading="lazy"
                                style={{
                                    width: "280px",
                                    position: "absolute",
                                    bottom: 0,
                                    left: 0,
                                    borderRadius: "10px"
                                }}
                            />
                        </Stack>
                    </Stack>
                </Grid>
            </Grid>
        </Container>
    );
};

export default OverseasWhyChoose;
