const AiletDateDataJson = {
    notification: "May 23, 2023",
    applicationformdate: "August 7, 2023",
    lastfillform: "November 15, 2023",
    appicationcorrect: "November 14, 2023 to November 15, 2023",
    admitcarddate: "November 24, 2023",
    examdate: "December 10, 2023",
    answerkeydate: "December 13, 2023",
    resultdate: "December 13, 2023",

};
export default AiletDateDataJson;
