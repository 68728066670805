import { Box, Container, Grid, List, ListItem, ListItemText, Typography } from '@mui/material'
import React from 'react'
import HeroImg1 from '../../assets/aboutusbenefits.png'
import '../../careerplatform.css'


const AboutUsKey = () => {
    const reasons = [
        {
            title: "Academic Support",
            content: "Tutoring, homework help, progress monitoring.",
        },
        {
            title: "Emotional Support",
            content: "Counseling, mentorship, peer groups.",
        },
        {
            title: "Parental Involvement",
            content: "Regular updates on student progress.",
        },
        {
            title: "Safety",
            content: "Ensuring student welfare, especially for boarders or international students.",
        },


    ];
    return (
        <>
            <Container sx={{ borderRadius: "20px", margin: "20px auto" }}>
                <Grid
                    container
                    display="flex"
                    justifyContent="space-between"
                    style={{
                        height: "100%",
                        padding: "50px 0"
                    }}
                >
                    <Grid item xs={12} sm={6} md={7} lg={7} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>


                        <Box sx={{ padding: 2 }}>
                            <Typography variant="h4" component="h2" className='box1' color="#EF5835" sx={{ fontWeight: 'bold' }}>
                                Key Components
                            </Typography>
                            <List >
                                {reasons?.map((reason, index) => (
                                    <ListItem key={index} className='box1' sx={{ display: 'list-item', listStyleType: 'disc', paddingLeft: 0 }}>
                                        <ListItemText
                                            primary={
                                                <Typography variant="body1" className='box2'>
                                                    <Typography variant="body1" className='box1' component="span" sx={{ fontWeight: 'bold' }}>
                                                        {reason.title}
                                                    </Typography> {reason.content}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </Box>

                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={5}
                        lg={5}
                        sx={{
                            width: "300px",
                            margin: "auto"
                        }}
                    >
                        <Box>
                            <img
                                src={HeroImg1}
                                alt="heroImg1"
                                loading="lazy"
                                style={{
                                    width: "300px",
                                    height: "300px",


                                    borderRadius: "50%"

                                }}
                            />                    </Box>
                    </Grid>
                </Grid>
            </Container>


        </>
    )
}

export default AboutUsKey
