import React from "react";
import Navbar from "../../pages/home/Header/Navbar";
import HelpCenter from "./HelpCenter/HelpCenter";
import LocalGaurdian from "./LocalGaurdian/Local";
import Progress from "./Progress/Progress";
import Form from "../../pages/home/Form/Form";
import Footer from "../../pages/home/Footer/footer";

const GetLocal = () => {
  return (
    <div>
      <Navbar />
      <LocalGaurdian />
      <Progress />
      <HelpCenter />
      <Form />
      <Footer />
    </div>
  );
};

export default GetLocal;
