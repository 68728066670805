import React from 'react';
import { Typography, List, ListItem, ListItemText, Container, Link } from '@mui/material';
import ResponsiveAppBar from './home/Header/Navbar';
import Addmition from './home/Footer/footer';

const TermsAndConditions = () => {
    return (
        <div>
            <ResponsiveAppBar />
            <Container sx={{ mb: "30px" }}>
                <Typography variant="h4" sx={{ fontFamily: 'Montserrat', fontWeight: 700, margin: "20px 0" }}>
                    Terms and Conditions
                </Typography>

                <Typography variant="body1" sx={{ fontFamily: 'Nunito' }}>
                    Welcome to www.careerplatform.co.in!
                </Typography>

                <Typography variant="body1" sx={{ fontFamily: 'Nunito' }}>
                    These terms and conditions outline the rules and regulations for the use of Career Platform's Website, located at www.careerplatform.co.in.
                </Typography>

                <Typography variant="body1" sx={{ fontFamily: 'Nunito' }}>
                    By accessing this website we assume you accept these terms and conditions. Do not continue to use www.careerplatform.co.in if you do not agree to take all of the terms and conditions stated on this page.
                </Typography>

                <Typography variant="h5" sx={{ fontFamily: 'Montserrat', fontWeight: 700, margin: "20px 0" }}>
                    Cookies
                </Typography>

                <Typography variant="body1" sx={{ fontFamily: 'Nunito' }}>
                    We employ the use of cookies. By accessing www.careerplatform.co.in, you agree to use cookies in agreement with the Career Platform's Privacy Policy.
                </Typography>

                <Typography variant="body1" sx={{ fontFamily: 'Nunito' }}>
                    Most interactive websites use cookies to let us retrieve the user's details for each visit. Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website. Some of our affiliate/advertising partners may also use cookies.
                </Typography>

                <Typography variant="h5" sx={{ fontFamily: 'Montserrat', fontWeight: 700, margin: "20px 0" }}>
                    License
                </Typography>

                <Typography variant="body1" sx={{ fontFamily: 'Nunito' }}>
                    Unless otherwise stated, Career Platform and/or its licensors own the intellectual property rights for all material on <Link href="https://www.careerplatform.co.in/" rel="external nofollow noopener" target="_blank">www.careerplatform.co.in</Link> All intellectual property rights are reserved. You may access this from www.careerplatform.co.in for your own personal use subjected to restrictions set in these terms and conditions.
                </Typography>

                <Typography variant="body1" sx={{ fontFamily: 'Nunito' }}>
                    You must not:
                </Typography>
                <List sx={{ fontFamily: 'Nunito' }}>
                    <ListItem>
                        <ListItemText
                            primary={
                                <>
                                    Republish material from{' '}
                                    <Link href="https://www.careerplatform.co.in">www.careerplatform.co.in</Link>
                                </>
                            }
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary={
                                <>
                                    Sell, rent or sub-license material from{' '}
                                    <Link href="https://www.careerplatform.co.in">www.careerplatform.co.in</Link>
                                </>
                            }
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary={
                                <>
                                    Reproduce, duplicate or copy material from{' '}
                                    <Link href="https://www.careerplatform.co.in">www.careerplatform.co.in</Link>
                                </>
                            }
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary={
                                <>
                                    Redistribute content from{' '}
                                    <Link href="https://www.careerplatform.co.in">www.careerplatform.co.in</Link>
                                </>
                            }
                        />
                    </ListItem>
                </List>

                <Typography variant="body1" sx={{ fontFamily: 'Nunito' }}>
                    This Agreement shall begin on the date hereof. Our Terms and Conditions were created with the help of the <a href="https://www.termsandconditionsgenerator.com/">Free Terms and Conditions Generator</a>.
                </Typography>
                <Typography variant="body1" sx={{ fontFamily: 'Nunito' }}>
                    Parts of this website offer an opportunity for users to post and exchange opinions and information in certain areas of the website. Career Platform does not filter, edit, publish, or review Comments prior to their presence on the website. Comments do not reflect the views and opinions of Career Platform, its agents, and/or affiliates. Comments reflect the views and opinions of the person who posts their views and opinions. To the extent permitted by applicable laws, Career Platform shall not be liable for the Comments or for any liability, damages, or expenses caused and/or suffered as a result of any use of and/or posting of and/or appearance of the Comments on this website.
                </Typography>

                <Typography variant="body1" sx={{ fontFamily: 'Nunito' }}>
                    Career Platform reserves the right to monitor all Comments and to remove any Comments which can be considered inappropriate, offensive, or cause a breach of these Terms and Conditions.
                </Typography>

                <Typography variant="body1" sx={{ fontFamily: 'Nunito' }}>
                    You warrant and represent that:
                </Typography>

                <List sx={{ fontFamily: 'Nunito' }}>
                    <ListItem>
                        <ListItemText primary="You are entitled to post the Comments on our website and have all necessary licenses and consents to do so;" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="The Comments do not invade any intellectual property right, including without limitation copyright, patent or trademark of any third party;" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="The Comments do not contain any defamatory, libelous, offensive, indecent or otherwise unlawful material which is an invasion of privacy;" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="The Comments will not be used to solicit or promote business or custom or present commercial activities or unlawful activity." />
                    </ListItem>
                </List>

                <Typography variant="body1" sx={{ fontFamily: 'Nunito' }}>
                    You hereby grant Career Platform a non-exclusive license to use, reproduce, edit and authorize others to use, reproduce and edit any of your Comments in any and all forms, formats or media.
                </Typography>

                <Typography variant="h5" sx={{ fontFamily: 'Montserrat', fontWeight: 700, margin: "20px 0" }}>
                    Hyperlinking to our Content
                </Typography>

                <Typography variant="body1" sx={{ fontFamily: 'Nunito' }}>
                    The following organizations may link to our Website without prior written approval:
                </Typography>

                <List sx={{ fontFamily: 'Nunito' }}>
                    <ListItem>
                        <ListItemText primary="Government agencies;" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Search engines;" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="News organizations;" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses; and" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="System wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Web site." />
                    </ListItem>
                </List>

                <Typography variant="body1" sx={{ fontFamily: 'Nunito' }}>
                    These organizations may link to our home page, to publications or to other Website information so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products and/or services; and (c) fits within the context of the linking party's site.
                </Typography>

                <Typography variant="body1" sx={{ fontFamily: 'Nunito' }}>
                    We may consider and approve other link requests from the following types of organizations:
                </Typography>

                <List sx={{ fontFamily: 'Nunito' }}>
                    <ListItem>
                        <ListItemText primary="Commonly-known consumer and/or business information sources;" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Dot.com community sites;" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Associations or other groups representing charities;" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Online directory distributors;" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Internet portals;" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Accounting, law and consulting firms; and" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Educational institutions and trade associations." />
                    </ListItem>
                </List>

                <Typography variant="body1" sx={{ fontFamily: 'Nunito' }}>
                    We will approve link requests from these organizations if we decide that: (a) the link would not make us look unfavorably to ourselves or to our accredited businesses; (b) the organization does not have any negative records with us; (c) the benefit to us from the visibility of the hyperlink compensates the absence of Career Platform; and (d) the link is in the context of general resource information.
                </Typography>

                <Typography variant="body1" sx={{ fontFamily: 'Nunito' }}>
                    If you are one of the organizations listed in paragraph 2 above and are interested in linking to our website, you must inform us by sending an e-mail to Career Platform. Please include your name, your organization name, contact information as well as the URL of your site, a list of any URLs from which you intend to link to our Website, and a list of the URLs on our site to which you would like to link. Wait 2-3 weeks for a response.
                </Typography>

                <Typography variant="body1" sx={{ fontFamily: 'Nunito' }}>
                    Approved organizations may hyperlink to our Website as follows:
                </Typography>

                <List sx={{ fontFamily: 'Nunito' }}>
                    <ListItem>
                        <ListItemText primary="By use of our corporate name;" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="By use of the uniform resource locator being linked to; or" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="By use of any other description of our Website being linked to that makes sense within the context and format of content on the linking party's site." />
                    </ListItem>
                </List>

                <Typography variant="body1" sx={{ fontFamily: 'Nunito' }}>
                    No use of Career Platform's logo or other artwork will be allowed for linking absent a trademark license agreement.
                </Typography>

                <Typography variant="h5" sx={{ fontFamily: 'Montserrat', fontWeight: 700, margin: "20px 0" }}>
                    iFrames
                </Typography>

                <Typography variant="body1" sx={{ fontFamily: 'Nunito' }}>
                    Without prior approval and written permission, you may not create frames around our Webpages that alter in any way the visual presentation or appearance of our Website.
                </Typography>

                <Typography variant="h5" sx={{ fontFamily: 'Montserrat', fontWeight: 700, margin: "20px 0" }}>
                    Content Liability
                </Typography>

                <Typography variant="body1" sx={{ fontFamily: 'Nunito' }}>
                    We shall not be held responsible for any content that appears on your Website. You agree to protect and defend us against all claims that are rising on your Website. No link(s) should appear on any Website that may be interpreted as libelous, obscene, or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of any third-party rights.
                </Typography>

                <Typography variant="h5" sx={{ fontFamily: 'Montserrat', fontWeight: 700, margin: "20px 0" }}>
                    Reservation of Rights
                </Typography>

                <Typography variant="body1" sx={{ fontFamily: 'Nunito' }}>
                    We reserve the right to request that you remove all links or any particular link to our Website. You agree to immediately remove all links to our Website upon request. We also reserve the right to amend these terms and conditions and its linking policy at any time. By continuously linking to our Website, you agree to be bound to and follow these linking terms and conditions.
                </Typography>

                <Typography variant="h5" sx={{ fontFamily: 'Montserrat', fontWeight: 700, margin: "20px 0" }}>
                    Removal of links from our website
                </Typography>

                <Typography variant="body1" sx={{ fontFamily: 'Nunito' }}>
                    If you find any link on our Website that is offensive for any reason, you are free to contact and inform us at any moment. We will consider requests to remove links but we are not obligated to do so or to respond to you directly.
                </Typography>

                <Typography variant="body1" sx={{ fontFamily: 'Nunito' }}>
                    We do not ensure that the information on this website is correct; we do not warrant its completeness or accuracy, nor do we promise to ensure that the website remains available or that the material on the website is kept up to date.
                </Typography>

                <Typography variant="h5" sx={{ fontFamily: 'Montserrat', fontWeight: 700, margin: "20px 0" }}>
                    Disclaimer
                </Typography>

                <Typography variant="body1" sx={{ fontFamily: 'Nunito' }}>
                    To the maximum extent permitted by applicable law, we exclude all representations, warranties, and conditions relating to our website and the use of this website. Nothing in this disclaimer will:
                </Typography>

                <List sx={{ fontFamily: 'Nunito' }}>
                    <ListItem>
                        <ListItemText primary="limit or exclude our or your liability for death or personal injury;" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="limit or exclude our or your liability for fraud or fraudulent misrepresentation;" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="limit any of our or your liabilities in any way that is not permitted under applicable law; or" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="exclude any of our or your liabilities that may not be excluded under applicable law." />
                    </ListItem>
                </List>
                <Typography variant="body1" sx={{ fontFamily: 'Nunito' }}>
                    The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort and for breach of statutory duty.
                </Typography>

                <Typography variant="body1" sx={{ fontFamily: 'Nunito' }}>
                    As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.
                </Typography>

            </Container>

            <Addmition margintop={"50px"} paddingtop={"-30px"} />

        </div>
    );
}

export default TermsAndConditions;
