import "./App.css";

// import { RouterProvider } from "react-router-dom";
// import appRouter from "./routes/appRouter";
// import Local from "./components/LocalGaurdian/Local";
// import Progress from "./components/LocalGaurdian/Progress";
// import HelpCenter from "./components/LocalGaurdian/HelpCenter";
// import Registration from "./components/Registration/Register";
import { RouterProvider } from "react-router-dom";
import appRouter from "./routes/appRouter";
import './careerplatform.css';

function App() {
  return (
    <div className="App">
      <RouterProvider router={appRouter} />
      </div>
    
     
    
    
  );
}

export default App;
