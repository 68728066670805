const IITDelhiPlacements = [
    {

        data: [
            { title: "Domestic placements (2021-22)", value: "603" },
            { title: "Foreign placements (2021-22)", value: "18" },
            { title: "Pre-placement offers (2021-22)", value: "153" },
            { title: "Number of Companies offered jobs (2021-22)", value: "134" }
        ]
    },
    {

        data: [
            { title: "Highest salary (INR)", value: "1,68,00,000" },
            { title: "Average salary (INR)", value: "27,28,535" },
            { title: "Number of students completed their B.Tech/ BE within stipulated timeline (2021-22)", value: "787" },
            { title: "Number of Companies offered jobs (2021-22)", value: "88.6%" }
        ]
    },
    {

        data: [
            { title: "Number of students that opted for entrepreneurship (2021-22)", value: "11" },
            { title: "Number of students that opted for higher studies (postgraduate) (2021-22)", value: "64" },
            { title: "Duration of placement season (days) (2021-22)", value: "180" }
        ]
    },

];

export default IITDelhiPlacements;
