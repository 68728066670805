import React from 'react'
import Addmition from '../home/Footer/footer'
import { Box } from '@mui/material'
import Navbar from "../home/Header/Navbar";
import AiimsTabDetails from './CollegeDetails/AiimsTabdetails';
import AiimsBanner from './CollegeDetails/AiimsBanner';


const AiimsDetails = () => {
    return (
        <div>
            <Navbar />
            <AiimsBanner />
            <Box sx={{ backgroundColor: "#252525" }}>
                <AiimsTabDetails />
                <Addmition margintop={"40px"} paddingtop={"-40px"} />
            </Box>

        </div>
    )
}

export default AiimsDetails
