const JeeAdvanceEligibilityDataJson = {
    eligbilitycriteria: [
        "Only those candidates who have passed the Class 12/equivalent examination in 2020, 2021, or those who are appearing in Class 12/equivalent examination in 2022, are eligible to appear in JEE (Advanced) - 2022",
        "The candidates should have qualified the JEE Main examination and must be in the top 2,50,000 candidates in order of merit. The cutoff for admissions into IITs should also be met by the candidates."
    ],

    qualifyingexam: [
        "The final examination of the 10+2 system, conducted by any recognized Central/ State Board",
"Intermediate or two-year Pre-University examination conducted by a recognized Board/ University",
"Final examination of the two-year course of the Joint Services Wing of the National Defence Academy",
"Senior Secondary School Examination conducted by the National Institute of Open Schooling with a minimum of five subjects",
"Any Public School/ Board/ University examination in India or any foreign country is recognized as equivalent to the 10+2 system by the Association of Indian Universities (AIU)",
"Higher Secondary Certificate Vocational Examination",
"A Diploma recognized by AICTE or a State board of technical education of at least 3 years duration",
"General Certificate Education (GCE) examination (London/Cambridge/Sri Lanka) at the Advanced (A) level.",
"High School Certificate Examination of the Cambridge University or International Baccalaureate Diploma of the International Baccalaureate Office, Geneva.",
"Candidates who have completed the Class 12 (or equivalent) examination outside India or from a Board not specified above should produce a certificate from the Association of Indian Universities (AIU) to the effect that the examination they have passed is equivalent to the Class 12 Examination",
"In case the Class 12 Examination is not a public examination, the candidate must have passed at least one public (Board or Pre-University) examination earlier"
    ],

    agelimit: ["Candidates should have been born on or after October 1, 1997"
    ],

    totalnumberofattempts: [
        "2 consecutive years"

    ],

    subjectcombination: [
        "Language, Mathematics, Physics, Chemistry/Biology/Biotechnology/Technical Vocational Subject, Any other subject"

    ],
    eligibilityRelaxation: [
        "Relaxation of 5 years in age limit for candidates belonging to SC/ST/PwD Category.",
"Candidates who appeared in class 12th (or equivalent) examination for the first time in 2020 OR 2021 AND successfully registered for JEE (Advanced) 2021 but were absent in BOTH the papers, i.e., Paper 1 and Paper 2, of JEE (Advanced) 2021, are eligible to directly appear for JEE (Advanced) 2022 and do not need to fulfil other criterias for eligbility. However, they must successfully register for the JEE (Advanced) 2022 in the online registration portal and pay the registration fee"
    ],

    reservationofseats: [
        "Other Backward Classes who belong to Non-Creamy Layer (OBC-NCL): 27%.",
        "Scheduled Caste (SC): 15%.",
        "Scheduled Tribe (ST): 7.5%.",
        "Economically Weaker Section (GEN- EWS): 10%.",
        "Open for all: 40.5%",
        "Within each category 5% horizontal reservation for PwD candidates",
    ],

};
export default JeeAdvanceEligibilityDataJson;
