import React from 'react'
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { Box, CardActionArea, Stack } from '@mui/material';
import '../../careerplatform.css'

const OverseasValueCard = ({ id, icon, title, about }) => {
    return (
        <div>
            <Stack
                sx={{
                    margin: "auto",
                }}
            >

                <Box sx={{
                    margin: "auto",
                }}>
                    <CardActionArea
                        sx={{
                            width: { xs: "100%", sm: 320, md: 270, lg: 270 },
                            height: { xs: "100%", sm: 320, md: 270, lg: 350 },
                            // transition: '#5cd2d6 0.3s ease',
                            '&:hover': {
                                backgroundColor: '#fffff',
                                borderRadius: '5px',
                                '& .MuiIconButton-root': {
                                    backgroundColor: '#fff',
                                },
                                '& .MuiTypography-root': {
                                },
                            },
                        }}
                    >
                        <Box sx={{ height: "120px" }}>

                            <IconButton sx={{
                                borderRadius: '50%',
                                height: '110px',
                                width: '110px',
                                display: "flex",
                                justifyContent: "center",
                                // alignItems: "center",
                                // margin: "auto",
                                boxShadow: '0px 0px 10px #fff',
                                margin: "20px auto auto auto",

                            }}>
                                <img
                                    src={icon}
                                    alt="heroImg1"
                                    loading="lazy"
                                    width={"100%"}
                                    style={{
                                        padding: '2px'
                                    }}
                                />

                            </IconButton>
                        </Box>

                        <CardContent sx={{ height: "150px" }}>
                            <Typography gutterBottom variant="h5" component="div"
                                className='box1'
                                sx={{
                                    color: "#fff",
                                    textAlign: "center",
                                    fontSize: "20px",
                                    fontStyle: "normal",
                                    fontWeight: 700,
                                    lineHeight: "125.259%",

                                }}>
                                {title}
                            </Typography>
                            <Typography variant="body2" color="text.secondary"
                                className='box2'

                                sx={{
                                    textAlign: "center",
                                    padding: "10px 0",
                                    color: '#fff',
                                    fontSize: '16px',

                                }}
                            >
                                {about}
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Box>
            </Stack>
        </div>
    )
}

export default OverseasValueCard