const jeeExamDetailContentJson = {
    formatName: "Joint Entrance Examination Main 1",
    shortHand: "JEE Main 1",
    testingAuthority: "National Testing Agency (NTA, since 2019)",
    examMode: "Computer based",
    frequency: "Twice a year",
    duration: "3 hours",
    sections: "6 (two sections in each of three subjects)",
    languagesAvailable: "English, Hindi, Assamese, Bengali, Gujarati, Kannada, Marathi, Malayalam, Odiya, Punjabi, Tamil, Telugu and Urdu",
    questionFormat: "Multiple choice and numerical value answer type questions",
    numberofQuestions: 90,
    examPattern:
      "There are two sections. Section A consists of 20 multiple choice questions for each subject; Section B consists of 10 numerical value questions, of which any 5 are to be attempted.",
    totalMarks: "300",
    markingScheme:
      "(+4) for correct answers, (-1) for incorrect answers",
    noofTestCitiesCentres: 501,
    coursesAvailableAfterExam: ["BE", "B.Tech", "B.Tech & M.Tech"],
    website: "https://jeemain.nta.nic.in/",
    contactDetails: [
        "Address: NSIC-MDBP Building, First Floor,Okhla Industrial Estate, New Delhi-110020",
      "Contact Number:  01169227700, 011-40759000E",  
      "E-mail:  jeemain@nta.ac.in"
    ]
  };
  export default jeeExamDetailContentJson;
  