const JeeAdvanceExamSyllabus = [
    {
        data: [
            { title: "Subject 1", value: "Physics" },
            {
                title: "Syllabus",
                value: [
                    "Physics and measurement",
                    "Vernier callipers-its use to measure the internal and external diameter and depth of a vessel",
                    "Rotational motion",
                    "Screw gauge-its use to determine thickness/ diameter of thin sheet/wire.",
                    "Thermodynamics",
                    "Simple Pendulum-dissipation of energy by plotting a graph between the square of amplitude and time.",
                    " Kinematics",
                    "Metre Scale - the mass of a given object by the principle of moments.",
                    "Work, energy, and power",
                    "Young's modulus of elasticity of the material of a metallic wire",
                    "Properties of solids and liquids",
                    "The surface tension of water by capillary rises and effect of detergents.",
                    "Gravitation",
                    "Co-efficient of Viscosity of a given viscous liquid by measuring terminal velocity of a given spherical body",
                    " Laws of motion",
                    'Plotting a cooling curve for the relationship between the temperature of a hot body and time',
                    'Oscillations and waves',
                    'Speed of sound in air at room temperature using a resonance tube',
                    'Electronic devices',
                    'Specific heat capacity of a given (i) solid and (ii) liquid by method of mixtures.',
                    'Kinetic theory of gases',
                    'The resistivity of the material of a given wire using a metre bridge',
                    'Current electricity',
                    "The resistance of a given wire using Ohm's law.",
                    'Communication systems',
                    'Potentiometer - i) Comparison of emf of two primary cells, (ii) Determination of internal resistance of a cell.',
                    'Electromagnetic induction and alternating currents',
                    'Resistance and figure of merit of a galvanometer by half deflection method',
                    'Magnetic effects of current and magnetism',
                    ' The focal length of; (i) Convex mirror (ii) Concave mirror, and (ii) Convex lens, using the parallax method',
                    ' Optics',
                    'The plot of the angle of deviation vs angle of incidence for a triangular prism',
                    'Electromagnetic waves',
                    'Refractive index of a glass slab using a travelling microscope',
                    'Atoms and nuclei',
                    'Characteristic curves of a p-n junction diode in forward and reverse bias',
                    'Electrostatics',
                    'Characteristic curves of a Zener diode and finding reverse break down voltage',
                    'Dual nature of matter and radiation',
                    'Characteristic curves of a transistor and finding current gain and voltage gain',
                    'Identification of Diode. LED, Transistor. IC. Resistor. A capacitor from a mixed collection of such items.',
                    'Using a multimeter to: (i) Identify the base of a transistor (ii) Distinguish between NPN and PNP type transistor (iii) See the unidirectional of current in case of a diode and an LED. (iv) Check the correctness or otherwise of a given electronic component (diode, transistor, or IC).'],
            },
            {
                title: "Subject 2",
                value: "Chemistry"
            },

            {
                title: "Syllabus",
                value: [
                    "Physical Chemistry:",
                    "Some basic concepts in chemistry",
                    "States of matter",
                    "Atomic structure",
                    "Chemical bonding and molecular structure",
                    "Chemical thermodynamics",
                    "Solutions",
                    "Equilibrium",
                    "Redox reactions and electrochemistry",
                    "Chemical kinetics",
                    "Surface chemistry",
                    "Inorganic Chemistry:",
                    "Classification of elements and periodicity in properties",
                    "Hydrogen",
                    "Block elements (alkali and alkaline earth metals)",
                    "P Block elements group 13 to group 18 elements",
                    "d- and f – block elements",
                    "Co-ordination compounds",
                    "Environmental chemistry",
                    "General principles and processes of isolation of metals",
                    "Organic Chemistry",
                    "Purification and characterization of organic compounds",
                    "Hydrocarbons",
                    "Chemistry in everyday life",
                    "Principles related to practical chemistry",
                    "Organic compounds containing halogens",
                    "Organic compounds containing oxygen",
                    "Organic compounds containing nitrogen",
                    "Polymers",
                    "Some basic principles of organic chemistry",
                    "Bio-molecules"
                ]
            },

            {
                title: "Subject 3",
                value: "Mathematics"
            },
            {
                title: "Syllabus",
                value: [
                    "Complex numbers and quadratic equations",
                    "Differential equations",
                    "Matrices and determinants",
                    "Binomial theorem and its simple applications",
                    "Sets, relations and functions",
                    "Sequence and Series",
                    "Mathematical induction",
                    "Vector algebra",
                    "Permutations and combinations",
                    "Statistics and probability",
                    "Mathematical reasoning",
                    "Trigonometry",
                    "Limit, continuity, and differentiability",
                    "Coordinate geometry",
                    "Integral calculus",
                    "Three-dimensional geometry"
                ]
            },
            { title: "Subject 1", value: "Physics" },
            {
                title: "Books",
                value: [
                    "Concepts of Physics Vol I and II by H.C. Verma",
                    "IIT Physics by D.C. Pandey",
                    "Problems in General Physics by I.E. Irodov",
                    "Understanding Physics by DC Pandey",
                    "Fundamentals of Physics by Halliday, Resnick & Walker",
                    "Problems in Physics by S.S. Krotov",
                    "Understanding Physics by Freedman and Young"
                ]
            },
            { title: "Subject 1", value: "Chemistry" },
            {
                title: "Books",
                value: [
                    "Organic Chemistry by O. P. Tandon",
                    "Physical Chemistry by O. P. Tandon",
                    "Concise Inorganic Chemistry by J. D. Lee",
                    "Organic Chemistry by Peter Sykes",
                    "Physical Chemistry by P.W. Atkins",
                    "Organic Chemistry by M. S. Chauhan",
                    "Organic Chemistry by Morrison and Boyd",
                    "Modern Approach to Chemical Calculations by R. C. Mukherjee"
                ]
            },

            { title: "Subject 2", value: "Mathematics" },
            {
                title: "Books",
                value: [
                    "Maths XI & XII by R.D. Sharma",
                    "Advanced Problems in Mathematics for JEE (Main & Advanced) by Vikas Gupta & Pankaj Joshi",
                    "Objective Mathematics for JEE Main & Advanced and Other Engineering Entrance Examinations by R.D. Sharma",
                    "Algebra- Higher Algebra by Hall and Knight",
                    "Coordinate Geometry - Plane Coordinate Geometry by S.L. Loney",
                    "Trigonometry & Geometry - Plane Trigonometry Part 1 by S.L. Loney",
                    "Calculus - Problems in Calculus of One Variable by I.A. Maron",
                    "Differential Calculus by A. Das Gupta",
                    "Course in Mathematics for IIT-JEE by Tata McGraw-Hill publications"
                ]
            },
        ],
    },
];

export default JeeAdvanceExamSyllabus;
