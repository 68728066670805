import { Box, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import image from '../../assets/bannerAbout.png'
import '../../careerplatform.css'


const AboutUsBanner = () => {
    return (
        <>
            <Box sx={{ bgcolor: "#ef5835", height: "auto", pt: "20px" }} >
                <Container>
                    <Grid container spacing={2}>
                        <Grid item md={6} xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Box>
                                <Typography className="box1" sx={{ color: "#ffffff", fontFamily: "montserrat", fontWeight: "600", fontSize: "24px" }}>
                                    Welcome to Career Platform


                                </Typography>
                                <Typography className="box2" sx={{ color: "#ffffff", fontFamily: "Nunito", fontWeight: "500", fontSize: "16px", pt: "30px" }}>
                                    At Career Platform, we are dedicated to shaping the future of education by providing comprehensive and personalized consultancy services. With a team of experienced counselors, and industry experts, we strive to empower students, parents, and educational institutions to navigate the complexities of the academic landscape with confidence and clarity.
                                </Typography>
                                <Typography className="box2" sx={{ color: "#ffffff", fontFamily: "Nunito", fontWeight: "500", fontSize: "16px", pt: "30px" }}>
                                    Our journey began with a simple yet profound mission: to bridge the gap between aspirations and achievements. We understand that each student is unique, with distinct passions, strengths, and aspirations. Therefore, we adopt a holistic approach, tailoring our services to meet the individual needs and goals of every client we serve.

                                </Typography>
                                <Typography className="box2" sx={{ color: "#ffffff", fontFamily: "Nunito", fontWeight: "500", fontSize: "16px", pt: "30px" }}>
                                    What sets us apart is our unwavering commitment to excellence and integrity. We believe in transparency, honesty, and ethical practices in all aspects of our work. Whether it's guiding students through the college admissions process, assisting educators in curriculum development, or supporting institutions in strategic planning, we prioritize the long-term success and well-being of our clients above all else.

                                </Typography>
                            </Box>

                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Box sx={{ width: { lg: "600px", md: "500px", xsx: "300px" } }}>

                                <img src={image} alt="aboutus_image" width={"100%"} />
                            </Box>
                        </Grid>
                    </Grid>
                </Container>

            </Box>

        </>
    )
}

export default AboutUsBanner
