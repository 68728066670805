import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import Illustration from "../../../assets/Illustration 1.png";

const Local = () => {
  return (
    <Grid container>
      <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            padding: "2rem",
            gap: "1rem",
          }}
        >
          <Box sx={{ fontFamily: "Nonito" }}>
            <Typography
            className="box1"
              sx={{
                fontSize: { xl: "65px", lg: "55px", xs: "45px", md: "50px" },
                fontWeight: "600",
                color: "#EF5835",
                marginTop: { xl: "10rem" },
              }}
            >
              Local{" "}
              <span style={{ color: "#000" }}>
                {" "}
                Guardian <br />
                Services{" "}
              </span>
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
        <Box
          sx={{
            display: "flex",
            justifyContent: {
              xl: "flex-start",
              lg: "flex-start",
              md: "flex-start",
              sm: "center",
              xs: "center",
            },
            alignItems: {
              xl: "center",
              lg: "center",
              md: "center",
              sm: "center",
              xs: "center",
            },
            mt: { md: "-2rem" }
          }}
        >
          <img src={Illustration} alt="" width="100%" />
        </Box>
      </Grid>
    </Grid>
  );
};

export default Local;
