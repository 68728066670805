import React, { useState, useEffect } from "react";
import { Box, Typography, InputBase, IconButton, Dialog, DialogContent, Button } from "@mui/material";
import { Icon } from "@iconify/react";
import AskYourExpertForm from "../pages/home/Form/AskYourExpert";

const Search = ({ placeholder, title, value, onChange, onReset }) => {
  const [open, setOpen] = useState(false);
  const [showReset, setShowReset] = useState(false);

  useEffect(() => {
    setShowReset(value.length > 0);
  }, [value]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box
      sx={{
        bgcolor: "#EF5835",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: { md: 400, sm: 300, xs: 300 },
        flexDirection: "column",
      }}
    >
      <Typography
        sx={{
          color: "white",
          mb: 2,
          fontSize: { md: "28px", xs: "20px" },
          fontFamily: "Montserrat",
          fontWeight: "bold",
        }}
      >
        {title}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "40%",
          minWidth: "250px",
          maxWidth: "500px",
        }}
      >
        <Box sx={{ minWidth: "240px", maxWidth: "480px", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>


          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              border: "1px solid white",
              borderRadius: 25,
              width: { md: "420px", xs: "240px" },
              position: "relative",
            }}
          >
            <IconButton style={{ padding: 10 }} aria-label="search">
              <Icon icon="material-symbols:search" fontSize={24} color="white" />
            </IconButton>
            <InputBase
              style={{ marginLeft: 8, flex: 1, color: "white" }}
              placeholder={placeholder}
              inputProps={{ "aria-label": "search" }}
              value={value}
              onChange={onChange}
            />
          </Box>
          {showReset && (
            <Button
              onClick={onReset}
              variant="outlined"
              sx={{
                ml: 2,
                color: "white",
                borderColor: "white",
                borderRadius: "20px",

                // alignSelf: "flex-start",
              }}
            >
              Reset
            </Button>
          )}
        </Box>
        <Box
          sx={{ marginTop: 1, color: "white", width: "100%", cursor: "pointer" }}
          onClick={handleClickOpen}
        >
          <Typography
            variant="body2"
            sx={{ textAlign: { md: "right", xs: "center" } }}
          >
            Need help? Contact our Experts
          </Typography>
        </Box>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "transparent",
            borderRadius: "28px",
          },
          "&:hover": {
            backgroundColor: "transparent",
            borderColor: "#fff",
            color: "#ffffff",
          },
        }}
      >
        <DialogContent
          sx={{ padding: "5px", backgroundColor: "transparent" }}
        >
          <Box sx={{ backgroundColor: "#fff" }}>
            <AskYourExpertForm onClose={handleClose} />
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default Search;
