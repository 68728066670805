const IITDelhiAdmission = [
  {


    data: [
      { title: "Biotechnology and Biochemical Engineering", value: "2253", valuetwo: "1894" },
      { title: "Chemical Engineering ", value: "1490", valuetwo: "1301" },
      { title: "Civil Engineering", value: "523", valuetwo: "2023" },
      { title: "Computer Science and Engineering", value: "3", valuetwo: "31" },
      { title: "Electrical Engineering", value: "364", valuetwo: "311" }
    ]
  },

];

export default IITDelhiAdmission;
