import { Box, Typography, Container, Tabs, Tab } from "@mui/material";
import React, { useState } from "react";
import ExamDetailContent from "./ExamDetailContent";
import examDetailContentJson from "../../../json/columntestdata";
import AiletAboutTableHeader from "../../../json/AiletaboutHeaders";
import AiletDateDataJson from "../../../json/AiletDateData";
import AiletDateTableHeader from "../../../json/AiletDateHeaders";
import AiletApplicationDataJson from "../../../json/AiletApplicationProcessData";
import AiletApplicationProcessHeader from "../../../json/AiletApplicationProcessHeader";
import AiletEligibilityDataJson from "../../../json/AiletEligibilityData";
import AiletEligibilityHeader from "../../../json/AiletEligibilityHeader";
import AiletAdmitCardDataJson from "../../../json/AiletAdmitCardData";
import AiletAdmitCardHeader from '../../../json/AiletAdmitCardHeader';
import AiletAcceptingCollegesDataJson from "../../../json/AiletAcceptingCollegesData";
import AiletAcceptingCollegesHeader from "../../../json/AiletAcceptingCollegesHeader";
import StripTitle from "../../../components/StripTitle";
import HeaderDynamicTable from "../../../components/TableComponent/HeaderDynamicTable";
import ExamCuttoffData from "../../../json/ExamCuttOff";
import DualColumnDynamicTable from "../../../components/TableComponent/TwoColumnTable";
import ExamSyllabus from "../../../json/ExamSyllabus";

const ExamDetail = () => {
    const [selectedTab, setSelectedTab] = useState(0);

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };



    return (
        <>
            <Box>
                <Container sx={{ py: 3 }}>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: { md: "row", sm: "column", xs: "column" }
                        }}
                    >
                        <Typography
                            variant="h6"
                            sx={{ color: "#EF5835" }}
                            fontWeight={"500"}
                            fontSize={"30px"}
                        >
                            {"AILET"}
                        </Typography>
                        <Typography
                            variant="h6"
                            sx={{ color: "#000000" }}
                            fontWeight={"600"}
                            fontSize={"30px"}
                        >
                            {" "}
                            {"\u00A0\u00A0"}
                            {"2024 -"}
                        </Typography>
                        <Typography variant="h6" fontWeight={"300"} fontSize={"30px"}>
                            {"\u00A0\u00A0"}
                            Dates, Application Form, Cutoff, Eligibility, Syllabus
                        </Typography>
                    </Box>
                    <Tabs
                        value={selectedTab}
                        onChange={handleTabChange}
                        variant="scrollable"
                        scrollButtons
                        allowScrollButtonsMobile





                        aria-label="scrollable auto tabs example"
                        sx={{
                            mt: "40px", '& .MuiTab-root': {
                                // fontSize: "13px",
                                color: '#898383',


                                '&.Mui-selected': {


                                    color: "#EF5835",



                                },

                            },
                            '& .MuiTabs-indicator': {
                                display: 'none'
                            }
                        }}
                    >
                        <Tab label="About the Exam" />
                        <Tab label="Dates" />
                        <Tab label="Application Process" />
                        <Tab label="Exams Cut off" />
                        <Tab label="Eligibility" />
                        <Tab label="Admit Card" />
                        <Tab label="Syllabus" />
                        <Tab label="Accepting Colleges" />
                    </Tabs>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            flexWrap: "wrap"
                        }}
                    >
                        {selectedTab === 0 && (

                            <ExamDetailContent
                                showHeader={true}
                                header={"About the Exam"}
                                headerContent={<Typography sx={{width:"100%",display:"flex",justifyContent:"center",alignItems:"center",maxWidth:{md:"none",xs:"70%"},textAlign:{md:"none",xs:"justify"}}}>"The All India Law Entrance Test (AILET) is an annual entrance examination conducted by Delhi’s National Law University (NLU) to select candidates for various courses at 20 member colleges. (Successful candidates gain admission to NLU for BA LLB, LLB, LLM and doctoral-level programmes). The test is a 90-minute in-person exam, testing candidates on their English, their reasoning, their legal aptitude and their knowledge of Mathematics and general knowledge. Applications to sit for the exam can be submitted on the university’s official website.In 2022, several changes were brought into the AILET BA LLB exam. For one, the governing body decided to scrap the mathematics and legal aptitude sections—instead, the logical reasoning section carries 50 per cent of the marks and includes questions on legal aptitude (legal questions that require no prior knowledge of the law). For another, the exam was held in 24 cities—two new locations, Dehradun and Vishakhapatnam, were added. Finally, if two or more candidates have the same rank, the tie will be broken based on the marks in the logical reasoning section, on the basis of age (the older candidate will go through), or a computerised draw of lots.Applications for the exam this year opened on January 17, with the exam itself held on June 26. The final master answer key was made public on July 1, with counselling (the seat allocation process) being held over several sections since then. As of July 19, third provisional merit lists for BA LLB candidates and LLM candidates had been published.For more information, the official AILET website can be accessed at the National Law University."</Typography>}
                                title={"Details"}
                                DynamicTabledata={ examDetailContentJson}
                                DynamicTableheader={AiletAboutTableHeader}
                                // customIndices={[3, 6, 9, 12, 15]}


                            />
                        )}
                        {selectedTab === 1 && (
                            <ExamDetailContent
                                title={"Dates"}
                                DynamicTabledata={AiletDateDataJson}
                                DynamicTableheader={AiletDateTableHeader}

                            />
                        )}
                        {selectedTab === 2 && (
                            <ExamDetailContent
                                title={"Application Process"}
                                DynamicTabledata={AiletApplicationDataJson}
                                DynamicTableheader={AiletApplicationProcessHeader}
                            />
                        )}
                        {selectedTab === 3 && (
                            <>
                                <Box
                                    sx={{
                                        justifyContent: "center", flexDirection: "column", width: '100%'
                                    }}
                                >

                                    <StripTitle title={"Exam Cut Off"} color={"#252525"} />
                                    {ExamCuttoffData?.map((item) => (
                                        <HeaderDynamicTable
                                            gapcolor={"transparent"}
                                            data={item}
                                            margin={"10px"}
                                            showsubheader={false}
                                            header1={"Category"}
                                            header2={"Cutoff marks and rank for BA LLB"}
                                            header3={"Cutoff marks and rank for LLM"}

                                        />
                                    ))}
                                </Box>
                            </>

                        )}
                        {selectedTab === 4 && (
                            <ExamDetailContent
                                title={"Exam Eligibility"}
                                DynamicTabledata={AiletEligibilityDataJson}
                                DynamicTableheader={AiletEligibilityHeader}
                            />
                        )}
                        {selectedTab === 5 && (
                            <ExamDetailContent
                                title={"Admit Card"}
                                DynamicTabledata={AiletAdmitCardDataJson}
                                DynamicTableheader={AiletAdmitCardHeader}
                            />
                        )}
                        {selectedTab === 6 && (
                            <>
                                <Box
                                    sx={{
                                        justifyContent: "center", flexDirection: "column", width: '100%'
                                    }}
                                >

                                    <StripTitle title={"Syllabus"} color={"#252525"} />
                                    {ExamSyllabus?.map((item) => (
                                        <DualColumnDynamicTable
                                            gapcolor={"transparent"}
                                            data={item}
                                            margin={"10px"}

                                            header1={"Syllabus and Subjects"}
                                            header2={"English, Current Affairs and General Knowledge, Logical Reasoning"}


                                        />
                                    ))}
                                </Box>
                            </>

                        )}
                        {selectedTab === 7 && (
                            <ExamDetailContent
                                title={"Accepting Colleges"}
                                DynamicTabledata={AiletAcceptingCollegesDataJson}
                                DynamicTableheader={AiletAcceptingCollegesHeader}

                            />
                        )}
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default ExamDetail;
