const JeeAiletApplicationProcessHeader = [
    { id: 1, label: "Application Mode", var: "applicationMode" },
    { id: 2, label: "Steps to Apply", var: "stepstoApply" },
    { id: 3, label: "Documents Required", var: "documentsRequired" },
    { id: 4, label: "How to fill Application Form", var: "HowtofillapplicationForm" },
    { id: 5, label: "Application Fees", var: "applicationFees" },
    { id: 6, label: "Fee Payment", var: "feePayment" },
    { id: 7, label: "Registration Form-Important Points", var: "importantPoints" },
    { id: 8, label: "Application Form Correction", var: "applicationformcorrection" },


];

export default JeeAiletApplicationProcessHeader;