import { Box, Typography, Container, Tabs, Tab } from "@mui/material";
import React, { useState } from "react";
import HeaderDynamicTable from "../../components/TableComponent/HeaderDynamicTable";
import JeeAiletDateTableHeader from "../../json/JeeAiletDateTableHeader";
import JeeAiletApplicationProcessHeader from "../../json/JeeAiletApplicationProcessHeader";
import JeeAdmitCardHeader from "../../json/JeeAdmitCardHeader";
import JeeCollegesHeader from "../../json/JeeCollegesHeader";
import ExamDetailContent from "../EntranceExam/ExamDetails/ExamDetailContent";
import DualColumnDynamicTable from "../../components/TableComponent/TwoColumnTable";
import StripTitle from "../../components/StripTitle";
import jeeAdvanceDetailContentJson from "../../json/JeeAdvance/JeeAdvanceDetailContentJson";
import JeeAdvanceAboutTableHeader from "../../json/JeeAdvance/JeeAdvanceAboutTableHeader";
import JeeAdvanceDateDataJson from "../../json/JeeAdvance/JeeAdvanceDateDataJson";
import JeeAdvanceApplicationDataJson from "../../json/JeeAdvance/JeeAdvanceApplicationDataJson";
import JeeAdvanceExamCuttoffData from "../../json/JeeAdvance/JeeAdvanceExamCutOff";
import JeeAdvanceEligibilityDataJson from "../../json/JeeAdvance/JeeAdvanceEligibilityDataJson";
import JeeAdvanceEligibilityHeader from "../../json/JeeAdvance/JeeAdvanceEligibilityHeader";
import JeeAdvanceAdmitCardDataJson from "../../json/JeeAdvance/JeeAdvanceAdmitCardDataJson";
import JeeAdvanceExamSyllabus from "../../json/JeeAdvance/JeeAdvanceExamSyllabus";
import JeeAdvanceCollegesDataJson from "../../json/JeeAdvance/JeeAdvanceCollegeDataJson";
import Navbar from '../home/Header/Navbar';
import Footer from '../home/Footer/footer'



const JeeAdvanceDetail = () => {
    const [selectedTab, setSelectedTab] = useState(0);

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };



    return (
        <>            <Navbar />

            <Box>
                <Container sx={{ py: 3 }}>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: { md: "row", sm: "column", xs: "column" }
                        }}
                    >
                        <Typography
                            variant="h6"
                            sx={{ color: "#EF5835" }}
                            fontWeight={"500"}
                            fontSize={"30px"}
                        >
                            {"JEE Advanced"}
                        </Typography>
                        <Typography
                            variant="h6"
                            sx={{ color: "#000000" }}
                            fontWeight={"600"}
                            fontSize={"30px"}
                        >
                            {" "}
                            {"\u00A0\u00A0"}
                            {"2024 -"}
                        </Typography>
                        <Typography variant="h6" fontWeight={"300"} fontSize={"30px"}>
                            {"\u00A0\u00A0"}
                            Dates, Application Form, Cutoff, Eligibility, Syllabus
                        </Typography>
                    </Box>
                    <Tabs
                        value={selectedTab}
                        onChange={handleTabChange}
                        variant="scrollable"
                        scrollButtons
                        allowScrollButtonsMobile





                        aria-label="scrollable auto tabs example"
                        sx={{
                            mt: "40px", '& .MuiTab-root': {
                                // fontSize: "13px",
                                color: '#898383',


                                '&.Mui-selected': {


                                    color: "#EF5835",



                                },

                            },
                            '& .MuiTabs-indicator': {
                                display: 'none'
                            }
                        }}
                    >
                        <Tab label="About the Exam" />
                        <Tab label="Dates" />
                        <Tab label="Application Process" />
                        <Tab label="Exams Cut off" />
                        <Tab label="Eligibility" />
                        <Tab label="Admit Card" />
                        <Tab label="Syllabus" />
                        <Tab label="Accepting Colleges" />
                    </Tabs>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            flexWrap: "wrap"
                        }}
                    >
                        {selectedTab === 0 && (

                            <ExamDetailContent
                                showHeader={true}
                                header={"About the Exam"}
                                headerContent={<Typography sx={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", maxWidth: { md: "none", xs: "70%" }, textAlign: { md: "none", xs: "justify" } }}>"The JEE (Joint Entrance Exam) Advanced paper is a national-level entrance exam for admission to the IITs (Indian Institutes of Technology). It is conducted by one of seven IITs - Kharagpur, Kanpur, Madras, Delhi, Bombay, Guwahati or Roorkee - or by IISc Bangalore, under the aegis of the Joint Admission Board. In 2022, the JEE Advanced paper is being conducted by IIT Bombay.
                                    To earn a seat for the JEE Advanced paper, candidates must first qualify through JEE Main Paper 1 by being in the top 250,000 in that exam. Those who qualify through the JEE Advanced are then granted admission to undergraduate engineering courses, learning to a bachelor's, integrated master's or a bachelor-master's dual degree in engineering, sciences or architecture. The programmes that qualified candidates can take up include four-year BTech and BSc degrees, as well as several five-year courses, including BArch, integrated MTech, integrated MSc, dual BTech-MTech and BSc-MSc programmes. (Note: not all programmes are available at all IITs, and the final list of courses available in academic year 2022-23 will be announced during the seat allocation process, according to the JEE Advanced Information Brochure published by IIT Bombay.)
                                    Post the exam, qualified candidates are placed at the various institutes by the Joint Seat Allocation Authority, based on the ranks earned in the JEE Advanced exam. There are various reservation criteria that apply, including 10 per cent of seats in every course for general category candidates from economically weaker backgrounds, 27 per cent for OBC (other backward classes) candidates from non-creamy layers, 15 per cent of seats for SC (scheduled caste) candidates and 7.5 per cent of seats for ST (scheduled tribe) candidates, and so on.
                                    The JEE Advanced 2022 exam comprises two compulsory papers, each of three hours duration and both currently scheduled for August 28, 2022.
                                    "</Typography>}
                                title={"Details"}
                                DynamicTabledata={jeeAdvanceDetailContentJson}
                                DynamicTableheader={JeeAdvanceAboutTableHeader}
                            // customIndices={[3, 6, 9, 12, 15]}


                            />
                        )}
                        {selectedTab === 1 && (
                            <ExamDetailContent
                                title={"Dates"}
                                DynamicTabledata={JeeAdvanceDateDataJson}
                                DynamicTableheader={JeeAiletDateTableHeader}

                            />
                        )}
                        {selectedTab === 2 && (
                            <ExamDetailContent
                                title={"Application Process"}
                                DynamicTabledata={JeeAdvanceApplicationDataJson}
                                DynamicTableheader={JeeAiletApplicationProcessHeader}
                            />
                        )}
                        {selectedTab === 3 && (
                            <>
                                <Box
                                    sx={{
                                        justifyContent: "center", flexDirection: "column", width: '100%'
                                    }}
                                >

                                    <StripTitle title={"Exam Cut Off"} color={"#252525"} />
                                    {JeeAdvanceExamCuttoffData?.map((item) => (
                                        <HeaderDynamicTable
                                            gapcolor={"transparent"}
                                            data={item}
                                            margin={"10px"}
                                            showsubheader={false}
                                            header1={"Category"}
                                            header2={"Minimum Marks in Each Subject"}
                                            header3={"Minimum Aggregate Marks"}
                                        />
                                    ))}
                                </Box>
                            </>

                        )}
                        {selectedTab === 4 && (
                            <ExamDetailContent
                                title={"Exam Eligibility"}
                                DynamicTabledata={JeeAdvanceEligibilityDataJson}
                                DynamicTableheader={JeeAdvanceEligibilityHeader}
                            />
                        )}
                        {selectedTab === 5 && (
                            <ExamDetailContent
                                title={"Admit Card"}
                                DynamicTabledata={JeeAdvanceAdmitCardDataJson}
                                DynamicTableheader={JeeAdmitCardHeader}
                            />
                        )}
                        {selectedTab === 6 && (
                            <>
                                <Box
                                    sx={{
                                        justifyContent: "center", flexDirection: "column", width: '100%'
                                    }}
                                >

                                    <StripTitle title={"Syllabus"} color={"#252525"} />
                                    {JeeAdvanceExamSyllabus?.map((item) => (
                                        <DualColumnDynamicTable
                                            gapcolor={"transparent"}
                                            data={item}
                                            margin={"10px"}
                                            coloumn1width={"40%"}
                                            header1={"Syllabus and Subjects"}
                                            header2={"Physics, Chemistry, Maths"}


                                        />
                                    ))}
                                </Box>
                            </>

                        )}
                        {selectedTab === 7 && (
                            <ExamDetailContent
                                title={"Accepting Colleges"}
                                DynamicTabledata={JeeAdvanceCollegesDataJson}
                                DynamicTableheader={JeeCollegesHeader}

                            />
                        )}
                    </Box>
                </Container>
            </Box>
            <Footer padding={"-70px"} margintop={"-20px"} />

        </>
    );
};

export default JeeAdvanceDetail;
