import React from 'react';
import ResponsiveAppBar from './home/Header/Navbar';
import Addmition from './home/Footer/footer';
import { Box, Container, Typography, Link, List, ListItem, ListItemText } from '@mui/material';

const PrivacyPolicy = () => {
    return (
        <div>
            <ResponsiveAppBar />
            <Box sx={{ mb: "60px" }}>
                <Container>
                    <Typography variant="h4" sx={{ fontFamily: 'Montserrat', fontWeight: 700, margin: "20px 0" }}>
                        Privacy Policy
                    </Typography>
                    <Typography variant="body1" sx={{ fontFamily: 'Nunito' }}>
                        Last updated: July 03, 2024
                    </Typography>



                    <Typography variant="h5" sx={{ fontFamily: 'Montserrat', fontWeight: 700, margin: "20px 0" }}>
                        Interpretation and Definitions
                    </Typography>
                    <Typography variant="h6" sx={{ fontFamily: 'Montserrat', fontWeight: 700, margin: "20px 0" }}>
                        Interpretation
                    </Typography>
                    <Typography variant="body1" sx={{ fontFamily: 'Nunito' }}>
                        The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
                    </Typography>

                    <Typography variant="h6" sx={{ fontFamily: 'Montserrat', fontWeight: 700, margin: "20px 0" }}>
                        Definitions
                    </Typography>
                    <Typography variant="body1" sx={{ fontFamily: 'Nunito' }}>
                        For the purposes of this Privacy Policy:
                    </Typography>

                    <List sx={{ fontFamily: 'Nunito' }}>
                        <ListItem>
                            <ListItemText primary={
                                <>
                                    <strong>Account:</strong> A unique account created for You to access our Service or parts of our Service.
                                </>
                            } />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={
                                <>
                                    <strong>Affiliate:</strong> An entity that controls, is controlled by, or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.
                                </>
                            } />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={
                                <>
                                    <strong>Company:</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to careerplatform.
                                </>
                            } />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={
                                <>
                                    <strong>Cookies:</strong> Small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.
                                </>
                            } />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={
                                <>
                                    <strong>Country:</strong> Karnataka, India
                                </>
                            } />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={
                                <>
                                    <strong>Device:</strong> Any device that can access the Service such as a computer, a cellphone or a digital tablet.
                                </>
                            } />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={
                                <>
                                    <strong>Personal Data:</strong> Any information that relates to an identified or identifiable individual.
                                </>
                            } />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={
                                <>
                                    <strong>Service:</strong> The Website.
                                </>
                            } />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={
                                <>
                                    <strong>Service Provider:</strong> Any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.
                                </>
                            } />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={
                                <>
                                    <strong>Third-party Social Media Service:</strong> Any website or any social network website through which a User can log in or create an account to use the Service.
                                </>
                            } />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={
                                <>
                                    <strong>Usage Data:</strong> Data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).
                                </>
                            } />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={
                                <>
                                    <strong>Website:</strong> Refers to careerplatform, accessible from <Link href="https://www.careerplatform.co.in/" rel="external nofollow noopener" target="_blank">https://www.careerplatform.co.in/</Link>
                                </>
                            } />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={
                                <>
                                    <strong>You:</strong> The individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.
                                </>
                            } />
                        </ListItem>
                    </List>

                    <Typography variant="h5" sx={{ fontFamily: 'Montserrat', fontWeight: 700 }}>
                        Collecting and Using Your Personal Data
                    </Typography>
                    <Typography variant="h6" sx={{ fontFamily: 'Montserrat', fontWeight: 700 }}>
                        Types of Data Collected
                    </Typography>
                    <Typography variant="h4" sx={{ fontFamily: 'Montserrat', fontWeight: 700, margin: "20px 0" }}>
                        Personal Data
                    </Typography>
                    <Typography variant="body1" sx={{ fontFamily: 'Nunito' }}>
                        While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:
                    </Typography>
                    <List sx={{ fontFamily: 'Nunito' }}>
                        <ListItem>
                            <ListItemText primary="Email address" />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="First name and last name" />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Phone number" />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Address, State, Province, ZIP/Postal code, City" />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Usage Data" />
                        </ListItem>
                    </List>
                    <Typography variant="h4" sx={{ fontFamily: 'Montserrat', fontWeight: 700, margin: "20px 0" }}>
                        Usage Data
                    </Typography>
                    <Typography variant="body1" sx={{ fontFamily: 'Nunito' }}>
                        Usage Data is collected automatically when using the Service.
                    </Typography>
                    <Typography variant="body1" sx={{ fontFamily: 'Nunito' }}>
                        Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.
                    </Typography>
                    <Typography variant="body1" sx={{ fontFamily: 'Nunito' }}>
                        When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.
                    </Typography>
                    <Typography variant="body1" sx={{ fontFamily: 'Nunito' }}>
                        We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.
                    </Typography>
                    <Typography variant="h4" sx={{ fontFamily: 'Montserrat', fontWeight: 700, margin: "20px 0" }}>
                        Information from Third-Party Social Media Services
                    </Typography>
                    <Typography variant="body1" sx={{ fontFamily: 'Nunito' }}>
                        The Company allows You to create an account and log in to use the Service through the following Third-party Social Media Services:
                    </Typography>
                    <List sx={{ fontFamily: 'Nunito' }}>
                        <ListItem sx={{ paddingY: 0.5 }}>
                            <ListItemText primary="Google" />
                        </ListItem>
                        <ListItem sx={{ paddingY: 0.5 }}>
                            <ListItemText primary="Facebook" />
                        </ListItem>
                        <ListItem sx={{ paddingY: 0.5 }}>
                            <ListItemText primary="Twitter" />
                        </ListItem>
                    </List>
                    <Typography variant="body1" sx={{ fontFamily: 'Nunito' }}>
                        If You decide to register through or otherwise grant us access to a Third-Party Social Media Service, We may collect Personal data that is already associated with Your Third-Party Social Media Service account, such as Your name, Your email address, Your activities or Your contact list associated with that account.
                    </Typography>
                    <Typography variant="body1" sx={{ fontFamily: 'Nunito' }}>
                        You may also have the option of sharing additional information with the Company through Your Third-Party Social Media Service account. If You choose to provide such information and Personal Data, during registration or otherwise, You are giving the Company permission to use, share, and store it in a manner consistent with this Privacy Policy.
                    </Typography>
                    <Typography variant="h4" sx={{ fontFamily: 'Montserrat', fontWeight: 700, margin: "20px 0" }}>
                        Use of Your Personal Data
                    </Typography>
                    <Typography variant="body1" sx={{ fontFamily: 'Nunito' }}>
                        The Company may use Personal Data for the following purposes:
                    </Typography>
                    <List sx={{ fontFamily: 'Nunito' }}>
                        <ListItem>
                            <ListItemText primary="To provide and maintain our Service, including to monitor the usage of our Service." />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="To manage Your Account: to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user." />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="To contact You: To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products, or contracted services, including the security updates, when necessary or reasonable for their implementation." />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="To provide You with news, special offers, and general information about other goods, services, and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information." />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="To manage Your requests: To attend and manage Your requests to Us." />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="For business transfers: We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred." />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="For other purposes: We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns, and to evaluate and improve our Service, products, services, marketing, and your experience." />
                        </ListItem>
                    </List>
                    <Typography variant="h5" sx={{ fontFamily: 'Montserrat', fontWeight: 700, margin: "20px 0" }}>
                        Retention of Your Personal Data
                    </Typography>
                    <Typography variant="body1" sx={{ fontFamily: 'Nunito' }}>
                        The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.
                    </Typography>
                    <Typography variant="body1" sx={{ fontFamily: 'Nunito' }}>
                        The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.
                    </Typography>
                    <Typography variant="h5" sx={{ fontFamily: 'Montserrat', fontWeight: 700, margin: "20px 0" }}>
                        Transfer of Your Personal Data
                    </Typography>
                    <Typography variant="body1" sx={{ fontFamily: 'Nunito' }}>
                        Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country, or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.
                    </Typography>
                    <Typography variant="body1" sx={{ fontFamily: 'Nunito' }}>
                        Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.
                    </Typography>
                    <Typography variant="body1" sx={{ fontFamily: 'Nunito' }}>
                        The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.
                    </Typography>
                    <Typography variant="h5" sx={{ fontFamily: 'Montserrat', fontWeight: 700, margin: "20px 0" }}>
                        Disclosure of Your Personal Data
                    </Typography>
                    <Typography variant="body1" sx={{ fontFamily: 'Nunito' }}>
                        Business Transactions
                    </Typography>
                    <Typography variant="body1" sx={{ fontFamily: 'Nunito' }}>
                        If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.
                    </Typography>
                    <Typography variant="body1" sx={{ fontFamily: 'Nunito' }}>
                        Law enforcement
                    </Typography>
                    <Typography variant="body1" sx={{ fontFamily: 'Nunito' }}>
                        Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).
                    </Typography>
                    <Typography variant="body1" sx={{ fontFamily: 'Nunito' }}>
                        Other legal requirements
                    </Typography>
                    <Typography variant="body1" sx={{ fontFamily: 'Nunito' }}>
                        The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:
                    </Typography>
                    <List sx={{ fontFamily: 'Nunito' }}>
                        <ListItem>
                            <ListItemText primary="Comply with a legal obligation" />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Protect and defend the rights or property of the Company" />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Prevent or investigate possible wrongdoing in connection with the Service" />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Protect the personal safety of Users of the Service or the public" />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Protect against legal liability" />
                        </ListItem>
                    </List>
                    <Typography variant="h5" sx={{ fontFamily: 'Montserrat', fontWeight: 700, margin: "20px 0" }}>
                        Security of Your Personal Data
                    </Typography>
                    <Typography variant="body1" sx={{ fontFamily: 'Nunito' }}>
                        The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.
                    </Typography>
                    <Typography variant="h5" sx={{ fontFamily: 'Montserrat', fontWeight: 700, margin: "20px 0" }}>
                        Children's Privacy
                    </Typography>
                    <Typography variant="body1" sx={{ fontFamily: 'Nunito' }}>
                        Our Service does not address anyone under the age of 18. We do not knowingly collect personally identifiable information from anyone under the age of 18. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 18 without verification of parental consent, We take steps to remove that information from Our servers.
                    </Typography>
                    <Typography variant="h5" sx={{ fontFamily: 'Montserrat', fontWeight: 700, margin: "20px 0" }}>
                        Changes to this Privacy Policy
                    </Typography>
                    <Typography variant="body1" sx={{ fontFamily: 'Nunito' }}>
                        We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.
                    </Typography>
                    <Typography variant="body1" sx={{ fontFamily: 'Nunito' }}>
                        You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
                    </Typography>
                    <Typography variant="h5" sx={{ fontFamily: 'Montserrat', fontWeight: 700, margin: "20px 0" }}>
                        Contact Us
                    </Typography>
                    <Typography variant="body1" sx={{ fontFamily: 'Nunito' }}>
                        If you have any questions about this Privacy Policy, you can contact us:
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemText
                                primary={<Typography component="div">By email:<a href='mailto:info@careerplatform.co.in' rel="external nofollow noopener noreferrer" target='_blank'> info@careerplatform.co.in </a> </Typography>}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary={<Typography component="div">By visiting this page on our website: <a href='https://www.careerplatform.co.in/' rel="external nofollow noopener noreferrer" target='_blank'>https://www.careerplatform.co.in/</a></Typography>}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary={<Typography component="div">By phone number: <a href='tel:+919379660310'>+91-9379660310</a></Typography>}
                            />
                        </ListItem>
                    </List>
                </Container>
            </Box>
            <Addmition margintop={"40px"} paddingtop={"-30px"} />
        </div>
    );
}

export default PrivacyPolicy;
