const NationalAboutData = {
    address: "Nagarbhavi, Bengaluru, Karnataka",
    city: "Bengaluru",
    state: "Karnataka",
    phone: "080-23014000",
    email: "vc@nls.ac.in",
    established: "1987",
    yearfirst: "1993",
    headoftheinstitution: "Prof Dr Sudhir Krishnaswamy",
    designation: "Vice Chancellor",
    institutetype: "Government",
    website: "www.nls.ac.in",
    antiraggingcell: "Yes",
    nationalserviceschemecell: "No",
    internalcomplaintscommitteecell: "Yes",
    internalqualityassurancecell: "Yes",
    totalnumberof: "No",
    
  
  };
  export default NationalAboutData;
  