const JeeExamCuttoffData = [
    {


        data: [
            { title: "General", value: "90.3765335", valuetwo: "87.8992241",valuethree:"90.3765335" },
            { title: "OBC-NCL", value: "72.8887969", valuetwo: "68.0234447",valuethree:"72.8887969" },
            { title: "Scheduled Caste", value: "50.1760245", valuetwo: "46.8825338",valuethree:"50.1760245" },
            { title: "Scheduled Tribe", value: "50.1760245", valuetwo: "34.6728999",valuethree:"39.0696101" },
            { title: "GEN-EWS", value: "70.2435518", valuetwo: "66.2214845",valuethree:"70.2435518" },
            { title: "PWD", value: "0.0618524", valuetwo: "0.0096375",valuethree:"0.0618524" },
            
        ]
    },

];

export default JeeExamCuttoffData;
