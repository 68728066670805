import { Box, Container, Typography } from "@mui/material";
import React from "react";
import DynamicTable from "../../../components/DynamicTable";

const ExamDetailContent = ({
  showHeader = false,
  showtitle = true,
  DynamicTabledata,
  DynamicTableheader,
  header,
  headerContent,
  tableheader,
  title,
  customIndices,
  gapcolor,
  color,
  singleTableheader,
}) => {
  return (
    <Box sx={{ py: 2.5 }}>
      {showHeader && (
        <Box>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Typography
              variant="h2"
              sx={{
                color: color ?? "#000000",
                fontWeight: "500",
                fontSize: "22px",
                position: "relative",
                "&::before": {
                  content: "''",
                  position: "absolute",
                  left: "-10px",
                  width: "5px",
                  height: "20px",
                  backgroundColor: "#EF5835",
                },
              }}
            >
              {header}
            </Typography>
          </Box>

          <Box sx={{ display: "flex", padding: "15px" }}>
            <Typography
              variant="h2"
              sx={{ color: color ?? "#000000" }}
              fontWeight={"300"}
              fontSize={"16px"}
            >
              {headerContent}
            </Typography>
          </Box>
        </Box>
      )}
      {showtitle && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            mt: "20px",
            mb: "20px",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              color: color ?? "#000000",
              fontWeight: "500",
              fontSize: "20px",
              position: "relative",
              "&::before": {
                content: "''",
                position: "absolute",
                left: "-10px",

                width: "5px",
                height: "20px",
                backgroundColor: "#EF5835",
              },
            }}
          >
            {title}
          </Typography>
        </Box>
      )}
        <DynamicTable
          data={DynamicTabledata}
          header={DynamicTableheader}
          customIndices={customIndices}
          gapcolor={gapcolor}
          showheader={tableheader}
          singleTableheader={singleTableheader}
        />
    </Box>
  );
};

export default ExamDetailContent;
