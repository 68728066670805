
import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { TextField, Button, Grid, Typography, Select, MenuItem, Container, Box, Checkbox } from '@mui/material';
import { styled } from "@mui/material/styles";
import '../../../../src/careerplatform.css'
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';

const schema = yup.object().shape({
  username: yup.string().required('First Name is required'),
  middlename: yup.string(),
  lastname: yup.string().required('Last name is required'),
  userdob: yup.date().required('Date of birth is required'),
  usergender: yup.string().required('Gender is required'),
  usercategory: yup.string(),
  usernationality: yup.string(),
  userbloodgroup: yup.string(),
  useremail: yup.string().email('Invalid email').required('Email is required'),
  usermobile: yup.string().matches(/^\d{10}$/, 'Invalid mobile number').required('Mobile number is required'),
  AddressLine1: yup.string(),
  AddressLine2: yup.string(),
  userpincode: yup.string(),
  usercity: yup.string(),
  userstate: yup.string(),
  usercountry: yup.string(),
  fathername: yup.string(),
  mothername: yup.string(),
  fatherqualification: yup.string(),
  motherqualification: yup.string(),
  parentemail: yup.string().email('Invalid email'),
  parentmobile: yup.string().matches(/^\d{10}$/, 'Invalid mobile number'),
  userexam: yup.string(),
  userpercentile: yup.string(),
  userscore: yup.string(),
  userexam2: yup.string(),
  userpercentile2: yup.string(),
  userscore2: yup.string(),
  nameofinstitution: yup.string(),
  nameofuniversity: yup.string(),
  yearofpassing: yup.string(),
  remarks: yup.string(),
  agg: yup.string(),
  nameofGraduationinstitution: yup.string(),
  nameofGraduationuniversity: yup.string(),
  nameofGraduationpassing: yup.string(),
  remarksGraduation: yup.string(),
  Graduationagg: yup.string(),
  nameoftwelvethinstitution: yup.string(),
  nameoftwelvethuniversity: yup.string(),
  nameoftwelvethpassing: yup.string(),
  remarkstwelveth: yup.string(),
  twelvethagg: yup.string(),
  nameoftenthinstitution: yup.string(),
  nameoftenthuniversity: yup.string(),
  nameoftenthpassing: yup.string(),
  remarkstenth: yup.string(),
  tenthagg: yup.string(),
  nameofothersinstitution: yup.string(),
  nameofothersuniversity: yup.string(),
  nameofotherspassing: yup.string(),
  remarksothers: yup.string(),
  othersagg: yup.string(),
  declaration: yup.bool().oneOf([true], 'You must agree to the declaration'),

});



const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};


const initialValues = {
  username: '',
  middlename: '',
  lastname: '',
  userdob: '',
  usergender: '',
  usercategory: '',
  usernationality: '',
  userbloodgroup: '',
  useremail: '',
  usermobile: "",
  AddressLine1: '',
  AddressLine2: '',
  userpincode: '',
  usercity: '',
  userstate: '',
  usercountry: '',
  fathername: '',
  mothername: '',
  fatherqualification: '',
  motherqualification: '',
  parentemail: '',
  parentmobile: '',
  userexam: '',
  userpercentile: '',
  userscore: '',
  userexam2: '',
  userpercentile2: '',
  userscore2: '',
  nameofinstitution: '',
  nameofuniversity: '',
  yearofpassing: '',
  remarks: '',
  agg: '',
  nameofGraduationinstitution: '',
  nameofGraduationuniversity: '',
  nameofGraduationpassing: '',
  remarksGraduation: '',
  Graduationagg: '',
  nameoftwelvethinstitution: '',
  nameoftwelvethuniversity: '',
  nameoftwelvethpassing: '',
  remarkstwelveth: '',
  twelvethagg: '',
  nameoftenthinstitution: '',
  nameoftenthuniversity: '',
  nameoftenthpassing: '',
  remarkstenth: '',
  tenthagg: '',
  nameofothersinstitution: '',
  nameofothersuniversity: '',
  nameofotherspassing: '',
  remarksothers: '',
  othersagg: '',
  yearofGraduationpassing
    : ""
  ,
  yearofotherspassing
    : ""
  ,

  yearoftenthpassing
    :
    "",
  yearoftwelvethpassing
    : "",

  document: null, // Placeholder for files
  file: null, // Placeholder for profile picture
};

const FormComponent = () => {
  const { control, handleSubmit, formState: { errors }, watch } = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialValues
  });
  console.log('errors :>> ', errors);

  const onSubmit = async (data) => {
    console.log('data :>> ', data);
    try {
      const documents = await Promise.all(
        Array.from(data.document).map(file => getBase64(file))
      );
      console.log('Documents:', documents);

      const profilePicture = await getBase64(data.file[0]);
      console.log('Profile Picture:', profilePicture);

      const payload = {
        personalDetail: {
          firstName: data.username,
          middleName: data.middlename,
          lastName: data.lastname,
          gender: data.usergender,
          dob: data.userdob,
          category: data.usercategory,
          nationality: data.usernationality,
          bloodGroup: data.userbloodgroup,
          mobile: data.usermobile,
          email: data.useremail
        },
        presentAddress: {
          addressLineOne: data.AddressLine1,
          addressLineTwo: data.AddressLine2,
          city: data.usercity,
          pinCode: data.userpincode,
          state: data.userstate,
          country: data.usercountry,
          isPermanentAddressSameAsPresent: data.isPermanentAddressSameAsPresent
        },
        familyDetails: {
          fatherName: data.fathername,
          motherName: data.mothername,
          fatherQualification: data.fatherqualification,
          motherQualification: data.motherqualification,
          email: data.parentemail,
          mobile: data.parentmobile
        },
        entranceExamScores: {
          exam: data.userexam,
          score: data.userscore,
          percentile: data.userpercentile,
          exam2: data.userexam2,
          score2: data.userscore2,
          percentile2: data.userpercentile2
        },
        academicQualification: {
          postGraduation: {
            nameOfInstitution: data.nameofinstitution,
            nameOfUniversityOrBoard: data.nameofuniversity,
            yearOfPassing: data.yearofpassing,
            aggregation: data.agg,
            remarks: data.remarks
          },
          graduation: {
            nameOfInstitution: data.nameofGraduationinstitution,
            nameOfUniversityOrBoard: data.nameofGraduationuniversity,
            yearOfPassing: data.nameofGraduationpassing,
            aggregation: data.Graduationagg,
            remarks: data.remarksGraduation
          },
          twelth: {
            nameOfInstitution: data.nameoftwelvethinstitution,
            nameOfUniversityOrBoard: data.nameoftwelvethuniversity,
            yearOfPassing: data.nameoftwelvethpassing,
            aggregation: data.twelvethagg,
            remarks: data.remarkstwelveth
          },
          tenth: {
            nameOfInstitution: data.nameoftenthinstitution,
            nameOfUniversityOrBoard: data.nameoftenthuniversity,
            yearOfPassing: data.nameoftenthpassing,
            aggregation: data.tenthagg,
            remarks: data.remarkstenth
          },
          otherQualification: {
            nameOfInstitution: data.nameofothersinstitution,
            nameOfUniversityOrBoard: data.nameofothersuniversity,
            yearOfPassing: data.nameofotherspassing,
            aggregation: data.othersagg,
            remarks: data.remarksothers
          }
        },
        uploadDocument: {
          url: documents
        },
        uploadProfilePicture: {
          image: profilePicture
        }
      };

      const response = await axios.post('https://career-platform-api.onrender.com/api/v1/onlineApplication/add-online-application', payload, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      toast.success('Application submitted successfully!', {
        position: "top-right"
      });
      console.log('Response:', response.data);
    } catch (error) {
      toast.error('Error uploading files. Please try again.', {
        position: "top-right"
      });
      console.error('Error uploading files:', error);
    }
  };



  console.log('watch() :>> ', watch());

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  return (
    <>
      <ToastContainer />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container>
          <Grid item xs={12} sx={{ mt: "30px" }}>
            <Box sx={{ display: "flex", flexDirection: "row" }}>

              <Typography variant="h4" className="box2" sx={{ fontWeight: "700", color: "#EF5835" }}>Online</Typography>
              <Typography variant="h4" className="box2" sx={{ fontWeight: "700" }}>&nbsp;Application</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ mt: "30px" }}>
            <Typography variant="h5" className="box2" sx={{ fontWeight: "700" }}>Personal Details</Typography>
          </Grid>
          <Grid container spacing={3} sx={{ mt: "10px" }}>
            <Grid item xs={12} sm={6}>
              <Controller
                name="username"
                control={control}

                render={({ field }) => (
                  <TextField focused color="warning" {...field} label="First Name" error={!!errors.username} helperText={errors.username?.message} fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="middllename"
                control={control}

                render={({ field }) => (
                  <TextField focused color="warning" {...field} label="Middle Name" error={!!errors.middlename} helperText={errors.middlename?.message} fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="lastname"
                control={control}

                render={({ field }) => (
                  <TextField focused color="warning" {...field} label="Last Name" error={!!errors.lastname} helperText={errors.lastname?.message} fullWidth />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Controller
                name="userdob"
                control={control}

                render={({ field }) => (
                  <TextField focused color="warning"  {...field} type="date" label="Date of Birth" error={!!errors.userdob} helperText={errors.userdob?.message} fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="usergender"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    labelId="demo-simple-select-standard-label"
                    id="services"
                    displayEmpty
                    sx={{
                      ".MuiOutlinedInput-notchedOutline": { border: 0 },
                      "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                        border: 0,
                      },
                      "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: 0,
                      },
                      border: "1px solid #EF5835",
                      borderRadius: "2rem",
                      color: "#EF5835",
                      width: {
                        xl: "10vw",
                        lg: "10vw",
                        md: "20vw",
                        sm: "50vw",
                        xs: "50vw",
                      },
                      height: "50px",
                      fontSize: {
                        xl: "20px",
                        lg: "20px",
                        sm: "23px",
                        xs: "18px",
                      },
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <MenuItem value="">
                      <em>Select</em>
                    </MenuItem>
                    <MenuItem value="male">Male</MenuItem>
                    <MenuItem value="female">Female</MenuItem>
                    <MenuItem value="others">Other</MenuItem>
                  </Select>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="usercategory"
                control={control}

                render={({ field }) => (
                  <TextField focused color="warning" {...field} label="Category" error={!!errors.usercategory} helperText={errors.usercategory?.message} fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="usernationality"
                control={control}

                render={({ field }) => (
                  <TextField focused color="warning" {...field} label="Nationality" error={!!errors.usernationality} helperText={errors.usernationality?.message} fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="userbloodgroup"
                control={control}

                render={({ field }) => (
                  <TextField focused color="warning" {...field} label="Blood Group" error={!!errors.userbloodgroup} helperText={errors.userbloodgroup?.message} fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="useremail"
                control={control}

                render={({ field }) => (
                  <TextField focused color="warning" {...field} label="Email" error={!!errors.useremail} helperText={errors.useremail?.message} fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="usermobile"
                control={control}

                render={({ field }) => (
                  <TextField focused color="warning" {...field} type='number' label="Mobile" error={!!errors.usermobile} helperText={errors.usermobile?.message} fullWidth />
                )}
              />
            </Grid>
            {/* Present Address */}
            <Grid item xs={12}>
              <Typography variant="h5" className='box2' sx={{ fontWeight: "700" }}>Present Address</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="AddressLine1"
                control={control}

                render={({ field }) => (
                  <TextField focused color="warning" {...field} label="Address Line 1" error={!!errors.AddressLine1} helperText={errors.AddressLine1?.message} fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="AddressLine2"
                control={control}

                render={({ field }) => (
                  <TextField focused color="warning" {...field} label="Address Line 2" fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="userpincode"
                control={control}

                render={({ field }) => (
                  <TextField focused color="warning" {...field} label="Pincode" error={!!errors.userpincode} helperText={errors.userpincode?.message} fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="usercity"
                control={control}

                render={({ field }) => (
                  <TextField focused color="warning" {...field} label="City" error={!!errors.usercity} helperText={errors.usercity?.message} fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="userstate"
                control={control}

                render={({ field }) => (
                  <TextField focused color="warning" {...field} label="State" error={!!errors.userstate} helperText={errors.userstate?.message} fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="usercountry"
                control={control}

                render={({ field }) => (
                  <TextField focused color="warning" {...field} label="Country" error={!!errors.usercountry} helperText={errors.usercountry?.message} fullWidth />
                )}
              />
            </Grid>
            {/* <Grid xs={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: {
                  xl: "row",
                  lg: "row",
                  md: "row",
                  sm: "column",
                  xs: "column",
                },
                gap: "2rem",
              }}
            >
              <Typography sx={{ fontWeight: "550" }}>
                Is Permanent Address same as Present ?
              </Typography>

              <Button
                variant="output"
                sx={{
                  border: "1px solid #EF5835",
                  "&:hover": { background: "#EF5835" },
                }}
              >
                Yes
              </Button>
              <Button
                variant="output"
                sx={{
                  border: "1px solid #EF5835",
                  "&:hover": { background: "#EF5835" },
                }}
              >
                No
              </Button>
            </Box>
          </Grid> */}
            {/* Family Details */}
            <Grid item xs={12}>
              <Typography variant="h5" className='box2' sx={{ fontWeight: "700" }}>Family Details</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="fathername"
                control={control}

                render={({ field }) => (
                  <TextField focused color="warning" {...field} label="Father's Name" error={!!errors.fathername} helperText={errors.fathername?.message} fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="mothername"
                control={control}

                render={({ field }) => (
                  <TextField focused color="warning" {...field} label="Mother's Name" error={!!errors.mothername} helperText={errors.mothername?.message} fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="fatherqualification"
                control={control}

                render={({ field }) => (
                  <TextField focused color="warning" {...field} label="Father's Qualification" fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="motherqualification"
                control={control}

                render={({ field }) => (
                  <TextField focused color="warning" {...field} label="Mother's Qualification" fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="parentemail"
                control={control}

                render={({ field }) => (
                  <TextField focused color="warning" {...field} label="Parent's Email" error={!!errors.parentemail} helperText={errors.parentemail?.message} fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="parentmobile"
                control={control}

                render={({ field }) => (
                  <TextField focused color="warning" {...field} type="number" label="Parent's Mobile" error={!!errors.parentmobile} helperText={errors.parentmobile?.message} fullWidth />
                )}
              />
            </Grid>
            {/* Entrance Exam Scores */}
            <Grid item xs={12}>
              <Typography variant="h5" className='box2' sx={{ fontWeight: "700" }}>Entrance Exam Scores</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="userexam"
                control={control}

                render={({ field }) => (
                  <TextField focused color="warning" {...field} label="Exam Name" fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="userpercentile"
                control={control}

                render={({ field }) => (
                  <TextField focused color="warning" {...field} label="Percentile" fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="userscore"
                control={control}

                render={({ field }) => (
                  <TextField focused color="warning" {...field} label="Score" fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="userexam2"
                control={control}

                render={({ field }) => (
                  <TextField focused color="warning" {...field} label="Exam Name 2" fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="userpercentile2"
                control={control}

                render={({ field }) => (
                  <TextField focused color="warning" {...field} label="Percentile 2" fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="userscore2"
                control={control}

                render={({ field }) => (
                  <TextField focused color="warning" {...field} label="Score 2" fullWidth />
                )}
              />
            </Grid>
            {/* Academic Qualification Acadmic1 */}
            <Grid item xs={12}>
              <Typography variant="h5" className='box2' sx={{ fontWeight: "700" }}>Academic Qualification</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" className='box2' sx={{ fontWeight: "500" }}>Post Graduation</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="nameofinstitution"
                control={control}

                render={({ field }) => (
                  <TextField focused color="warning" {...field} label="Name of Institution" fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="nameofuniversity"
                control={control}

                render={({ field }) => (
                  <TextField focused color="warning" {...field} label="Name of University/Board" fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="yearofpassing"
                control={control}

                render={({ field }) => (
                  <TextField focused color="warning" {...field} label="Year of Passing" fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="remarks"
                control={control}

                render={({ field }) => (
                  <TextField focused color="warning" {...field} label="Remarks" fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="agg"
                control={control}

                render={({ field }) => (
                  <TextField focused color="warning" {...field} label="Aggregate" fullWidth />
                )}
              />
            </Grid>
            {/* Graduation fileds/ */}
            <Grid item xs={12}>
              <Typography variant="h6" className='box2' sx={{ fontWeight: "500" }}>Graduation</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="nameofGraduationinstitution"
                control={control}

                render={({ field }) => (
                  <TextField focused color="warning" {...field} label="Name of Institution" fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="nameofGraduationuniversity"
                control={control}

                render={({ field }) => (
                  <TextField focused color="warning" {...field} label="Name of University/Board" fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="yearofGraduationpassing"
                control={control}

                render={({ field }) => (
                  <TextField focused color="warning" {...field} label="Year of Passing" fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="remarksGraduation"
                control={control}

                render={({ field }) => (
                  <TextField focused color="warning" {...field} label="Remarks" fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="Graduationagg"
                control={control}

                render={({ field }) => (
                  <TextField focused color="warning" {...field} label="Aggregate" fullWidth />
                )}
              />
            </Grid>

            {/* 12 class / */}
            <Grid item xs={12}>
              <Typography variant="h6" className='box2' sx={{ fontWeight: "500" }}>12th Class</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="nameoftwelvethinstitution"
                control={control}

                render={({ field }) => (
                  <TextField focused color="warning" {...field} label="Name of Institution" fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="nameoftwelvethuniversity"
                control={control}

                render={({ field }) => (
                  <TextField focused color="warning" {...field} label="Name of University/Board" fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="yearoftwelvethpassing"
                control={control}

                render={({ field }) => (
                  <TextField focused color="warning" {...field} label="Year of Passing" fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="remarkstwelveth"
                control={control}

                render={({ field }) => (
                  <TextField focused color="warning" {...field} label="Remarks" fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="twelvethagg"
                control={control}

                render={({ field }) => (
                  <TextField focused color="warning" {...field} label="Aggregate" fullWidth />
                )}
              />
            </Grid>

            {/* 10 class / */}
            <Grid item xs={12}>
              <Typography variant="h6" className='box2' sx={{ fontWeight: "500" }}>10th Class</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="nameoftenthinstitution"
                control={control}

                render={({ field }) => (
                  <TextField focused color="warning" {...field} label="Name of Institution" fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="nameoftenthuniversity"
                control={control}

                render={({ field }) => (
                  <TextField focused color="warning" {...field} label="Name of University/Board" fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="yearoftenthpassing"
                control={control}

                render={({ field }) => (
                  <TextField focused color="warning" {...field} label="Year of Passing" fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="remarkstenth"
                control={control}

                render={({ field }) => (
                  <TextField focused color="warning" {...field} label="Remarks" fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="tenthagg"
                control={control}

                render={({ field }) => (
                  <TextField focused color="warning" {...field} label="Aggregate" fullWidth />
                )}
              />
            </Grid>

            {/* others Qualification / */}
            <Grid item xs={12}>
              <Typography variant="h6" className='box2' sx={{ fontWeight: "500" }}>Other Qualification</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="nameofothersinstitution"
                control={control}

                render={({ field }) => (
                  <TextField focused color="warning" {...field} label="Name of Institution" fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="nameofothersuniversity"
                control={control}

                render={({ field }) => (
                  <TextField focused color="warning" {...field} label="Name of University/Board" fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="yearofotherspassing"
                control={control}

                render={({ field }) => (
                  <TextField focused color="warning" {...field} label="Year of Passing" fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="remarksothers"
                control={control}

                render={({ field }) => (
                  <TextField focused color="warning" {...field} label="Remarks" fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="othersagg"
                control={control}

                render={({ field }) => (
                  <TextField focused color="warning" {...field} label="Aggregate" fullWidth />
                )}
              />
            </Grid>
            <Grid mt={10} container>
              <Grid item xl={12} lg={10} md={10} sm={12} xs={12}>
                <Box>
                  <Typography
                    mt={0}
                    className="box2"
                    sx={{
                      fontSize: {
                        xl: "1.5rem",
                        lg: "1.5rem",
                        sm: "2rem",
                        xs: "1.5rem",
                      },
                      fontWeight: "650",
                      // paddingLeft: { xl: "8.9%", lg: "11%", md: "11%" },
                      textAlign: {
                        xl: "start",
                        lg: "start",
                        md: "start",
                        sm: "center",
                        xs: "center",
                      },
                    }}
                  >
                    Upload Document
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    mt={2}
                    className="box2"
                    sx={{
                      fontSize: {
                        xl: "1rem",
                        lg: "1.5rem",
                        sm: "2rem",
                        xs: "0.8rem",
                      },
                      fontWeight: "650",
                      color: "grey",
                      // paddingLeft: { xl: "8.9%", lg: "11%", md: "11%" },
                      textAlign: {
                        xl: "start",
                        lg: "start",
                        md: "start",
                        sm: "center",
                        xs: "center",
                      },
                    }}
                  >
                    Document in PDF, JPEG, DOC Formats, Upload only 5 documents
                    each document of size 1 MB
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                xl={12}
                lg={10}
                md={10}
                sm={12}
                xs={12}
                sx={{ mt: { xl: "5rem", xs: "0rem" } }}
              >
                <Box
                  sx={{
                    position: "relative",
                    width: "100%",
                    maxWidth: "90%",
                    height: "30vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "30px auto",
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      border: "4px dashed #EF5835",
                      borderRadius: "25px",
                      width: { xl: "100%", xs: "100%" },
                      height: { xl: "50vh", xs: "30vh" },
                    }}
                  />
                  <Controller
                    name="document"
                    control={control}
                    render={({ field }) => (
                      <>
                        <Button
                          className="box2"
                          sx={{
                            background: "#EF5835",
                            textTransform: "none",
                            fontSize: { xl: "20px", xs: "10px" },
                            width: "25%",
                            borderRadius: "2rem",
                            "&:hover": {
                              background: "#fff",
                              color: "#EF5835",
                              border: "1px solid #EF5835",
                            },
                          }}
                          component="label"
                          role={undefined}
                          variant="contained"
                          tabIndex={-1}
                        >
                          Upload
                          <VisuallyHiddenInput
                            type="file"
                            multiple
                            onChange={(e) => field.onChange(e.target.files)}
                          />
                        </Button>
                        {errors.document && (
                          <Typography color="error" variant="body2">
                            {errors.document.message}
                          </Typography>
                        )}
                      </>
                    )}
                  />
                </Box>
              </Grid>
            </Grid>

            {/* Upload Profile Picture */}
            <Grid mt={10} container>
              <Grid item xl={12} lg={10} md={10} sm={12} xs={12}>
                <Box>
                  <Typography
                    mt={0}
                    className="box2"
                    sx={{
                      fontSize: {
                        xl: "1.5rem",
                        lg: "1.5rem",
                        sm: "2rem",
                        xs: "1.5rem",
                      },
                      fontWeight: "650",
                      // paddingLeft: { xl: "8.9%", lg: "11%", md: "11%" },
                      textAlign: {
                        xl: "start",
                        lg: "start",
                        md: "start",
                        sm: "center",
                        xs: "center",
                      },
                    }}
                  >
                    Upload Profile Picture
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    mt={2}
                    className="box2"
                    sx={{
                      fontSize: {
                        xl: "1rem",
                        lg: "1.5rem",
                        sm: "2rem",
                        xs: "0.8rem",
                      },
                      fontWeight: "650",
                      color: "grey",
                      // paddingLeft: { xl: "8.9%", lg: "11%", md: "11%" },
                      textAlign: {
                        xl: "start",
                        lg: "start",
                        md: "start",
                        sm: "center",
                        xs: "center",
                      },
                    }}
                  >
                    Document in PNG, JPEG, GIF Formats, document of size 10 MB
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                xl={12}
                lg={10}
                md={10}
                sm={12}
                xs={12}
                sx={{ mt: { xl: "5rem", xs: "0rem" } }}
              >
                <Box
                  sx={{
                    position: "relative",
                    width: "100%",
                    maxWidth: "90%",
                    height: "30vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "30px auto",
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      border: "4px dashed #EF5835",
                      borderRadius: "25px",
                      width: { xl: "100%", xs: "100%" },
                      height: { xl: "50vh", xs: "30vh" },
                    }}
                  />
                  <Controller
                    name="file"
                    control={control}
                    render={({ field }) => (
                      <>
                        <Button
                          className="box2"
                          sx={{
                            background: "#EF5835",
                            textTransform: "none",
                            fontSize: { xl: "20px", xs: "10px" },
                            width: "25%",
                            borderRadius: "2rem",
                            "&:hover": {
                              background: "#fff",
                              color: "#EF5835",
                              border: "1px solid #EF5835",
                            },
                          }}
                          component="label"
                          role={undefined}
                          variant="contained"
                          tabIndex={-1}
                        >
                          Upload
                          <VisuallyHiddenInput
                            type="file"
                            onChange={(e) => field.onChange(e.target.files)}
                          />
                        </Button>
                        {errors.file && (
                          <Typography color="error" variant="body2">
                            {errors.file.message}
                          </Typography>
                        )}
                      </>
                    )}
                  />
                </Box>
              </Grid>
            </Grid>
            <Box sx={{ margin: "80px 30px" }}>

              <Box display="flex" alignItems="flex-start" mt={2}>
                <Controller
                  name="declaration"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => <Checkbox {...field} color="error" />}
                />
                <Typography variant="body2" color={errors.declaration ? 'error' : 'textSecondary'} sx={{ ml: 1 }}>
                  I understand that this resignation is only to express my willingness to join the college and course specified here above.
                  The admission procedures are exclusive of this and will abide by the rules of admission into the specified college and course.
                  I also declare that the information given here is true to the best of my knowledge. I am aware that the application fee is non-refundable.
                </Typography>
              </Box>
              {errors.declaration && (
                <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                  You must agree to the declaration.
                </Typography>
              )}
            </Box>

            <Grid item xs={12}>
              <Box sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mb: { md: "5rem", xs: "2rem" },
                mt: { md: "3rem", xs: "-0.10rem" }
              }}>
                <Button variant="contained"
                  type="submit"
                  sx={{

                    background: "#EF5835",
                    width: { xl: "300px", xs: "150px" },
                    height: "50px",
                    borderRadius: "16px",
                    "&:hover": {
                      background: "#fff",
                      border: "1px solid #EF5835",
                      color: "#EF5835",
                    },
                  }}>
                  Submit
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </form>
    </>
  );
};

export default FormComponent;
