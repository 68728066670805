const AbbsRankings = [
    {
        header: "Ranking over the years",
        data: [
            { title: "Rank 2023", value: "NP" },
            { title: "Rank 2022", value: "NP" },

        ]
    },


];

export default AbbsRankings;
