import { Box, Container, Tabs, Tab, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ExamDetailContent from "../../EntranceExam/ExamDetails/ExamDetailContent";
import IITDelhiAboutData from "../../../json/CollegeDetailsJson/IITDelhiAboutData";
import IITDelhiAboutHeader from "../../../json/CollegeDetailsJson/IITDelhiAboutHeader";
import IITDelhiCourse from "../../../json/CollegeDetailsJson/IITDelhiCourse";
import SingleHeaderDynamicTable from "../../../components/TableComponent/SingleheaderTable";
import IITDelhiFeeData from "../../../json/CollegeDetailsJson/IItDelhifeeData";
import IITDelhiFeeHeader from "../../../json/CollegeDetailsJson/IItDelhifeeHeader";
import IITDelhiRankings from "../../../json/CollegeDetailsJson/IITDelhiRanking";
import IITDelhiAdmissionContent from "../../../json/CollegeDetailsJson/IITDelhiAdmissionContent";
import StripHeaderComponent from "../../../components/StripHeadercomponent";
import StripTitle from "../../../components/StripTitle";
import IITDelhiPlacements from "../../../json/CollegeDetailsJson/IITDelhiPacement";
import IITDelhiInternship from "../../../json/CollegeDetailsJson/IITDelhiInternShip";
import IITDelhiIncubation from "../../../json/CollegeDetailsJson/IITDelhiIncubation";
import HeaderDynamicTable from "../../../components/TableComponent/HeaderDynamicTable";
import IITDelhiAdmission from "../../../json/CollegeDetailsJson/IITDelhiAdmission";

const CollegeTabDetails = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <>
      <Box sx={{ backgroundColor: "#252525" }}>
        <Container>
          <Box sx={{ width: "100%" }}>
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              aria-label="scrollable auto tabs example"
              sx={{
                fontSize: "13px",
                // mt: "40px",
                "& .MuiTab-root": {
                  // fontSize: "13px",
                  color: "#898383",

                  "&.Mui-selected": {
                    color: "#EF5835",
                  },
                },
                "& .MuiTabs-indicator": {
                  display: "none",
                },
                "& .MuiTabs-flexContainer": {
                  display: "flex",
                  justifyContent: "space-around",
                },
                "& .MuiTabScrollButton-root": {
                  color: "#ffffff", // Change color of scroll buttons to white
                },
              }}
            >
              <Tab label="About the College" />
              <Tab label="Admission" />
              <Tab label="Courses and Fee" />
              <Tab label="Placements" />
              <Tab label="Rankings" />
            </Tabs>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                flexWrap: "wrap",
              }}
            >
              {selectedTab === 0 && (
                <ExamDetailContent
                  color={"#ffffff"}
                  showHeader={true}
                  header={"About the College"}
                  headerContent={
                    "IIT Delhi’s 320-acre campus is located in the heart of the capital, about 10 kilometres from Indira Gandhi International Airport. Offering undergraduate, postgraduate and doctoral programmes in several disciplines, the institute is home to several centres of excellence established for research and development. A fully-residential institute, IIT Delhi’s infrastructure includes hostels for male and female students (equipped with dining facilities), indoor and outdoor sports facilities and a hospital. Academic infrastructure includes several well-equipped laboratories under each of the departments and a large central library stocked with print and digital resources and offering access to academic networks. In terms of placements, the institute says notable recruiters include Honeywell, Airbus, Boeing, ONGC, L&T, the Tata Group, Google and Microsoft, among several others. The institute’s office of career services says notable recruiters include American Express, Samsung, Goldman Sachs, HCL and Microsoft, among several others."
                  }
                  title={"Details"}
                  DynamicTabledata={IITDelhiAboutData}
                  DynamicTableheader={IITDelhiAboutHeader}
                  gapcolor={"#252525"}
                />
              )}
              {selectedTab === 1 && (
                <Box sx={{ width: "100%" }}>
                  <Box sx={{ padding: "10px 0px" }}>
                    {IITDelhiAdmissionContent?.map((item) => (
                      <StripHeaderComponent
                        data={item}
                        margin={"10px"}
                        color={"#ffffff"}
                      />
                    ))}
                  </Box>
                  <StripTitle title={"Cut Off:"} />
                  {IITDelhiAdmission?.map((item) => (
                    <HeaderDynamicTable
                      data={item}
                      margin={"10px"}
                      showheader3={false}
                      header1={"Branch"}
                      header2={"IIT Delhi JEE Advanced Cutoff"}
                      subheader1={"Opening rank 2021"}
                      subheader2={"Opening rank 2020"}
                    />
                  ))}
                </Box>
              )}
              {selectedTab === 2 && (
                <>
                  <Box
                    sx={{
                      justifyContent: "center",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <StripTitle title={"College Courses"} />

                    {IITDelhiCourse?.map((item) => (
                      <SingleHeaderDynamicTable data={item} margin={"10px"} />
                    ))}
                    <ExamDetailContent
                      color="#ffffff"
                      title={"Fees"}
                      DynamicTabledata={IITDelhiFeeData}
                      DynamicTableheader={IITDelhiFeeHeader}
                    />
                  </Box>
                </>
              )}
              {selectedTab === 3 && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <StripTitle title={"Placements"} />
                  {IITDelhiPlacements?.map((item) => (
                    <SingleHeaderDynamicTable
                      data={item}
                      margin={"10px"}
                      showheader={false}
                    />
                  ))}
                  <StripTitle title={"Internships"} />
                  {IITDelhiInternship?.map((item) => (
                    <SingleHeaderDynamicTable
                      data={item}
                      margin={"10px"}
                      showheader={false}
                    />
                  ))}
                  <StripTitle title={"Incubation Center"} />
                  {IITDelhiIncubation?.map((item) => (
                    <SingleHeaderDynamicTable
                      data={item}
                      margin={"10px"}
                      showheader={false}
                    />
                  ))}
                </Box>
              )}
              {selectedTab === 4 && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      mt: "20px",
                      mb: "20px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        color: "#ffffff",
                        fontWeight: "550",
                        fontSize: "16px",
                        position: "relative",
                        "&::before": {
                          content: "''",
                          position: "absolute",
                          left: "-10px",

                          width: "5px",
                          height: "20px",
                          backgroundColor: "#EF5835",
                        },
                      }}
                    >
                      {"Rankings"}
                    </Typography>
                  </Box>
                  {IITDelhiRankings?.map((item) => (
                    <SingleHeaderDynamicTable
                      data={item}
                      margin={"10px"}
                      headercolor={"#ffffff"}
                    />
                  ))}
                </Box>
              )}
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default CollegeTabDetails;
