const AiimsAboutHeader = [
    { id: 1, label: "Address", var: "address" },
    { id: 2, label: "City", var: "city" },
    { id: 3, label: "State", var: "state" },
    { id: 4, label: "Email", var: "email" },
    { id: 5, label: "Year established", var: "established" },
    { id: 6, label: "Year first MBBS batch graduated", var: "btechgraduated" },
    { id: 7, label: "Head of the Institution", var: "head" },
    { id: 7, label: "Designation of Head of the Institution", var: "designation" },
    { id: 8, label: "Institute type", var: "type" },
    { id: 9, label: "Website", var: "website" },
    { id: 10, label: "Anti-ragging cell", var: "raggingcell" },
    { id: 11, label: "National Service Scheme unit", var: "nationalservice" },
    { id: 12, label: "Internal Complaints Committee cell", var: "complaintcell" },
    { id: 13, label: "Internal Quality Assurance Cell", var: "qualityassurance" },
    { id: 14, label: "Total number of MBBS students graduated since the college was established", var: "noofgraduates" },

];

export default AiimsAboutHeader;
