import { Icon } from "@iconify/react/dist/iconify.js";
import { Box, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from 'react-router'
import '../../careerplatform.css'
import fileimage from '../../assets/examfileimage.png'


const ExamCard = ({
    data,
    hoverHeight,
    cardWidth,
    cardHeight,

}) => {

    const navigate = useNavigate();
    return (
        <Box
            sx={{
                width: cardWidth ?? "200px",
                height: cardHeight ?? "200px",
                position: "relative",
                padding: "0 15px",
                backgroundColor: "#ffdbcc",
                backgroundImage: `url(${fileimage})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "90px",
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                borderRadius: "10px",
                "&:hover .childBox": {
                    height: hoverHeight ?? "22%",
                },
                cursor: "pointer",
                overflow: "hidden",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",

            }}
            onClick={() => navigate(data.path)}
        >

            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",


                    // pt: "15px "
                }}
            >


                <Typography
                    variant="h5"
                    className="box1"
                    sx={{
                        fontSize: "22px",
                        fontWeight: "700",
                        display: "flex",
                        justifyContent: "center",
                        textAlign: "center",
                        color: "#FF4D00"
                    }}
                >
                    {data?.name}
                </Typography>
            </Box>
            <Box
                className="childBox"
                sx={{
                    position: "absolute",
                    left: "0",
                    bottom: "0",
                    width: "100%",
                    height: "22%",
                    backgroundColor: "#FF4D00",
                    borderRadius: "30px 30px 10px 10px",
                    color: "#ffffff",
                    padding: "15px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",

                    transition: "all 0.5s linear",
                    "&:hover": {
                        height: hoverHeight ?? "22%",
                    },
                }}
            >


                <Typography
                    variant="h1"
                    fontSize="16px" className="box1" fontWeight={"600"}
                >
                    {data?.title}
                </Typography>
                <Icon icon="prime:chevron-circle-right" fontSize={"30px"} />

            </Box>
        </Box>
    );
};

export default ExamCard;
