import { Box, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import FlipCard from '../../components/Card/FlipCard'
import OverseasKeyData from './OverseasKeyJson'
import '../../careerplatform.css'

const OverseasKey = () => {
    return (
        <Box sx={{ padding: "50px 0", }}>
            <Container>

                <Grid container
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    direction="column"
                >
                    <Typography
                        color="#00999e"
                        className='box1'
                        textAlign="center" sx={{ fontWeight: "650", fontSize: "28px", color: "#ef5835", pb: "20px" }}>
                        Key Features
                    </Typography>



                    <Container
                        style={{
                            height: "100%",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    ><Box
                        style={{
                            gap: "2rem",
                            justifyContent: "center",
                            margin: "auto",
                        }}

                    > <Grid container display={"flex"} justifyContent={"center"} spacing={2}>

                                {
                                    OverseasKeyData.map((card) => (
                                        <Grid item md={3} xs={12} sm={6} sx={{ display: "flex", justifyContent: "center" }}>
                                            <FlipCard data={card} />

                                        </Grid>
                                    ))
                                }
                            </Grid>
                        </Box>
                    </Container>
                </Grid>

            </Container>

        </Box>
    )
}

export default OverseasKey
