import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import React from "react";

const Addmition = () => {
  return (
    <Box sx={{ background: "#EF5835" }}>
      <Container>
        <Grid>
          <Box
            py={{ lg: 15, md: 10, sm: 7, xs: 4 }}
            sx={{
              marginTop: {
                xl: "-4px",
                lg: "-0.3%",
                md: "-5px",
                sm: "-0.3rem",
                xs: "25rem",
              },
            }}
          >
            <Typography
              className="box1"
              variant="h3"
              sx={{ color: "#fff", textAlign: "center", fontWeight: 700 }}
            >
              “Get Admission to your desired
              <br />
              College right now”
            </Typography>
          </Box>

          <Box
            // mt={15}
            sx={{
              color: "#fff",
              display: "flex",
              justifyContent: "center",
              textAlign: { xs: "center" },
              gap: { xl: "16%", lg: "10%", md: "10%" },
              flexDirection: {
                xl: "row",
                lg: "row",
                md: "row",
                sm: "column",
                xs: "column",
              },
              paddingBottom: "5%",
            }}
          >
            <Box>
              <Typography mt={2}>
                <Divider
                  sx={{
                    width: {
                      xl: "110%",
                      lg: "100%",
                      backgroundColor: "white",
                      fontWeight: "bold",
                    },
                  }}
                />
              </Typography>
              <Typography
                mt={5}
                className="box1"
                sx={{
                  fontSize: { xl: "1.5rem", lg: "1.5rem", xs: "1rem" },
                  textAlign: {
                    xl: "start",
                    lg: "start",
                    md: "start",
                    xs: "center",
                  },
                }}
              >
                01.
              </Typography>
              <Typography
                mt={2}
                className="box2"
                sx={{
                  fontSize: { xl: "1.5rem", lg: "1.5rem", xs: "1rem" },
                  textAlign: {
                    xl: "start",
                    lg: "start",
                    md: "start",
                    xs: "center",
                  },
                }}
              >
                Placement Guarantee
              </Typography>
              <Typography
                mt={2}
                className="box2"
                sx={{
                  fontSize: { xl: "1.5rem", lg: "1.5rem", xs: "1rem" },
                  textAlign: {
                    xl: "start",
                    lg: "start",
                    md: "start",
                    xs: "center",
                  },
                }}
              >
                We make sure that we admit
                <br />
                students to those
                <br />
                colleges which provide 100%
                <br /> placements
              </Typography>
            </Box>
            <Box>
              <Typography mt={2}>
                <Divider
                  sx={{
                    width: { xl: "110%", lg: "100%" },
                    backgroundColor: "white",
                    fontWeight: "bold",
                  }}
                />
              </Typography>
              <Typography
                mt={5}
                className="box1"
                sx={{
                  fontSize: { xl: "1.5rem", lg: "1.5rem", xs: "1rem" },
                  textAlign: {
                    xl: "start",
                    lg: "start",
                    md: "start",
                    xs: "center",
                  },
                }}
              >
                02.
              </Typography>
              <Typography
                mt={2}
                className="box2"
                sx={{
                  fontSize: { xl: "1.5rem", lg: "1.5rem", xs: "1rem" },
                  textAlign: {
                    xl: "start",
                    lg: "start",
                    md: "start",
                    xs: "center",
                  },
                }}
              >
                Top Colleges all over India
              </Typography>
              <Typography
                mt={2}
                className="box2"
                sx={{
                  fontSize: { xl: "1.5rem", lg: "1.5rem", xs: "1rem" },
                  textAlign: {
                    xl: "start",
                    lg: "start",
                    md: "start",
                    xs: "center",
                  },
                }}
              >
                We focus only on reputed
                <br />
                educational institutions for
                <br />
                admissions of the students
                <br />
                who come to us
              </Typography>
            </Box>
            <Box>
              <Typography mt={2}>
                <Divider
                  sx={{
                    width: { xl: "110%", lg: "100%" },
                    backgroundColor: "white",
                    fontWeight: "bold",
                  }}
                />
              </Typography>
              <Typography
                mt={5}
                className="box1"
                sx={{
                  fontSize: { xl: "1.5rem", lg: "1.5rem", xs: "1rem" },
                  textAlign: {
                    xl: "start",
                    lg: "start",
                    md: "start",
                    xs: "center",
                  },
                }}
              >
                03.
              </Typography>
              <Typography
                mt={2}
                className="box2"
                sx={{
                  fontSize: { xl: "1.5rem", lg: "1.5rem", xs: "1rem" },
                  textAlign: {
                    xl: "start",
                    lg: "start",
                    md: "start",
                    xs: "center",
                  },
                }}
              >
                Get Local Guardian
              </Typography>
              <Typography
                mt={2}
                className="box2"
                sx={{
                  fontSize: { xl: "1.5rem", lg: "1.5rem", xs: "1rem" },
                  textAlign: {
                    xl: "start",
                    lg: "start",
                    md: "start",
                    xs: "center",
                  },
                }}
              >
                A Guardianship service to
                <br />
                monitor a student’s progress
                <br />
                and well-being, when the
                <br /> student is away.
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Container>
    </Box>
  );
};

export default Addmition;
