import { Box, Typography, Container, Tabs, Tab } from "@mui/material";
import React, { useState } from "react";
import NeetTableHeader from "../../json/NeetDetailsJson/NeetTableHeader";
import neetexamDetailContentJson from "../../json/NeetDetailsJson/NeetexamDetailContentJson";
import NeetDateTableHeader from "../../json/NeetDetailsJson/NeetDateTableHeader";
import NeetDateDataJson from "../../json/NeetDetailsJson/NeetDateDataJson";
import NeetApplicationProcessHeader from "../../json/NeetDetailsJson/NeetApplicationProcessHeader";
import NeetApplicationDataJson from "../../json/NeetDetailsJson/NeetApplicationDataJson";
import NeetEligibilityDataJson from "../../json/NeetDetailsJson/NeetEligibilityDataJson";
import NeetAdmitCardHeader from "../../json/NeetDetailsJson/NeetAdmitCardHeader";
import NeetAdmitCardDataJson from "../../json/NeetDetailsJson/NeetAdmitCardDataJson";
import NeetExamSyllabus from "../../json/NeetDetailsJson/NeetExamSyllabus";
import NeetAcceptingCollegesDataJson from "../../json/NeetDetailsJson/NeetAcceptingCollegesDataJson";
import ExamDetailContent from "../EntranceExam/ExamDetails/ExamDetailContent";
import StripTitle from "../../components/StripTitle";
import DualColumnDynamicTable from "../../components/TableComponent/TwoColumnTable";
import HeaderDynamicTable from "../../components/TableComponent/HeaderDynamicTable";
import ExamCuttoffData from "../../json/ExamCuttOff";
import AiletAcceptingCollegesHeader from "../../json/AiletAcceptingCollegesHeader";
import AiletEligibilityHeader from "../../json/AiletEligibilityHeader";

const NeetExamDetail = () => {
    const [selectedTab, setSelectedTab] = useState(0);

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };



    return (
        <>
            <Box>
                <Container sx={{ py: 3 }}>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: { md: "row", sm: "column", xs: "column" }
                        }}
                    >
                        <Typography
                            variant="h6"
                            sx={{ color: "#EF5835" }}
                            fontWeight={"500"}
                            fontSize={"30px"}
                        >
                            {"NEET"}
                        </Typography>
                        <Typography
                            variant="h6"
                            sx={{ color: "#000000" }}
                            fontWeight={"600"}
                            fontSize={"30px"}
                        >
                            {" "}
                            {"\u00A0\u00A0"}
                            {"2024 -"}
                        </Typography>
                        <Typography variant="h6" fontWeight={"300"} fontSize={"30px"}>
                            {"\u00A0\u00A0"}
                            Dates, Application Form, Cutoff, Eligibility, Syllabus
                        </Typography>
                    </Box>
                    <Tabs
                        value={selectedTab}
                        onChange={handleTabChange}
                        variant="scrollable"
                        scrollButtons
                        allowScrollButtonsMobile





                        aria-label="scrollable auto tabs example"
                        sx={{
                            mt: "40px", '& .MuiTab-root': {
                                // fontSize: "13px",
                                color: '#898383',


                                '&.Mui-selected': {


                                    color: "#EF5835",



                                },

                            },
                            '& .MuiTabs-indicator': {
                                display: 'none'
                            }
                        }}
                    >
                        <Tab label="About the Exam" />
                        <Tab label="Dates" />
                        <Tab label="Application Process" />
                        {/* <Tab label="Exams Cut off" /> */}
                        <Tab label="Eligibility" />
                        <Tab label="Admit Card" />
                        <Tab label="Syllabus" />
                        <Tab label="Accepting Colleges" />
                    </Tabs>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            flexWrap: "wrap"
                        }}
                    >
                        {selectedTab === 0 && (

                            <ExamDetailContent
                                showHeader={true}
                                header={"About the Exam"}
                                headerContent={<Typography sx={{width:"100%",display:"flex",justifyContent:"center",alignItems:"center",maxWidth:{md:"none",xs:"70%"},textAlign:{md:"none",xs:"justify"}}}>"The National Eligibility and Entrance Test (NEET) is the national level examination for admissions to all undergraduate medical courses in all medical institutions in India. This includes institutions like the AIIMS (All India Institutes of Medical Education), JIPMER (Jawaharlal Institute of Postgraduate Medical Education and Research) and so on, which formerly conducted their own entrance examinations.
                                NEET tests candidates on four subjects—Physics, Chemistry, Botany and Zoology—with two sections per subject, 35 compulsory questions and 10 optional ones to be chosen from a pool of 15. In terms of format, the exam is multiple choice, includes negative marking (a point lost for each incorrect answer and four points for each correct one), and is conducted in-person.
                                In 2022, the NEET examination was conducted on July 17. Per media reports, some 1.6 million candidates attempted the paper, out of a total 1.8 million that had registered. The exam was held across nearly 500 Indian cities and at 14 international testing centres. In the weeks leading to the exam, Indian social media recorded loud and prolonged protests by a section of students and candidates demanding a postponement."</Typography>}
                                title={"Details"}
                                DynamicTabledata={ neetexamDetailContentJson}
                                DynamicTableheader={NeetTableHeader}
                                // customIndices={[3, 6, 9, 12, 15]}


                            />
                        )}
                        {selectedTab === 1 && (
                            <ExamDetailContent
                                title={"Dates"}
                                DynamicTabledata={NeetDateDataJson}
                                DynamicTableheader={NeetDateTableHeader}

                            />
                        )}
                        {selectedTab === 2 && (
                            <ExamDetailContent
                                title={"Application Process"}
                                DynamicTabledata={NeetApplicationDataJson}
                                DynamicTableheader={NeetApplicationProcessHeader}
                            />
                        )}
                        {/* {selectedTab === 3 && (
                            <>
                                <Box
                                    sx={{
                                        justifyContent: "center", flexDirection: "column", width: '100%'
                                    }}
                                >

                                    <StripTitle title={"Exam Cut Off"} color={"#252525"} />
                                    {ExamCuttoffData?.map((item) => (
                                        <HeaderDynamicTable
                                            gapcolor={"transparent"}
                                            data={item}
                                            margin={"10px"}
                                            showsubheader={false}
                                            header1={"Category"}
                                            header2={"Cutoff marks and rank for BA LLB"}
                                            header3={"Cutoff marks and rank for LLM"}

                                        />
                                    ))}
                                </Box>
                            </>

                        )} */}
                        {selectedTab === 3 && (
                            <ExamDetailContent
                                title={"Exam Eligibility"}
                                DynamicTabledata={NeetEligibilityDataJson}
                                DynamicTableheader={AiletEligibilityHeader}
                            />
                        )}
                        {selectedTab === 4 && (
                            <ExamDetailContent
                                title={"Admit Card"}
                                DynamicTabledata={NeetAdmitCardDataJson}
                                DynamicTableheader={NeetAdmitCardHeader}
                            />
                        )}
                        {selectedTab === 5 && (
                            <>
                                <Box
                                    sx={{
                                        justifyContent: "center", flexDirection: "column", width: '100%'
                                    }}
                                >

                                    <StripTitle title={"Syllabus"} color={"#252525"} />
                                    {NeetExamSyllabus?.map((item) => (
                                        <DualColumnDynamicTable
                                            gapcolor={"transparent"}
                                            data={item}
                                            margin={"10px"}

                                            header1={"Syllabus and Subjects"}
                                            header2={"Physics, Chemistry and Biology"}


                                        />
                                    ))}
                                </Box>
                            </>

                        )}
                        {selectedTab === 6 && (
                            <ExamDetailContent
                                title={"Accepting Colleges"}
                                DynamicTabledata={NeetAcceptingCollegesDataJson}
                                DynamicTableheader={AiletAcceptingCollegesHeader}

                            />
                        )}
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default NeetExamDetail;
