const JeeAdvanceApplicationDataJson = {
    applicationMode: "Online",
    stepstoApply: [
        "Step 1: Visit JEE Advanced site",

        "Step 2: Login using JEE Mains application number and password",

        "Step 3: All the details in accordance with the JEE Main form will be already available just need to verify them and confirm the details, fill choice of examination cities (08 choices)",

        "Step 4: Upload the documents required",

        "Step 5: Pay registration fees",

        "Step 6: Download confirmation page"
    ],
    documentsRequired: [
        "PDF of Candidate’s Class X marksheet or birth certificate",
        "Class XII or equivalent marksheet",
        "Gazette notification showing change of name (if applicable)",
        "scribe request letter(if applicable)",
        "compensatory time request letter (if applicable)",
        "DS certificate (if applicable)",
        "OCI card/ foreign passport or citizenship certificate (if applicable)",
        "Category Certificate (if applicable)",
        "PwD Certificate (wherever applicable)",
    ],
    HowtofillapplicationForm: [
        "The candidates need not fill in all the details again as the details filled while registring for JEE Main will already be available and the candidates just need to verify them",
        "The candidates just need to fill in the prefernce of examination cities in 8 choices",
        "A password for future references (in respect of JEE Advance) should also be generated"],
    applicationFees: [
        "Male (Gen/Gen-EWS/OBC(NCL)): Rs.2800",
        'Female (Gen/Gen-EWS/OBC(NCL)): Rs. 1400',
        "Male & Female (SC/ST/PwD): Rs. 1400",
        "Candidates residing in SAARC countries: USD 75",
        "Candidates residing in non-SAARC countries: USD 150"
    ],
    feePayment: [
        "The fee can be submitted only online through Net Banking, Credit Card, Debit Card, UPI, or Paytm Services. Processing charges and GST as applicable are chargeable from the candidate (in addition to the examination fee) by the concerned Bank/Payment Gateway Integrator"],

    importantPoints: [
        "For Candidates seeking admission under the GEN-EWS category GEN-EWS certificate (FORM-GEN-EWS) should have been issued on or after April 01, 2022 in consonance with the latest guidelines of the Government of India, If any GEN-EWS candidate fails to submit the GEN-EWS certificate (issued on or after April 01, 2022) at the time of online registration, the candidate has to upload a declaration [Declaration in Lieu of GEN-EWS Certificate] to that effect.",
        "For Candidates seeking admission under the OBC-NCL category: OBC-NCL certificate (FORM-OBC-NCL) should have been issued on or after April 01, 2022 in consonance with the latest guidelines of the Government of India. If any OBC-NCL candidate fails to submit the OBCNCL certificate (issued on or after April 01, 2022) at the time of online registration, the candidate has to upload a declaration [Declaration in Lieu of OBC-NCL Certificate] to that effect.",
        "For Candidates seeking admission under the SC or ST category: Caste (for SC) or tribe (for ST) certificate (FORM-SC/ST).",
        "For Candidates seeking admission under the PwD category Physical Disability certificate (Unique Disability Identification (UDID) Certificate or FORM-PwD [II/III/IV, whichever is applicable]) issued by the notified medical authority.",
        "Dyslexic candidates need to submit FORM-DYSLEXIC 1 and 2 instead of FORM-PwD. The certificate must mention ‘SEVERE’ under Dyslexia category to enable the candidate to get the benefit of PwD category.",
        "Individual IITs constitute medical boards at the time of admission to test the candidate’s level of disability. The opinion of the medical board shall be final and on this basis, the benefit of PwD category may be allowed or withheld. If the benefit is withheld, the admission to the concerned IIT may be cancelled.",
        "For candidates opting for compensatory time: Letter to the Chairperson of the nearest Zonal Coordinating IIT by filling FORM-COMPENSATORY TIME along with the copy of the Disability Certificate.",
        "For Candidates needing the services of a scribe: Request letter to the Chairperson of the nearest Zonal coordinating IIT by filling FORM-SCRIBE",
        "For Candidates seeking admission under the DS category6: Certificate (FORM-DS) issued by respective record offices of Armed Forces Personnel",


    ],
    applicationformcorrection: ["Will be notified soon"],

};
export default JeeAdvanceApplicationDataJson;
