import React from 'react'
import Addmition from '../home/Footer/footer'
import { Box } from '@mui/material'
import Navbar from "../home/Header/Navbar";
import AmityUniversityTabDetails from './CollegeDetails/AmityUniversityTabdetails';
import AmityBanner from './CollegeDetails/AmityUniversityBanner';


const AmityUniversityDetails = () => {
    return (
        <div>
            <Navbar />
            <AmityBanner />
            <Box sx={{ backgroundColor: "#252525" }}>
                <AmityUniversityTabDetails />
                <Addmition margintop={"40px"} paddingtop={"-40px"} />
            </Box>

        </div>
    )
}

export default AmityUniversityDetails
