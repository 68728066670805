const IITDelhiRankings = [
    {
        header: "Ranking over the years",
        data: [
            { title: "Rank 2023", value: "1" },
            { title: "Rank 2022", value: "1" },
     
        ]
    },
    {
        header: "Rank among private/ government colleges",
        data: [
            { title: "G/P Rank 2023", value: "27,28,535" },
            { title: "G/P Rank 2022", value: "787" },
    
        ]
    }

];

export default IITDelhiRankings;
