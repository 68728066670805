const JeeAdvanceAdmitCardDataJson = {
    availabilityofadmitcard: [
        "August 23, 2022",
    ],

    officialwebsitetodownloadadmitcard: ["https://jeeadv.ac.in/"],

    particularsrequiredtodownloadadmitcard: [
        "Registration Number, Date of Birth, Mobile Number"
    ],

    importantdetailsavailableinadmitcard: [
        "Examination Schedule",
        "Roll NO, Candidate's Name",
        "Parent/ Guardian's Name",
        "Zone, Centre Code",
        "Date of Birth",
        "JEE MAIN Application Number",
        "Gender",
        "Category",
        "Nationality",
        "Address of Examination Centre",
        "Candidate's Photograph"
        , "Candidate's Signature"
    ],

    contactdetails: [
        "JEE (Advanced) Office, IIT Bombay, Mumbai - 400076",
        "Phone: +91-22-2576 4063 / +91-22-2576 9093",
        "Email: jeeadv2022@iitb.ac.in"
    ],

    compatiblebrowsersfordownloading: [
        "Internet Explorer, Firefox, Google Chrome"
    ],

    howtodownloadadmitcard: [
        "Step 1: Visit https://jeeadv.ac.in.",
        "Step 2: Click on the link for JEE Advanced 2022 ADMIT CARD, a window requiring login will be opened",
        "Step 3:The login can be done through registration number, date of birth and phone number",
        "Step 4: The JEE ADvanced 2022 Admit Card will appear on display, download, and print for future references"
    ],

    stepstodownloadadmitcard: [
        "Candidates with thick-soled shoes and clothes with large buttons will not be allowed to enter the examination centre",
        "Jewellery and metal items",
        "Personal belongings including electronic devices, mobile phones, calculators, smart watch etc.",
        "Candidates are prohibited from smoking or eating inside exam hall",
        "Slide rules, log tables, printed or written material, bits of papers, pager"
    ],

    itemsnotpermittedinsideexamcenter: [
        "Candidates with thick-soled shoes and clothes with large buttons will not be allowed to enter the examination centre",
        "Jewellery and metal items",
        "Personal belongings including electronic devices, mobile phones, calculators, smart watch etc",
        "Candidates are prohibited from smoking or eating inside exam hall",
        "Slide rules, log tables, printed or written material, bits of papers, pager"
    ],

    itemsallowedinsideexamcenter: [
        "Print copy of Admit Card along with Self Declaration duly filled in",
        "One passport size photograph (same as uploaded on the Online Application Form) for pasting on the specific space in the Attendance Sheet at Centre during the examination",
        "Any one of the authorized photo IDs (must be original, valid, and non-expired) – School Identity Card/ PAN card/ Driving License/ Voter ID/ Passport/ Aadhaar Card (With photograph)/E-Aadhaar with photograph/ Ration Card with photograph/ Class 12 Board Admit Card with photograph/ Bank Passbook with Photograph",
        "PwD certificate issued by the authorized medical officer, if claiming the relaxation under PwD category or PwD Certificate regarding physical limitation in an examination to write",
        "A transparent water bottle",
        'A transparent Ball Point Pen',
        'A sanitizer',
        'Diabetic patients can bring in sugar tablets or fruits. However, no packaged food like candy or chocolates will be allowed',
    ],

    guidelinesforadmitcard: [

        "Candidates must check their JEE Advance admit card carefully for their:",
        "Name",
        "Paper",
        "Date of Birth",
        "Gender",
        "Test Centre Name, City, State Code of Eligibility",
        "Category",
        "In case they find any discrepancy, immediately communicate to JEE Advance conducting authority and Chairman of JEE Advanced 2022 of the respective zonal coordinating IIT in case of any discrepancy. Candidates can also contact the Chairman of zonal IIT in case of any problem in downloading the JEE Advanced 2022 admit card."
    ],

    examdayguidelines: [
        "As a precaution for COVID-19, the candidate must reach centre as indicated against Reporting/Entry time at centre (as a precaution it advised to reach 2 hours before commencement of the examination)",
        "No candidate shall be permitted to enter after the Gate Closing Time",
        "All candidates are required to download and carefully read the Instructions and Advisory for COVID-19 given with the Admit Card and strictly adhere to them",
        'Before reaching the JEE Advanced exam centres, candidates must duly fill the self-declaration form',
        'No Candidate would be allowed to enter the Examination Centre, without Admit Card, Valid ID Proof, and proper frisking',
        'The candidate should ensure that the Question Paper available on the computer is as per his/her opted subject indicated in the Admit Card. In case, the subject of the Question Paper is other than his/her opted subject, the same may be brought to the notice of the Invigilator concerned',
        'In case a candidate, by furnishing false information, appears in more than one shift/date, his candidature will be cancelled, and his result will not be declared',
        'For those who are unable to appear on the scheduled date of test for any reason, re-test shall not be held under any circumstances',
        'Blank paper of rough work will be provided in the examination hall. Candidates must write their name and roll number at the top of their sheet. This sheet needs to be returned to the invigilator before leaving the examination hall',
        'Candidates need to enter the required details in the attendance sheet, put signature and paste photograph at the designated place and they need to ensure that their left thumb impression should be clear and not smudge on the attendance sheet.',
        'If the body temperature of a candidate is higher than COVID-19 norms, he or she will be required to take an exam in a separate room.',
        'Candidates will have to drop their Admit card as well as their rough papers in the box at the centres after displaying it to their invigilators',
        'No candidate shall be permitted to leave the Examination Room/ Hall before the end of the examination',
        'On completion of the examination, please wait for instructions from Invigilator and do not get up from your seat until advised. The candidates will be permitted to move out one at a time only.'
    ],
};
export default JeeAdvanceAdmitCardDataJson;
