const NationalAdmissionContent = [
  {
    title: "Eligibility Criteria:",
    content: [
      "Candidates must have passed 10+2 or equivalent examination with at least 45% marks in aggregate.",

      "For SC, ST, and PwD candidates, the eligibility percentage is 40%",
    ],
  },
  {
    title: "Admission Process",
    content: ["The selection of candidates is done basis the CLAT"],
  },
  {
    title: "How to Apply?",
    content: [
      "Visit the official website of NLSIU.",

      "Fill the application form",

      "Upload the self-attested copies of required documents",

      "Pay the application fee through the preferred mode",
    ],
  },
];

export default NationalAdmissionContent;
