const InsituteAdmissionContent = [
    {
      title: "Eligibility Criteria:",
      content: [
        "Minimum 45% aggregate in Class 12",
      ],
    },
    {
      title: "Admission Process",
      content: ["For admission to BA LLB and BCom LLB courses, candidates need to get valid scores in CLAT"],
    },
  ];
  
  export default InsituteAdmissionContent;
  