import React from "react";
import { createBrowserRouter } from "react-router-dom";
import HomePage from "../pages/home/HomePage";
import CollageListPage from "../pages/college/CollageListPage";
import EntranceExams from "../pages/EntranceExam/EntranceExams";
import ExamDetailPage from "../pages/EntranceExam/ExamDetails/ExamDetailPages";
import CollegeDetail from "../pages/college/CollegeDetailPage";
import Career from "../components/Career/career";
import ApplyOnline from "../components/ApplyOnline/applyOnline";
// import Local from "../components/Local/local";
import Form from "../components/Form/form";
import EngineeringCollage from "../pages/EngineeringCollage/EngineeringCollageListPage";
import ManagementCollage from "../pages/ManagementCollage/ManagementCollageListData";
import MedicalCollage from "../pages/MedicalCollage/MedicalCollageListData";
import GetLocal from "../components/Local/Local";
import PrivacyPoclicy from "../pages/PrivacyPoclicy";
import TermsAndConditions from "../pages/TermsAndCondition";
import JeeAdvanceDetail from "../pages/JeeExamDetail/JeeAdvance";
import JeeExamDetailPage from "../pages/JeeExamDetail/JeeExamDetailPage";
import NeetExamDetail from "../pages/NeetExamDetail/NeetExamDetail";
import ABBSCollegeDetails from "../pages/college/ABBSCollegeDetails";
import NationalCollegeDetail from "../pages/national/NationalCollegeDetail";
import InstituteCollegeDetail from "../pages/institute/InstitudeDetail";
import ChristianCollegeDetail from "../pages/Christian/ChristianDetaile";
import AmityUniversityDetails from "../pages/college/AmityUniversityDetails";
import AiimsDetails from "../pages/college/AiimsDetail";
import ReturnRefundPolicy from "../pages/ReturnPolicy";
import OverseasPage from "../pages/Overseas/OverseasPage";
import AboutUsPage from "../pages/AboutUs/AboutUsPage";

const appRouter = createBrowserRouter([
  { path: "/", element: <HomePage /> },
  { path: "/college-list", element: <CollageListPage /> },
  { path: "/examlist", element: <EntranceExams /> },
  { path: "/examdetails", element: <ExamDetailPage /> },
  { path: "/collegedetails", element: <CollegeDetail /> },
  { path: "/overseas-study", element: <OverseasPage /> },
  { path: "/aboutus", element: <AboutUsPage /> },
  { path: "/Abbscollegedetails", element: <ABBSCollegeDetails /> },
  { path: "/amityuniversity", element: <AmityUniversityDetails /> },
  { path: "/Aiims", element: <AiimsDetails /> },
  { path: "/career", element: <Career /> },
  { path: '/applyOnline', element: <ApplyOnline /> },
  { path: "/form", element: <Form /> },
  { path: "/engineeringcollege", element: <EngineeringCollage /> },
  { path: "/managementcollege", element: <ManagementCollage /> },
  { path: "/medicalcollege", element: <MedicalCollage /> },
  { path: "/getLocal", element: <GetLocal /> },
  { path: "/jeeExamDetail", element: <JeeExamDetailPage /> },
  { path: "/jeeAdvanceDetail", element: <JeeAdvanceDetail /> },
  { path: "/privacypolicy", element: <PrivacyPoclicy /> },
  { path: "/refundpolicy", element: <ReturnRefundPolicy /> },
  { path: "/termsandconditions", element: <TermsAndConditions /> },
  { path: "/neetexamdetail", element: <NeetExamDetail /> },
  { path: "/nationalcollegedetail", element: <NationalCollegeDetail /> },
  { path: "/institutecollegedetail", element: <InstituteCollegeDetail /> },
  { path: "/christiancollegedetail", element: <ChristianCollegeDetail /> },
]);

export default appRouter;
