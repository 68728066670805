const IITDelhiAdmissionContent = [
    {
        title: "Selection Criteria:",
        content: [
            "JEE Advanced",
            "JoSAA Counselling"

        ]
    },
    {
        title: "Eligibility Criteria:",
        content: [
            "50% aggregate in 10+2 or equivalent with Physics and Mathematics as compulsory subject or Diploma in Engineering"
        ]
    },
    {
        title: "Admission Process",
        content: [

            "Step 1: Visit the official website of IIT Delhi (www.iitd.ac.in). On its homepage, click on ‘Admissions’. From the drop-down menu   that appears, further, click on ‘Announcements’.",
            "Step 2: The admission brochure, admission pamphlet and prospectus can be downloaded from this page. Click on ‘Apply Now’ button to proceed further.",
            "Step 3: The registration page opens up. Click on ‘New User Register Here’. Fill the registration form with all the details, including name, email ID, contact number, course preference, etc. Also, create an alpha-numeric password for future logins",

            "Step 4: Further, fill in the remaining application form by addressing other details, including guardian info, address of communication, qualifications, etc.",

            "Step 5: Upload all the required documents in PDF format. Also, upload a passport-size photograph and signature and JPG/ JPEG format",

            "Step 6: Conclude the application process by paying an application fee using a debit card/ credit card/ net banking."
        ]
    },
    {
        title: "List of required documents:",
        content: [
            "Candidates need to submit scanned copies of the below-mentioned documents while filling IIT Delhi application form in online mode:",

            "Latest passport-size photograph",

            "First and last pages of Aadhar Card/ Voter ID Card/ Passport",

            "PAN Card",

            "Admission offer letter",

            "Entrance exam scorecard",

            "Qualifying degree certificate",

            "Qualifying exam mark sheet",

            "Category certificate (only in case of candidates belonging to SC/ ST/ OBC/ EWS/ PwD category)",

            "No objection certificate (only in case of part-time applicants)",

            "Sponsorship certificate/ Relieving letter (in case of full-time sponsored/ full-time working candidates)",

            "Medical certificate (in case of full-time candidates)"
        ]
    }
];

export default IITDelhiAdmissionContent;
