import React from 'react'
import Navbar from "../home/Header/Navbar";
import Addmition from "../home/Footer/footer";

import { Box, Container, Typography } from '@mui/material';
import OverseasBanner from './OverseasBanner';
import OverseasKey from './OverseasKey';
import OverseasValue from './OverseasValue';
import OverseasWhyChoose from './OverseasWhyChoose';
import '../../careerplatform.css'



const OverseasPage = () => {
    return (
        <>
            <Navbar />
            <OverseasBanner />
            <OverseasKey />
            <OverseasValue />
            <OverseasWhyChoose />
            {/* <Container> */}
            <Box sx={{ padding: "20px", bgcolor: "#ef5835" }}>
                <Container>

                    <Typography variant="body1" className="box1" sx={{ fontWeight: 'bold', color: "#fff", textAlign: "center", fontSize: "24px" }}>
                        Conclusion
                    </Typography>
                    <Typography variant="body2" className="box2" sx={{ color: "#fff", textAlign: "center", fontSize: "18px" }}>
                        Our Overseas Admission Consultancy simplifies the process of studying abroad, providing expert guidance and comprehensive support to help students achieve their educational dreams.
                    </Typography>
                </Container>

            </Box>
            {/* </Container> */}
            <Addmition margintop={"40px"} paddingtop={"-30px"} />

        </>
    )
}

export default OverseasPage
