import React, { useEffect } from "react";

import Navbar from "../home/Header/Navbar";
import Addmition from "../home/Footer/footer";

import CollegeBanner from "./CollegeDetails/CollegeBanner";
import CollegeTabDetails from "./CollegeDetails/CollegeTabdetails";
import { Box } from "@mui/material";

const CollegeDetail = () => {
    // const [value, setValue] = React.useState("");
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Navbar />
            <CollegeBanner />
            <Box sx={{ backgroundColor: "#252525" }}>
                <CollegeTabDetails />
                <Addmition margintop={"40px"} paddingtop={"40px"} />
            </Box>
        </>
    );
};

export default CollegeDetail;
