import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import Wellnessicon from "../../../assets/Group 45.png";

const Wellness = () => {
  return (
    <Box sx={{ background: "#EF5835", height: "auto", mt: { md: "-3rem" } }}>
      <Grid
        container
        direction={{ xs: "column", md: "row" }}
        alignItems="center"
        spacing={2}
      >
        <Grid
          item
          xs={12}
          md={6}

        >
          <Box sx={{
            // textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            ml: { xs: "6rem", md: "15rem" },
            maxWidth: { md: "50%", xs: "50%" },
            pt: { md: "2rem" },
            pb: { md: "2rem" }
          }}
          >
            <img
              src={Wellnessicon}
              alt="WellnessIcon"
              width="100%"
              height="auto"

            />
          </Box>

        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            textAlign: "left",
            marginTop: { xs: "10px", md: "0px" },
          }}
        >
          <Typography
          className="box2"
            sx={{
              color: "white",
              fontSize: { xl: "1.5rem", lg: "1.5rem", xs: "1rem" },
              fontFamily: "Nunito",
              lineHeight: "1.5",
              width: "70%"
            }}
          >
            When your child is away from home, you will want to know that they
            are safe and doing well. Career Platform offers a{" "}
            <span style={{ fontWeight: "bold" }}>guardianship service </span> to
            monitor a student's progress and well-being, and report to parents
            in their language.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Wellness;
