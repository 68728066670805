const AiletApplicationDataJson = {
    applicationMode: "Online",
    stepstoApply: ["Step 1: visit https://nationallawuniversitydelhi.in/",

        "Step 2: click 'register'.",

        "Step 3: submit name, mobile, email to register.",

        "Step 4:After successful registration, you will receive an application number/login Id. A password will also be sent by email/SMS",

        "Step 5: Select Course: before filling out application     form select the course you want to apply for- BA LLB (Hons.), LLM and PhD",

        "Step 6: Fill in the application form",

        "Step 7: Upload the reuired documents,",

        " Step 8: Fee Payment"],
    documentsRequired: ["Photograph (Max Size : 1 MB)",

        "Signature (Max Size : 1 MB)",

        "BPL certificate issued by the competitive authority (if you belong to BPL category) (Max Size: 2 MB)",

        "Passport (if you belong to a Foreign National category) (Max Size: 2 MB)"],
    HowtofillapplicationForm: ["The Application Form is divided into 4 sections:",

        "Personal Details: Applicant's Name, Date of Birth, Gender, Mobile Number, Email id, Category, Nationality,Religion, PwD details, Kashmiri Migrants or not,Belonging from Jammu & Kashmir or not"
        , "Center Choice and Contact Details: 3 choice of Centers, correspondence address"
        , "Family Details: Father's Name, Occupation , Mobile Number, Annnual Income, E-mail, Office address; Mother's Name, Occupation, Mobile number, Annual Income, E-mail, Office address",
        "Academic Qualification: Class 10th details, Class 12th details, Graduation details (in case of LLM and PhD)"
    ],
    applicationFees: ["3050 for all",
        "For SC/ST: 1050",
        "BPL in SC/ST are exempted from fee"],
    feePayment: ["The application fee can be submitted online using a debit card, credit card, net banking and UPI"],

    importantPoints: [
        "Incomplete application form will be summarily rejected and no request will be entertained in this regard.",
        "Photograph & signature of the Candidate:",
        "The candidate should upload his/her passport size photograph and coloured with white background. Size of image should not be more than 1 MB.",
        "Passport size photograph should not have cap, goggles etc. (Spectacles are allowed). The face of the candidate should cover about 75% of the photograph. The photograph must clearly show the face of the candidate in the preview box/page.",
        "Image should be .jpg/jpeg/png format.",
        "The candidate should also upload his/ her scanned signature. Signature should be clear and without overwriting on white paper sheet within a box 6x3 cms (width*height). Size of image should not be more than 1MB.",
        "Candidate signature should be clear and without overwriting on a white paper with Black/Blue pen within a box of 6x3cms (width*height) and clearly visible in the preview box/ page",
        "The scanned signature should be in .jpg/jpeg/png format only.",
        "The candidate should keep two identical photographs with him/ her, in reserve, which may be used for pasting on the Admit Card at the time of Entrance Test/ Counselling/ Admission if required.",
        "The candidate will be solely responsible for all the consequences arising out of any error or omission in the Application Form.",
        "Before submission of online application, candidates are requested to check their details entered in the form. Once you have submitted the online application, you will be able to check the details by clicking VIEW SUBMITTED APPLICATION form option. If you have submitted the incorrect details in the form, you can delete the application form and fill it once again and submit the form.",
        "Please note, in case you have selected option YES to BPL category under the category SC/ST, you will not be able to delete the application form once submitted.",
        "Candidate will be required to produce the following documents/certificate in original at the time of admission/counselling process:",
        "In case of B.A.LL.B.(Hons): Marks Statement of Class XII and X.",
        "Latest Character Certificate",
        "Transfer Certificate",
        "Valid SC/ST/OBC/EWS/ Persons with Disability / Kashmiri Migrant/ Resident of J&K Certificate (wherever applicable)",

    ],

};
export default AiletApplicationDataJson;
