const NeetAdmitCardDataJson = {
  availabilityofadmitcard: ["To be announced"],

  officialwebsitetodownloadadmitcard: ["https://neet.nta.nic.in/"],

  particularsrequiredtodownloadadmitcard: [
    "Application Number and Date of Birth",
  ],

  importantdetailsavailableinadmitcard: [
    "Name of the Candidate",
    "Father’s name",
    "Date of birth",
    "Gender",
    "Application number",
    "Roll number",
    "QR code",
    "Category of applicant",
    "Sub-category",
    "NEET 2022 exam date and time",
    "Reporting time and time for last entry",
    "Address of aspirant",
    "Language (medium) of question paper",
    "NEET exam centre name and address",
    "Exam centre number",
    "Passport size photograph (uploaded in the application form)",
    "Signature (uploaded during registration)",
    "Space to paste passport size photograph (same as used during registration)",
    "Signature of the candidate (to be done in the exam hall in front of the invigilator)",
    "Signature of parent",
    "NEET-UG senior director signature",
    "Other general instructions to be followed",
  ],

  contactdetails: [
    "Address - C-20 1A/8, Sector-62, IITK outreach centre, Noida-201 309",
    "Phone number - 8076535482 and 7703859909",

    "Email ID - neet@nta.ac.in",
  ],

  compatiblebrowsersfordownloading: [
    "Internet Explorer, Google Chrome, Firefox",
  ],

  howtodownloadadmitcard: [
    "Step 1: Visit the official website of NTA, neet.nta.nic.in",
    "Step 2 : Click on the ‘NEET UG 2022 admit card’ link",
    "Step 3: Log in to the Applicant portal",
    "Step 4: NEET 2022 admit card will pop up on the screen",
    "Step 5: Verify the details mentioned on the admit card",
    "Step 6 : Download and take it printouts",
  ],

  itemsnotpermittedinsideexamcenter: [
    "Any item like textual material (printed or written), bits of papers, Geometry/Pencil Box, Plastic Pouch, Calculator, Pen, Scale, Writing Pad, Pen Drives, Eraser, Calculator, Log Table, Electronic Pen/Scanner, etc.",
    "Any communication device like Mobile Phone, Bluetooth, Earphones, Microphone, Pager, Health Band, etc.",
    "Other items like Wallet, Goggles, Handbags, Belt, Cap, etc.",
    "Any Watch/Wristwatch, Bracelet, Camera, etc. e) Any ornaments/metallic items.",
    "Any food items opened or packed, water bottle, etc.",
    "Any other item which could be used for unfair means, by hiding communication devices like a microchip, camera, Bluetooth device, etc.",
  ],

  itemsnotpermittedinsideexamcenter2: [
    "Along with NEET admit card 2022, medical aspirants are required to carry certain documents to the examination centre without which entry of candidates will be restricted. These include:",
    "Passport size photograph (same as on the application form),",
    "Valid ID proof [PAN card/Driving license/Voter ID/Passport/Aadhaar Card (with photograph)/Ration card any other government document],",
    "PwD certificate (if applicable),",
    "Proforma of admit card of NEET with a postcard size photo pasted on it",
  ],

  examdayguidelines: [
    "Candidates will have to begin reporting at the examination centre at 11 PM as the reporting time is 12 PM. This one-hour advance reporting is to avoid last-minute rush. The authorities at the exam centre of NEET will stop entry after 1:30, thus, candidates must reach prior to the given deadline",
    "It must be noted that NTA will fix jammers in the area around the examination centre of NTA NEET 2022 to restrict usage of communication devices and curbing malpractices",
    "To avoid cases of impersonation in the entrance examination candidates will be made to go through biometric verification process",
    "Candidates are barred from taking along certain items to the examination centre. Special care must be taken in following these instructions",
    "It must be made sure that candidates sign NEET 2022 admit card and the attendance sheet in front of the invigilator",
    "The ID proof and admit card of NEET will be thoroughly checked by the authorities at the examination centre",
    "Candidates are advised to carefully read all the instructions mentioned on NEET question paper",
    "The passport size photograph which use to be carried by aspirants have to handover to the official at the examination centre, to be pasted on the attendance sheet",
    "Candidates must fill in the details section of the NEET 2022 OMR sheet correctly and in a legible handwriting",
    "Care must be taken by candidates that they do not indulge in any sort of conversation with the person sitting next to them, as this might lead to cancellation of candidature. Also, they will not be allowed to leave the examination hall before the exam concludes for all aspirants",
    "Since the entrance examination will be video graphed by the authorities candidates must cooperate in the same. They must sit upright so that they are clearly captured in the video",
  ],
};
export default NeetAdmitCardDataJson;
