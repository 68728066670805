import React from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box
} from "@mui/material";

const HeaderDynamicTable = ({
    data,
    margin,
    headercolor,
    showheader = true,
    header1,
    subheader1,
    subheader2,
    header2,
    showheader3 = true,
    showsubheader = true,
    header3, gapcolor,
    showheader4 = true,
    header4
}) => {
    return (
        <TableContainer component={Paper} sx={{ margin: margin ?? "0" }}>
            <Table>
                {showheader && (
                    <TableHead>
                        <TableRow sx={{ backgroundColor: "#FF876B" }}>
                            <TableCell
                                sx={{
                                    borderRight: "4px solid white",
                                    color: "#880800",
                                    fontWeight: "600",
                                    textAlign: "start",
                                    fontSize: "16px"
                                }}
                            >
                                {header1}
                            </TableCell>

                            <TableCell
                                colSpan={showheader3 ? 1 : 2}
                                sx={{
                                    color: headercolor ?? "#880800",
                                    borderRight: showheader3 ? "4px solid white" : "none",
                                    fontWeight: "600",
                                    textAlign: "start",
                                    fontSize: "16px"
                                }}
                            >
                                {header2}
                                {showsubheader && (
                                    <Box sx={{ display: "flex", justifyContent: "space-around" }}>
                                        <TableCell
                                            sx={{
                                                color: headercolor ?? "#880800",
                                                borderRight: "none",
                                                fontWeight: "600",
                                                textAlign: "start",
                                                fontSize: "14px",
                                                borderBottom: "none"
                                            }}
                                        >
                                            {subheader1}
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                color: headercolor ?? "#880800",
                                                borderRight: "none",
                                                fontWeight: "600",
                                                textAlign: "start",
                                                fontSize: "14px",

                                                borderBottom: "none"
                                            }}
                                        >
                                            {subheader2}
                                        </TableCell>
                                    </Box>)}


                            </TableCell>
                            {showheader3 && (
                                <TableCell
                                    sx={{

                                        color: "#880800",
                                        fontWeight: "600",
                                        textAlign: "start",
                                        fontSize: "16px"
                                    }}
                                >
                                    {header3}
                                </TableCell>
                            )}
                            {showheader4 && (
                                <TableCell
                                    sx={{

                                        color: "#880800",
                                        fontWeight: "600",
                                        textAlign: "start",
                                        fontSize: "16px"
                                    }}
                                >
                                    {header4}
                                </TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                )}
                {showheader && (
                    <TableBody>
                        <TableRow sx={{ backgroundColor: gapcolor ?? "#252525" }}>
                            <TableCell colSpan={3}></TableCell>
                        </TableRow>
                    </TableBody>
                )}
                <TableBody sx={{ borderCollapse: "collapse" }}>
                    {data?.data.map((item, index) => (
                        <React.Fragment key={item.id}>
                            <TableRow
                                sx={{
                                    width: "100%",
                                    backgroundColor:
                                        Math.floor(index / 3) % 2 === 0
                                            ? index % 3 === 0
                                                ? "#FF876B"
                                                : index % 3 === 1
                                                    ? "#EF5835"
                                                    : "#FF876B"
                                            : index % 3 === 0
                                                ? "#FF876B"
                                                : index % 3 === 1
                                                    ? "#EF5835"
                                                    : "#FF876B"
                                }}
                            >
                                <TableCell
                                    sx={{
                                        borderRight: "4px solid white",
                                        color: "#ffffff",
                                        fontWeight: "600",
                                        textAlign: "start",
                                        verticalAlign: "top",
                                        borderBottom: "none"
                                    }}
                                >
                                    {item.title}
                                </TableCell>
                                <TableCell
                                    sx={{
                                        borderRight: "4px solid white",
                                        color: "#ffffff",
                                        fontWeight: "400",
                                        textAlign: "start",
                                        verticalAlign: "top",
                                        borderBottom: "none"
                                    }}
                                >
                                    {item.value}
                                </TableCell>
                                <TableCell
                                    sx={{
                                        color: "#ffffff",
                                        fontWeight: "400",
                                        padding: "10px 40px",
                                        borderBottom: "none"
                                    }}
                                >
                                    {item.valuetwo}
                                </TableCell>
                                <TableCell
                                    sx={{
                                        color: "#ffffff",
                                        fontWeight: "400",
                                        padding: "10px 40px",
                                        borderBottom: "none"
                                    }}
                                >
                                    {item.valuethree}
                                </TableCell>
                            </TableRow>
                            {(index + 1) % 3 === 0 && index !== item.length - 1 && (
                                <TableRow
                                    key={`gap_${index}`}
                                    style={{ backgroundColor: gapcolor ?? "#252525", border: "none" }}
                                >
                                    <TableCell colSpan={3} style={{ border: "none" }} />
                                </TableRow>
                            )}
                        </React.Fragment>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default HeaderDynamicTable;
