const IITDelhiIncubation = [
    {

        data: [
            { title: "Does the college have an incubation centre?", value: "Yes" },
            { title: "Number of companies set up at the incubation centre in the past 5 years", value: "86" },
           
        ]
    },
 
   

];

export default IITDelhiIncubation;
