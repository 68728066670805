const NeetAcceptingCollegesDataJson = {
    listofcollegesacceptingtheexamscorerank: [
        "medical_colleges",
        "ACPM Medical College, Dhule",
    "ACSR Government Medical College, Nellore",
    "AGMC, Agartala",
    "Ahmedabad Municipal Corporation Medical Education Trust Medical College",
    "AIIMS Bathinda",
    "AIIMS Bilaspur",
    "AIIMS Delhi",
    "AIIMS Hyderabad",
    "AIIMS Raipur",
    "AIIMS Mangalgiri",
    "All India Institute of Medical Sciences",
    "Amrita Vishwa Vidyapeetham",
    "Andhra Medical College, Visakhapatnam",
    "Anugrah Narayan Magadh Medical College, Gaya",
    "Armed Forces Medical College, Pune",
    "Ashwini Rural Medical College, Hospital & Research Centre, Solapur",
    "Assam Medical College, Dibrugarh",
    "Atal Bihari Vajpayee Government Medical College, Vidisha",
    "Autonomous State Medical College, Etah",
    "Autonomous State Medical College, Fatehpur",
    "Autonomous State Medical College, Ghazipur",
    "Autonomous State Medical College, Hardoi",
    "Autonomous State Medical College, Mirzapur",
    "Autonomous State Medical College, Pratapgarh",
    "Autonomous State Medical College, Siddharthnagar",
    "B.J. Medical College, Ahmedabad",
    "B.J. Medical College, Pune",
    "Banaras Hindu University",
    "Bankura Sammilani Medical College, Bankura",
    "Bharati Vidyapeeth Deemed University Medical College & Hospital, Sangli",
    "Bharati Vidyapeeth Deemed University Medical College and Hospital, Sangli",
    "Bharati Vidyapeeth University Medical College, Pune",
    "BIMS, Belgaum",
    "BIMS, Bidar",
    "BJ Medical College, Ahmedabad",
    "BJ Medical College, Pune",
    "BMC, Bangalore",
    "BMC, Sagar",
    "BPS Government Medical College, Sonepat",
    "BRD Medical College, Gorakhpur",
    "Burdwan Medical College, Burdwan",
    "BVD Universal Medical College, Pune",
    "Calcutta National Medical College, Kolkata",
    "Chamarajanagar Institute of Medical Sciences, Chamarajanagar",
    "Christian Medical College",
    "CMC, Chengalpattu",
    "CMC, Coimbatore",
    "College of Medicine and JNM Hospital, WBUHS, Kalyani",
    "CU Shah Medical College, Surendra Nagar",
    "Darbhanga Medical College, Laheriasarai",
    "Diamond Harbour Government Medical College and Hospital",
    "Diphu Medical College & Hospital, Assam",
    "Dr BSA Medical College, Delhi",
    "Dr RPGMC, Tanda",
    "Dr SN Medical College, Jodhpur",
    "Dr VM Medical College, Sholapur",
    "Dr YSP Government Medical College, Nahan",
    "Dr. D Y Patil Medical College, Kolhapur",
    "Dr. DY Patil Medical College, Navi Mumbai",
    "Dr. DY PMCH and Research Centre, Pune",
    "Dr. DYP Education Society, Kolhapur",
    "Dr. Panjabrao Alias Bhausaheb Deshmukh Memorial Medical College, Amravati",
    "Dr. RMLIMS, Lucknow",
    "Dr. S.N. Medical College, Jodhpur",
    "Dr. Shankarrao Chavan Govt. Medical College, Nanded",
    "Dr. Ulhas Patil Medical College & Hospital, Jalgaon",
    "Dr. V.M. Medical College, Sholapur",
    "Dr.Vasantrao Pawar Med. Col. Hosp. & Research Centre,Nasik",
    "ESIC Medical College, Faridabad",
    "ESIC Medical College, Gulbarga",
    "ESIC Medical College, Hyderabad",
    "ESIC PGIMSR, Joka, Kolkata",
    "ESI-MC&PGIMS&R, Bangalore",
    "ESI-PGIMSR, Chennai",
    "Fakhruddin Ali Ahmed Medical College, Barpeta",
    "Gandhi Medical College, Bhopal",
    "Gandhi Medical College, Secunderabad",
    "GCS Medical College, Ahmedabad",
    "GDMC, Dharmapuri",
    "Geetanjali Medical College & Hospital,Udaipur",
    "GGS Medical College, Faridkot",
    "GIMS, Gadag",
    "GIMS, Gulbarga",
    "GMC, Amritsar",
    "GMC, Aurangabad",
    "GMC, Nagpur",
    "GMCB",
    "GMCH, Chandrapur",
    "GMERS Medical College, Dharpur",
    "GMERS Medical College, Gandhinagar",
    "GMERS Medical College, Gotri, Vadodara",
    "GMERS Medical College, Sola, Ahmedabad",
    "GMERS Medical College, Valsad",
    "Goa Medical College, Panaji",
    "Government Doon Medical College, Dehradun",
    "Government institute of Medical Sciences, Kasna",
    "Government M.K.M M.C, Salem",
    "Government Medical College and Hospital, Balangir",
    "Government Medical College and Hospital, Chandigarh",
    "Government Medical College Hamirpur",
    "Government Medical College Kannur",
    "Government Medical College Khaleelwadi",
    "Government Medical College Kota",
    "Government Medical College Ratlam",
    "Government Medical College Siddipet (GMCS)",
    "Government Medical College, Akola",
    "Government Medical College, Anantapuram",
    "Government Medical College, Azamgarh",
    "Government Medical College, Bahraich",
    "Government Medical College, Barmer",
    "Government Medical College, Basti",
    "Government Medical College, Bettiah",
    "Government Medical College, Bharatpur",
    "Government Medical College, Bhilwara",
    "Government Medical College, Churu",
    "Government Medical College, Datia",
    "Government Medical College, Dungarpur",
    "Government Medical College, Ernakulam",
    "Government Medical College, ESI and Hospital, Coimbatore",
    "Government Medical College, Gondia",
    "Government Medical College, Kannauj",
    "Government Medical College, Khandwa",
    "Government Medical College, Kolkata",
    "Government Medical College, Kollam",
    "Government Medical College, Kota",
    "Government Medical College, Kottayam",
    "Government Medical College, Kozhikode",
    "Government Medical College, Latur",
    "Government Medical College, Mahabubangar",
    "Government Medical College, Manjeri",
    "Government Medical College, Miraj",
    "Government Medical College, Nalgonda",
    "Government Medical College, Nanded",
    "Government Medical College, Omandurar",
    "Government Medical College, Palakkad",
    "Government Medical College, Pali",
    "Government Medical College, Patiala",
    "Government Medical College, Rajnandgaon",
    "Government Medical College, Shahdol",
    "Government Medical College, Shahjahanpur",
    "Government Medical College, Surat",
    "Government Medical College, Thiruvananthapuram",
    "Government Medical College, Thrissur",
    "Government Miraj Medical College, Miraj",
    "Government of Medical College and Hospital, Balasore",
    "Government Pudukkottai Medical College, Pudukkottai",
    "Government Sivgangai Medical College, Sivagangai",
    "Govt. Medical College, Aurangabad",
    "Govt. Medical College, Bhavnagar",
    "Govt. Medical College, Nagpur",
    "GR Medical College, Gwalior",
    "Grant Medical College & Sir J.J. Hospital Mumbai",
    "GSVM Medical College, Kanpur",
    "GTMC, Thiruvarur",
    "Gujarat Adani Institute of Medical Sciences, Bhuj",
    "Guntur Medical College, Guntur",
    "Guwahati Medical College, Guwahati",
    "GVMC, Vellore",
    "GVMC, Villupuram",
    "HBTMC and RNCGH, Juhu, Mumbai",
    "HIMS, Hassan",
    "IGMC, Nagpur",
    "IGMC, Shimla",
    "IMS BHU, Varanasi",
    "Indira Gandhi Institute of Medical Sciences, Patna",
    "Indira Gandhi Medical College, Nagpur",
    "Institute of Medical Science and Research, Vidyagiri, Satara",
    "IPGMER, Kolkata",
    "IRT Perundurai Medical College, Perundurai",
    "Jawaharlal Institute of Postgraduate Medical Education & Research",
    "Jawaharlal Nehru Medical College, Bhagalpur",
    "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha",
    "Jhalawar Medical College, Jhalawa",
    "JLN IMS, Imphal",
    "JLN Medical College, Ajmer",
    "Jorhat Medical College and Hospital, Jorhat",
    "K.J. Mehta General Hospital & College of Medical Sciences, Amargadh",
    "Kakatiya Medical College Warangal",
    "Kalpana Chawla Government Medical College, Karnal",
    "Kanyakumari Government Medical College, Asaripallam",
    "KAPVG Medical College, Tiruchirapalli",
    "Karnataka Institute of Medical Sciences, Hubli",
    "Katuri Medical College & Hospital, Guntur",
    "Kempegowda Institute of Medical Sciences",
    "Kerala Medical College, Palakkad",
    "Khaja Bandanawaz Institute of Medical Sciences",
    "Kodagu Institute of Medical Sciences",
    "Koppal Institute of Medical Sciences",
    "Krishna Institute of Medical Sciences",
    "Kurnool Medical College, Kurnool",
    "Lady Hardinge Medical College, New Delhi",
    "Lakhimpur Medical College, Lakhimpur",
    "Lala Lajpat Rai Memorial Medical College, Meerut",
    "Lalit Narayan Mithila University",
    "Lokmanya Tilak Municipal Medical College, Mumbai",
    "Lord Buddha Koshi Medical College and Hospital",
    "M.G.M. Medical College, Jamshedpur",
    "M.G.M. Medical College, Kishanganj",
    "M.G.M. Medical College, Navi Mumbai",
    "M.G.M. Medical College, Wardha",
    "M.K.C.G. Medical College, Berhampur",
    "M.P. Shah Medical College, Jamnagar",
    "Maharaja Institute of Medical Sciences",
    "Maharaja's Institute of Medical Sciences",
    "Maharashtra Institute of Medical Education and Research",
    "Mahatma Gandhi Institute of Medical Sciences",
    "Mahatma Gandhi Memorial Medical College, Indore",
    "Malda Medical College and Hospital, Malda",
    "Malik Deenar Charitable Hospital and Medical Research Centre, Kasaragod",
    "Mandya Institute of Medical Sciences",
    "MGM Medical College & LSK Hospital, Kishanganj",
    "MGM Medical College, Aurangabad",
    "MGM Medical College, Navi Mumbai",
    "MGM Medical College, Wardha",
    "MKCG Medical College and Hospital, Berhampur",
    "MMC, Chennai",
    "MMC, Madurai",
    "Motilal Nehru Medical College, Allahabad",
    "Mysore Medical College",
    "NALMCH, Belgaum",
    "Nalanda Medical College & Hospital, Patna",
    "Narayan Medical College & Hospital, Sasaram",
    "National Institute of Medical Science & Research",
    "Nethra Institute of Medical Sciences",
    "Nil Ratan Sircar Medical College & Hospital, Kolkata",
    "NKP Salve Institute of Medical Sciences",
    "NMC, Bellary",
    "NMC, Kurnool",
    "North Bengal Medical College, Darjeeling",
    "Osmania Medical College, Hyderabad",
    "Padmashree Dr. D.Y. Patil Medical College Hospital and Research Centre",
    "Pandit Raghunath Murmu Medical College, Baripada",
    "Patna Medical College",
    "PGIMER, Chandigarh",
    "Pondicherry Institute of Medical Sciences, Pondicherry",
    "PSG Institute of Medical Sciences",
    "Pt. JNM Medical College, Raipur",
    "R.G. Kar Medical College, Kolkata",
    "Rajah Muthiah Medical College, Annamalainagar",
    "Rajendra Institute of Medical Sciences, Ranchi",
    "Rangaraya Medical College, Kakinada",
    "Rani Durgawati Medical College, Banda",
    "RCSM GMC, Kolhapur",
    "RG Kar Medical College and Hospital",
    "RIMS, Adilabad",
    "RIMS, Raipur",
    "RIMS, Ranchi",
    "RKDF Medical College Hospital and Research Centre",
    "RL Jalappa Medical College",
    "RMH Medical College",
    "RVM Institute of Medical Sciences",
    "S. Nijalingappa Medical College and HSK Hospital",
    "S.S. Institute of Medical Sciences and Research Centre",
    "Sadbhavna Medical College",
    "Sadhu Vaswani Medical College",
    "Sagar Dutta Memorial Medical College and Hospital",
    "Sanjay Gandhi Postgraduate Institute of Medical Sciences",
    "Sanjay Gandhi Memorial Hospital",
    "Sardar Patel Medical College",
    "SASTRA University, Thanjavur",
    "Saveetha Medical College",
    "SCB Medical College and Hospital, Cuttack",
    "Shadan Institute of Medical Sciences",
    "Shyam Shah Medical College",
    "Shri Guru Ram Rai Institute of Medical and Health Sciences",
    "Shri Ram Murti Smarak Institute of Medical Sciences",
    "Shyam Shah Medical College",
    "Sikkim Manipal Institute of Medical Sciences",
    "SIMS, Shimoga",
    "Sir J.J. Group of Hospitals",
    "Sivaganga Government Medical College",
    "SMC, Surat",
    "SMS Medical College, Jaipur",
    "Smt. Kashibai Navale Medical College and General Hospital",
    "Smt. NHL Municipal Medical College",
    "SNMC, Bagalkot",
    "SNMC, Jodhpur",
    "Sri Balaji Medical College and Hospital",
    "Sri Devaraj Urs Medical College",
    "Sri Manakula Vinayagar Medical College",
    "Sri Muthukumaran Medical College",
    "Sri Siddhartha Medical College",
    "Sri Venkateswara Institute of Medical Sciences",
    "Sri Venkateswara Medical College",
    "SRMC, Chennai",
    "SRMS Institute of Medical Sciences",
    "SSMC, Rewa",
    "St. John's Medical College",
    "Stanley Medical College",
    "Subbaiah Institute of Medical Sciences",
    "Subharti Medical College",
    "Sukma Medical College",
    "Surat Municipal Institute of Medical Education and Research",
    "SVIMS, Tirupati",
    "Swami Ramanand Teerth Rural Medical College",
    "Teerthanker Mahaveer Medical College",
    "Thanjavur Medical College",
    "Theni Government Medical College",
    "Tirunelveli Medical College",
    "Trichy SRM Medical College Hospital and Research Centre",
    "Tripura Medical College",
    "Uttarakhand Institute of Medical Sciences",
    "Vardhman Mahavir Medical College",
    "Varun Arjun Medical College and Rohilkhand Hospital",
    "VCSGGIMSR, Garhwal",
    "Vellore Medical College",
    "Vidisha Medical College",
    "VIMSAR, Burla",
    "VMMC & Safdarjung Hospital",
    "Vydehi Institute of Medical Sciences and Research Centre",
    "West Bengal University of Health Sciences",
    "dental_colleges",
    "A B Shetty Memorial Institute of Dental Sciences, Mangalore",
    "Adhiparasakthi Dental College & Hospital, Melmaruvathur",
    "Aman Dental College & Hospital, Rajkot",
    "Aman Dental College, Ghaziabad",
    "Amrita School of Dentistry, Kochi",
    "Army College of Dental Sciences, Secunderabad",
    "Asan Memorial Dental College & Hospital, Chengalpattu",
    "Bangalore Institute of Dental Sciences & Hospital, Bangalore",
    "Bharati Vidyapeeth Deemed University Dental College & Hospital, Sangli",
    "Bharati Vidyapeeth Dental College & Hospital, Navi Mumbai",
    "Bharati Vidyapeeth Dental College & Hospital, Pune",
    "BRS Dental College & Hospital, Panchkula",
    "CDSRI, Guntur",
    "Christian Dental College, Ludhiana",
    "Coorg Institute of Dental Sciences, Virajpet",
    "Dasmesh Institute of Research & Dental Sciences, Faridkot",
    "Dental College, Rajah Muthiah Dental College and Hospital, Annamalainagar",
    "Dr. D. Y. Patil Dental College & Hospital, Navi Mumbai",
    "Dr. D. Y. Patil Dental College & Hospital, Pune",
    "Dr. M. R. Ambedkar Dental College & Hospital, Bangalore",
    "ESIC Dental College & Hospital, New Delhi",
    "Farooqia Dental College & Hospital, Mysore",
    "Goa Dental College & Hospital, Panaji",
    "Govt. Dental College & Hospital, Amritsar",
    "Govt. Dental College & Hospital, Patiala",
    "Govt. Dental College & Hospital, Vijayawada",
    "Govt. Dental College, Medical Campus, Aurangabad",
    "Govt. Dental College, Thiruvananthapuram",
    "Govt. Dental College, Trivandrum",
    "Govt. Dental College, Vellore",
    "GSL Dental College & Hospital, Rajahmundry",
    "Gulbarga Institute of Medical Sciences, Gulbarga",
    "Himachal Dental College, Sundernagar",
    "Indira Gandhi Institute of Dental Sciences, Pondicherry",
    "ITS Dental College, Hospital & Research Centre, Greater Noida",
    "ITS Dental College, Muradnagar",
    "JSS Dental College & Hospital, Mysore",
    "K. D. Dental College & Hospital, Mathura",
    "KLE Society's Institute of Dental Sciences, Bangalore",
    "KLE VK Institute of Dental Sciences, Belgaum",
    "Krishnadevaraya College of Dental Sciences & Hospital, Bangalore",
    "Maharaja Ganga Singh Dental College & Research Centre, Sriganganagar",
    "Manipal College of Dental Sciences, Mangalore",
    "Manipal College of Dental Sciences, Manipal",
    "Mithila Minority Dental College & Hospital, Darbhanga",
    "MNR Dental College & Hospital, Sangareddy",
    "Modern Dental College & Research Centre, Indore",
    "Mysore Dental College & Hospital, Mysore",
    "Nair Hospital Dental College, Mumbai",
    "Narsinhbhai Patel Dental College & Hospital, Visnagar",
    "Navodaya Dental College & Hospital, Raichur",
    "NIMS Dental College, Jaipur",
    "Patna Dental College & Hospital, Patna",
    "People's College of Dental Sciences & Research Centre, Bhopal",
    "Ragas Dental College & Hospital, Chennai",
    "Rajarajeswari Dental College & Hospital, Bangalore",
    "Rishiraj College of Dental Sciences & Research Centre, Bhopal",
    "Sardar Patel Post Graduate Institute of Dental & Medical Sciences, Lucknow",
    "Saveetha Dental College & Hospitals, Chennai",
    "Seema Dental College & Hospital, Rishikesh",
    "Sharda University School of Dental Sciences, Greater Noida",
    "Sree Balaji Dental College & Hospital, Chennai",
    "Sri Balaji Vidyapeeth Deemed University, Mahatma Gandhi Dental College & Hospital, Pondicherry",
    "Sri Hasanamba Dental College & Hospital, Hassan",
    "Sri Rajiv Gandhi College of Dental Sciences & Hospital, Bangalore",
    "Sri Ramachandra Dental College & Hospital, Chennai",
    "Sri Sankara Dental College, Thiruvananthapuram",
    "Sri Venkateswara Dental College & Hospital, Chennai",
    "St. Joseph Dental College & Hospital, Eluru",
    "Subbaiah Dental College, Shimoga",
    "SVS Institute of Dental Sciences, Mahbubnagar",
    "Tagore Dental College & Hospital, Chennai",
    "Tamil Nadu Government Dental College & Hospital, Chennai",
    "The Oxford Dental College & Hospital, Bangalore",
    "Vaidik Dental College & Research Centre, Daman",
    "Vardhman Mahavir Medical College & Safdarjung Hospital, New Delhi",
    "Vasantdada Patil Dental College & Hospital, Sangli",
    "Vyas Dental College & Hospital, Jodhpur",
    "Yenepoya Dental College, Mangalore"
    ],
};
export default NeetAcceptingCollegesDataJson;
