const JeeAiletAboutTableHeader = [
    { id: 1, label: "Format Name", var: "formatName" },
    { id: 2, label: "ShortHand", var: "shortHand" },
    { id: 3, label: "Testing Authority", var: "testingAuthority" },
    { id: 4, label: "Exam Mode", var: "examMode" },
    { id: 5, label: "Frequecy", var: "frequency" },
    { id: 6, label: "Duration", var: "duration" },
    { id: 7, label: "Sections", var: "sections" },
    { id: 8, label: "Languages Available", var: "languagesAvailable" },
    { id: 9, label: "Question Format", var: "questionFormat" },
    { id: 10, label: "Number of Questions", var: "numberofQuestions" },
    { id: 11, label: "Exam Pattern", var: "examPattern" },
    { id: 12, label: "Total Marks", var: "totalMarks" },
    { id: 13, label: "Marking Scheme", var: "markingScheme" },
    { id: 14, label: "No. of Test Cities/ Centres ", var: "noofTestCitiesCentres" },
    { id: 15, label: "Courses Available After Exam", var: "coursesAvailableAfterExam" },
    { id: 16, label: "Website", var: "website" },
    { id: 17, label: "Contact Details", var: "contactDetails" },
];

export default JeeAiletAboutTableHeader;