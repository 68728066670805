const AiimsAboutData = {
    address: "Ansari Nagar, New Delhi, Delhi",
    city: "New Delhi",
    state: "Delhi",
    email: "dean@aiims.edu/ associatedeanacad@aiims.edu",
    established: "1956",
    btechgraduated: "1961",
    head: "Prof M Srinivas",
    designation: "Director",
    type: "Government",
    website: "www.aiims.edu",
    raggingcell: "Yes",
    nationalservice:
        "No",
    complaintcell: "Yes",
    qualityassurance:
        "Yes",
    noofgraduates: "3,501"

};
export default AiimsAboutData;
